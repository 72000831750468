import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { CPBLResource } from '~~apis/resource';

const Stats = () => {
  const toast = useToast();
  const handleUpdateCPBLStats = () => {
    CPBLResource.updateCPBLStats().then(() => {
      toast({
        title: '更新成功！',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  return (
    <Box w="100%">
      <Button onClick={handleUpdateCPBLStats}>更新 CPBL 聯盟三圍（影響 OPS+）</Button>
    </Box>
  );
};

export default Stats;
