import React, { useState, useEffect } from 'react';
import { Box, Select, Spinner } from '@chakra-ui/react';

const NestSelect = ({ nestSelections, shoudUpdate, selectionsUpdated, decisionUpdate, decisionFreeze, styles }) => {
  const [readyStatus, setReadyStatus] = useState(nestSelections.map((selection) => selection.isReady));
  const [selections, setSelections] = useState(nestSelections);
  useEffect(() => {
    if (!shoudUpdate) {
      return;
    }
    setSelections(nestSelections);
    setReadyStatus(nestSelections.map((selection) => selection.isReady));
    selectionsUpdated();
  }, [shoudUpdate, nestSelections, selectionsUpdated, selections]);

  const removeNonDisplay = (allSelections) => {
    const filteredSelections = [];
    for (let index in allSelections) {
      index = parseInt(index);
      if (
        !readyStatus[index] &&
        index !== 0 &&
        (!allSelections[index - 1].isReady || !allSelections[index - 1].target)
      ) {
        break;
      }
      filteredSelections.push(allSelections[index]);
    }

    return filteredSelections;
  };

  return (
    <Box {...styles}>
      {removeNonDisplay(selections).map((selection, index) => {
        if (!readyStatus[index]) {
          if (index !== 0 && (!selections[index - 1].isReady || !selections[index - 1].target)) {
            return <></>;
          }
          return <Spinner key={`spinner-${selection.placeholder}`} py="1" />;
        }

        return (
          <Select
            key={`select-${selection.placeholder}`}
            placeholder={selection.placeholder}
            onChange={decisionUpdate(index)}
            isDisabled={decisionFreeze}
            py="1"
          >
            {selection.options.map((option) => (
              <option key={`${index}-${selection.placeholder}-${option.value}`} value={option.value}>
                {option.display}
              </option>
            ))}
          </Select>
        );
      })}
    </Box>
  );
};

export default NestSelect;
