import React, { useState } from 'react';
import { HStack, Icon, Box, Alert } from '@chakra-ui/react';
import { BsImageFill } from 'react-icons/bs';
import { MediaResource } from '~~apis/resource';

const UploadMedia = ({ updateMedia, iconSize }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileOnChange = async (e) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setIsUploading(true);
    setMessage('上傳中...');

    try {
      const file = e.target.files[0];
      const result = await MediaResource.uploadMedia({ file });
      updateMedia(result.data);
      setMessage('');
    } catch (e) {
      setIsError(true);
      setMessage(e.toString());
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <HStack spacing={2}>
      <Box borderRadius="md" border="1px" borderColor="gray.200" boxShadow="1px 1px gray" p={2}>
        <label style={{ cursor: 'pointer' }}>
          <input
            type="file"
            accept="image/*"
            multiple={false}
            style={{ display: 'none' }}
            onChange={handleFileOnChange}
            disabled={isUploading}
          />
          <Icon as={BsImageFill} boxSize={!!iconSize ? iconSize : 10} />
        </label>
      </Box>
      {message && (
        <Alert status={isUploading ? 'warning' : isError ? 'error' : 'info'} fontSize="sm">
          {message}
        </Alert>
      )}
    </HStack>
  );
};

export default UploadMedia;
