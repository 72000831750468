import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  createNews: false,
  updateNews: false,
  deleteNews: false,
};

const News = {
  createNews: ({ seasonUniqid, gameUniqid, news }) => {
    if (debugStatus.createNews) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          game_uniqid: 'game_uniqid',
          news: NEWS_MOCK_DATA,
          link: 'https://www.rebas.tw/season/season_uniqid?game=game_uniqid',
        },
        error: false,
        message: 'Create news successfully',
      });
    }

    return ApiService.post(`/api/seasons/${seasonUniqid}/games/${gameUniqid}/news`, {
      data: news,
    });
  },
  updateNews: ({ seasonUniqid, gameUniqid, news }) => {
    if (debugStatus.updateNews) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        date: {
          game_uniqid: 'game_uniqid',
          news: NEWS_MOCK_DATA,
          link: 'https://www.rebas.tw/season/season_uniqid?game=game_uniqid',
        },
        error: false,
        message: 'Update news successfully',
      });
    }

    return ApiService.put(`/api/seasons/${seasonUniqid}/games/${gameUniqid}/news`, {
      data: news,
    });
  },
  deleteNews: ({ seasonUniqid, gameUniqid }) => {
    if (debugStatus.deleteNews) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        date: {},
        error: false,
        message: 'Delete news successfully',
      });
    }

    return ApiService.delete(`/api/seasons/${seasonUniqid}/games/${gameUniqid}/news`);
  },
};

export default News;

const NEWS_MOCK_DATA = {
  title: '曲球戰術失靈 華江連丟6BB保送竹中B闖冠軍戰',
  content:
    '華江賽前喊話要力阻冠軍成風城內戰，昨日用大量曲球封鎖了成功打線，想在今日重施故技，但竹中B打者的都能確實選掉，3局上更讓華江投手連投6個四壞，該局只靠1支安打就奪5分。而華江昨天十分挺投手的守備，今日再被打回原樣，6次失誤讓竹中B輕鬆以15比1闖進冠軍戰。\r\n\r\n華江今日派出左投彭品儒先發，一上場就用大幅度曲球竄進好球帶，但竹中B打者冷靜選球，開路先鋒李昀崢率先安打上壘，又靠失誤、保送繼承滿壘，接者5棒陳守恩把握機會，擊出深遠右外野三壘打，一次送回壘上所有跑者。2局上竹中B又添3分。\r\n\r\n到了3局上華江彭品儒繼續用大曲球進攻打者，但好球率過低的情況下，讓竹中B打者不費吹灰之力就站上壘包，3個保送後華江換上許哲睿，但控球不佳又再連投3次保送，變提油救火，此時竹中B卓建均適時一棒又再清空壘包，雖然許哲睿之後連飆2K結束這半局，但已被竹中B再攻下5分。\r\n\r\n華江打線在首局靠2安打加上陳厚安的高飛犧牲打奪1分，但之後在竹中B穩定的守備之下，連得點圈都無法站上。在另一地竹中A也順利晉級的情況下，只能看著今年夏聯冠軍成新竹囊中物。',
  image_path: '/user/erondu/240x160',
  image_url: 'https://source.unsplash.com/user/erondu/240x160',
  preview_image_path: '/user/erondu/240x160',
  preview_image_url: 'https://source.unsplash.com/user/erondu/240x160',
  source: {
    author: '楊乂豪',
    city: '新北',
    interviewer: '楊乂豪',
    photographer: '錢維億',
  },
};
