import React, { useEffect, useState, Fragment } from 'react';
import { Box, VStack, Flex, Button, Text, SimpleGrid, Table, Thead, Tr, Th, Td, Tbody } from '@chakra-ui/react';
import { FormControl, FormLabel, Input, Heading, Select } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import CommonUtils from '~~utils/CommonUtils';
import { LoadingAlertDialog } from '~~components';
import { FirstBaseResource } from '~~apis/resource';
import { INITIAL_GAME_DATA } from '../Consts';
import { ballInResult, staticResult, dropPoints, trajectoryMapping } from './PbPUpload';

const PbPDisplayColumns = [
  {
    display: '局數',
    column: 'inning',
    editable: false,
  },
  {
    display: '上下半',
    column: 'inningFrame',
    editable: false,
  },
  {
    display: '投手',
    column: 'pitcher',
    editable: false,
  },
  {
    display: '打者',
    column: 'batter',
    editable: false,
  },
  {
    display: '攻擊輪',
    column: 'PARound',
    editable: false,
  },
  {
    display: '棒次',
    column: 'PAOrder',
    editable: false,
  },
  {
    display: '客場分數',
    column: 'away_score',
    editable: true,
  },
  {
    display: '主場分數',
    column: 'home_score',
    editable: true,
  },
  {
    display: '開始出局數',
    column: 'start_outs',
    editable: true,
  },
  {
    display: '當下出局數',
    column: 'outs',
    editable: true,
  },
  {
    display: '壘包（一壘+1；二壘+2；三壘+4）',
    column: 'bases',
    editable: true,
  },
  {
    display: '得分棒次（無為 0）',
    column: 'R',
    editable: true,
  },
  {
    display: '得分為責失（無留空；是 1；否 0）',
    column: 'is_ER',
    editable: true,
  },
  {
    display: '落點（1-9,34,46,56）',
    column: 'drop_point',
    editable: true,
  },
  {
    display: '彈道（G,L,F）',
    column: 'trajectory',
    editable: true,
  },
  {
    display: '打點',
    column: 'RBI',
    editable: true,
  },
  {
    display: '打席結果',
    column: 'result',
    editable: true,
  },
];

const getPAColumn = (PA) => {
  return {
    inning: PA.inning,
    inningFrame: PA.inning_frame,
    pitcher: `${PA.pitcher.name} #${PA.pitcher.number}`,
    batter: `${PA.batter.name} #${PA.batter.number}`,
    PARound: PA.PA_round,
    PAOrder: PA.PA_order,
    away_score: PA.away_score,
    home_score: PA.home_score,
    start_outs: PA.start_outs,
    outs: PA.outs,
    bases: PA.bases,
    R: PA.R,
    is_ER: PA.is_ER ? '1' : PA.R > 0 ? '0' : '',
    drop_point: PA.drop_point,
    trajectory: PA.trajectory,
    RBI: PA.RBI,
    result: PA.result,
  };
};

const EditGame = ({ team, game, locations, cancelEdit, completedEdit }) => {
  const toast = useToast();

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const [pbp, setPbP] = useState([]);
  const [opponentPbP, setOpponentPbP] = useState([]);
  const [pbpEditIndex, setPbPEditIndex] = useState(-1);
  const [pbpEditOpponent, setPbPEditOpponent] = useState(false);

  const { register, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    reset({
      opponent_team_name: game.opponent_team_name,
      started_at: game.started_at,
      tags: [],
      info: { ...game.info, awayScores: game.info.away_scores.join(','), homeScores: game.info.home_scores.join(',') },
      pbp: [],
    });
  }, [game.info, game.opponent_team_name, game.started_at, reset]);

  useEffect(() => {
    setPbP(game.pbp.map((PA) => getPAColumn(PA)));
    setOpponentPbP(game.opponent_pbp.map((PA) => getPAColumn(PA)));
  }, [game]);

  const errorDisplay = (message, withStar = true) => {
    if (!withStar) {
      return (
        <Text fontSize="xs" color="red.500">
          {message}
        </Text>
      );
    }

    return (
      <Text fontSize="xs" color="red.500">
        * {message}
      </Text>
    );
  };

  const requiredDisplay = () => <Text color="red.500"> *</Text>;

  const back = () => {
    reset(INITIAL_GAME_DATA);
    cancelEdit();
  };

  const onSubmit = (data) => {
    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');

    FirstBaseResource.updateGameInfo({
      teamUniqid: team.uniqid,
      gameUniqid: game.uniqid,
      started_at: data.started_at,
      info: {
        location_uniqid: data.info.location_uniqid,
        end_outs: data.info.end_outs,
      },
    })
      .then(() => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage('更新成功！');
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      completedEdit();
    }
  };

  const pbpEdit = (index, isOpponent) => () => {
    setPbPEditIndex(index);
    setPbPEditOpponent(isOpponent);

    if (index < 0) {
      return;
    }

    toast({
      description: '這邊更新打席資訊沒辦法幫忙檢查前後邏輯，請保重',
      status: 'warning',
      duration: 5000,
      isClosable: true,
    });
  };

  const handlePbPEditSubmit = () => {
    let PA = game.pbp[pbpEditIndex];
    if (pbpEditOpponent) {
      PA = game.opponent_pbp[pbpEditIndex];
    }

    if (!PA) {
      return;
    }

    const updatableColumns = {
      away_score: PA.away_score,
      home_score: PA.home_score,
      start_outs: PA.start_outs,
      outs: PA.outs,
      bases: PA.bases,
      R: PA.R,
      is_ER: PA.is_ER,
      drop_point: PA.drop_point,
      trajectory: PA.trajectory,
      RBI: PA.RBI,
      result: PA.result,
    };

    const editColumns = document.querySelectorAll('.pbp-editing-columns');
    editColumns.forEach((column) => {
      let value = parseInt(column.value);
      switch (column.name) {
        case 'drop_point':
        case 'trajectory':
        case 'result':
          value = column.value;
          break;
        case 'is_ER':
          value = value === 1;
          break;
        default:
          break;
      }
      updatableColumns[column.name] = value;
    });

    const errorToast = (message) => {
      toast({
        description: message,
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    };

    if (Number.isNaN(updatableColumns.away_score)) {
      return errorToast('客場分數格式錯誤');
    }
    if (Number.isNaN(updatableColumns.home_score)) {
      return errorToast('客場分數格式錯誤');
    }
    if (
      Number.isNaN(updatableColumns.start_outs) ||
      updatableColumns.start_outs < 0 ||
      updatableColumns.start_outs > 2
    ) {
      return errorToast('開始出局數格式錯誤');
    }
    if (Number.isNaN(updatableColumns.outs) || updatableColumns.outs < 0 || updatableColumns.outs > 2) {
      return errorToast('開始出局數格式錯誤');
    }
    if (Number.isNaN(updatableColumns.bases) || updatableColumns.bases < 0 || updatableColumns.bases > 7) {
      return errorToast('壘包格式錯誤');
    }
    if (Number.isNaN(updatableColumns.R) || updatableColumns.R < 0 || updatableColumns.R > 9) {
      return errorToast('得分格式錯誤（R；責失）');
    } else if (updatableColumns.R === 0 && updatableColumns.is_ER) {
      return errorToast('得分格式錯誤（R；責失）');
    }

    if (updatableColumns.result === '' || staticResult.includes(updatableColumns.result)) {
      if (updatableColumns.drop_point !== '' || updatableColumns.trajectory !== '') {
        return errorToast('打席結果格式錯誤（結果；落點；彈道）');
      }
    }

    if (!ballInResult.includes(updatableColumns.result) && !staticResult.includes(updatableColumns.result)) {
      return errorToast('打席結果格式錯誤（結果；落點；彈道）');
    }

    if (ballInResult.includes(updatableColumns.result)) {
      if (
        !dropPoints.includes(updatableColumns.drop_point) ||
        !Object.values(trajectoryMapping).includes(updatableColumns.trajectory)
      ) {
        return errorToast('打席結果格式錯誤（結果；落點；彈道）');
      }
    }

    FirstBaseResource.updateGamePA({
      teamUniqid: team.uniqid,
      gameUniqid: game.uniqid,
      PA: {
        seq: pbpEditIndex,
        inning: PA.inning,
        inning_frame: PA.inning_frame,
        PA_round: PA.PA_round,
        PA_order: PA.PA_order,
      },
      updatableColumns,
    })
      .then(() => {
        toast({
          description: '更新成功',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        const targetPbP = pbpEditOpponent ? opponentPbP : pbp;
        const updatePbPFunction = pbpEditOpponent ? setOpponentPbP : setPbP;
        const newPbP = targetPbP.map((targetPA, index) => {
          if (index !== pbpEditIndex) {
            return targetPA;
          }

          return getPAColumn({
            ...PA,
            ...updatableColumns,
          });
        });
        updatePbPFunction(newPbP);
        pbpEdit(-1, true)();
      })
      .catch((e) => {
        toast({
          title: '更新失敗',
          description: e,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const getPbPEditRow = (PA) => (
    <Tr backgroundColor="teal.100">
      {PbPDisplayColumns.map((column) => {
        if (!column.editable) {
          return (
            <Th key={column.column} whiteSpace="nowrap">
              {PA[column.column]}
            </Th>
          );
        }

        return (
          <Th key={column.column} minW="2rem">
            <Input
              className="pbp-editing-columns"
              size="sm"
              type="text"
              padding="0 0.1rem"
              name={column.column}
              defaultValue={PA[column.column]}
            />
          </Th>
        );
      })}
      <Th>
        <Button size="xs" colorScheme="blue" onClick={handlePbPEditSubmit}>
          更新
        </Button>
        <Button size="xs" colorScheme="transparent" onClick={pbpEdit(-1, false)}>
          取消
        </Button>
      </Th>
    </Tr>
  );

  return (
    <Box>
      <Flex alignItems="center">
        <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={back}>
          取消
        </Button>
      </Flex>
      <Heading
        fontSize="lg"
        padding="0.5rem 0"
      >{`${team.name}(${team.full_name}) ${game.started_at} vs ${game.opponent_team_name} 賽事更新`}</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} alignItems="start">
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">對手名稱</Flex>
            </FormLabel>
            <Input size="sm" type="text" name="opponent_team_name" ref={register()} disabled />
          </FormControl>
          <SimpleGrid w="100%" columns="2">
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  開賽時間{requiredDisplay()}
                  {errors.started_at && errorDisplay('開賽時間為必填且格式需符合 yyyy/MM/dd hh:mm')}
                </Flex>
              </FormLabel>
              <Input
                type="text"
                name="started_at"
                ref={register({
                  required: true,
                  pattern: CommonUtils.dateTimeStringPattern,
                })}
                size="sm"
              />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  地點{requiredDisplay()}
                  {errors.info?.location_uniqid && errorDisplay('地點為必填', false)}
                </Flex>
              </FormLabel>
              <Select name="info.location_uniqid" placeholder="請選擇" ref={register()} size="sm">
                {locations.map((location) => (
                  <option key={location.uniqid} value={location.uniqid}>
                    {`${location.name}`}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">主客場</Flex>
              </FormLabel>
              <Select name="info.side" placeholder="請選擇" ref={register()} size="sm" disabled>
                <option value="AWAY">客場（先攻）</option>
                <option value="HOME">主場（後攻）</option>
              </Select>
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">總局數</Flex>
              </FormLabel>
              <Input size="sm" type="text" name="info.innings" ref={register()} disabled />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">客場各局比分（各局請以 , 區分）</Flex>
              </FormLabel>
              <Input size="sm" type="text" name="info.awayScores" ref={register()} disabled />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">主場各局比分（各局請以 , 區分）</Flex>
              </FormLabel>
              <Input size="sm" type="text" name="info.homeScores" ref={register()} disabled />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  比賽結束出局數（通常是 3 ，除非是提前結束或裁定之類沒打完）{requiredDisplay()}
                  {errors.info?.end_outs && errorDisplay('比賽結束出局數格式不正確', false)}
                </Flex>
              </FormLabel>
              <Input
                size="sm"
                type="number"
                name="info.end_outs"
                ref={register({ required: true, valueAsNumber: true, min: 0, max: 3 })}
              />
            </FormControl>
          </SimpleGrid>
          <Flex>
            <Button type="submit">確認更新</Button>
          </Flex>
        </VStack>
      </form>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={'更新賽事'}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
      <Heading fontSize="md" padding="0.5rem 0">{`${team.name} 打席列表`}</Heading>
      <Table variant="simple" size="sm" display="block" w="unset" overflowX="auto">
        <Thead>
          <Tr>
            {PbPDisplayColumns.map((column) => (
              <Th key={column.display}>{column.display}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {pbp.map((PA, index) => {
            if (pbpEditIndex === index && pbpEditOpponent === false) {
              return <Fragment key={index}>{getPbPEditRow(PA)}</Fragment>;
            }

            return (
              <Tr key={index}>
                {PbPDisplayColumns.map((column) => (
                  <Td key={column.column} whiteSpace="nowrap">
                    {PA[column.column]}
                  </Td>
                ))}
                <Th>
                  <Button size="xs" colorScheme="orange" onClick={pbpEdit(index, false)}>
                    編輯
                  </Button>
                </Th>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Heading fontSize="md" padding="0.5rem 0">{`${game.opponent_team_name} 打席列表`}</Heading>
      <Table variant="simple" size="sm" display="block" w="unset" overflowX="auto">
        <Thead>
          <Tr>
            {PbPDisplayColumns.map((column) => (
              <Th key={column.display}>{column.display}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {opponentPbP.map((PA, index) => {
            if (pbpEditIndex === index && pbpEditOpponent === true) {
              return <Fragment key={index}>{getPbPEditRow(PA)}</Fragment>;
            }

            return (
              <Tr key={index}>
                {PbPDisplayColumns.map((column) => (
                  <Td key={column.column} whiteSpace="nowrap">
                    {PA[column.column]}
                  </Td>
                ))}
                <Th>
                  <Button size="xs" colorScheme="orange" onClick={pbpEdit(index, true)}>
                    編輯
                  </Button>
                </Th>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default EditGame;
