import React, { useEffect } from 'react';
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';

const WebSiteStats = () => {
  useEffect(() => {
    // return;

    const script = document.createElement('script');
    script.innerHTML = `
      var VIEW_ID = '222374326';
      function queryReports() {
        gapi.client.request({
          path: '/v4/reports:batchGet',
          root: 'https://analyticsreporting.googleapis.com/',
          method: 'POST',
          body: {
            reportRequests: [
              {
                viewId: VIEW_ID,
                dateRanges: [
                  {
                    startDate: '30daysAgo',
                    endDate: 'today'
                  },
                  {
                    startDate: '60daysAgo',
                    endDate: 'today'
                  },
                ],
                metrics: [
                  {
                    expression: 'ga:pageviews'
                  }
                ]
              }
            ]
          }
        }).then(displayResults, console.error.bind(console));
      }

      function displayResults(response) {
        const totalPageViews = response.result.reports[0].data.totals;
        const oneMonth = totalPageViews[0].values[0];
        const twoMonth = totalPageViews[1].values[0];
        document.getElementById('web-site-stats-pageviews-one-month').innerHTML = oneMonth;
        document.getElementById('web-site-stats-pageviews-two-month').innerHTML = '/' + twoMonth;
      }
    `;
    document.body.appendChild(script);

    const callScript = document.createElement('script');
    callScript.src = 'https://apis.google.com/js/client:platform.js';
    callScript.async = true;
    document.body.appendChild(callScript);
  }, []);

  return (
    <Box padding="1rem 0">
      <Flex alignItems="center" padding="0 0 0.5rem 0">
        <Heading size="md" padding="0 2rem 0 0">
          網站數據
        </Heading>
        <p className="g-signin2" data-onsuccess="queryReports"></p>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap="0.5rem">
        <Box border="1px solid gray" padding="1rem" textAlign="center">
          <Text>近 30/60 天網站瀏覽量</Text>
          <Heading size="lg" id="web-site-stats-pageviews-one-month">
            讀取中...
          </Heading>
          <Text id="web-site-stats-pageviews-two-month"></Text>
        </Box>
      </Grid>
    </Box>
  );
};

export default WebSiteStats;
