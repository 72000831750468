import React, { useState, useEffect } from 'react';
import { Button, IconButton, VStack, Alert, AlertIcon, Spinner, HStack, Select, Text, Kbd } from '@chakra-ui/react';
import { Input, InputGroup, InputLeftAddon, InputRightAddon, useDisclosure, Modal } from '@chakra-ui/react';
import { ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { EditIcon, AddIcon } from '@chakra-ui/icons';
import { find, propEq } from 'ramda';
import CommonUtils from '~~utils/CommonUtils';
import { DatePicker, GameNode, GameDelete } from '~~components';
import { SeasonResource, TeamResource } from '~~apis/resource';

const STATUS_DISPLAY = {
  SCHEDULED: '尚未開始',
  PLAYING: '進行中',
  FINISHED: '已完賽',
};

const GameSimpleCUModal = ({ game, seasonUniqid, games, groups, teams, reloadRequired, customIcon }) => {
  const isNew = !game;
  if (isNew) {
    game = {
      uniqid: 'N',
      seq: '？',
      away: {
        team: '',
      },
      home: {
        team: '',
      },
      group: 'NONE',
      info: {
        status: 'SCHEDULED',
        nodes: {
          base: [],
          link: [],
        },
        time: {
          scheduled_start_at: '',
        },
      },
    };
  }

  const [groupOptions, setGroupOptions] = useState(groups);
  useEffect(() => {
    if (groupOptions.length === 0) {
      SeasonResource.getSeasonGroups({ seasonUniqid }).then((data) => {
        const responseGroups = data.data;
        setGroupOptions([{ name: 'NONE', priority: 0 }].concat(responseGroups));
      });
    }
    // eslint-disable-next-line
  }, []);

  const [teamOptions, setTeamOptions] = useState(teams);
  useEffect(() => {
    if (teams.length === 0) {
      TeamResource.getSeasonTeams({ seasonUniqid }).then((data) => {
        setTeamOptions(data.data);
      });
    }
    // eslint-disable-next-line
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [seq, setSeq] = useState(game.seq);
  const [away, setAway] = useState(game.away.team);
  const [home, setHome] = useState(game.home.team);
  const [group, setGroup] = useState(game.group);
  const [nodes, setNodes] = useState(game.info.nodes);
  const [scheduledStartAt, setScheduledStartAt] = useState(
    CommonUtils.objectifyDate(game.info.time.scheduled_start_at),
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitAble, setSubmitable] = useState(false);

  useEffect(() => {
    if (game.info.status !== 'SCHEDULED' && (!away || !home)) {
      return setSubmitable(false);
    }
    if (!CommonUtils.stringtifyDate(scheduledStartAt)) {
      return setSubmitable(false);
    }

    setSubmitable(true);
  }, [away, game.info.status, home, scheduledStartAt]);

  const handleSeqUpdate = (e) => {
    setSeq(e.target.value);
  };

  const handleStateUpdate = (setFunction) => (e) => {
    setFunction(e.target.value);
  };

  const handleDatePickerChange = (e) => {
    const value = e.target.value;
    const type = e.target.name;
    setScheduledStartAt({ ...scheduledStartAt, [type]: value });
  };

  const addNode = (type) => () => {
    let reverseType = 'base';
    if (type === 'base') {
      reverseType = 'link';
    }

    nodes[type].push({ type: '', side: '', [reverseType]: { game_uniqid: '', game_seq: '' } });
    setNodes(JSON.parse(JSON.stringify(nodes)));
  };

  const deleteNode = (type, index) => () => {
    nodes[type].splice(index, 1);
    setNodes(JSON.parse(JSON.stringify(nodes)));
  };

  const handleNodeChange = (type, index) => (e) => {
    let reverseType = 'base';
    if (type === 'base') {
      reverseType = 'link';
    }

    const name = e.target.name;
    const value = e.target.value;
    if (name === 'game') {
      const selectedGame = find(propEq('uniqid', value))(games);
      nodes[type][index][reverseType] = {
        game_uniqid: selectedGame.uniqid,
        game_seq: selectedGame.seq,
      };
    } else {
      nodes[type][index][name] = value;
    }

    setNodes(JSON.parse(JSON.stringify(nodes)));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    SeasonResource.updateGameSchedule({
      seasonUniqid,
      gameUniqid: game.uniqid,
      away,
      home,
      group,
      nodes,
      scheduledStartAt,
      seq,
    })
      .then(() => {
        reloadRequired();
        onClose();
      })
      .catch((e) => {
        setErrorMessage(e.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {customIcon ? (
        customIcon(onOpen)
      ) : isNew ? (
        <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen} size="sm">
          增加賽事
        </Button>
      ) : (
        <IconButton icon={<EditIcon />} colorScheme="orange" onClick={onOpen} size="sm" />
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              {isNew ? '新增' : '編輯'}賽事資訊 <Kbd>G{seq}</Kbd> - <Kbd>{STATUS_DISPLAY[game.info.status]}</Kbd>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={8} align="start">
                <InputGroup size="sm">
                  <InputLeftAddon children="流水號 G" />
                  <Input borderRadius="0" placeholder="請填入數字" value={seq} onChange={handleSeqUpdate} />
                  <InputRightAddon children="賽季關閉自動編號更動方有效" />
                </InputGroup>
                <HStack spacing={4} w="100%" justify="space-around">
                  <HStack spacing={2}>
                    <VStack align="center">
                      <Text>客場</Text>
                    </VStack>
                    <VStack align="center">
                      <Select onChange={handleStateUpdate(setAway)} value={away}>
                        <option value="">未定（限尚未開始的比賽）</option>
                        {teamOptions.map((team) => (
                          <option key={team.name} value={team.name}>
                            {team.name}
                          </option>
                        ))}
                      </Select>
                    </VStack>
                  </HStack>
                  <HStack spacing={2}>
                    <VStack align="center">
                      <Select onChange={handleStateUpdate(setHome)} value={home}>
                        <option value="">未定（限尚未開始的比賽）</option>
                        {teamOptions.map((team) => (
                          <option key={team.name} value={team.name}>
                            {team.name}
                          </option>
                        ))}
                      </Select>
                    </VStack>
                    <VStack align="center">
                      <Text>主場</Text>
                    </VStack>
                  </HStack>
                </HStack>
                <HStack spacing={4}>
                  <VStack spacing={8} align="center">
                    <Text>分類</Text>
                    <Text>預計開始時間</Text>
                  </VStack>
                  <VStack spacing={8} align="start">
                    <Select variant="filled" value={group} onChange={handleStateUpdate(setGroup)}>
                      {groupOptions.map((group) => (
                        <option key={group.name} value={group.name}>
                          {group.name}
                        </option>
                      ))}
                    </Select>
                    <DatePicker
                      handleDatePickerChange={handleDatePickerChange}
                      values={scheduledStartAt}
                      styleProps={{ spacing: 10 }}
                    />
                  </VStack>
                </HStack>
                <GameNode
                  gameUniqid={game.uniqid}
                  nodes={nodes}
                  games={games}
                  handleNodeChange={handleNodeChange}
                  deleteNode={deleteNode}
                  addNode={addNode}
                />
                {errorMessage && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              {isLoading ? (
                <Spinner />
              ) : (
                <HStack w="100%" justify="space-between">
                  {isNew ? (
                    <div></div>
                  ) : (
                    <GameDelete seasonUniqid={seasonUniqid} game={game} reloadRequired={reloadRequired} />
                  )}
                  <HStack spacing={2}>
                    <Button mr={3} variant="ghost" onClick={onClose}>
                      取消
                    </Button>
                    <Button colorScheme="green" isDisabled={!submitAble} onClick={handleSubmit}>
                      送出
                    </Button>
                  </HStack>
                </HStack>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default GameSimpleCUModal;
