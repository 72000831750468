import React from 'react';
import { useToast } from '@chakra-ui/react';
import XLSX from 'xlsx';
import { find, findIndex, findLastIndex } from 'ramda';

const playerListSheetName = '球員名單';
const PAStatusSheetName = '打席資訊';
const PAResultSheetName = '打席結果';
const PABallCountSheetName = '打席用球';

export const ballInResult = ['1B', '2B', '3B', 'HR', 'E', 'FC', 'GO', 'FO', 'GIDP', 'SH', 'SF'];
export const staticResult = ['uBB', 'HBP', 'IBB', 'SO', 'IR', 'IH', 'ID'];
export const dropPoints = ['1', '2', '3', '4', '5', '6', '34', '46', '56', '7', '78', '8', '89', '9', 'x'];
export const trajectoryMapping = { '1': 'G', '2': 'F', '3': 'L', x: 'x' };

const getCellValue = (cell) => {
  if (!cell?.v && cell?.v !== 0) {
    return '';
  }
  return cell.v.toString();
};

const PbPUpload = ({
  targetTeamName,
  opponentTeamName,
  inningFrame,
  setPbp,
  setFielders,
  setOpponentPbp,
  setOpponentFielders,
}) => {
  const toast = useToast();
  const errorToast = (message) => {
    toast({
      title: message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const handleFileOnChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length !== 1) {
      return;
    }

    if (!targetTeamName || !opponentTeamName || !inningFrame) {
      errorToast('欄位未輸入齊全');
      return;
    }

    toast({
      title: '讀取中，請稍後',
      status: 'info',
      duration: 1000,
      isClosable: true,
    });

    const file = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      let includeOpponent = false;
      const side = inningFrame === 'BOTTOM' ? 'HOME' : 'AWAY';
      const opponentInningFrame = inningFrame === 'BOTTOM' ? 'TOP' : 'BOTTOM';

      const players = workbook.Sheets[playerListSheetName];
      if (!players) {
        errorToast(`格式錯誤，應包含 ${playerListSheetName} 表單`);
        return;
      }

      // 單面版
      let PAStatus = workbook.Sheets[PAStatusSheetName];
      let PAResult = workbook.Sheets[PAResultSheetName];
      let PABallCounts = workbook.Sheets[PABallCountSheetName];
      let opponentPAStatus = null;
      let opponentPAResult = null;
      let opponentPABallCounts = null;

      // 雙面版
      const twoSideSheet = {
        away: {
          PAStatus: workbook.Sheets[`AWAY-${PAStatusSheetName}`],
          PAResult: workbook.Sheets[`AWAY-${PAResultSheetName}`],
          PABallCounts: workbook.Sheets[`AWAY-${PABallCountSheetName}`],
        },
        home: {
          PAStatus: workbook.Sheets[`HOME-${PAStatusSheetName}`],
          PAResult: workbook.Sheets[`HOME-${PAResultSheetName}`],
          PABallCounts: workbook.Sheets[`HOME-${PABallCountSheetName}`],
        },
      };
      if (!PAStatus || !PAResult || !PABallCounts) {
        if (
          !twoSideSheet.away.PAStatus ||
          !twoSideSheet.away.PAResult ||
          !twoSideSheet.away.PABallCounts ||
          !twoSideSheet.home.PAStatus ||
          !twoSideSheet.home.PAResult ||
          !twoSideSheet.home.PABallCounts
        ) {
          errorToast(`格式錯誤`);
          errorToast(`單面版應包含 ${PAStatusSheetName} ${PAResultSheetName} ${PABallCountSheetName} 三張表單`);
          errorToast(
            `雙面版應包含 AWAY-${PAStatusSheetName} AWAY-${PAResultSheetName} AWAY-${PABallCountSheetName} 與 HOME- 共六張表單`,
          );
          return;
        }
        includeOpponent = true;
        PAStatus = twoSideSheet[side.toLowerCase()].PAStatus;
        PAResult = twoSideSheet[side.toLowerCase()].PAResult;
        PABallCounts = twoSideSheet[side.toLowerCase()].PABallCounts;

        const opponentSide = side === 'AWAY' ? 'HOME' : 'AWAY';
        opponentPAStatus = twoSideSheet[opponentSide.toLowerCase()].PAStatus;
        opponentPAResult = twoSideSheet[opponentSide.toLowerCase()].PAResult;
        opponentPABallCounts = twoSideSheet[opponentSide.toLowerCase()].PABallCounts;
      }

      const { targetPlayers, opponentPlayers, error: errorRegistry } = registerPlayer({
        sheet: players,
        targetTeamName,
        opponentTeamName,
      });
      if (errorRegistry) {
        return;
      }

      const { pbp: pbpV1, error: errorStatus } = parsePAStatus({
        sheet: PAStatus,
        inningFrame,
        targetPlayers,
        opponentPlayers,
      });
      if (errorStatus) {
        return;
      }

      const { pbp: pbpV2, error: errorResult } = parsePAResult({ sheet: PAResult, pbp: pbpV1 });
      if (errorResult) {
        return;
      }

      const { pbp: pbpV3, opponentFielders, error: errorBallCounts } = parsePABallCountsWithFielding({
        sheet: PABallCounts,
        pbp: pbpV2,
        opponentPlayers,
      });
      if (errorBallCounts) {
        return;
      }

      setPbp(pbpV3);
      setFielders([]);
      setOpponentPbp([]);
      setOpponentFielders(opponentFielders);

      if (includeOpponent) {
        const { pbp: opponentPbpV1, error: opponentErrorStatus } = parsePAStatus({
          sheet: opponentPAStatus,
          inningFrame: opponentInningFrame,
          targetPlayers: opponentPlayers,
          opponentPlayers: targetPlayers,
        });
        if (opponentErrorStatus) {
          return;
        }

        const { pbp: opponentPbpV2, error: opponentErrorResult } = parsePAResult({
          sheet: opponentPAResult,
          pbp: opponentPbpV1,
        });
        if (opponentErrorResult) {
          return;
        }

        const {
          pbp: opponentPbpV3,
          opponentFielders: fielders,
          error: opponentErrorBallCounts,
        } = parsePABallCountsWithFielding({
          sheet: opponentPABallCounts,
          pbp: opponentPbpV2,
          opponentPlayers: targetPlayers,
        });
        if (opponentErrorBallCounts) {
          return;
        }
        setFielders(fielders);
        setOpponentPbp(opponentPbpV3);
        console.log(fielders);
        console.log(opponentPbpV3);
      }

      console.log(opponentFielders);
      console.log(pbpV3);

      toast({
        title: '上傳檔案分析成功！',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const registerPlayer = ({ sheet, targetTeamName, opponentTeamName }) => {
    /**
     * 固定欄位 A1 球員名單
     * A2 C2 球隊名稱
     * A4 B4 之後球員背號 + 名稱
     * C4 D4 之後球員背號 + 名稱
     */
    if (!sheet['A2'] || !sheet['C2']) {
      errorToast('球員名單 格式錯誤，A2 C2 應包含兩隊隊名');
      return { error: true };
    }
    const leftSideTeamName = sheet['A2'].v.toString();
    const rightSideTeamName = sheet['C2'].v.toString();

    let leftSideTarget = true;
    if (rightSideTeamName === targetTeamName) {
      leftSideTarget = false;
    } else if (leftSideTeamName !== targetTeamName) {
      errorToast('球員名單 格式錯誤，找不到目標分析球隊(A2 or C2)');
      return { error: true };
    }

    if (
      (leftSideTarget && opponentTeamName !== rightSideTeamName) ||
      (!leftSideTarget && opponentTeamName !== leftSideTeamName)
    ) {
      errorToast('球員名單 格式錯誤，找不到對手球隊隊名(A2 or C2)');
      return { error: true };
    }

    let seq = 4;
    let leftSidePlayers = [];
    while (getCellValue(sheet[`A${seq}`])) {
      const playerNumber = getCellValue(sheet[`A${seq}`]);
      const playerName = getCellValue(sheet[`B${seq}`]);
      leftSidePlayers.push({ uniqid: '', name: playerName, number: playerNumber });
      seq++;
    }

    seq = 4;
    let rightSidePlayers = [];
    while (getCellValue(sheet[`C${seq}`])) {
      const playerNumber = getCellValue(sheet[`C${seq}`]);
      const playerName = getCellValue(sheet[`D${seq}`]);
      rightSidePlayers.push({ uniqid: '', name: playerName, number: playerNumber });
      seq++;
    }

    if (leftSideTarget) {
      return { targetPlayers: leftSidePlayers, opponentPlayers: rightSidePlayers };
    }
    return { targetPlayers: rightSidePlayers, opponentPlayers: leftSidePlayers };
  };

  const parsePAStatus = ({ sheet, inningFrame, targetPlayers, opponentPlayers }) => {
    const pbpRows = [];

    const getBases = (seq) => {
      const first = getCellValue(sheet[`M${seq}`]);
      const second = getCellValue(sheet[`N${seq}`]);
      const third = getCellValue(sheet[`O${seq}`]);
      let bases = 0;
      if (first === '1') {
        bases += 1;
      }
      if (second === '1') {
        bases += 2;
      }
      if (third === '1') {
        bases += 4;
      }

      return bases;
    };

    const getR = (seq, order) => {
      const R = parseInt(getCellValue(sheet[`P${seq}`]));
      const ROrder = parseInt(getCellValue(sheet[`Q${seq}`]));

      if (R !== 1) {
        return { R: 0, is_ER: false };
      }

      // 預設 true
      const is_ER = parseInt(getCellValue(sheet[`S${seq}`])) !== 0;
      if (1 <= ROrder && ROrder <= 9) {
        return { R: ROrder, is_ER };
      }

      // 沒有說明得分的棒次，就預設下一棒時得分
      return { R: (order + 1) % 9, is_ER };
    };

    const getSB = (seq, order) => {
      const SB = parseInt(getCellValue(sheet[`T${seq}`]));
      const SBOrder = [
        parseInt(getCellValue(sheet[`U${seq}`])),
        parseInt(getCellValue(sheet[`V${seq}`])),
        parseInt(getCellValue(sheet[`W${seq}`])),
      ];

      const result = [];
      if (!(1 <= SB && SB <= 3)) {
        return result;
      }

      for (let i = 0; i < SB; i++) {
        if (1 <= SBOrder[i] && SBOrder[i] <= 9) {
          result.push(SBOrder[i]);
          continue;
        }
        // 沒有說明盜壘成功的棒次，就預設下一棒時盜壘成功
        result.push((order + 1) % 9);
      }

      return result;
    };

    const getCS = (seq, order) => {
      const CS = parseInt(getCellValue(sheet[`X${seq}`]));
      const CSOrder = parseInt(getCellValue(sheet[`Y${seq}`]));

      if (CS !== 1) {
        return 0;
      }

      if (1 <= CSOrder && CSOrder <= 9) {
        return CSOrder;
      }

      // 沒有說明盜壘失敗的棒次，就預設下一棒時盜壘失敗
      return (order + 1) % 9;
    };

    const getPO = (seq, order) => {
      const PO = parseInt(getCellValue(sheet[`Z${seq}`]));
      const POOrder = parseInt(getCellValue(sheet[`AA${seq}`]));

      if (PO !== 1) {
        return 0;
      }

      if (1 <= POOrder && POOrder <= 9) {
        return POOrder;
      }

      // 沒有說明牽制出局的棒次，就預設下一棒時牽制出局
      return (order + 1) % 9;
    };

    let seq = 2;
    let emptyLineTolerance = 3;
    while (emptyLineTolerance > 0) {
      seq++;
      if (getCellValue(sheet[`A${seq}`])) {
        emptyLineTolerance = 3;
      } else {
        emptyLineTolerance--;
        continue;
      }

      const batterNumber = getCellValue(sheet[`D${seq}`]);
      const order = parseInt(getCellValue(sheet[`E${seq}`]));
      const round = parseInt(getCellValue(sheet[`F${seq}`]));
      if (!batterNumber || Number.isNaN(order) || Number.isNaN(round)) {
        errorToast(`打席資訊 D${seq} E${seq} F${seq} 格式錯誤，打者背號、棒次與攻擊輪為必填`);
        return { error: true };
      }

      const batter = find((p) => p.number === batterNumber, targetPlayers);
      if (!batter) {
        console.log(targetPlayers);
        errorToast(`打席資訊 D${seq} 內容錯誤，打者背號需包含在球員名單內`);
        return { error: true };
      }

      const status = {
        inning: -1,
        inning_frame: inningFrame,
        pitcher: {},
        batter,
        PA_round: round,
        PA_order: order,
        pinch: '',
        pitcher_hand: ['L', 'R'].includes(getCellValue(sheet[`G${seq}`])) ? getCellValue(sheet[`G${seq}`]) : '',
        batter_hand: ['L', 'R'].includes(getCellValue(sheet[`H${seq}`])) ? getCellValue(sheet[`H${seq}`]) : '',
        away_score: -1,
        home_score: -1,
        start_outs: -1,
        outs: -1,
        bases: getBases(seq),
        ball_counts: [],
        drop_point: '',
        trajectory: '',
        RBI: 0,
        result: '',
        ...getR(seq, order), // R, is_ER
        R_count_on_pitcher: {}, // 失分算在非面對的投手身上時（換投後FC之類）
        SB: getSB(seq, order),
        CS: getCS(seq, order),
        PO: getPO(seq, order),
      };

      const pitcherNumber = getCellValue(sheet[`C${seq}`]);
      const pitcher = find((p) => p.number === pitcherNumber, opponentPlayers);
      if (!pitcher) {
        errorToast(`打席資訊 C${seq} 內容錯誤，投手背號需包含在球員名單內`);
        return { error: true };
      }
      status.pitcher = pitcher;

      const RCountOnPitcherNumber = getCellValue(sheet[`R${seq}`]);
      if (RCountOnPitcherNumber) {
        const RCountOnPitcher = find((p) => p.number === RCountOnPitcherNumber, opponentPlayers);
        if (!RCountOnPitcher) {
          errorToast(`打席資訊 R${seq} 內容錯誤，投手背號需包含在球員名單內`);
          return { error: true };
        }
        status.R_count_on_pitcher = RCountOnPitcher;
      }

      const mustNotEmptyColumn = { inning: 'B', away_score: 'I', home_score: 'J', start_outs: 'K', outs: 'L' };
      let emptyError = false;
      Object.keys(mustNotEmptyColumn).forEach((key) => {
        const cellPosition = `${mustNotEmptyColumn[key]}${seq}`;
        const cell = sheet[cellPosition];
        if (!cell || !cell.v.toString()) {
          errorToast(`打席資訊 ${cellPosition} 格式錯誤，不應為空`);
          emptyError = true;
          return;
        }

        const value = parseInt(cell.v.toString());
        if (Number.isNaN(value) || value < 0) {
          errorToast(`打席資訊 ${cellPosition} 格式錯誤，應為零或正整數`);
          emptyError = true;
          return;
        }

        if (['start_outs', 'outs'].includes(key) && value > 2) {
          errorToast(`打席資訊 ${cellPosition} 格式錯誤，應為 0、1、2 其中之一`);
          emptyError = true;
          return;
        }

        status[key] = value;
      });
      if (emptyError) {
        return { error: true };
      }

      const originOrderBatter = find((PA) => PA.PA_order === order, pbpRows);
      if (originOrderBatter && originOrderBatter.batter.number !== batterNumber) {
        status.pinch = 'PH';
      }

      // 該局第一個要 0 out 0 bases
      if (!find((pbpRow) => pbpRow.inning === status.inning, pbpRows)) {
        if (status.outs !== 0 || status.bases !== 0) {
          errorToast(`打席資訊 I J K L${seq} 內容錯誤，該局首打者應該 0 出局壘上無人`);
          return { error: true };
        }
      }

      const PASeq = getCellValue(sheet[`A${seq}`]);
      if (PASeq === 'PR') {
        const originPAIndex = findLastIndex((PA) => PA.PA_order === order && PA.PA_round === round, pbpRows);
        if (originPAIndex < 0) {
          errorToast(`打席資訊 E${seq} F${seq} 格式錯誤，代跑找不到對應打者`);
          return { error: true };
        }

        if (originPAIndex === pbpRows.length - 1) {
          pbpRows.push({ ...status, pinch: 'PR' });
        } else {
          pbpRows.splice(originPAIndex + 1, 0, { ...status, pinch: 'PR' });
        }
        continue;
      }

      pbpRows.push(status);
    }
    return { pbp: pbpRows };
  };

  const parsePAResult = ({ sheet, pbp }) => {
    const pbpLength = pbp.length;
    let PRDelta = 0;
    for (let i = 0; i < pbpLength; i++) {
      const row = pbp[i];
      const seq = i + 3 - PRDelta;
      if (row.pinch === 'PR') {
        PRDelta++;
        continue;
      }

      // 未完成打席
      const PANO = getCellValue(sheet[`A${seq}`]);
      if (PANO === 'NONE') {
        continue;
      }

      const result = getCellValue(sheet[`E${seq}`]);
      if (!result) {
        errorToast(`打席結果 E${seq} 格式錯誤，不應為空`);
        return { error: true };
      }
      if (!ballInResult.includes(result) && !staticResult.includes(result)) {
        errorToast(`打席結果 E${seq} 格式錯誤，無相符事件`);
        return { error: true };
      }

      row.result = result;
      const RBI = parseInt(getCellValue(sheet[`D${seq}`]));
      row.RBI = Number.isNaN(RBI) ? 0 : RBI;

      if (staticResult.includes(result) && 'ID' !== result) {
        continue;
      }

      const dropPoint = getCellValue(sheet[`B${seq}`]);
      const trajectory = getCellValue(sheet[`C${seq}`]);
      // 妨礙守備可能有打球進場內，也可能沒有
      if ('ID' === result && !dropPoint && !trajectory) {
        continue;
      }

      if (!dropPoint) {
        errorToast(`打席結果 B${seq} 格式錯誤，不應為空`);
        return { error: true };
      }
      if (!dropPoints.includes(dropPoint)) {
        errorToast(`打席結果 B${seq} 格式錯誤，無相符落點`);
        return { error: true };
      }
      row.drop_point = dropPoint;

      if (!trajectory) {
        errorToast(`打席結果 C${seq} 格式錯誤，不應為空`);
        return { error: true };
      }
      if (!trajectoryMapping[trajectory]) {
        errorToast(`打席結果 C${seq} 格式錯誤，無相符軌跡`);
        return { error: true };
      }
      row.trajectory = trajectoryMapping[trajectory];
    }

    return { pbp };
  };

  const parsePABallCountsWithFielding = ({ sheet, pbp, opponentPlayers }) => {
    const ballEventMapping = {
      1: 'S', // Strike
      2: 'SW', // Swing
      3: 'B', // Ball
      4: 'F', // Foul
      5: 'H', // Hit
    };

    const fieldingPositions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'DH'];

    const isFieldingInfo = (cellValue) => {
      return cellValue.slice(0, 4) === 'POS:';
    };

    let totalBallCount = 0;
    const getBallCountsWithFielding = (seq) => {
      const ballCounts = [];
      const fielders = [];

      const possibleCounts = [];
      for (let j = 'B'.charCodeAt(0); j <= 'Z'.charCodeAt(0); j++) {
        possibleCounts.push(String.fromCharCode(j));
      }

      for (let j = 'A'.charCodeAt(0); j <= 'B'.charCodeAt(0); j++) {
        for (let k = 'A'.charCodeAt(0); k <= 'Z'.charCodeAt(0); k++) {
          possibleCounts.push(String.fromCharCode(j) + String.fromCharCode(k));
        }
      }

      const possibleCountLength = possibleCounts.length;
      for (let j = 0; j < possibleCountLength; j++) {
        const columnName = possibleCounts[j];
        const cellValue = getCellValue(sheet[`${columnName}${seq}`]);
        if (!cellValue) {
          return { ballCounts, fielders };
        }

        if (isFieldingInfo(cellValue)) {
          const fieldersInCell = cellValue.slice(4).trim().split(';');
          for (let fielderInCell of fieldersInCell) {
            const [fielderNumber, fielderPosition] = fielderInCell.split('>');
            const player = find((p) => p.number === fielderNumber, opponentPlayers);
            if (!player) {
              errorToast(
                `打席用球 ${columnName}${seq} 格式錯誤(${cellValue}) -> 無該選手(${fielderInCell})，請參考說明填寫`,
              );
              return { ballCounts: false, fielders: false };
            }
            if (!fieldingPositions.includes(fielderPosition)) {
              errorToast(
                `打席用球 ${columnName}${seq} 格式錯誤(${cellValue}) -> 無該守位(${fielderInCell})，請參考說明填寫`,
              );
              return { ballCounts: false, fielders: false };
            }

            fielders.push({
              player,
              position: fielderPosition === 'DH' ? 10 : parseInt(fielderPosition),
              in_play_ball_counts: totalBallCount,
            });
          }
          continue;
        }

        if (!ballEventMapping[cellValue]) {
          errorToast(`打席用球 ${columnName}${seq} 格式錯誤(${cellValue})，請參考說明填寫`);
          return { ballCounts: false, fielders: false };
        }

        totalBallCount++;
        ballCounts.push(ballEventMapping[cellValue]);
      }

      return { ballCounts, fielders };
    };

    let opponentFielders = [];
    const pbpLength = pbp.length;
    let PRDelta = 0;
    for (let i = 0; i < pbpLength; i++) {
      const row = pbp[i];
      const seq = i + 3 - PRDelta;
      if (row.pinch === 'PR') {
        PRDelta++;
        continue;
      }

      const { ballCounts, fielders } = getBallCountsWithFielding(seq);
      if (ballCounts === false) {
        return { error: true };
      }
      row.ball_counts = ballCounts;
      opponentFielders = opponentFielders.concat(fielders);
    }

    return { pbp, opponentFielders };
  };

  return <input type="file" accept=".xlsx,.xls" multiple={false} onChange={handleFileOnChange} />;
};

export default PbPUpload;
