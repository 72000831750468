import React, { useEffect, useState } from 'react';
import { Box, Divider, Flex, Spinner, Text, IconButton, Spacer } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { find, findIndex } from 'ramda';
import { FeatureTitle } from '~~components';
import { LeagueResource } from '~~apis/resource';
import ListLeague from './components/List';
import ListSeason from './components/ListSeason';
import EditLeague from './components/Edit';
import EditSeason from './components/EditSeason';
import EditSeasonPlugin from './components/EditSeasonPlugin';

const League = () => {
  const [leagues, setLeagues] = useState([]);
  const [league, setLeague] = useState({});
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState({});
  const [action, setAction] = useState('LOADING');

  useEffect(() => {
    LeagueResource.getLeagues().then((data) => {
      setLeagues(data.data);
      setAction('LIST_LEAGUES');
    });
  }, []);

  const editLeague = (uniqid) => () => {
    const selectedLeague = find((l) => l.uniqid === uniqid)(leagues);
    setLeague(selectedLeague);
    setAction('EDIT_LEAGUE');
  };

  const cancelLeagueEdit = () => {
    setLeague({});
    setAction('LIST_LEAGUES');
  };

  const completedLeagueEdit = (leagueData) => {
    const selectedLeagueIndex = findIndex((l) => l.uniqid === league.uniqid)(leagues);
    leagues[selectedLeagueIndex] = leagueData;
    setLeague(leagues[selectedLeagueIndex]);
    setLeagues(leagues);
    setAction('LIST_LEAGUES');
  };

  const leagueCreated = () => {
    setAction('LOADING');
    LeagueResource.getLeagues().then((data) => {
      setLeagues(data.data);
      setAction('LIST_LEAGUES');
    });
  };

  const listSeason = (uniqid) => () => {
    const selectedLeague = find((l) => l.uniqid === uniqid)(leagues);
    setLeague(selectedLeague);
    setSeasons([]);
    LeagueResource.getLeague({ leagueUniqid: uniqid }).then((data) => {
      setSeasons(data.data.seasons);
      setAction('LIST_SEASONS');
    });
  };

  const hideSeasons = () => {
    setSeasons([]);
    setAction('LIST_LEAGUES');
  };

  const editSeason = (uniqid) => () => {
    const selectedSeason = find((s) => s.uniqid === uniqid)(seasons);
    setSeason(selectedSeason);
    setAction('EDIT_SEASON');
  };

  const editSeasonPlugin = (uniqid) => () => {
    const selectedSeason = find((s) => s.uniqid === uniqid)(seasons);
    setSeason(selectedSeason);
    setAction('EDIT_SEASON_PLUGIN');
  };

  const cancelSeasonEdit = () => {
    setSeason({});
    setAction('LIST_SEASONS');
  };

  const completedSeasonEdit = (seasonData) => {
    const selectedSeasonIndex = findIndex((s) => s.uniqid === season.uniqid)(seasons);
    seasons[selectedSeasonIndex] = seasonData;
    setSeason(seasons[selectedSeasonIndex]);
    setSeasons(seasons);
    setAction('LIST_SEASONS');
  };

  const seasonCreated = () => {
    setSeasons([]);
    LeagueResource.getLeague({ leagueUniqid: league.uniqid }).then((data) => {
      setSeasons(data.data.seasons);
    });
  };

  return (
    <Box w="100%">
      <FeatureTitle title="主辦單位" />
      {action === 'LOADING' && <Spinner />}
      {action === 'LIST_SEASONS' && (
        <>
          <Flex>
            <Text>{league.name}</Text>
            <Spacer />
            <IconButton icon={<CloseIcon />} size="xs" variant="ghost" onClick={hideSeasons} />
          </Flex>
          <ListSeason
            leagueUniqid={league.uniqid}
            seasons={seasons}
            startEdit={editSeason}
            startEditPlugin={editSeasonPlugin}
            created={seasonCreated}
          />
          <Divider my="5" />
        </>
      )}
      {(action === 'LIST_LEAGUES' || action === 'LIST_SEASONS') && (
        <>
          <Text>主辦單位列表</Text>
          <ListLeague leagues={leagues} listSeason={listSeason} startEdit={editLeague} created={leagueCreated} />
        </>
      )}
      {action === 'EDIT_LEAGUE' && (
        <EditLeague
          league={league}
          existNames={leagues.map((l) => l.name)}
          cancelEdit={cancelLeagueEdit}
          completedEdit={completedLeagueEdit}
        />
      )}
      {action === 'EDIT_SEASON' && (
        <EditSeason
          leagueUniqid={league.uniqid}
          season={season}
          existTitles={seasons.map((s) => s.title)}
          cancelEdit={cancelSeasonEdit}
          completedEdit={completedSeasonEdit}
        />
      )}
      {action === 'EDIT_SEASON_PLUGIN' && (
        <EditSeasonPlugin
          leagueUniqid={league.uniqid}
          season={season}
          cancelEdit={cancelSeasonEdit}
          completedEdit={completedSeasonEdit}
        />
      )}
    </Box>
  );
};

export default League;
