import React, { useEffect, useState, useReducer } from 'react';
import { Box, Button, Flex, Heading, IconButton, Input, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { CPBLResource } from '~~apis/resource';

const articleStateInit = {
  title: '',
  link: '',
  errMsg: '',
  id: 0,
  status: 'NONE',
};

const articleReducer = (state, action) => {
  switch (action.type) {
    case 'new':
      return { ...articleStateInit, status: 'NEW' };
    case 'edit':
      return { ...articleStateInit, ...action.payload, status: 'EDIT' };
    case 'update':
      return { ...state, ...action.payload };
    case 'err':
      return { ...state, errMsg: action.payload };
    case 'cancel':
      return { ...articleStateInit };
    default:
      throw new Error();
  }
};

const getArticles = (callback) => {
  CPBLResource.readArticles().then(({ data }) => {
    callback(data);
  });
};

const Article = () => {
  const toast = useToast();
  const [articles, setArticles] = useState([]);
  const [articleState, articleDispatch] = useReducer(articleReducer, articleStateInit);

  useEffect(() => {
    getArticles((data) => setArticles(data));
  }, []);

  const handleNewArticle = () => {
    articleDispatch({ type: 'new' });
  };

  const handleEditArticle = (article) => () => {
    articleDispatch({ type: 'edit', payload: { ...article } });
  };

  const handleDeleteArticle = (article) => () => {
    let shouldDelete = true;
    setTimeout(() => {
      if (!shouldDelete) {
        return;
      }

      CPBLResource.deleteArticle({ articleID: article.id })
        .then(() => {
          getArticles((data) => {
            setArticles(data);
          });
          toast({
            title: `刪除成功！`,
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((e) => {
          toast({
            title: `刪除失敗！`,
            description: e,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }, 3000);

    toast({
      title: `三秒後刪除文章（關閉以取消）`,
      description: `文章標題：${article.title}`,
      status: 'info',
      duration: 3500,
      isClosable: true,
      onCloseComplete: () => {
        shouldDelete = false;
      },
    });
  };

  const handleInputChange = (e) => {
    const inputs = {
      title: articleState.title,
      link: articleState.link,
    };
    inputs[e.target.name] = e.target.value;
    articleDispatch({ type: 'update', payload: inputs });
  };

  const handleArticleConfirm = (confirmWording) => () => {
    const title = articleState.title.trim();
    const link = articleState.link.trim();
    if (!title || !link) {
      articleDispatch({ type: 'err', payload: '標題與連結皆為必填' });
      return;
    }

    if ('https://' !== link.slice(0, 8)) {
      articleDispatch({ type: 'err', payload: '連結需以 https:// 為開頭（不能用 http）' });
      return;
    }

    const data = { title, link };
    let method = 'createArticle';
    if (articleState.id > 0) {
      data.articleID = articleState.id;
      method = 'updateArticle';
    }

    CPBLResource[method](data)
      .then(() => {
        getArticles((data) => {
          setArticles(data);
          articleDispatch({ type: 'cancel' });
        });
        toast({
          title: `${confirmWording}成功！`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((e) => {
        toast({
          title: `${confirmWording}失敗！`,
          description: e,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleCancel = () => {
    articleDispatch({ type: 'cancel' });
  };

  const getArticleEditRow = (confirmWording) => (
    <Box w="100%">
      <Flex w="100%" alignItems="center">
        <Text whiteSpace="nowrap">標題</Text>
        <Input size="sm" name="title" value={articleState.title} onChange={handleInputChange} />
      </Flex>
      <Flex w="100%" alignItems="center">
        <Text whiteSpace="nowrap">連結</Text>
        <Input size="sm" name="link" value={articleState.link} onChange={handleInputChange} />
      </Flex>
      <Flex w="100%" justifyContent="flex-end" alignItems="center">
        {articleState.errMsg && (
          <Text fontSize="sm" textColor="red">
            {articleState.errMsg}
          </Text>
        )}
        <Button size="sm" colorScheme="green" onClick={handleArticleConfirm(confirmWording)}>
          {confirmWording}
        </Button>
        <Button size="sm" onClick={handleCancel}>
          取消
        </Button>
      </Flex>
    </Box>
  );

  return (
    <Box w="100%">
      <Heading fontSize="md">最新 10 篇文章（依狀況顯示於首頁）</Heading>
      <Flex flexDir="column" alignItems="start" maxW="30rem">
        {articleState.status === 'NONE' ? (
          <Button size="sm" onClick={handleNewArticle}>
            新增
          </Button>
        ) : articleState.status === 'NEW' ? (
          getArticleEditRow('新增')
        ) : (
          <></>
        )}
        {articles.map((article) => {
          if (article.id === articleState.id) {
            return getArticleEditRow('更新');
          }
          return (
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              padding="0.5rem"
              margin="0.5rem 0"
              boxShadow="2px 2px 4px -4px black"
            >
              {article.title}
              {articleState.status === 'NONE' && (
                <Flex>
                  <Button size="xs" colorScheme="orange" marginLeft="1rem" onClick={handleEditArticle(article)}>
                    編輯
                  </Button>
                  <Button size="xs" colorScheme="red" marginLeft="1rem" onClick={handleDeleteArticle(article)}>
                    刪除
                  </Button>
                  <IconButton
                    size="xs"
                    icon={<ExternalLinkIcon />}
                    as="a"
                    target="_blank"
                    href={article.link}
                    marginLeft="1rem"
                  />
                </Flex>
              )}
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default Article;
