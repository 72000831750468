import React from 'react';
import { Text } from '@chakra-ui/react';

const FeatureTitle = ({ title, styles }) => {
  return (
    <Text mb="2" fontSize="lg" {...styles}>
      {title}
    </Text>
  );
};

export default FeatureTitle;
