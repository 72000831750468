import React from 'react';
import { Box, VStack, HStack, Divider, Text } from '@chakra-ui/react';
import { sort, isNil } from 'ramda';
import CommonUtils from '~~utils/CommonUtils';

const displayGroupName = (group, editButton) => {
  if (group.name === 'NONE') {
    return '未分類';
  }

  return (
    <>
      {group.name}
      {editButton}
    </>
  );
};

const dateSort = (games) => {
  const dateCompare = (a, b) => {
    const aDate = new Date(a.info.time.scheduled_start_at);
    const bDate = new Date(b.info.time.scheduled_start_at);
    if (aDate === bDate) {
      return a.seq - b.seq;
    }
    return aDate - bDate;
  };
  const sortGames = sort(dateCompare, games);

  const dateGroups = [];
  const dateGroupMapping = {};
  sortGames.forEach((game) => {
    const scheduledAt = new Date(game.info.time.scheduled_start_at);
    const date = `${scheduledAt.getFullYear()}-${CommonUtils.dateAddZero(
      scheduledAt.getMonth() + 1,
    )}-${CommonUtils.dateAddZero(scheduledAt.getDate())}`;
    if (isNil(dateGroupMapping[date])) {
      dateGroupMapping[date] = dateGroups.length;
      dateGroups[dateGroups.length] = {
        date,
        games: [],
      };
    }

    const dateIndex = dateGroupMapping[date];
    dateGroups[dateIndex].games.push(game);
  });

  return dateGroups;
};

const displayGameName = (game) => {
  const scheduledAt = new Date(game.info.time.scheduled_start_at);
  return `G${game.seq} ${CommonUtils.dateAddZero(scheduledAt.getHours())}:${CommonUtils.dateAddZero(
    scheduledAt.getMinutes(),
  )} ${CommonUtils.displayGameTeam('away', game)} v.s. ${CommonUtils.displayGameTeam('home', game)}`;
};

const Group = ({ group, games, gameCU, editButton }) => {
  const dateGroups = dateSort(games);

  return (
    <Box w="16%" ml={2} display="inline-block" verticalAlign="top" whiteSpace="normal">
      <Text fontSize="lg">{displayGroupName(group, editButton)}</Text>
      <Divider my={2} />
      <VStack spacing={2} align="start">
        {dateGroups.map((dateGroup) => (
          <Box key={dateGroup.date}>
            <Text fontSize="xs">{dateGroup.date}</Text>
            <VStack spacing={3} align="start">
              {dateGroup.games.map((game) => (
                <HStack key={game.uniqid} spacing={2} borderWidth={1} borderRadius={5} p={1}>
                  <Text fontSize="xs">{displayGameName(game)}</Text>
                  {gameCU(game)}
                </HStack>
              ))}
            </VStack>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default Group;
