import React from 'react';
import { useToast } from '@chakra-ui/react';
import XLSX from 'xlsx';
import { find, sum } from 'ramda';
import { useGameDetailState } from '../hooks';
import { calcTeamHFromBatters, calcTeamEFromFielders } from '../Consts';

const awayBatterSheetName = 'AWAY 客場 - 打者';
const awayPitcherSheetName = 'AWAY 客場 - 投手';
const homeBatterSheetName = 'HOME 主場 - 打者';
const homePitcherSheetName = 'HOME 主場 - 投手';

const fieldPositionMapping = {
  '1': 'P',
  '2': 'C',
  '3': '1B',
  '4': '2B',
  '5': '3B',
  '6': 'SS',
  '7': 'LF',
  '8': 'CF',
  '9': 'RF',
  DH: 'DH',
  PR: 'PR',
  PH: 'PH',
};

const getCellValue = (cell, defaultValue = '') => {
  if (!cell?.v && cell?.v !== 0) {
    return defaultValue;
  }
  return cell.v.toString();
};

const BoxScoreSimplifyUpload = ({ awayForm, homeForm }) => {
  const toast = useToast();
  const { awayTeamPlayers, homeTeamPlayers } = useGameDetailState();

  const errorToast = (message) => {
    toast({
      title: message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  const handleFileOnChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length !== 1) {
      return;
    }

    toast({
      title: '讀取中，請稍後',
      status: 'info',
      duration: 1000,
      isClosable: true,
    });

    const file = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const awayBatters = workbook.Sheets[awayBatterSheetName];
      const awayPitchers = workbook.Sheets[awayPitcherSheetName];
      const homeBatters = workbook.Sheets[homeBatterSheetName];
      const homePitchers = workbook.Sheets[homePitcherSheetName];

      if (!awayBatters || !awayPitchers || !homeBatters || !homePitchers) {
        errorToast(
          `格式錯誤，應包含 ${awayBatterSheetName} ${awayPitcherSheetName} ${homeBatterSheetName} ${homePitcherSheetName} 表單`,
        );
        return;
      }

      const awayGetPlayer = (number) => {
        return find((p) => p.number === number, awayTeamPlayers);
      };
      const homeGetPlayer = (number) => {
        return find((p) => p.number === number, homeTeamPlayers);
      };

      const batterPitcherValidate = (side, batters, opponentPitchers) => {
        console.log(batters);
        console.log(opponentPitchers);
        const shouldEqualColumns = [
          { batter: 'PA', pitcher: 'BF', display: '打席' },
          { batter: 'H', pitcher: 'H', display: '（被）安打' },
          { batter: 'HR', pitcher: 'HR', display: '（被）全壘打' },
          { batter: 'BB', pitcher: 'BB', display: '四死球' },
          { batter: 'IBB', pitcher: 'IBB', display: '故意四死球' },
          { batter: 'HBP', pitcher: 'HB', display: '觸身球' },
          { batter: 'SO', pitcher: 'SO', display: '（被）三振' },
          { batter: 'R', pitcher: 'R', display: '得/失分' },
        ];

        for (let column of shouldEqualColumns) {
          if (
            sum(batters.map((b) => parseInt(b[column.batter]))) !==
            sum(opponentPitchers.map((b) => parseInt(b[column.pitcher])))
          ) {
            errorToast(`${side}打者與對手投手 ${column.display}數 加總不同`);
            return false;
          }
        }

        return true;
      };

      const {
        batters: awayBatterStatistics,
        fielders: awayFielderStatistics,
        error: awayStatisticsError,
      } = parsePAStatistics({
        sheet: awayBatters,
        getPlayer: awayGetPlayer,
        sheetName: awayBatterSheetName,
      });
      if (awayStatisticsError) {
        return;
      }

      const { pitchers: awayPitcherStatistics, error: awayPitcherError } = parsePitcherStatistics({
        sheet: awayPitchers,
        getPlayer: awayGetPlayer,
        sheetName: awayPitcherSheetName,
      });
      if (awayPitcherError) {
        return;
      }

      const {
        batters: homeBatterStatistics,
        fielders: homeFielderStatistics,
        error: homeStatisticsError,
      } = parsePAStatistics({
        sheet: homeBatters,
        getPlayer: homeGetPlayer,
        sheetName: homeBatterSheetName,
      });
      if (homeStatisticsError) {
        return;
      }

      const { pitchers: homePitcherStatistics, error: homePitcherError } = parsePitcherStatistics({
        sheet: homePitchers,
        getPlayer: homeGetPlayer,
        sheetName: homePitcherSheetName,
      });
      if (homePitcherError) {
        return;
      }

      if (
        !batterPitcherValidate('客場', awayBatterStatistics, homePitcherStatistics) ||
        !batterPitcherValidate('主場', homeBatterStatistics, awayPitcherStatistics)
      ) {
        return;
      }

      awayForm.setValue('hits', calcTeamHFromBatters(awayBatterStatistics));
      awayForm.setValue('errors', calcTeamEFromFielders(awayFielderStatistics));
      awayForm.setValue('batters', awayBatterStatistics);
      awayForm.setValue('players', awayFielderStatistics);
      awayForm.setValue('pitchers', awayPitcherStatistics);

      homeForm.setValue('hits', calcTeamHFromBatters(homeBatterStatistics));
      homeForm.setValue('errors', calcTeamEFromFielders(homeFielderStatistics));
      homeForm.setValue('batters', homeBatterStatistics);
      homeForm.setValue('players', homeFielderStatistics);
      homeForm.setValue('pitchers', homePitcherStatistics);

      console.log(awayBatterStatistics);
      console.log(awayFielderStatistics);
      console.log(awayPitcherStatistics);

      console.log(homeBatterStatistics);
      console.log(homeFielderStatistics);
      console.log(homePitcherStatistics);
      toast({
        title: '上傳檔案分析成功！',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const parsePAStatistics = ({ sheet, getPlayer, sheetName }) => {
    const PAOrder = {};
    let PATotal = 0;
    const batters = [];
    const fielders = [];

    let seq = 1;
    let emptyLineTolerance = 3;
    while (emptyLineTolerance > 0) {
      seq++;
      if (getCellValue(sheet[`A${seq}`])) {
        emptyLineTolerance = 3;
      } else {
        emptyLineTolerance--;
        continue;
      }

      const playerNumber = getCellValue(sheet[`A${seq}`]);
      if (!playerNumber) {
        errorToast(`${sheetName} A${seq} 格式錯誤，選手背號為必填`);
        return { error: true };
      }

      const player = getPlayer(playerNumber);
      if (!player) {
        errorToast(`${sheetName} A${seq} 格式錯誤，找不到背號 ${playerNumber} 的球員`);
        return { error: true };
      }

      const order = parseInt(getCellValue(sheet[`B${seq}`]));
      const positions = getCellValue(sheet[`C${seq}`]).split(',');
      const positionLength = positions.length;
      if (positionLength < 1) {
        errorToast(`${sheetName} C${seq} 格式錯誤，至少要有一個守位（用 , 區分）`);
        return { error: true };
      }
      for (let position of positions) {
        if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', 'DH', 'PH', 'PR'].includes(position)) {
          errorToast(`${sheetName} C${seq} 格式錯誤，包含無法辨識之守位（1 - 9 DH PR PH）`);
          return { error: true };
        }
      }

      if (Number.isNaN(order) && (positionLength !== 1 || positions[0] !== '1')) {
        errorToast(`${sheetName} B${seq} C${seq} 格式錯誤，無棒次選手只能是投手`);
        return { error: true };
      }

      // 守備資訊
      const POs = getCellValue(sheet[`J${seq}`]).split(',');
      const As = getCellValue(sheet[`K${seq}`]).split(',');
      const Es = getCellValue(sheet[`L${seq}`]).split(',');
      const PBs = getCellValue(sheet[`N${seq}`]).split(',');
      const SBs = getCellValue(sheet[`O${seq}`]).split(',');
      const CSs = getCellValue(sheet[`P${seq}`]).split(',');
      const fielder = { info: { number: playerNumber }, fieldings: [] };
      positions.forEach((position, index) => {
        const fielding = {
          position: fieldPositionMapping[position],
          order: (index + 1).toString(),
          PO: POs[index] ? POs[index] : '0',
          A: As[index] ? As[index] : '0',
          E: Es[index] ? Es[index] : '0',
          PB: PBs[index] ? PBs[index] : '0',
          SB: SBs[index] ? SBs[index] : '0',
          CS: CSs[index] ? CSs[index] : '0',
        };
        fielding.TC = parseInt(fielding.PO) + parseInt(fielding.A) + parseInt(fielding.E);
        fielder.fieldings.push(fielding);
      });
      fielders.push(fielder);

      if (Number.isNaN(order)) {
        continue;
      }

      if (1 > order || order > 9) {
        errorToast(`${sheetName} B${seq} 格式錯誤，打者僅能包含 1 - 9 棒`);
        return { error: true };
      }

      // 打者資訊
      const statistics = {
        player: { number: playerNumber },
        order: order.toString(),
        is_PH: !!PAOrder[order],
        PA: getCellValue(sheet[`D${seq}`], '0'),
        AB: '0',
        H: '0',
        TWOB: '0',
        THREEB: '0',
        HR: '0',
        SO: '0',
        BB: '0',
        IBB: '0',
        HBP: '0',
        SF: '0',
        SAC: '0',
        GIDP: '0',
        FC: '0',
        E: '0',
        F: '0',
        G: '0',
        RBI: getCellValue(sheet[`E${seq}`], '0'),
        R: getCellValue(sheet[`F${seq}`], '0'),
        SB: getCellValue(sheet[`G${seq}`], '0'),
        CS: getCellValue(sheet[`H${seq}`], '0'),
      };

      batters.push(statistics);
      const batterIndex = batters.length - 1;

      const PA = Number.isNaN(parseInt(statistics.PA)) ? 0 : parseInt(statistics.PA);
      if (PA === 0) {
        continue;
      }

      PATotal += PA;
      if (!PAOrder[order]) {
        PAOrder[order] = [];
      }

      for (let i = 0; i < PA; i++) {
        PAOrder[order].push(batterIndex);
      }
    }

    // 將打席結果對應至打者中
    const maxParsingColumns = 50;
    const possiblePAColumn = ['S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    for (let j = 'A'.charCodeAt(0); j <= 'Z'.charCodeAt(0); j++) {
      for (let k = 'A'.charCodeAt(0); k <= 'Z'.charCodeAt(0); k++) {
        possiblePAColumn.push(String.fromCharCode(j) + String.fromCharCode(k));
        if (possiblePAColumn.length > maxParsingColumns) {
          break;
        }
      }
      if (possiblePAColumn.length > maxParsingColumns) {
        break;
      }
    }
    const PAResultList = [];
    let startFrom = 1;
    for (let PAColumn of possiblePAColumn) {
      let includeOnPAResult = false;
      for (let i = 1; i <= 9; i++) {
        let rowNumber = (startFrom % 9) + 1;
        if (rowNumber === 1) {
          rowNumber = 10;
        }

        const PAResult = getCellValue(sheet[`${PAColumn}${rowNumber}`]);
        if (!PAResult) {
          break;
        }

        includeOnPAResult = true;
        PAResultList.push(PAResult);
        startFrom++;
      }

      if (!includeOnPAResult) {
        break;
      }
    }

    if (PAResultList.length !== PATotal) {
      errorToast(`${sheetName} 打席結果總 PA 數與打者總 PA 數不符`);
      return { error: true };
    }

    const addOne = (origin) => {
      return (parseInt(origin) + 1).toString();
    };

    console.log(PAResultList);
    let PAResultError = false;
    PAResultList.forEach((PAResult, index) => {
      const round = Math.floor(index / 9);
      const order = (index + 1) % 9 === 0 ? 9 : (index + 1) % 9;
      const batter = batters[PAOrder[order][round]];

      if (!batter) {
        errorToast(`${sheetName} 第${order}棒打席數有誤，無法匹配打席結果`);
        PAResultError = true;
        return;
      }

      switch (PAResult) {
        case '1B':
          batter.H = addOne(batter.H);
          batter.AB = addOne(batter.AB);
          break;
        case '2B':
          batter.H = addOne(batter.H);
          batter.TWOB = addOne(batter.TWOB);
          batter.AB = addOne(batter.AB);
          break;
        case '3B':
          batter.H = addOne(batter.H);
          batter.THREEB = addOne(batter.THREEB);
          batter.AB = addOne(batter.AB);
          break;
        case 'HR':
          batter.H = addOne(batter.H);
          batter.HR = addOne(batter.HR);
          batter.AB = addOne(batter.AB);
          break;
        case 'SH':
        case 'SAC':
        case 'SF':
        case 'HBP':
          batter[PAResult === 'SH' ? 'SAC' : PAResult] = addOne(batter[PAResult === 'SH' ? 'SAC' : PAResult]);
          break;
        case 'IBB':
          batter.IBB = addOne(batter.IBB);
        case 'uBB':
          batter.BB = addOne(batter.BB);
          break;
        default:
          // 不記錄且無打數
          if (['IR', 'IH'].includes(PAResult)) {
            break;
          }

          // 不記錄但有打數
          if (['ID'].includes(PAResult)) {
            batter.AB = addOne(batter.AB);
            break;
          }

          if (!['E', 'FC', 'GO', 'FO', 'GIDP', 'SO'].includes(PAResult)) {
            errorToast(`${sheetName} 第${round}輪第${order}棒打席結果有誤，無對應代號`);
            PAResultError = true;
            return;
          }

          // 正常加結果加打數
          let PAResultKey = PAResult;
          if (PAResult === 'GO') {
            PAResultKey = 'G';
          }
          if (PAResult === 'FO') {
            PAResultKey = 'F';
          }
          batter[PAResultKey] = addOne(batter[PAResultKey]);
          batter.AB = addOne(batter.AB);
          break;
      }
    });
    if (PAResultError) {
      return { error: true };
    }

    return { batters, fielders, error: false };
  };

  const parsePitcherStatistics = ({ sheet, getPlayer, sheetName }) => {
    const pitchers = [];

    let seq = 1;
    let emptyLineTolerance = 3;
    while (emptyLineTolerance > 0) {
      seq++;
      if (getCellValue(sheet[`A${seq}`])) {
        emptyLineTolerance = 3;
      } else {
        emptyLineTolerance--;
        continue;
      }

      const playerNumber = getCellValue(sheet[`A${seq}`]);
      if (!playerNumber) {
        errorToast(`${sheetName} A${seq} 格式錯誤，選手背號為必填`);
        return { error: true };
      }

      const pitcher = getPlayer(playerNumber);
      if (!pitcher) {
        errorToast(`${sheetName} A${seq} 格式錯誤，找不到背號 ${playerNumber} 的球員`);
        return { error: true };
      }

      pitchers.push({
        player: { number: playerNumber },
        order: pitchers.length + 1,
        IPOut: getCellValue(sheet[`B${seq}`], '0'),
        NP: getCellValue(sheet[`C${seq}`], '0'),
        BF: getCellValue(sheet[`D${seq}`], '0'),
        H: getCellValue(sheet[`E${seq}`], '0'),
        HR: getCellValue(sheet[`F${seq}`], '0'),
        BB: getCellValue(sheet[`G${seq}`], '0'),
        HB: getCellValue(sheet[`H${seq}`], '0'),
        SO: getCellValue(sheet[`I${seq}`], '0'),
        WP: getCellValue(sheet[`J${seq}`], '0'),
        BK: getCellValue(sheet[`K${seq}`], '0'),
        R: getCellValue(sheet[`L${seq}`], '0'),
        ER: getCellValue(sheet[`M${seq}`], '0'),
        IBB: getCellValue(sheet[`N${seq}`], '0'),
      });
    }

    return { pitchers, error: false };
  };

  return <input type="file" accept=".xlsx,.xls" multiple={false} onChange={handleFileOnChange} />;
};

export default BoxScoreSimplifyUpload;
