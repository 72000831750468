import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  userExists: false,
  getPermissionList: false,
  setUserPermission: false,
};

const User = {
  userExists: ({ email }) => {
    if (debugStatus.getPermissionList) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          exists: true,
          uniqid: 'afixl',
          email: 'test@test.com',
          user_name: 'test',
          first_name: 'est',
          last_name: 't',
        },
        error: false,
        message: 'Request successfully',
      });
    }

    return ApiService.get(`/api/user/exists?email=${encodeURI(email)}`);
  },

  getPermissionList: ({ target, targetUniqid }) => {
    if (debugStatus.getPermissionList) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [],
        error: false,
        message: 'Get permission successfully',
      });
    }

    return ApiService.get(`/api/permission/${target}/${targetUniqid}`);
  },

  setUserPermission: ({ userUniqid, target, targetUniqid, authority }) => {
    if (debugStatus.setUserPermission) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          permission: 'ADMIN',
        },
        error: false,
        message: 'Set permission successfully',
      });
    }

    return ApiService.put(`/api/users/${userUniqid}/permission/${target}/${targetUniqid}`, {
      data: { authority },
    });
  },
};

export default User;
