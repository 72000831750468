import React, { useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import FeatureTitle from '~~components/FeatureTitle';
import Stats from './components/Stats';
import Article from './components/Article';

const panelList = [
  { unique: 'STATS', display: '數據更新' },
  { unique: 'ARTICLE', display: '最新文章' },
];

const getPanel = (panel) => {
  switch (panel) {
    case 'STATS':
      return <Stats />;
    case 'ARTICLE':
      return <Article />;
    default:
      return;
  }
};

const CPBL = () => {
  const [panel, setPanel] = useState('STATS');

  const handlePanelChange = (p) => () => {
    setPanel(p);
  };

  return (
    <Box w="100%">
      <FeatureTitle title="CPBL" />
      <Flex flexWrap="wrap" padding="1rem 0">
        {panelList.map((p) => (
          <Button
            size="md"
            colorScheme={panel === p.unique ? 'red' : 'blue'}
            onClick={handlePanelChange(p.unique)}
            margin="0.2rem 0.5rem"
          >
            {p.display}
          </Button>
        ))}
      </Flex>
      {getPanel(panel)}
    </Box>
  );
};

export default CPBL;
