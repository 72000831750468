import ApiService from '../ApiService';

const PluginOrder = {
  getPluginOrders: ({ limit, page }) => {
    return ApiService.get(`/api/plugin_orders?limit=${limit}&page=${page}`);
  },

  updatePluginOrderReceipt: ({ pluginOrderUniqid, data }) => {
    return ApiService.put(`/api/plugin_orders/${pluginOrderUniqid}/receipt`, { data });
  },
};

export default PluginOrder;
