import React, { useState, useEffect } from 'react';
import { Box, Grid, SimpleGrid, VStack, Divider, Button, Text, Collapse, Image, Select } from '@chakra-ui/react';
import { Input, HStack, IconButton } from '@chakra-ui/react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronDownIcon, DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { sum, filter, find, propEq } from 'ramda';
import { useFieldArray, Controller } from 'react-hook-form';
import { PlayerResource } from '~~apis/resource';
import { useGameDetailState, useGameDetailDispatch } from '../hooks';
import Actions from '../Actions';

const INFO_TYPE_MAPPING = {
  BATTER: '打者',
  PITCHER: '投手',
};

const INFO_VALUE_MAPPING = {
  BATTER: {
    GWRBI: '勝利打點',
  },
  PITCHER: {
    W: '勝投',
    L: '敗投',
    H: '中繼',
    SV: '救援成功',
    BS: '救援失敗',
  },
  BOTH: {
    MVP: 'MVP',
    SP_RECORD: '隨你輸入（其他）',
  },
};

const Team = ({ teamSide, form: { register, watch, control, setValue, getValues } }) => {
  const gameDetailDispatch = useGameDetailDispatch();
  const { seasonTeams, [`${teamSide}TeamPlayers`]: teamPlayers } = useGameDetailState();
  const [seasonTeam, setSeasonTeam] = useState({});

  const teamName = watch('team_name');
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'records',
  });

  const [recordsDisplayData, setRecordDisplayData] = useState(
    fields.map((f) => {
      return {
        player_type: f.player_type,
        value: f.value,
      };
    }),
  );

  useEffect(() => {
    const TEAM_SIDE = teamSide.toUpperCase();
    if (!teamName) {
      gameDetailDispatch({ type: Actions[`UPDATE_${TEAM_SIDE}_TEAM_PLAYERS`], payload: [] });
      setSeasonTeam({});
      return;
    }

    const selectedSeasonTeam = find(propEq('name', teamName))(seasonTeams);
    setSeasonTeam(selectedSeasonTeam);
    PlayerResource.getSeasonTeamPlayers({
      seasonUniqid: selectedSeasonTeam.season.uniqid,
      teamName,
    }).then((data) => {
      gameDetailDispatch({ type: Actions[`UPDATE_${TEAM_SIDE}_TEAM_PLAYERS`], payload: data.data });
    });
  }, [gameDetailDispatch, seasonTeams, teamName, teamSide]);

  const removeInfoRecord = (index) => () => {
    remove(index);

    recordsDisplayData.splice(index, 1);
    setRecordDisplayData(recordsDisplayData);
  };

  const addInfoRecord = () => {
    append({ player: { number: '' }, player_type: '', value: '', comment: '' });
  };

  const recordUpdate = (onChange, updateParam, index) => (e) => {
    onChange();

    const currentRecords = getValues().records ? getValues().records : [];
    if (!currentRecords[index]) {
      currentRecords[index] = { player: { number: '' }, player_type: '', value: '', comment: '' };
    }

    if (updateParam === 'number') {
      currentRecords[index].player.number = e.target.value;
    } else {
      currentRecords[index][updateParam] = e.target.value;
    }
    setValue('records', currentRecords);
    if (!recordsDisplayData[index]) {
      recordsDisplayData[index] = {
        player_type: '',
        value: '',
      };
    }

    recordsDisplayData[index][updateParam] = e.target.value;
    setRecordDisplayData(recordsDisplayData.slice(0));
  };

  return (
    <VStack spacing={4}>
      <Text fontSize="lg">{teamSide === 'away' ? 'AWAY 客場' : 'HOME 主場'}</Text>
      <FormControl>
        <FormLabel htmlFor={'game-team-team-' + teamSide}>球隊</FormLabel>
        <Select id={'game-team-team-' + teamSide} name="team_name" ref={register()} size="sm">
          <option key={'game-team-team-first-' + teamSide} value="">
            未定（僅限尚未開始的比賽適用）
          </option>
          {seasonTeams.map((seasonTeam) => (
            <option key={seasonTeam.name} value={seasonTeam.name}>
              {seasonTeam.name}
            </option>
          ))}
        </Select>
      </FormControl>
      {seasonTeam?.origin?.icon_url && <Image my="1" height="100px" src={seasonTeam.origin.icon_url} />}
      {seasonTeam && (
        <FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Box Score 打者紀錄</FormLabel>
            <Switch size="sm" name="record_battings" ref={register()} />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Box Score 投手紀錄</FormLabel>
            <Switch size="sm" name="record_pitchings" ref={register()} />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Box Score 守備紀錄</FormLabel>
            <Switch size="sm" name="record_fieldings" ref={register()} />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">得分僅記錄總分</FormLabel>
            <Switch size="sm" name="runs_without_innings" ref={register()} />
          </FormControl>
          <Divider borderColor="gray.400" mb="2" />
          <FormLabel>紀錄</FormLabel>
          {fields.map((info, index) => {
            const playerType = recordsDisplayData[index] ? recordsDisplayData[index].player_type : '';
            const value = recordsDisplayData[index] ? recordsDisplayData[index].value : '';
            return (
              <Box key={info.id}>
                <HStack spacing={2} my={2}>
                  <SimpleGrid columns={2} gap={2}>
                    <Controller
                      control={control}
                      name={`records[${index}].player_type`}
                      defaultValue={info.player_type}
                      render={({ value, onChange }) => (
                        <Select
                          size="sm"
                          value={value}
                          placeholder="選擇選手型態"
                          onChange={recordUpdate(onChange, 'player_type', index)}
                        >
                          {Object.keys(INFO_TYPE_MAPPING).map((type) => (
                            <option key={type} value={type}>
                              {INFO_TYPE_MAPPING[type]}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    {playerType && (
                      <Controller
                        control={control}
                        name={`records[${index}].value`}
                        defaultValue={info.value}
                        render={({ value, onChange }) => (
                          <Select
                            size="sm"
                            value={value}
                            placeholder="選擇紀錄"
                            onChange={recordUpdate(onChange, 'value', index)}
                          >
                            {Object.keys(INFO_VALUE_MAPPING[playerType]).map((value) => (
                              <option key={value} value={value}>
                                {INFO_VALUE_MAPPING[playerType][value]}
                              </option>
                            ))}
                            {Object.keys(INFO_VALUE_MAPPING.BOTH).map((value) => (
                              <option key={value} value={value}>
                                {INFO_VALUE_MAPPING.BOTH[value]}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                    )}
                    {value === 'SP_RECORD' && (
                      <Input
                        size="sm"
                        name={`records[${index}].comment`}
                        ref={register()}
                        defaultValue={info.comment}
                        placeholder="輸入什麼前台顯示甚麼，不做分析用"
                      />
                    )}
                    {value && teamPlayers.length > 0 && (
                      <Controller
                        control={control}
                        name={`records[${index}].player.number`}
                        defaultValue={info.player?.number}
                        render={({ value, onChange }) => (
                          <Select
                            size="sm"
                            value={value}
                            placeholder="請選擇"
                            onChange={recordUpdate(onChange, 'number', index)}
                          >
                            {teamPlayers.map((player) => (
                              <option key={player.number} value={player.number}>
                                {`${player.number} ${player.name}`}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                    )}
                  </SimpleGrid>
                  <IconButton icon={<DeleteIcon />} onClick={removeInfoRecord(index)} size="sm" />
                </HStack>
                <Divider borderColor="gray.400" mb="2" />
              </Box>
            );
          })}
          <IconButton icon={<AddIcon />} onClick={addInfoRecord} size="sm" />
        </FormControl>
      )}
    </VStack>
  );
};

const TeamScoreBoard = ({ teamSide, infoForm, form: { register, watch, control, getValues, setValue } }) => {
  const innings = infoForm.watch('innings');

  const teamName = watch('team_name');

  const recordBattings = watch('record_battings');
  const recordFieldings = watch('record_fieldings');
  const runsWithoutInnings = watch('runs_without_innings');

  const calcRuns = (index) => (e) => {
    const currentScores = getValues().scores;
    currentScores[index] = e.target.value;
    setValue('scores', currentScores);
    setValue('runs', sum(filter((score) => !Number.isNaN(parseInt(score)), currentScores)).toString());
  };

  return (
    <Grid templateColumns={`3fr repeat(${parseInt(innings) + 3}, 1fr)`} gap={1} bg="gray.200" py="2">
      <Text textAlign="center">{teamName}</Text>
      {runsWithoutInnings ? (
        <>
          {[...Array(parseInt(innings))].map((_, index) => (
            <Text textAlign="center" key={index}>
              -
            </Text>
          ))}
          <Input size="sm" type="text" variant="flushed" textAlign="center" name="runs" ref={register()} />
        </>
      ) : (
        <>
          {[...Array(parseInt(innings))].map((_, index) => (
            <Controller
              key={`scores-${teamSide}-${index}`}
              control={control}
              name={`scores[${index}]`}
              render={({ value }) => (
                <Input
                  size="sm"
                  type="text"
                  variant="flushed"
                  textAlign="center"
                  onChange={calcRuns(index)}
                  value={value}
                />
              )}
            />
          ))}
          <Input
            size="sm"
            type="text"
            variant="unstyled"
            textAlign="center"
            name="runs"
            isDisabled={true}
            isReadOnly={true}
            ref={register()}
          />
        </>
      )}
      {!recordBattings ? (
        <Input size="sm" type="text" variant="flushed" textAlign="center" name="hits" ref={register()} />
      ) : (
        <Input
          size="sm"
          type="text"
          variant="unstyled"
          textAlign="center"
          name="hits"
          isDisabled={true}
          isReadOnly={true}
          ref={register()}
        />
      )}
      {!recordFieldings ? (
        <Input size="sm" type="text" variant="flushed" textAlign="center" name="errors" ref={register()} />
      ) : (
        <Input
          size="sm"
          type="text"
          variant="unstyled"
          textAlign="center"
          name="errors"
          isDisabled={true}
          isReadOnly={true}
          ref={register()}
        />
      )}
    </Grid>
  );
};

// let renderCount = 0;
const Teams = ({ infoForm, awayForm, homeForm }) => {
  // renderCount++;
  // console.log(`Teams ${renderCount}`);

  const [showTeams, setShowTeams] = useState(false);

  const { register, watch } = infoForm;

  const innings = watch('innings');
  const awayTeamName = awayForm.watch('team_name');
  const homeTeamName = homeForm.watch('team_name');

  const collapseOnClick = () => {
    setShowTeams(!showTeams);
  };

  return (
    <Box>
      <Button pl="0" variant="ghost" onClick={collapseOnClick} _focus={{}}>
        <Text fontSize="xl">
          {showTeams ? <ChevronDownIcon /> : <ChevronRightIcon />}
          對戰組合
        </Text>
      </Button>
      <Divider borderColor="gray.400" my="2" />
      <Collapse in={showTeams}>
        <SimpleGrid columns={2} spacing={10}>
          <Team teamSide="away" form={awayForm} />
          <Team teamSide="home" form={homeForm} />
        </SimpleGrid>
        {awayTeamName && homeTeamName && (
          <>
            <Text fontSize="lg" mt="5" mb="2">
              計分板
            </Text>
            <FormControl>
              <FormLabel>總局數</FormLabel>
              <Select name="innings" ref={register()} size="xs">
                {[...Array(28).keys()].map((inningNumber) => (
                  <option key={inningNumber} value={inningNumber}>
                    {inningNumber === 0 ? '無局數紀錄' : inningNumber}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Grid templateColumns={`3fr repeat(${parseInt(innings) + 3}, 1fr)`} gap={1} bg="gray.200" mt="5" py="2">
              <Text textAlign="center">隊名</Text>
              {[...Array(parseInt(innings))].map((_, index) => (
                <Text key={`scores-name-${index}`} textAlign="center">
                  {index + 1}
                </Text>
              ))}
              <Text textAlign="center">R</Text>
              <Text textAlign="center">H</Text>
              <Text textAlign="center">E</Text>
            </Grid>
            <TeamScoreBoard teamSide="away" infoForm={infoForm} form={awayForm} />
            <TeamScoreBoard teamSide="home" infoForm={infoForm} form={homeForm} />
          </>
        )}
      </Collapse>
    </Box>
  );
};

export default Teams;
