import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid, VStack, HStack, Textarea, Flex, Button, Image, Text, Center } from '@chakra-ui/react';
import { FormControl, FormLabel, Input, InputGroup, InputRightAddon, InputLeftAddon, Select } from '@chakra-ui/react';
import { DeleteIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { filter } from 'ramda';
import { ConfirmAlertDialog, UploadMedia, LoadingAlertDialog } from '~~components';
import { LeagueResource } from '~~apis/resource';

const Edit = ({ league: originLeague, existNames, cancelEdit, completedEdit }) => {
  const [league, setLeague] = useState(originLeague);

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const {
    register,
    handleSubmit,
    errors,
    reset,
    // formState: { isDirty },
  } = useForm();

  useEffect(() => {
    setLeague(originLeague);
    reset(originLeague);
  }, [originLeague, reset]);

  const errorDisplay = (message) => (
    <Text fontSize="xs" color="red.500">
      * {message}
    </Text>
  );

  const updateLogoCoverImage = (type) => (result) => {
    setLeague({
      ...league,
      [`${type}_path`]: result.path,
      [`${type}_url`]: result.url,
    });
  };

  const updateIndexFeatureds = (result) => {
    league.index_featureds.push({
      path: result.path,
      url: result.url,
    });
    setLeague({
      ...league,
      index_featureds: league.index_featureds.slice(0),
    });
  };

  const deleteIndexFeatured = (index) => () => {
    league.index_featureds.splice(index, 1);
    setLeague({
      ...league,
      index_featureds: league.index_featureds.slice(0),
    });
  };

  const back = () => {
    reset(originLeague);
    cancelEdit();
  };

  const onSubmit = (data) => {
    const submitLeague = {
      ...league,
      ...data,
    };

    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');
    LeagueResource.updateLeague({ leagueUniqid: league.uniqid, data: submitLeague })
      .then(() => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage('儲存成功！');
        setLeague(submitLeague);
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      completedEdit(league);
    }
  };

  return (
    <Box onSubmit={handleSubmit(onSubmit)}>
      <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={back}>
        取消
      </Button>
      <form>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel htmlFor="league-name">
              <Flex alignItems="center">
                主辦單位名稱
                {errors.name &&
                  errorDisplay(errors.name.type === 'required' ? '主辦單位名稱不得為空' : '主辦單位名稱重複')}
              </Flex>
            </FormLabel>
            <Input
              id="league-name"
              size="sm"
              type="text"
              name="name"
              ref={register({
                required: true,
                validate: {
                  exists: (value) => filter((n) => n !== originLeague.name, existNames).includes(value) === false,
                },
              })}
            />
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              舉辦賽事層級
              {errors.type && errorDisplay('請選擇舉辦賽事層級')}
            </Flex>
            <Select name="type" placeholder="舉辦賽事層級" ref={register({ required: true })} size="sm" mb="2">
              <option value="UNDER_ELEMENTARY_SCHOOL">國小以下</option>
              <option value="ELEMENTARY_SCHOOL">少棒（國小為主）</option>
              <option value="JUNIOR_HIGH_SCHOOL">青少棒（國中為主）</option>
              <option value="SENIOR_HIGH_SCHOOL">青棒（高中為主）</option>
              <option value="COLLEGE">大專</option>
              <option value="AFTER_COLLEGE">成棒（社會人）</option>
              <option value="OTHER">其它（不分年齡的社區球隊等）</option>
            </Select>
          </FormControl>
          <SimpleGrid columns={2} spacing={2} w="100%">
            <FormControl>
              <HStack spacing={5}>
                <FormLabel htmlFor="league-logo-url">Logo - 長寬比 1：1</FormLabel>
                <UploadMedia id="league-logo-url" updateMedia={updateLogoCoverImage('logo')} iconSize={5} />
              </HStack>
              {league.logo_url && <Image my="1" height="150px" src={league.logo_url} />}
            </FormControl>
            <FormControl>
              <HStack spacing={5}>
                <FormLabel htmlFor="league-cover-url">封面 - 長寬比 4：3</FormLabel>
                <UploadMedia id="league-cover-url" updateMedia={updateLogoCoverImage('cover')} iconSize={5} />
              </HStack>
              {league.cover_url && <Image my="1" height="150px" src={league.cover_url} />}
            </FormControl>
          </SimpleGrid>
          <FormControl>
            <FormLabel htmlFor="league-description">
              <Flex alignItems="center">
                主辦單位簡介（200字內）
                {errors.description && errorDisplay('簡介不得為空且最多 200 字')}
              </Flex>
            </FormLabel>
            <Textarea
              size="lg"
              id="league-description"
              h="20vh"
              name="description"
              ref={register({ required: true, maxLength: 200 })}
            />
          </FormControl>
          <FormControl>
            <SimpleGrid columns={4} spacing={2}>
              <InputGroup size="sm">
                <Input type="text" name="index_stats.total_years" ref={register} />
                <InputRightAddon children="年歷史" />
              </InputGroup>
              <InputGroup size="sm">
                <Input type="text" name="index_stats.total_teams" ref={register} />
                <InputRightAddon children="支球隊相互切磋" />
              </InputGroup>
              <InputGroup size="sm">
                <InputLeftAddon children="共" />
                <Input type="text" name="index_stats.total_players" ref={register} />
                <InputRightAddon children="位球員參與" />
              </InputGroup>
            </SimpleGrid>
          </FormControl>
          <FormControl>
            <FormLabel>
              <HStack spacing={5}>
                <FormLabel htmlFor="league-index-featureds">首頁精選照片 - 長寬比 4：3；五張為佳</FormLabel>
                {league.index_featureds.length < 5 && (
                  <UploadMedia id="league-index-featureds" updateMedia={updateIndexFeatureds} iconSize={5} />
                )}
              </HStack>
            </FormLabel>
            <SimpleGrid columns={3} spacing={2}>
              {league.index_featureds.map((media, index) => (
                <Box boxSize="xs" boxShadow="md" p="2" position="relative">
                  <ConfirmAlertDialog
                    buttonStyles={{ variant: 'ghost', position: 'absolute', top: '2', right: '2' }}
                    icon={<DeleteIcon />}
                    title="刪除媒體檔"
                    content="確定要刪除該媒體檔嗎？"
                    confirmColor="orange"
                    handleConfirm={deleteIndexFeatured(index)}
                  />
                  <Center w="100%" h="100%">
                    <Image src={media.url} maxW="100%" maxH="100%" />
                  </Center>
                </Box>
              ))}
            </SimpleGrid>
          </FormControl>
          <Button type="submit">確認送出</Button>
        </VStack>
      </form>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={'更新主辦單位設定'}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </Box>
  );
};

export default Edit;
