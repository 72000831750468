import React from 'react';
import { Button, Text, useToast } from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { SeasonResource } from '~~apis/resource';

const GroupDelete = ({ seasonUniqid, name, reloadRequired, size = 'md' }) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  const removeGroup = () => {
    SeasonResource.deleteSeasonGroup({ seasonUniqid, name })
      .then(() => {
        reloadRequired();
      })
      .catch((e) => {
        toast({
          title: '刪除分類失敗',
          description: e.toString(),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <>
      <Button colorScheme="red" onClick={() => setIsOpen(true)} size={size}>
        刪除
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              刪除分類
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>確定要刪除此分類？</Text>
              <Text>{name}</Text>
              <Text>此動作不可回復，且可能重新排序現有賽事</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button colorScheme="red" onClick={removeGroup} ml={3}>
                確定刪除
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default GroupDelete;
