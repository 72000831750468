import React, { useEffect, useState, Fragment } from 'react';
import { Box, Flex, Spinner, Text, Input, FormControl, FormLabel, IconButton } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Image, useToast, Icon, Grid } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { MdAttachMoney } from 'react-icons/md';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { reverse, find } from 'ramda';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { FeatureTitle, ConfirmAlertDialog } from '~~components';
import { FirstBaseResource, LocationResource } from '~~apis/resource';
import { INITIAL_TEAM_DATA } from './Consts';
import Edit from './components/Edit';
import ImportGame from './components/ImportGame';
import EditGame from './components/EditGame';
import { exportTeamPbP } from './service';

const FirstBase = () => {
  const toast = useToast();
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState({});
  const [action, setAction] = useState('LIST_TEAM');

  const [editingGame, setEditingGame] = useState(null);

  const [locations, setLocations] = useState([]);

  const { register, watch } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const [originQuery, setOriginQuery] = useState('INIT');
  const teamQuery = watch('query');

  const [checkGamesIndex, setCheckGamesIndex] = useState(-1);

  useEffect(() => {
    LocationResource.getLocations().then((data) => {
      setLocations(data.data);
    });
  }, []);

  useEffect(() => {
    const query = teamQuery ? teamQuery : '';
    if (originQuery === query) {
      return;
    }

    setAction('LOADING');
    FirstBaseResource.getTeams({ query }).then((data) => {
      setTeams(data.data);
      setAction('LIST_TEAM');
    });
    setOriginQuery(query);
  }, [originQuery, teamQuery]);

  const importGame = (uniqid) => () => {
    const selectedTeam = find((t) => t.uniqid === uniqid)(teams);
    setTeam(selectedTeam);
    setAction('IMPORT_GAME');
  };

  const handleCheckGamesIndex = (index) => () => {
    if (checkGamesIndex === index) {
      return setCheckGamesIndex(-1);
    }
    setCheckGamesIndex(index);
  };

  const updateTeam = (uniqid) => () => {
    const selectedTeam = find((t) => t.uniqid === uniqid)(teams);
    setTeam(selectedTeam);
    setAction('EDIT_TEAM');
  };

  const newTeam = () => {
    setTeam(INITIAL_TEAM_DATA);
    setAction('NEW_TEAM');
  };

  const cancelEdit = () => {
    setTeam({});
    setAction('LIST_TEAM');
  };

  const completedEdit = () => {
    FirstBaseResource.getTeams({ query: teamQuery }).then((data) => {
      setTeams(data.data);
      setAction('LIST_TEAM');
    });
  };

  const editGame = (teamUniqid, gameUniqid) => () => {
    const selectedTeam = find((t) => t.uniqid === teamUniqid)(teams);
    const selectedGame = find((g) => g.uniqid === gameUniqid, selectedTeam.games);
    if (!selectedTeam || !selectedGame) {
      return;
    }

    FirstBaseResource.getGames({ teamUniqid, gameUniqids: [gameUniqid] }).then(({ data }) => {
      setTeam(selectedTeam);
      setEditingGame(data[0]);
      setAction('EDIT_GAME');
    });
  };

  const deleteGame = (gameUniqid) => () => {
    const team = teams[checkGamesIndex];
    if (!team) {
      return;
    }

    FirstBaseResource.deleteGame({ teamUniqid: team.uniqid, gameUniqid })
      .then(() => {
        toast({
          title: '刪除成功！',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
        setAction('LOADING');
        FirstBaseResource.getTeams({ query: teamQuery }).then((data) => {
          setTeams(data.data);
          setAction('LIST_TEAM');
        });
      })
      .catch((e) => {
        toast({
          title: `刪除失敗！${e}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Box w="100%">
      <FeatureTitle title="First Base" />
      {['LIST_TEAM', 'LOADING'].includes(action) && (
        <>
          <FormControl my="10">
            <FormLabel>球隊搜尋</FormLabel>
            <Input name="query" ref={register({ required: true })} size="xs" />
          </FormControl>
          {action === 'LOADING' && <Spinner />}
          <Text>球隊列表</Text>
          <Table size="sm" variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>球隊名稱</Th>
                <Th>球隊 Icon</Th>
                <Th>操作</Th>
                <Th>使用期限</Th>
              </Tr>
            </Thead>
            <Tbody>
              {teams.map((team, index) => (
                <Fragment key={team.uniqid}>
                  <Tr>
                    <Td whiteSpace="nowrap">
                      {team.name}
                      <br />
                      <small>({team.full_name})</small>
                      <br />
                      <small>{team.uniqid}</small>
                    </Td>
                    <Td>
                      {team.icon_url ? (
                        <Image boxSize="64px" objectFit="cover" src={team.icon_url} alt={team.name} />
                      ) : (
                        '無'
                      )}
                    </Td>
                    <Td>
                      <Flex justifyContent="space-between">
                        <Flex>
                          <Button size="xs" colorScheme="green" onClick={importGame(team.uniqid)}>
                            匯入賽事
                          </Button>
                          <Button size="xs" onClick={handleCheckGamesIndex(index)}>
                            賽事列表
                          </Button>
                          <Button size="xs" colorScheme="orange" onClick={updateTeam(team.uniqid)}>
                            編輯
                          </Button>
                          <Link to={`/permission/firstbase/${team.uniqid}`}>
                            <Button size="xs" colorScheme="purple">
                              權限管理
                            </Button>
                          </Link>
                          <IconButton
                            size="xs"
                            icon={<ExternalLinkIcon />}
                            as="a"
                            target="_blank"
                            href={`${process.env.REACT_APP_MAINPAGE_URL}/first-base/${team.uniqid}`}
                          />
                        </Flex>
                        <ConfirmAlertDialog
                          buttonStyles={{ size: 'xs', colorScheme: 'orange' }}
                          buttonDisplay="匯出逐打席"
                          title="匯出逐打席資料"
                          content={`確定要匯出 ${team.full_name} 逐打席資料嗎？ 可能需要一點時間`}
                          confirmColor="orange"
                          handleConfirm={exportTeamPbP(team)}
                        />
                      </Flex>
                    </Td>
                    <Td>{team.due_date}</Td>
                  </Tr>
                  {checkGamesIndex === index && (
                    <Tr key={`${team.uniqid}-games`}>
                      <Td></Td>
                      <Td colSpan="2">
                        {reverse(team.games).map((game) => (
                          <Grid templateColumns="3fr 1fr" key={game.uniqid}>
                            <Text padding="0.5rem 0" borderBottom="1px solid gray">
                              {`${format(new Date(game.started_at), 'yyyy/MM/dd HH:mm')}` +
                                ` vs ${game.opponent_team_name}`}
                            </Text>
                            <Flex borderBottom="1px solid gray" alignItems="center">
                              <Button size="xs" colorScheme="orange" onClick={editGame(team.uniqid, game.uniqid)}>
                                編輯
                              </Button>
                              <ConfirmAlertDialog
                                buttonStyles={{ size: 'xs', colorScheme: 'red' }}
                                buttonDisplay="刪除"
                                title="刪除賽事"
                                content={`確定要刪除 ${format(new Date(game.started_at), 'yyyy/MM/dd HH:mm')} 對上 ${
                                  game.opponent_team_name
                                } 的賽事嗎？`}
                                confirmColor="red"
                                handleConfirm={deleteGame(game.uniqid)}
                              />
                              <Text fontSize="sm">{game.uniqid}</Text>
                            </Flex>
                          </Grid>
                        ))}
                        {team.games.length === 0 && <Text>無賽事</Text>}
                      </Td>
                      <Td></Td>
                    </Tr>
                  )}
                </Fragment>
              ))}
              <Tr>
                <Td>
                  <Button leftIcon={<Icon as={MdAttachMoney} />} size="xs" onClick={newTeam}>
                    啟用球隊
                  </Button>
                </Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      )}
      {['NEW_TEAM', 'EDIT_TEAM'].includes(action) && (
        <Edit team={team} cancelEdit={cancelEdit} completedEdit={completedEdit} />
      )}
      {action === 'IMPORT_GAME' && (
        <ImportGame
          team={team}
          originPlayers={team.players}
          locations={locations}
          cancelImport={cancelEdit}
          completedImport={completedEdit}
        />
      )}
      {action === 'EDIT_GAME' && (
        <EditGame
          team={team}
          game={editingGame}
          locations={locations}
          cancelEdit={cancelEdit}
          completedEdit={completedEdit}
        />
      )}
    </Box>
  );
};

export default FirstBase;
