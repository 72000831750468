export const INITIAL_TEAM_DATA = {
  uniqid: '',
  name: '',
  icon_url: '',
  icon_path: '',
  due_date: '',
};

export const INITIAL_GAME_DATA = {
  opponent_team_name: '',
  started_at: '',
  tags: [],
  info: {
    side: '',
    innings: 0,
    end_outs: 3,
    is_winner: true,
    away_scores: [],
    home_scores: [],
    location_uniqid: '',
  },
  pbp: [],
};

export const INITIAL_TAG_DATA = {
  name: '',
  content: '',
};

export const TEAM_STATUS_MAPPING = {
  ENABLED: '啟用',
  DISABLED: '停用',
};
