import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, Spinner, Text, Spacer, IconButton, Divider } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { find, propEq } from 'ramda';
import { useForm } from 'react-hook-form';
import { FeatureTitle } from '~~components';
import { SeasonResource, TeamResource, GameDetailResource } from '~~apis/resource';
import ListSeason from './components/ListSeason';
import ListGame from './components/ListGame';
import Info from './components/Info';
import Teams from './components/Teams';
import Panel from './components/Panel';
import BoxScore from './components/BoxScore';
import PlayerDisplay from './components/PlayerDisplay';
import { formatBoxScoreForForm } from './Consts';
import { useGameDetailDispatch } from './hooks';
import Actions from './Actions';

const orderFielders = (batters, pitchers, players) => {
  const fielders = [];
  batters.forEach((batter) => {
    const fielder = find((f) => f.info.number === batter.player.number, fielders);
    if (fielder) {
      return;
    }

    const player = find((p) => p.info.number === batter.player.number, players);
    if (!player) {
      return;
    }
    fielders.push(player);
  });

  pitchers.forEach((pitcher) => {
    const fielder = find((f) => f.info.number === pitcher.player.number, fielders);
    if (fielder) {
      return;
    }

    const player = find((p) => p.info.number === pitcher.player.number, players);
    if (!player) {
      return;
    }
    fielders.push(player);
  });

  return fielders;
};

const destructGame = (game, seasonTeams) => {
  const mirorGame = JSON.parse(JSON.stringify(game));

  formatBoxScoreForForm(mirorGame.away.batters);
  formatBoxScoreForForm(mirorGame.away.pitchers);
  formatBoxScoreForForm(mirorGame.away.players);
  formatBoxScoreForForm(mirorGame.home.batters);
  formatBoxScoreForForm(mirorGame.home.pitchers);
  formatBoxScoreForForm(mirorGame.home.players);

  const info = {
    ...mirorGame.info,
    group: mirorGame.group,
    innings: mirorGame.info.innings.toString(),
    uniqid: mirorGame.uniqid,
  };

  const away = {
    ...mirorGame.away,
    players: orderFielders(mirorGame.away.batters, mirorGame.away.pitchers, mirorGame.away.players),
    team: find(propEq('name', mirorGame.away.team))(seasonTeams),
    team_name: mirorGame.away.team,
    runs: mirorGame.away.runs.toString(),
    hits: mirorGame.away.hits.toString(),
    errors: mirorGame.away.errors.toString(),
  };

  const home = {
    ...mirorGame.home,
    players: orderFielders(mirorGame.home.batters, mirorGame.home.pitchers, mirorGame.home.players),
    team: find(propEq('name', mirorGame.home.team))(seasonTeams),
    team_name: mirorGame.home.team,
    runs: mirorGame.home.runs.toString(),
    hits: mirorGame.home.hits.toString(),
    errors: mirorGame.home.errors.toString(),
  };
  return {
    info: JSON.parse(JSON.stringify(info)),
    away: JSON.parse(JSON.stringify(away)),
    home: JSON.parse(JSON.stringify(home)),
  };
};

// let renderCount = 0;
const GameDetail = () => {
  // renderCount++;
  // console.log(`GameDetail ${renderCount}`);

  const gameDetailDispatch = useGameDetailDispatch();

  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState({});

  const [action, setAction] = useState('LOADING');

  const location = useLocation();
  const parmas = new URLSearchParams(location.search);
  const urlSeasonUniqid = parmas.get('season');
  const urlGameUniqid = parmas.get('game');

  const infoForm = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const awayForm = useForm();
  const homeForm = useForm();

  useEffect(() => {
    SeasonResource.getSeasons().then((data) => {
      setSeasons(data.data);
      if (!urlSeasonUniqid) {
        setAction('LIST_SEASON');
        return;
      }

      const selectedSeason = find((s) => s.uniqid === urlSeasonUniqid)(data.data);
      setSeason(selectedSeason);
      setAction('LOADING');
    });
  }, [urlSeasonUniqid]);

  useEffect(() => {
    if (!season.uniqid) {
      return;
    }

    TeamResource.getSeasonTeams({ seasonUniqid: season.uniqid }).then((data) => {
      const seasonTeamData = data.data;
      gameDetailDispatch({ type: Actions.UPDATE_SEASON_TEAMS, payload: seasonTeamData });

      SeasonResource.getSeasonGroups({ seasonUniqid: season.uniqid }).then((data) => {
        gameDetailDispatch({ type: Actions.UPDATE_SEASON_GROUPS, payload: data.data });

        GameDetailResource.getGames({ seasonUniqid: season.uniqid }).then((data) => {
          gameDetailDispatch({ type: Actions.UPDATE_GAMES, payload: data.data });

          if (!urlGameUniqid) {
            setAction('LIST_GAME');
            return;
          }

          const selectedGame = find((g) => g.uniqid === urlGameUniqid)(data.data);
          const { info, away, home } = destructGame(selectedGame, seasonTeamData);
          gameDetailDispatch({ type: Actions.UPDATE_GAME_NODES, payload: info.nodes });
          infoForm.reset(info);
          awayForm.reset(away);
          homeForm.reset(home);
          setAction('EDIT_GAME');
        });
      });
    });
    // eslint-disable-next-line
  }, [gameDetailDispatch, awayForm.reset, homeForm.reset, infoForm.reset, season, urlGameUniqid]);

  const listGame = (uniqid) => () => {
    const selectedSeason = find((s) => s.uniqid === uniqid)(seasons);
    setSeason(selectedSeason);
    gameDetailDispatch({ type: Actions.UPDATE_GAMES, payload: [] });
    setAction('LOADING');
  };

  const hideGames = () => {
    gameDetailDispatch({ type: Actions.UPDATE_GAMES, payload: [] });
    setAction('LIST_SEASON');
  };

  const editGame = (uniqid) => () => {
    window.location.href = `${location.pathname}?season=${season.uniqid}&game=${uniqid}`;
  };

  const gameCreated = () => {
    gameDetailDispatch({ type: Actions.UPDATE_GAMES, payload: [] });
    setAction('LOADING');
    GameDetailResource.getGames({ seasonUniqid: season.uniqid }).then((data) => {
      gameDetailDispatch({ type: Actions.UPDATE_GAMES, payload: data.data });
      setAction('LIST_GAME');
    });
  };

  const gameDeleted = () => {
    gameCreated();
  };

  return (
    <Box w="100%">
      <FeatureTitle title="賽事管理" />
      <Flex>
        <Box w={action === 'EDIT_GAME' ? '80%' : '100%'}>
          {action === 'LOADING' && <Spinner />}
          {action === 'LIST_GAME' && (
            <>
              <Flex>
                <Text>{season.title}</Text>
                <Spacer />
                <IconButton icon={<CloseIcon />} size="xs" variant="ghost" onClick={hideGames} />
              </Flex>
              <ListGame seasonUniqid={season.uniqid} startEdit={editGame} created={gameCreated} deleted={gameDeleted} />
              <Divider my="5" />
            </>
          )}
          {(action === 'LIST_SEASON' || action === 'LIST_GAME') && (
            <>
              <Text>賽季列表</Text>
              <ListSeason seasons={seasons} listGame={listGame} />
            </>
          )}
          {action === 'EDIT_GAME' && (
            <>
              <Info infoForm={infoForm} />
              <Teams infoForm={infoForm} awayForm={awayForm} homeForm={homeForm} />
              <BoxScore teamSide="away" form={awayForm} />
              <BoxScore teamSide="home" form={homeForm} />
              <PlayerDisplay awayForm={awayForm} homeForm={homeForm} />
            </>
          )}
        </Box>
        {action === 'EDIT_GAME' && (
          <Box w="20%">
            <Panel seasonUniqid={season.uniqid} infoForm={infoForm} awayForm={awayForm} homeForm={homeForm} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default GameDetail;
