import React, { useState, useEffect } from 'react';
import { Button, VStack, HStack, Alert, AlertIcon, Box, Text } from '@chakra-ui/react';
import { Heading, Switch, FormControl, FormLabel, Flex, Image, Spinner } from '@chakra-ui/react';
import { InputGroup, Input, InputLeftAddon } from '@chakra-ui/react';
import { RadioGroup, Radio } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader } from '@chakra-ui/react';
import { ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { find, propEq, isNil, isEmpty } from 'ramda';
import { UploadMedia, SeasonTeamDelete } from '~~components';
import { TeamResource } from '~~apis/resource';

const TeamCUD = ({ seasonTeam, seasonUniqid, seasonTeams, seasonTeamCUD }) => {
  const isNew = !seasonTeam;
  let initTeamOptions = [];
  if (isNew) {
    seasonTeam = {
      name: '',
      origin: {
        name: '',
        uniqid: 'none',
        icon_url: '',
      },
    };
  } else {
    initTeamOptions = [
      {
        experiences: [],
        icon_url: seasonTeam.origin.icon_url,
        name: seasonTeam.origin.name,
        uniqid: seasonTeam.origin.uniqid,
      },
    ];
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [teams, setTeams] = useState(initTeamOptions);
  const [possibleTeams, setPossibleTeams] = useState(initTeamOptions);
  const [sameNameTeam, setSameNameTeam] = useState({ ignore: false, showWarning: false });
  const [sameNameTeamExist, setSameNameTeamExist] = useState(false);
  const [teamLink, setTeamLink] = useState(seasonTeam.origin.uniqid);
  const [teamName, setTeamName] = useState(seasonTeam.origin.name);
  const [seasonTeamName, setSeasonTeamName] = useState(seasonTeam.name);
  const [sameNameInSeason, setSameNameInSeason] = useState(seasonTeam.name === seasonTeam.origin.name);
  const [iconPath, setIconPath] = useState('');
  const [iconUrl, setIconUrl] = useState(seasonTeam.origin.icon_url);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitAble, setSubmitable] = useState(false);

  useEffect(() => {
    setSameNameTeam({ ignore: false, showWarning: false });
    setSameNameTeamExist(false);
    setIconPath('');
    setIsLoading(false);
    setErrorMessage('');
  }, [isOpen]);

  useEffect(() => {
    let message = '';
    const existSeasonTeam = find(propEq('name', seasonTeamName))(seasonTeams);
    if (!isNil(existSeasonTeam) && seasonTeamName !== seasonTeam.name) {
      message = `該隊名已存在於此聯賽中`;
    }
    setErrorMessage(message);

    if (isEmpty(seasonTeamName) || !isEmpty(errorMessage)) {
      return setSubmitable(false);
    }

    return setSubmitable(true);
  }, [errorMessage, seasonTeams, seasonTeamName, seasonTeam.name]);

  useEffect(() => {
    if (isEmpty(teamName)) {
      return setTeams([]);
    }

    TeamResource.searchTeams({ query: teamName }).then((data) => {
      setTeams(data.data);
    });
  }, [teamName]);

  useEffect(() => {
    const similarLimit = 6;
    const displayTeams = [];
    let sameNameTeamExist = false;
    teams.forEach((team) => {
      if (displayTeams.length > similarLimit && team.name !== teamName) {
        return;
      }

      if (team.name === teamName) {
        sameNameTeamExist = true;
      }
      displayTeams.push(team);
    });
    setPossibleTeams(displayTeams);
    setSameNameTeamExist(sameNameTeamExist);
    if (!find(propEq('uniqid', teamLink))(teams)) {
      setTeamLink('none');
    }
  }, [teamLink, teamName, teams]);

  useEffect(() => {
    if (teamLink === 'none') {
      return;
    }

    const existTeam = find(propEq('uniqid', teamLink))(teams);
    setTeamName(existTeam.name);
  }, [teamLink, teams]);

  const handleTeamNameChange = (e) => {
    setTeamName(e.target.value);
    if (sameNameInSeason) {
      setSeasonTeamName(e.target.value);
    }
  };

  const handleSeasonTeamNameChange = (e) => {
    setSeasonTeamName(e.target.value);
  };

  const handleSameName = () => {
    if (!sameNameInSeason) {
      setSeasonTeamName(teamName);
    }
    setSameNameInSeason(!sameNameInSeason);
  };

  const updateIcon = (data) => {
    setIconPath(data.path);
    setIconUrl(data.url);
  };

  const handleSubmit = () => {
    let teamUniqid = '';
    if (teamLink !== 'none') {
      teamUniqid = teamLink;
    } else if (sameNameTeamExist && !sameNameTeam.ignore && !sameNameTeam.showWarning) {
      return setSameNameTeam({ ...sameNameTeam, showWarning: true });
    }

    setIsLoading(true);
    const functionCalled = isNew ? 'createSeasonTeam' : 'updateSeasonTeam';
    TeamResource[functionCalled]({
      seasonUniqid,
      originSeasonTeamName: seasonTeam.name,
      teamUniqid,
      teamName,
      seasonTeamName,
      iconPath,
    })
      .then(() => {
        onClose();
        seasonTeamCUD();
      })
      .catch((e) => {
        setErrorMessage(e.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isNew ? (
        <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen} size="sm">
          登錄
        </Button>
      ) : (
        <Button colorScheme="orange" onClick={onOpen} size="xs">
          編輯球隊
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>球隊{isNew ? '登錄' : `編輯 ${seasonTeam.name}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={8}>
                <InputGroup>
                  <InputLeftAddon children="球隊名稱" />
                  <Input
                    type="text"
                    borderLeftRadius="0"
                    placeholder="球隊名稱"
                    value={teamName}
                    onChange={handleTeamNameChange}
                    isDisabled={teamLink !== 'none'}
                  />
                </InputGroup>
                <FormControl as={Flex} justifyContent="start" alignItems="start">
                  <FormLabel htmlFor="seam-name">於此賽季使用別名</FormLabel>
                  <Switch size="sm" isChecked={!sameNameInSeason} onChange={handleSameName} />
                </FormControl>
                {!sameNameInSeason && (
                  <InputGroup>
                    <InputLeftAddon children="別名" />
                    <Input
                      type="text"
                      borderLeftRadius="0"
                      placeholder="別名"
                      value={seasonTeamName}
                      onChange={handleSeasonTeamNameChange}
                    />
                  </InputGroup>
                )}
                {teamLink === 'none' && (
                  <FormControl>
                    <HStack spacing={5}>
                      <FormLabel>球隊 Logo</FormLabel>
                      <UploadMedia updateMedia={updateIcon} iconSize={5} />
                    </HStack>
                    {iconUrl && <Image my="1" height="150px" src={iconUrl} />}
                  </FormControl>
                )}
                {errorMessage && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
              </VStack>
              {possibleTeams.length > 0 && (
                <Box p={2}>
                  <Text>連動球隊</Text>
                  <RadioGroup onChange={setTeamLink} value={teamLink}>
                    <VStack p={3} spacing={2} bg="gray.200" borderRadius={10} align="left">
                      <Radio value="none">不連動</Radio>
                      {possibleTeams.map((team) => (
                        <Radio key={`team-registry-exp-radio-${team.uniqid}`} value={team.uniqid}>
                          <HStack>
                            <Heading size="md">{team.name}</Heading>
                            <Text fontSize="sm" as="i">
                              經歷
                            </Text>
                            {team.experiences.map((experience) => (
                              <Text key={`team-registry-exp-${experience.season}-${experience.team}`} fontSize="xs">
                                {`${experience.season_year}-${experience.season} ${experience.team}`} <br />
                              </Text>
                            ))}
                          </HStack>
                        </Radio>
                      ))}
                    </VStack>
                  </RadioGroup>
                </Box>
              )}
              {sameNameTeam.showWarning && (
                <Alert status="warning">
                  <AlertIcon />
                  如確認相同名稱的球隊不需連動，請繼續送出，紀錄將會拆開計算。否則請選擇連動
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              {isLoading ? (
                <Spinner />
              ) : (
                <HStack w="100%" justify="space-between">
                  {isNew ? (
                    <div></div>
                  ) : (
                    <SeasonTeamDelete
                      seasonUniqid={seasonUniqid}
                      seasonTeamName={seasonTeam.name}
                      reloadRequired={seasonTeamCUD}
                    />
                  )}
                  <HStack spacing={2}>
                    <Button mr={3} variant="ghost" onClick={onClose}>
                      取消
                    </Button>
                    <Button colorScheme="green" isDisabled={!submitAble} onClick={handleSubmit}>
                      送出
                    </Button>
                  </HStack>
                </HStack>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default TeamCUD;
