import React from 'react';
import { Button, IconButton, Flex } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import NewLeague from './New';

const List = ({ leagues, listSeason, startEdit, created }) => {
  return (
    <Table size="sm" variant="striped" colorScheme="gray">
      <Thead>
        <Tr>
          <Th>單位名稱</Th>
          <Th>操作</Th>
          <Th isNumeric>連結</Th>
        </Tr>
      </Thead>
      <Tbody>
        {leagues.map((league) => (
          <Tr key={league.uniqid}>
            <Td w="1%" whiteSpace="nowrap">
              {league.name}
            </Td>
            <Td>
              <Flex>
                <Button size="xs" colorScheme="orange" onClick={startEdit(league.uniqid)}>
                  編輯
                </Button>
                <Button size="xs" colorScheme="blue" onClick={listSeason(league.uniqid)}>
                  顯示賽事
                </Button>
                <Link to={`/permission/league/${league.uniqid}`}>
                  <Button size="xs" colorScheme="purple">
                    權限管理
                  </Button>
                </Link>
              </Flex>
            </Td>
            <Td isNumeric>
              <IconButton
                size="xs"
                icon={<ExternalLinkIcon />}
                as="a"
                target="_blank"
                href={`${process.env.REACT_APP_MAINPAGE_URL}/league/${league.uniqid}`}
              />
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td>
            <NewLeague existNames={leagues.map((l) => l.name)} created={created} />
          </Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default List;
