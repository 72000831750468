import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getPurchasedPlugins: false,
  updatePurchasedPlugin: false,
};

const UserPlugin = {
  getPurchasedPlugins: ({ limit, page }) => {
    if (debugStatus.getPurchasedPlugins) {
    }

    return ApiService.get(`/api/plugins/purchased?limit=${limit}&page=${page}`);
  },

  updatePurchasedPlugin: ({ id, data }) => {
    if (debugStatus.updatePurchasedPlugin) {
    }

    return ApiService.put(`/api/plugins/purchased/${id}`, { data });
  },
};

export default UserPlugin;
