import React from 'react';
import { Box, Grid, IconButton, Text } from '@chakra-ui/react';
import { Input, Select } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { sum, filter } from 'ramda';
import { useFieldArray, Controller } from 'react-hook-form';
import { FIELDING_STAT_KEYS, POSITION_STAT, calcTeamEFromFielders } from '../Consts';

const FieldingBoxScore = ({
  id,
  recordIndex,
  form: { register, control, getValues, setValue },
  playerNumber,
  getPlayerNameByNumber,
  players,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `players[${recordIndex}].fieldings`,
  });

  const addFielderRecord = () => {
    const init = {
      position: '',
      order: fields.length === 0 ? 1 : (parseInt(fields[fields.length - 1].order) + 1).toString(),
    };
    FIELDING_STAT_KEYS.forEach((key) => {
      if (key === 'position' || key === 'order') {
        return;
      }
      init[key] = '0';
    });
    append(init);
  };

  const removeFielderRecord = (index) => () => {
    remove(index);
  };

  const calcTC = (onChange, index, param) => (e) => {
    onChange();

    const currentData = getValues().players[recordIndex].fieldings[index];
    currentData[param] = e.target.value;
    currentData.TC = sum(
      filter((value) => !Number.isNaN(parseInt(value)), [currentData.PO, currentData.A, currentData.E]),
    ).toString();
    setValue(`players[${recordIndex}].fieldings[${index}][${param}]`, currentData[param]);
    setValue(`players[${recordIndex}].fieldings[${index}].TC`, currentData.TC);

    if (param === 'E') {
      setValue('errors', calcTeamEFromFielders(getValues().players));
    }
  };

  if (fields.length === 0) {
    return (
      <Grid
        key={id}
        templateColumns={`3fr 1fr 2fr repeat(${FIELDING_STAT_KEYS.length - 1}, 1fr)`}
        gap={1}
        bg="gray.200"
        my="1"
        py="2"
      >
        <Select
          size="sm"
          name={`players[${recordIndex}].info.number`}
          ref={register()}
          disabled={true}
          isReadOnly={true}
        >
          <option value={playerNumber}>{`${playerNumber} ${getPlayerNameByNumber(playerNumber, players)}`}</option>
        </Select>
        <Box>
          <IconButton icon={<AddIcon />} onClick={addFielderRecord} size="sm" />
        </Box>
      </Grid>
    );
  }

  return fields.map((fielding, index) => (
    <Grid
      key={`${id}-${index}`}
      templateColumns={`3fr 1fr 2fr repeat(${FIELDING_STAT_KEYS.length - 1}, 1fr)`}
      gap={1}
      bg="gray.200"
      my="1"
      py="2"
    >
      {index === 0 ? (
        <Select
          key={`${fielding.id}-number`}
          size="sm"
          name={`players[${recordIndex}].info.number`}
          ref={register()}
          disabled={true}
          isReadOnly={true}
        >
          <option value={playerNumber}>{`${playerNumber} ${getPlayerNameByNumber(playerNumber, players)}`}</option>
        </Select>
      ) : (
        <Text key={`${fielding.id}-number`} textAlign="center"></Text>
      )}
      <Input
        key={`${fielding.id}-order`}
        size="sm"
        type="text"
        variant="flushed"
        textAlign="center"
        name={`players[${recordIndex}].fieldings[${index}].order`}
        ref={register()}
        defaultValue={fielding.order.toString()}
      />
      <Select
        key={`${fielding.id}-position`}
        name={`players[${recordIndex}].fieldings[${index}].position`}
        ref={register()}
        defaultValue={fielding.position.toString()}
        placeholder="請選擇"
        size="sm"
      >
        {Object.keys(POSITION_STAT).map((position) => (
          <option key={`${fielding.id}-position-${position}`} value={position}>
            {POSITION_STAT[position]}
          </option>
        ))}
      </Select>
      <Input
        key={`${fielding.id}-TC`}
        size="sm"
        type="text"
        variant="unstyled"
        textAlign="center"
        name={`players[${recordIndex}].fieldings[${index}].TC`}
        ref={register()}
        value={fielding.TC.toString()}
        disabled={true}
        isReadOnly={true}
      />
      {['PO', 'A', 'E'].map((key) => (
        <Controller
          control={control}
          key={`${fielding.id}-${key}`}
          name={`players[${recordIndex}].fieldings[${index}][${key}]`}
          defaultValue={fielding[key].toString()}
          render={({ value, onChange }) => (
            <Input
              size="sm"
              type="text"
              variant="flushed"
              textAlign="center"
              defaultValue={value}
              onChange={calcTC(onChange, index, key)}
            />
          )}
        />
      ))}
      {filter((k) => !['order', 'position', 'TC', 'PO', 'A', 'E'].includes(k), FIELDING_STAT_KEYS).map((key) => (
        <Input
          key={`${fielding.id}-${key}`}
          size="sm"
          type="text"
          variant="flushed"
          textAlign="center"
          name={`players[${recordIndex}].fieldings[${index}][${key}]`}
          ref={register()}
          defaultValue={fielding[key].toString()}
        />
      ))}
      <IconButton
        key={`${fielding.id}-removeFielderRecord`}
        size="sm"
        icon={<DeleteIcon />}
        onClick={removeFielderRecord(index)}
      />
      {index + 1 === fields.length && (
        <>
          <Box key={`${fielding.id}-addFielderRecord`}></Box>
          <Box key={`${fielding.id}-addFielderRecord-`}>
            <IconButton icon={<AddIcon />} onClick={addFielderRecord} size="sm" />
          </Box>
        </>
      )}
    </Grid>
  ));
};

export default FieldingBoxScore;
