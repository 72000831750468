import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getLocations: false,
  createLocation: false,
  updateLocation: false,
  deleteLocation: false,
};

const Location = {
  getLocations: () => {
    if (debugStatus.getLocations) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [
          {
            address1: '雙十路一段16號',
            address2: '',
            address3: '',
            city: '台中市',
            comment: '',
            country: 'TW',
            cover_url: '',
            lat: 24.14851,
            lng: 120.688736,
            name: '台體大棒球場',
            postcode: '404',
            state: '',
            type: 'BASEBALL_FIELD',
            uniqid: 'fewa',
          },
          {
            address1: '橋新三路58號',
            address2: '',
            address3: '',
            city: '高雄市',
            comment: '',
            country: 'TW',
            cover_url: '',
            lat: 22.743296,
            lng: 120.3121,
            name: '高雄國慶青埔棒球場',
            postcode: '825',
            state: '',
            type: 'BASEBALL_FIELD',
            uniqid: 'ovUK',
          },
        ],
        error: false,
        message: 'Get locations successfully',
      });
    }

    return ApiService.get(`/api/locations`);
  },

  createLocation: ({ data }) => {
    if (debugStatus.createLocation) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          address1: '雙十路一段16號',
          address2: '',
          address3: '',
          city: '台中市',
          comment: '',
          country: 'TW',
          cover_url: '',
          lat: 24.14851,
          lng: 120.688736,
          name: '台體大棒球場',
          postcode: '404',
          state: '',
          type: 'BASEBALL_FIELD',
          uniqid: 'audk',
        },
        error: false,
        message: 'Create location successfully',
      });
    }

    return ApiService.post(`/api/locations`, { data });
  },

  updateLocation: ({ locationUniqid, data }) => {
    if (debugStatus.updateLocation) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          address1: '雙十路一段16號',
          address2: '',
          address3: '',
          city: '台中市',
          comment: '',
          country: 'TW',
          cover_url: '',
          lat: 24.14851,
          lng: 120.688736,
          name: '台體大棒球場',
          postcode: '404',
          state: '',
          type: 'BASEBALL_FIELD',
          uniqid: locationUniqid,
        },
        error: false,
        message: 'Update location successfully',
      });
    }

    return ApiService.put(`/api/locations/${locationUniqid}`, { data });
  },

  deleteLocation: ({ locationUniqid }) => {
    if (debugStatus.deleteLocation) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Delete location successfully',
      });
    }

    return ApiService.delete(`/api/locations/${locationUniqid}`);
  },
};

export default Location;
