import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Divider, useToast, Input } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { format as formatDate } from 'date-fns';
import { PluginOrderResource } from '~~apis/resource';
import { FeatureTitle } from '~~components';
import { findIndex } from 'ramda';

const orderStatus = {
  PENDING_PAYMENT: '待付款',
  PAYMENT_FAILURE: '付款失敗',
  CREATED: '成立',
  CANCELED: '已取消',
  REFUND_REQUESTED: '申請退款',
  REFUNDED: '已退款',
  NONE: '無',
};

const paymentType = {
  ECPAY: '綠界',
};

const receiptTypes = {
  PERSONAL: '個人',
  DONATE: '捐贈',
  COMPANY: '公司（統編）',
};

const PluginOrder = () => {
  const toast = useToast();
  const [pluginOrders, setPluginOrders] = useState([]);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPluginOrder, setSelectedPluginOrder] = useState({
    user: {
      uniqid: '',
      email: '',
      user_name: '',
      first_name: '',
      last_name: '',
      avatar_path: '',
      avatar_url: '',
    },
    uniqid: '',
    status: 'NONE',
    currency: 'NTD',
    payment_type: 'ECPAY',
    payment_id: '',
    payment_amount: 0,
    paid_at: null,
    buyer_name: '',
    buyer_phone: '',
    buyer_tel: '',
    buyer_city: '',
    buyer_postcode: '',
    buyer_street: '',
    receipt_type: 'PERSONAL',
    receipt_number: '',
    receipt_created_at: null,
    comment: '',
    created_at: null,
    items: [],
  });
  const [receiptNumber, setReceiptNumber] = useState('');
  const [receiptCreatedAt, setReceiptCreatedAt] = useState('');
  const [receiptInUpdate, setReceiptInUpdate] = useState(false);

  useEffect(() => {
    PluginOrderResource.getPluginOrders({ limit, page }).then(({ data }) => {
      setPluginOrders(data);
    });
  }, [limit, page]);

  const viewModal = (order) => () => {
    setSelectedPluginOrder(order);
    setReceiptNumber(order.receipt_number);
    setReceiptCreatedAt(!order.receipt_created_at ? '' : formatDate(new Date(order.receipt_created_at), 'yyyy-MM-dd'));
    setReceiptInUpdate(false);
    onOpen();
  };

  const handleUpdateReceipt = () => {
    if (!receiptInUpdate) {
      setReceiptInUpdate(true);
      return;
    }

    if (receiptNumber.trim() !== '' && receiptCreatedAt.trim() === '') {
      toast({ title: '請設定發票日期', status: 'error', second: 1 });
      return;
    }

    PluginOrderResource.updatePluginOrderReceipt({
      pluginOrderUniqid: selectedPluginOrder.uniqid,
      data: {
        receipt_number: receiptNumber.trim(),
        receipt_created_at: !receiptCreatedAt.trim() ? '' : formatDate(new Date(receiptCreatedAt.trim()), 'yyyy/MM/dd'),
      },
    })
      .then(({ data: newPluginOrder }) => {
        toast({ title: '更新成功！', status: 'success', second: 1 });
        setSelectedPluginOrder(newPluginOrder);
        const pluginIndex = findIndex((o) => o.uniqid === newPluginOrder.uniqid, pluginOrders);
        if (pluginIndex < 0) {
          window.location.reload();
          return;
        }

        const newPluginOrders = [...pluginOrders];
        newPluginOrders[pluginIndex] = newPluginOrder;
        setPluginOrders(newPluginOrders);
      })
      .catch((e) => {
        console.error(e);
        toast({ title: '更新失敗！', status: 'error', second: 1 });
      });
  };

  return (
    <Box w="100%">
      <FeatureTitle title="擴充套件訂單" />
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>訂單編號</Th>
            <Th>用戶</Th>
            <Th>狀態</Th>
            <Th>發票</Th>
            <Th>訂單金額</Th>
            <Th>建立時間</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {pluginOrders.map((pluginOrder, index) => (
            <Tr key={index}>
              <Td whiteSpace="nowrap">{pluginOrder.uniqid}</Td>
              <Td>{pluginOrder.user.user_name}</Td>
              <Td>{orderStatus[pluginOrder.status]}</Td>
              <Td {...(pluginOrder.status === 'CREATED' && !pluginOrder.receipt_created_at ? { color: 'red' } : {})}>
                {!pluginOrder.receipt_number ? '未開立' : pluginOrder.receipt_number}
              </Td>
              <Td>{`NT$ ${new Intl.NumberFormat('zh-TW').format(pluginOrder.payment_amount)}`}</Td>
              <Td>
                <Button size="xs" onClick={viewModal(pluginOrder)}>
                  檢視
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>訂單 {selectedPluginOrder.uniqid}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm">購買人</Text>
            <Text fontSize="md">
              {selectedPluginOrder.user.user_name}({selectedPluginOrder.user.email})
            </Text>
            <Divider opacity={0.2} my={2} />
            <Text fontSize="sm">發票人</Text>
            <Text fontSize="md">
              {selectedPluginOrder.buyer_name}({selectedPluginOrder.buyer_phone})
              {selectedPluginOrder.buyer_tel && `(${selectedPluginOrder.buyer_tel})`}
            </Text>
            <Text fontSize="md">{`${selectedPluginOrder.buyer_city} ${selectedPluginOrder.buyer_postcode} ${selectedPluginOrder.buyer_street}`}</Text>
            <Divider opacity={0.2} my={2} />
            <Text fontSize="sm">發票資訊</Text>
            <Text fontSize="md">{receiptTypes[selectedPluginOrder.receipt_type]}</Text>
            <Text fontSize="md">
              {selectedPluginOrder.receipt_number}(
              {!selectedPluginOrder.receipt_created_at
                ? '尚未開立'
                : formatDate(new Date(selectedPluginOrder.receipt_created_at), 'yyyy/MM/dd')}
              )
            </Text>
            <Divider opacity={0.2} my={2} />
            <Text fontSize="sm">訂單內容</Text>
            {selectedPluginOrder.items.map((item, i) => (
              <Text fontSize="md">
                {`${i + 1}. ${item.name} `}
                {`NT$ ${new Intl.NumberFormat('zh-TW').format(item.retail_price)} `}
                {`x${item.amount}`}
              </Text>
            ))}
            <Divider opacity={0.2} my={2} />
            <Text fontSize="sm">付款資訊</Text>
            <Text fontSize="md">
              {paymentType[selectedPluginOrder.payment_type]}({selectedPluginOrder.payment_id})
            </Text>
            <Text fontSize="md">{`NT$ ${new Intl.NumberFormat('zh-TW').format(
              selectedPluginOrder.payment_amount,
            )}`}</Text>
            {!!selectedPluginOrder.paid_at && (
              <Text fontSize="md">{formatDate(new Date(selectedPluginOrder.paid_at), 'yyyy/MM/dd')}</Text>
            )}
            <Divider opacity={0.2} my={2} />
            <Text fontSize="sm">用戶備註</Text>
            <Text fontSize="md">{!selectedPluginOrder.comment ? '無' : selectedPluginOrder.comment}</Text>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" w="100%">
              <Button colorScheme="green" size="xs" onClick={handleUpdateReceipt}>
                更新發票
              </Button>
              <Flex>
                <Input
                  w="10rem"
                  size="xs"
                  value={receiptNumber}
                  visibility={receiptInUpdate ? 'visible' : 'hidden'}
                  onChange={(e) => setReceiptNumber(e.target.value)}
                />
                <Input
                  type="date"
                  w="10rem"
                  size="xs"
                  value={receiptCreatedAt}
                  visibility={receiptInUpdate ? 'visible' : 'hidden'}
                  onChange={(e) => setReceiptCreatedAt(e.target.value)}
                />
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PluginOrder;
