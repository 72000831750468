import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import useIsInViewport from 'use-is-in-viewport';
import { BATTING_STAT_NAME, BATTING_STAT_KEYS } from '../Consts';
import { PITCHING_STAT_NAME, PITCHING_STAT_KEYS } from '../Consts';
import { FIELDING_STAT_NAME, FIELDING_STAT_KEYS } from '../Consts';

const unstickyHeadingProps = {
  my: '5',
};

// let renderCount = 0;
const BoxScoreHeader = ({ playerType, headingLanguage, boxRef }) => {
  // renderCount++;
  // console.log(`BoxScoreHeader ${renderCount}`);

  const [titleInView, titleRef] = useIsInViewport();
  const [boxInView, wrappedBoxRef] = useIsInViewport();

  const headingRef = useRef(null);
  const [headingProps, setHeadingProps] = useState(unstickyHeadingProps);
  const [fixedHeading, showFixedHeading] = useState(false);
  useEffect(() => {
    if (titleInView || !boxInView) {
      return showFixedHeading(false);
    }
    const rect = headingRef.current.getBoundingClientRect();
    setHeadingProps({
      pos: 'fixed',
      zIndex: '100',
      top: '0px',
      w: rect.width,
    });
    showFixedHeading(true);
  }, [titleInView, boxInView]);

  const genHeadingFunction = {
    batter: (ref, props) => {
      return (
        <Grid
          ref={ref}
          templateColumns={`3fr repeat(${BATTING_STAT_KEYS.length + 2}, 1fr)`}
          gap={1}
          bg="gray.300"
          py={2}
          {...props}
        >
          <Text textAlign="center">球員</Text>
          <Text textAlign="center">替補</Text>
          {BATTING_STAT_KEYS.map((key) => (
            <Text textAlign="center" key={key}>
              {BATTING_STAT_NAME[key][headingLanguage]}
            </Text>
          ))}
        </Grid>
      );
    },

    pitcher: (ref, props) => {
      return (
        <Grid
          ref={ref}
          templateColumns={`3fr repeat(${PITCHING_STAT_KEYS.length + 1}, 1fr)`}
          gap={1}
          bg="gray.300"
          py={2}
          {...props}
        >
          <Text textAlign="center">球員</Text>
          {PITCHING_STAT_KEYS.map((key) => (
            <Text textAlign="center" key={key}>
              {PITCHING_STAT_NAME[key][headingLanguage]}
            </Text>
          ))}
        </Grid>
      );
    },

    fielder: (ref, props) => {
      return (
        <Grid
          ref={ref}
          templateColumns={`3fr 1fr 2fr repeat(${FIELDING_STAT_KEYS.length - 1}, 1fr)`}
          gap={1}
          bg="gray.300"
          py={2}
          {...props}
        >
          <Text textAlign="center">球員</Text>
          {FIELDING_STAT_KEYS.map((key) => (
            <Text textAlign="center" key={key}>
              {FIELDING_STAT_NAME[key][headingLanguage]}
            </Text>
          ))}
        </Grid>
      );
    },
  };

  return (
    <>
      <div ref={titleRef}></div>
      {genHeadingFunction[playerType](headingRef, unstickyHeadingProps)}
      {fixedHeading && genHeadingFunction[playerType](null, headingProps)}
      <Box ref={wrappedBoxRef} position="absolute" height={boxRef.current?.clientHeight}></Box>
    </>
  );
};

export default BoxScoreHeader;
