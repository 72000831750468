import React from 'react';
import { Button, IconButton, Flex } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { TYPES_DISPLAY } from '~~utils/Season';

const ListSeason = ({ seasons, listGame }) => {
  return (
    <Table size="sm" variant="striped" colorScheme="gray">
      <Thead>
        <Tr>
          <Th>賽季名稱</Th>
          <Th>操作</Th>
          <Th>賽事種類</Th>
          <Th>所屬聯盟</Th>
          <Th isNumeric>連結</Th>
        </Tr>
      </Thead>
      <Tbody>
        {seasons.map((season) => (
          <Tr key={season.uniqid}>
            <Td w="1%" whiteSpace="nowrap">
              {season.title}
            </Td>
            <Td w="1%" whiteSpace="nowrap">
              <Flex>
                <Button size="xs" colorScheme="blue" onClick={listGame(season.uniqid)}>
                  顯示賽事
                </Button>
              </Flex>
            </Td>
            <Td w="1%" whiteSpace="nowrap">
              {TYPES_DISPLAY[season.type]}
            </Td>
            <Td w="1%" whiteSpace="nowrap">
              {season.league.name}
            </Td>
            <Td isNumeric>
              <IconButton
                size="xs"
                icon={<ExternalLinkIcon />}
                as="a"
                target="_blank"
                href={`${process.env.REACT_APP_MAINPAGE_URL}/league/${season.league.uniqid}?season=${season.uniqid}`}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ListSeason;
