export const INITIAL_NEWS_DATA = {
  title: '',
  image_path: '',
  image_url: '',
  preview_image_path: '',
  preview_image_url: '',
  source: {
    author: '',
    interviewer: '',
    city: '',
    photographer: '',
  },
  content: '',
};
