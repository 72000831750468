import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, Input, FormControl, FormLabel } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { reverse } from 'ramda';
import { Link } from 'react-router-dom';
import { FeatureTitle } from '~~components';
import { PlayerResource } from '~~apis/resource';

const Player = () => {
  const [players, setPlayers] = useState([]);
  const [action, setAction] = useState('LIST_PLAYER');
  const [collapseIndex, setCollapseIndex] = useState(-1);

  const { register, watch } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const playerQuery = watch('query');

  useEffect(() => {
    if (!playerQuery || !playerQuery.trim()) {
      return;
    }

    setAction('LOADING');
    PlayerResource.searchPlayers({ query: playerQuery }).then(({ data }) => {
      setPlayers(data);
      setAction('LIST_PLAYER');
    });
  }, [playerQuery]);

  const handlePlayerCollapse = (index) => () => {
    setCollapseIndex(collapseIndex === index ? -1 : index);
  };

  return (
    <Box w="100%">
      <FeatureTitle title="球員管理" />
      <FormControl my="10">
        <FormLabel>球員搜尋</FormLabel>
        <Input name="query" ref={register({ required: true })} size="xs" />
      </FormControl>
      {action === 'LOADING' && <Spinner />}
      <Text>球員列表</Text>
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>球員名稱</Th>
            <Th>操作</Th>
            <Th>參與賽季</Th>
          </Tr>
        </Thead>
        <Tbody>
          {players.map((player, index) => (
            <>
              <Tr key={player.uniqid}>
                <Td whiteSpace="nowrap">{player.name}</Td>
                <Td>
                  <Flex>
                    <Link to={`/permission/player/${player.uniqid}`}>
                      <Button size="xs" colorScheme="purple">
                        權限管理
                      </Button>
                    </Link>
                  </Flex>
                </Td>
                <Td>
                  <Button size="xs" colorScheme="blue" onClick={handlePlayerCollapse(index)}>
                    {collapseIndex === index ? '收回' : '展開'}
                  </Button>
                  最近一次：
                  {player.experiences.length === 0 ? (
                    <>無</>
                  ) : (
                    <>{`${reverse(player.experiences)[0].team} #${reverse(player.experiences)[0].number} (${
                      reverse(player.experiences)[0].season
                    })`}</>
                  )}
                </Td>
              </Tr>
              {collapseIndex === index &&
                reverse(player.experiences).map((experience, experienceIndex) => (
                  <Tr key={experienceIndex}>
                    <Td></Td>
                    <Td>{`${experience.team} #${experience.number}`}</Td>
                    <Td>{`${experience.season} (${experience.season_year})`}</Td>
                  </Tr>
                ))}
            </>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Player;
