import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Input, Select, Divider, Badge } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Icon, useToast, useDisclosure, Modal } from '@chakra-ui/react';
import { ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { MdAttachMoney } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { FeatureTitle, ConfirmAlertDialog } from '~~components';
import { FirstBaseResource, LeagueResource, TeamResource, PlayerResource, UserResource } from '~~apis/resource';

const TARGET_LIST = {
  firstbase: {
    name: 'First Base',
    hint: '球隊 ID（或從 First Base 選擇球隊 > 權限管理）',
  },
  league: {
    name: '聯盟',
    hint: '聯盟 ID（或從 聯盟管理 選擇聯盟 > 權限管理）',
  },
  team: {
    name: '球隊',
    hint: '球隊 ID（或從 球隊管理 選擇球隊 > 權限管理）',
  },
  player: {
    name: '球員',
    hint: '球員 ID（或從 球員管理 選擇球員 > 權限管理）',
  },
};

const PERMISSION_MAPPING = {
  ADMIN: '管理者',
  EDITOR: '編輯者',
  VIEWER: '瀏覽者',
};

const Permission = () => {
  const history = useHistory();
  const { target: urlTarget, target_uniqid: urlTargetUniqid } = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [target, setTarget] = useState('');
  const [targetUniqid, setTargetUniqid] = useState('');
  const [targetDisplay, setTargetDisplay] = useState('');
  const [fixTarget, setFixTarget] = useState(false);

  const [userList, setUserList] = useState([]);

  const [modalAction, setModalAction] = useState('NEW');
  const [modalEmail, setModalEmail] = useState('');
  const [modalPermission, setModalPermission] = useState('');

  const updateTargetHandler = (e) => {
    setTarget(e.target.value);
    setTargetUniqid('');
  };

  const updateTargetUniqidHandler = (e) => {
    setTargetUniqid(e.target.value);
  };

  useEffect(() => {
    if (urlTarget && urlTargetUniqid) {
      setTarget(urlTarget);
      setTargetUniqid(urlTargetUniqid);
    }

    const errorToast = (msg) => {
      toast({
        title: msg,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    };

    const getPermissionList = () => {
      UserResource.getPermissionList({ target: urlTarget, targetUniqid: urlTargetUniqid })
        .then(({ data }) => {
          setUserList(data.users);
          setFixTarget(true);
        })
        .catch((e) => {
          errorToast(`獲取權限失敗！${e.message}`);
        });
    };

    switch (urlTarget) {
      case 'firstbase':
        FirstBaseResource.getTeamInfo({ teamUniqid: urlTargetUniqid })
          .then(({ data: teamData }) => {
            setTargetDisplay(`${teamData.name}(${teamData.full_name})`);
            getPermissionList();
          })
          .catch(() => {
            errorToast(`找不到對應 First Base 球隊`);
          });
        break;
      case 'league':
        LeagueResource.getLeague({ leagueUniqid: urlTargetUniqid })
          .then(({ data: leagueData }) => {
            setTargetDisplay(leagueData.name);
            getPermissionList();
          })
          .catch(() => {
            errorToast(`找不到對應 聯盟`);
          });
        break;
      case 'team':
        TeamResource.getTeam({ teamUniqid: urlTargetUniqid })
          .then(({ data: teamData }) => {
            setTargetDisplay(teamData.name);
            getPermissionList();
          })
          .catch(() => {
            errorToast(`找不到對應 球隊`);
          });
        break;
      case 'player':
        PlayerResource.getPlayer({ playerUniqid: urlTargetUniqid })
          .then(({ data: playerData }) => {
            setTargetDisplay(playerData.name);
            getPermissionList();
          })
          .catch(() => {
            errorToast(`找不到對應 球員`);
          });
        break;
      default:
        break;
    }
  }, [toast, urlTarget, urlTargetUniqid]);

  const handleSearchClick = () => {
    switch (target) {
      case 'platform':
        UserResource.getPermissionList({ target, targetUniqid: 0 }).then((data) => {
          setTargetDisplay('平台（僅 管理者 權限有效）');
          setUserList(data.data.users);
          setTargetUniqid(0);
          setFixTarget(true);
        });
        break;
      default:
        if (!targetUniqid) {
          return toast({
            title: `請輸入對應 ID`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
        cancelSearchResult();
        history.push(`/permission/${target}/${targetUniqid}`);
        break;
    }
  };

  const cancelSearchResult = () => {
    setUserList([]);
    setFixTarget(false);
    history.push(`/permission`);
  };

  const handleEditUser = (index) => () => {
    const user = userList[index];
    setModalAction('EDIT');
    setModalEmail(user.email);
    setModalPermission(user.permission);
    onOpen();
  };

  const removePermission = (index) => () => {
    const user = userList[index];
    UserResource.setUserPermission({ userUniqid: user.uniqid, target, targetUniqid, authority: 'REMOVE' })
      .then(() => {
        toast({
          title: '刪除成功！',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
        handleSearchClick();
      })
      .catch((e) => {
        toast({
          title: `刪除失敗！${e.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const updatePermission = () => {
    if (!modalPermission || !modalEmail) {
      return toast({
        title: '信箱與權限皆為必填',
        status: 'error',
        duration: 1000,
        isClosable: true,
      });
    }
    UserResource.userExists({ email: modalEmail })
      .then((data) => {
        UserResource.setUserPermission({
          userUniqid: data.data.uniqid,
          target,
          targetUniqid,
          authority: modalPermission,
        })
          .then(() => {
            toast({
              title: '更新權限成功！',
              status: 'success',
              duration: 1000,
              isClosable: true,
            });
            handleSearchClick();
            onClose();
          })
          .catch((e) => {
            toast({
              title: `更新失敗！${e.message}`,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          });
      })
      .catch(() => {
        toast({
          title: `信箱 ${modalEmail} 找不到對應用戶，請確認用戶存在`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleNewUser = () => {
    setModalAction('NEW');
    setModalEmail('');
    setModalPermission('');
    onOpen();
  };

  const handleEmailUpdate = (e) => {
    setModalEmail(e.target.value);
  };

  const handlePermissionUpdate = (e) => {
    setModalPermission(e.target.value);
  };

  return (
    <Box w="100%">
      <FeatureTitle title="權限管理" />
      <Text>權限類型</Text>
      <Select
        size="sm"
        w="10%"
        placeholder="請選擇"
        value={target}
        onChange={updateTargetHandler}
        isDisabled={fixTarget}
      >
        <option value="platform">後台</option>
        {Object.keys(TARGET_LIST).map((target) => (
          <option key={target} value={target}>
            {TARGET_LIST[target].name}
          </option>
        ))}
      </Select>
      {TARGET_LIST[target] && (
        <>
          <Text>{TARGET_LIST[target].hint}</Text>
          <Box w="50%">
            <Input size="sm" value={targetUniqid} onChange={updateTargetUniqidHandler} isDisabled={fixTarget} />
          </Box>
        </>
      )}
      {target !== '' && (
        <Button size="sm" onClick={handleSearchClick} isDisabled={fixTarget}>
          查詢
        </Button>
      )}
      {fixTarget && (
        <Button size="sm" onClick={cancelSearchResult}>
          重新搜尋
        </Button>
      )}
      {fixTarget && (
        <>
          <Divider my="2" />
          <Text>
            {targetDisplay}
            {target !== 'platform' && <Badge colorScheme="red">所有的改動皆會在用戶端的帳號管理看到唷！</Badge>}
          </Text>
          <Table size="sm" variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>信箱</Th>
                <Th>帳號</Th>
                <Th>權限</Th>
                <Th>操作</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userList.map((user, index) => (
                <Tr key={user.uniqid}>
                  <Td whiteSpace="nowrap">{user.email}</Td>
                  <Td whiteSpace="nowrap">{user.user_name}</Td>
                  <Td whiteSpace="nowrap">{PERMISSION_MAPPING[user.permission]}</Td>
                  <Td>
                    <Flex>
                      <Button size="xs" colorScheme="orange" onClick={handleEditUser(index)}>
                        編輯
                      </Button>
                      <ConfirmAlertDialog
                        buttonStyles={{ size: 'xs', colorScheme: 'red' }}
                        buttonDisplay="刪除"
                        title="刪除權限"
                        content={`確定要刪除 ${user.email} 的權限嗎？`}
                        confirmColor="red"
                        handleConfirm={removePermission(index)}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
              <Tr>
                <Td whiteSpace="nowrap">
                  <Button leftIcon={<Icon as={MdAttachMoney} />} size="xs" onClick={handleNewUser}>
                    新增權限
                  </Button>
                </Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>{modalAction === 'NEW' ? '新增用戶權限' : '更新用戶權限'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>帳戶信箱</Text>
              <Input size="sm" isDisabled={modalAction === 'EDIT'} value={modalEmail} onChange={handleEmailUpdate} />
              <Text>帳戶權限</Text>
              <Select size="sm" placeholder="請選擇" value={modalPermission} onChange={handlePermissionUpdate}>
                {Object.keys(PERMISSION_MAPPING).map((key) => (
                  <option key={key} value={key}>
                    {PERMISSION_MAPPING[key]}
                  </option>
                ))}
              </Select>
            </ModalBody>
            <ModalFooter>
              <Button size="sm" onClick={onClose}>
                取消
              </Button>
              <Button size="sm" colorScheme="blue" onClick={updatePermission}>
                {modalAction === 'NEW' ? '新增' : '更新'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
};

export default Permission;
