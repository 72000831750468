import { findIndex, is, sum, filter } from 'ramda';
import CommonUtils from '~~utils/CommonUtils';

export const GAME_STATUS_KEY = {
  SCHEDULED: '尚未開始',
  PLAYING: '進行中',
  FINISHED: '完賽',
};

export const GAME_FINISHED_STATUS_KEY = {
  NORMAL: '正常',
  CALLED: '提前結束',
  FORFEITED: '裁定比賽',
};

export const appendFieldingToBatterPitcher = (players, batters, pitchers) => {
  players.forEach((player) => {
    player.fieldings.forEach((fielding) => {
      if (fielding.position === 'P') {
        const pitcherIndex = findIndex((p) => p.player.number === player.info.number)(pitchers);
        if (pitcherIndex < 0) {
          return;
        }

        if (!pitchers[pitcherIndex].fieldings) {
          pitchers[pitcherIndex].fieldings = [];
        }
        pitchers[pitcherIndex].fieldings.push(fielding);
        return;
      }

      const batterIndex = findIndex((p) => p.player.number === player.info.number)(batters);
      if (batterIndex < 0) {
        return;
      }

      if (!batters[batterIndex].fieldings) {
        batters[batterIndex].fieldings = [];
      }
      batters[batterIndex].fieldings.push(fielding);
    });
  });
};

export const formatBoxScoreForForm = (players) => {
  players.forEach((player) => {
    const keys = Object.keys(player);
    keys.forEach((key) => {
      if (!is(Number, player[key])) {
        return;
      }

      player[key] = player[key].toString();
    });
    if (player.fieldings) {
      formatBoxScoreForForm(player.fieldings);
    }
  });
};

export const BATTING_STAT_NAME = {
  order: {
    CH: '打序',
    EN: '打序',
  },
  PA: {
    CH: '打席',
    EN: 'PA',
  },
  AB: {
    CH: '打數',
    EN: 'AB',
  },
  H: {
    CH: '安打',
    EN: 'H',
  },
  TWOB: {
    CH: '二壘安打',
    EN: '2B',
  },
  THREEB: {
    CH: '三壘安打',
    EN: '3B',
  },
  HR: {
    CH: '全壘打',
    EN: 'HR',
  },
  SO: {
    CH: '三振',
    EN: 'SO',
  },
  BB: {
    CH: '四死球',
    EN: 'BB',
  },
  IBB: {
    CH: '故意四壞',
    EN: 'IBB',
  },
  HBP: {
    CH: '觸身球',
    EN: 'HBP',
  },
  SF: {
    CH: '犧牲飛球',
    EN: 'SF',
  },
  SAC: {
    CH: '犧牲短打',
    EN: 'SAC',
  },
  GIDP: {
    CH: '滾地雙殺',
    EN: 'GIDP',
  },
  FC: {
    CH: '野選上壘',
    EN: 'FC',
  },
  E: {
    CH: '失誤上壘',
    EN: 'E',
  },
  F: {
    CH: '飛球出局',
    EN: 'F',
  },
  G: {
    CH: '滾地出局',
    EN: 'G',
  },
  SB: {
    CH: '盜壘成功',
    EN: 'SB',
  },
  CS: {
    CH: '盜壘失敗',
    EN: 'CS',
  },
  R: {
    CH: '得分',
    EN: 'R',
  },
  RBI: {
    CH: '打點',
    EN: 'RBI',
  },
};

export const BATTING_STAT_KEYS = Object.keys(BATTING_STAT_NAME);

export const PITCHING_STAT_NAME = {
  order: {
    CH: '上場順序',
    EN: '上場順序',
  },
  IPOut: {
    CH: '出局數',
    EN: 'IPOut',
  },
  NP: {
    CH: '球數',
    EN: 'NP',
  },
  BF: {
    CH: '打席',
    EN: 'BF',
  },
  H: {
    CH: '被安打',
    EN: 'H',
  },
  HR: {
    CH: '被全壘打',
    EN: 'HR',
  },
  BB: {
    CH: '四死球',
    EN: 'BB',
  },
  IBB: {
    CH: '故意四壞',
    EN: 'IBB',
  },
  HB: {
    CH: '觸身球',
    EN: 'HB',
  },
  SO: {
    CH: '三振',
    EN: 'SO',
  },
  WP: {
    CH: '暴投',
    EN: 'WP',
  },
  BK: {
    CH: '投手犯規',
    EN: 'BK',
  },
  R: {
    CH: '失分',
    EN: 'R',
  },
  ER: {
    CH: '責失',
    EN: 'ER',
  },
};

export const PITCHING_STAT_KEYS = Object.keys(PITCHING_STAT_NAME);

export const FIELDING_STAT_NAME = {
  order: {
    CH: '守備順序',
    EN: '守備順序',
  },
  position: {
    CH: '守位',
    EN: '守位',
  },
  TC: {
    CH: '守備機會',
    EN: 'TC',
  },
  PO: {
    CH: '刺殺',
    EN: 'PO',
  },
  A: {
    CH: '助殺',
    EN: 'A',
  },
  E: {
    CH: '失誤',
    EN: 'E',
  },
  PB: {
    CH: '捕逸',
    EN: 'PB',
  },
  SB: {
    CH: '被盜壘',
    EN: 'SB',
  },
  CS: {
    CH: '阻殺',
    EN: 'CS',
  },
};

export const FIELDING_STAT_KEYS = Object.keys(FIELDING_STAT_NAME);

export const POSITION_STAT = {
  P: 'P - 1',
  C: 'C - 2',
  '1B': '1B - 3',
  '2B': '2B - 4',
  '3B': '3B - 5',
  SS: 'SS - 6',
  LF: 'LF - 7',
  CF: 'CF - 8',
  RF: 'RF - 9',
  DH: 'DH',
  PH: 'PH',
  PR: 'PR',
};

const INITIAL_INFO_DATA = {
  location_uniqid: '',
  time: {
    scheduled_start_at: {
      ...CommonUtils.objectifyDate(),
    },
    started_at: {
      ...CommonUtils.objectifyDate(),
    },
    ended_at: {
      ...CommonUtils.objectifyDate(),
    },
  },
  status: '',
  nodes: {
    base: [],
    link: [],
  },
  group: '',
};

export const initTeam = () => {
  return {
    team: null,
    team_name: '',
    scores: [],
    hits: '0',
    errors: '0',
    batters: [],
    pitchers: [],
    players: [],
    records: [],
  };
};

export const DATA_KEY_NAME = {
  INFO: 'info',
  AWAY: 'away',
  HOME: 'home',
};

export const INITIAL_DATA = {
  editable: false,
  isNew: true,
  seasonUniqid: '',
  gameUniqid: '',
  [DATA_KEY_NAME.INFO]: {
    ...INITIAL_INFO_DATA,
  },
  [DATA_KEY_NAME.AWAY]: initTeam(),
  [DATA_KEY_NAME.HOME]: initTeam(),
};

export const calcTeamHFromBatters = (currentBatters) => {
  return sum(filter((b) => !Number.isNaN(parseInt(b.H)), currentBatters).map((b) => b.H)).toString();
};

export const calcTeamEFromFielders = (currentFielders) => {
  let teamErrors = 0;
  currentFielders.forEach((fielder) => {
    teamErrors += sum(filter((f) => !Number.isNaN(parseInt(f.E)), fielder.fieldings).map((f) => f.E));
  });

  return teamErrors.toString();
};
