import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getTeam: false,
  getSeasonTeams: false,
  createSeasonTeam: false,
  updateSeasonTeam: false,
  deleteSeasonTeam: false,
  searchTeams: false,
  updateTeam: false,
  deleteTeam: false,
};

const Team = {
  getTeam: async ({ teamUniqid }) => {
    if (debugStatus.getTeam) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          experiences: [
            {
              season: '2020 OOOO XX',
              season_uniqid: 'oooo-2020-xx',
              season_year: 2020,
              team: 'AAAA',
            },
            {
              season: '2021 OOOO XX',
              season_uniqid: 'oooo-2021-xx',
              season_year: 2021,
              team: 'AAAA',
            },
          ],
          icon_url: '',
          name: 'AAAA',
          uniqid: 'wxyz',
        },
        error: false,
        message: 'Get team successfully',
      });
    }

    return ApiService.get(`/api/teams/${teamUniqid}`);
  },

  getSeasonTeams: async ({ seasonUniqid }) => {
    if (debugStatus.getSeasonTeams) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [],
        error: false,
        message: 'Get season teams successfully',
      });
    }

    return ApiService.get(`/api/seasons/${seasonUniqid}/teams`);
  },
  createSeasonTeam: ({ seasonUniqid, teamUniqid, teamName, seasonTeamName, iconPath }) => {
    if (debugStatus.createSeasonTeam) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Create season team successfully',
      });
    }

    return ApiService.post(`/api/seasons/${seasonUniqid}/teams`, {
      data: {
        team_uniqid: teamUniqid,
        team_name: teamName,
        season_team_name: seasonTeamName,
        icon_path: iconPath,
      },
    });
  },
  updateSeasonTeam: ({ seasonUniqid, originSeasonTeamName, teamUniqid, teamName, seasonTeamName, iconPath }) => {
    if (debugStatus.updateSeasonTeam) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Update season team successfully',
      });
    }

    return ApiService.put(`/api/seasons/${seasonUniqid}/teams/${encodeURIComponent(originSeasonTeamName)}`, {
      data: {
        team_uniqid: teamUniqid,
        team_name: teamName,
        season_team_name: seasonTeamName,
        icon_path: iconPath,
      },
    });
  },
  deleteSeasonTeam: ({ seasonUniqid, seasonTeamName }) => {
    if (debugStatus.deleteSeasonTeam) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Delete season team successfully',
      });
    }

    return ApiService.delete(`/api/seasons/${seasonUniqid}/teams/${encodeURIComponent(seasonTeamName)}`);
  },
  searchTeams: ({ query }) => {
    if (debugStatus.searchTeams) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [],
        error: false,
        message: 'Get teams successfully',
      });
    }

    return ApiService.get(`/api/teams?query=${encodeURIComponent(query)}`);
  },

  updateTeam: ({ teamUniqid, data }) => {
    if (debugStatus.updateTeam) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          experiences: [
            {
              season: '2020 北區高中棒球聯會夏季聯賽',
              season_uniqid: 'nH8l-2020-Sc',
              season_year: 2020,
              team: '新莊高中',
            },
            {
              season: '2021 北區高中棒球聯會冬季聯賽',
              season_uniqid: 'nH8l-2021-23',
              season_year: 2021,
              team: '新莊高中',
            },
          ],
          icon_url: 'https://storage.googleapis.com/dev-rebas/t/igNa/1610511383-coIw4Zvay8',
          name: '新莊高中 TEST',
          uniqid: 'igNa',
        },
        error: false,
        message: 'Update team successfully',
      });
    }

    return ApiService.put(`/api/teams/${teamUniqid}`, { data });
  },

  deleteTeam: ({ teamUniqid }) => {
    if (debugStatus.deleteTeam) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Delete team successfully',
      });
    }

    return ApiService.delete(`/api/teams/${teamUniqid}`);
  },
};

export default Team;
