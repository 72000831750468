import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Icon } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, useToast } from '@chakra-ui/react';
import { find, findIndex } from 'ramda';
import { BiLocationPlus } from 'react-icons/bi';
import { FeatureTitle, ConfirmAlertDialog } from '~~components';
import { LocationResource } from '~~apis/resource';
import Edit from './components/Edit';
import { TYPE_MAPPING, INITIAL_LOCATION_DATA } from './Consts';

const Location = () => {
  const toast = useToast();
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState({});
  const [action, setAction] = useState('LOADING');

  useEffect(() => {
    setAction('LOADING');
    LocationResource.getLocations().then((data) => {
      setLocations(data.data);
      setAction('LIST_LOCATION');
    });
  }, []);

  const startEdit = (uniqid) => () => {
    const selectedLocation = find((l) => l.uniqid === uniqid)(locations);
    setLocation(selectedLocation);
    setAction('EDIT_LOCATION');
  };

  const newLocation = () => {
    setLocation(INITIAL_LOCATION_DATA);
    setAction('NEW_LOCATION');
  };

  const deleteLocation = (locationUniqid) => () => {
    toast({
      title: '刪除中，請稍後',
      status: 'info',
      duration: 1000,
      isClosable: true,
    });
    LocationResource.deleteLocation({ locationUniqid })
      .then(() => {
        toast({
          title: '刪除成功！',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });

        LocationResource.getLocations().then((data) => {
          setLocations(data.data);
          setAction('LIST_LOCATION');
        });
      })
      .catch((e) => {
        toast({
          title: `刪除失敗！${e.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const cancelEdit = () => {
    setLocation({});
    setAction('LIST_LOCATION');
  };

  const completedEdit = (data) => {
    if (action === 'NEW_LOCATION') {
      LocationResource.getLocations().then((data) => {
        setLocations(data.data);
        setAction('LIST_LOCATION');
      });
      return;
    }

    const selectedLocationIndex = findIndex((l) => l.uniqid === location.uniqid)(locations);
    locations[selectedLocationIndex] = data;
    setLocation({});
    setLocations(locations);
    setAction('LIST_LOCATION');
  };

  return (
    <Box w="100%">
      <FeatureTitle title="地點管理" />
      {action === 'LOADING' && <Spinner />}
      {action === 'LIST_LOCATION' && (
        <Table size="sm" variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>地點名稱</Th>
              <Th>地點型態</Th>
              <Th>操作</Th>
              <Th>地址</Th>
            </Tr>
          </Thead>
          <Tbody>
            {locations.map((location) => (
              <Tr key={location.uniqid}>
                <Td whiteSpace="nowrap">{location.name}</Td>
                <Td>{TYPE_MAPPING[location.type]}</Td>
                <Td>
                  <Flex>
                    <Button size="xs" colorScheme="orange" onClick={startEdit(location.uniqid)}>
                      編輯
                    </Button>
                    <ConfirmAlertDialog
                      buttonStyles={{ size: 'xs', colorScheme: 'red' }}
                      buttonDisplay="刪除"
                      title="刪除球隊"
                      content={`確定要刪除 ${location.name} 嗎？（已被使用地點將無法刪除）`}
                      confirmColor="red"
                      handleConfirm={deleteLocation(location.uniqid)}
                    />
                  </Flex>
                </Td>
                <Td>{`${location.country}, ${location.postcode} ${location.city} ${location.address1} ${location.address2} ${location.address3}`}</Td>
              </Tr>
            ))}
            <Tr>
              <Td>
                <Button leftIcon={<Icon as={BiLocationPlus} />} size="xs" onClick={newLocation}>
                  新增地點
                </Button>
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      )}
      {['NEW_LOCATION', 'EDIT_LOCATION'].includes(action) && (
        <Edit
          location={location}
          existLocationNames={locations.map((l) => l.name)}
          cancelEdit={cancelEdit}
          completedEdit={completedEdit}
        />
      )}
    </Box>
  );
};

export default Location;
