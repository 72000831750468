import React, { useEffect, useState, useRef } from 'react';
import { Box, VStack, Flex, Button, Text, Divider, Heading } from '@chakra-ui/react';
import { FormControl, Switch, useToast } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { SeasonResource } from '~~apis/resource';
import { PluginMapping } from '../../UserPlugin/Const';

const EditSeason = ({ season: originSeason, cancelEdit, completedEdit }) => {
  const [plugins, setPlugins] = useState({
    open_registration: false,
    expand_media_space: false,
    paper_record_online: false,
    offline_commissioner: false,
  });
  const [newSeason, setNewSeason] = useState(null);

  const [targetPlugin, setTargetPlugin] = useState('');

  const [isDiscardOpen, setIsDiscardOpen] = useState(false);
  const onDiscardClose = () => setIsDiscardOpen(false);
  const onDiscardOpen = () => setIsDiscardOpen(true);
  const discardCancelRef = useRef();

  const toast = useToast();

  useEffect(() => {
    const pluginStatus = {};
    Object.keys(PluginMapping.TOURNAMENT).forEach((pluginName) => {
      pluginStatus[pluginName] = !!originSeason.config[pluginName];
    });
    setPlugins(pluginStatus);
  }, [originSeason]);

  const handleTargetPlugin = (target) => (e) => {
    e.preventDefault();
    setTargetPlugin(target);
    onDiscardOpen();
  };

  const triggerConfirm = () => {
    SeasonResource.updateSeasonPlugin({
      seasonUniqid: originSeason.uniqid,
      pluginName: targetPlugin,
      toOpen: !plugins[targetPlugin],
    })
      .then(({ data: newSeasonData }) => {
        setNewSeason(newSeasonData);
        const pluginStatus = {};
        Object.keys(PluginMapping.TOURNAMENT).forEach((pluginName) => {
          pluginStatus[pluginName] = newSeasonData.config[pluginName];
        });
        setPlugins(pluginStatus);
        toast({ title: '更新成功！', status: 'success' });
      })
      .catch((e) => {
        toast({ title: `更新失敗！${e.toString()}`, status: 'error', second: 5 });
      })
      .finally(() => {
        onDiscardClose();
      });
  };

  return (
    <Box>
      {!newSeason ? (
        <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={cancelEdit}>
          取消
        </Button>
      ) : (
        <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={() => completedEdit(newSeason)}>
          返回
        </Button>
      )}
      <Heading my={2}>{originSeason.title}</Heading>
      <VStack>
        {Object.keys(PluginMapping.TOURNAMENT).map((pluginName) => (
          <FormControl key={pluginName}>
            <Flex alignItems="center">
              <input type="checkbox" onClick={handleTargetPlugin(pluginName)} checked={!!plugins[pluginName]} />
              {PluginMapping.TOURNAMENT[pluginName]}
            </Flex>
          </FormControl>
        ))}
      </VStack>
      <AlertDialog isOpen={isDiscardOpen} leastDestructiveRef={discardCancelRef} onClose={onDiscardClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              擴充套件更新
            </AlertDialogHeader>
            <AlertDialogBody textAlign="center">
              <Text>
                <strong>即將{plugins[targetPlugin] ? '關閉' : '開啟'}</strong>
                {PluginMapping.TOURNAMENT[targetPlugin]}
              </Text>
              <Divider my={2} />
              <i>此項目為付費項目，請確認是否與主辦單位做過確認</i>
              <Divider my={2} borderColor="white" />
              <i>系統將同步寄信通知平台管理者</i>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={discardCancelRef} onClick={onDiscardClose}>
                取消
              </Button>
              <Button colorScheme="blue" onClick={triggerConfirm} ml={3}>
                確定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default EditSeason;
