import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getLeagues: false,
  getLeague: false,
  createLeague: false,
  updateLeague: false,
};

const League = {
  getLeagues: () => {
    if (debugStatus.getLeagues) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [
          {
            cover_path: '',
            cover_url: '',
            description: '',
            index_featureds: [],
            logo_path: '',
            logo_url: '',
            name: '黑豹旗全國高中棒球大賽',
            type: 'STUDENT',
            uniqid: 'blpa',
          },
          {
            cover_path: '',
            cover_url: '',
            description: '',
            index_featureds: [],
            logo_path: '',
            logo_url: '',
            name: '北中南桃四聯明星對抗賽',
            type: 'STUDENT',
            uniqid: '4H8l',
          },
          {
            cover_path: '',
            cover_url: '',
            description: '',
            index_featureds: [],
            logo_path: '',
            logo_url: '',
            name: '北區高中棒球聯會',
            type: 'STUDENT',
            uniqid: 'nH8l',
          },
        ],
        error: false,
        message: 'Get leagues successfully',
      });
    }

    return ApiService.get(`/api/leagues`);
  },

  getLeague: ({ leagueUniqid }) => {
    if (debugStatus.getLeague) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          cover_path: '',
          cover_url: '',
          description: '',
          index_featureds: [],
          logo_path: '',
          logo_url: '',
          name: '北區高中棒球聯會',
          type: 'STUDENT',
          uniqid: 'nH8l',
          seasons: [],
        },
        error: false,
        message: 'Get league successfully',
      });
    }

    return ApiService.get(`/api/leagues/${leagueUniqid}?seasons=true&config=true`);
  },

  createLeague: ({ name, type }) => {
    if (debugStatus.createLeague) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          cover_path: '',
          cover_url: '',
          description: '',
          index_featureds: [],
          logo_path: '',
          logo_url: '',
          name: '黑豹旗全國高中棒球大賽',
          type: 'SENIOR_HIGH_SCHOOL',
          uniqid: 'blpa',
        },
        error: false,
        message: 'Create league successfully',
      });
    }

    return ApiService.post(`/api/leagues`, {
      data: {
        name,
        type,
      },
    });
  },

  updateLeague: ({ leagueUniqid, data }) => {
    if (debugStatus.updateLeague) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          cover_path: '/l/blpa/1608045993-YJ46j7sCQJ',
          cover_url: 'https://storage.googleapis.com/dev-rebas/l/blpa/1608045993-YJ46j7sCQJ',
          description: 'TEST\n\nTEST',
          index_featureds: [
            {
              path: '/l/blpa/1608046003-x77w7QQWkR',
              url: 'https://storage.googleapis.com/dev-rebas/l/blpa/1608046003-x77w7QQWkR',
            },
            {
              path: '/l/blpa/1608046007-t8OEeB27Wo',
              url: 'https://storage.googleapis.com/dev-rebas/l/blpa/1608046007-t8OEeB27Wo',
            },
          ],
          logo_path: '/l/blpa/1608045990-4HFYRmv7uz',
          logo_url: 'https://storage.googleapis.com/dev-rebas/l/blpa/1608045990-4HFYRmv7uz',
          name: '黑豹旗全國高中棒球大賽',
          type: 'STUDENT',
          uniqid: 'blpa',
        },
        error: false,
        message: 'Update league successfully',
      });
    }

    return ApiService.put(`/api/leagues/${leagueUniqid}`, {
      data,
    });
  },
};

export default League;
