import React, { useEffect, useState } from 'react';
import { Box, VStack, HStack, Flex, Button, Image, Text, Select } from '@chakra-ui/react';
import { FormControl, FormLabel, Input, Center, CloseButton } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import CommonUtils from '~~utils/CommonUtils';
import { UploadMedia, LoadingAlertDialog } from '~~components';
import { FirstBaseResource } from '~~apis/resource';
import { TEAM_STATUS_MAPPING } from '../Consts';
import { format as formatDate } from 'date-fns';

const Edit = ({ team: originTeam, cancelEdit, completedEdit }) => {
  const [isNew, setIsNew] = useState(false);
  const [team, setTeam] = useState(originTeam);

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const {
    register,
    handleSubmit,
    errors,
    reset,
    // formState: { isDirty },
  } = useForm();

  useEffect(() => {
    setIsNew(originTeam.uniqid === '');
    setTeam(originTeam);

    let due_date = '';
    if (originTeam.uniqid !== '') {
      due_date = formatDate(new Date(originTeam.due_date), 'yyyy/MM/dd');
    }
    reset({ ...originTeam, due_date });
  }, [originTeam, reset]);

  const errorDisplay = (message, withStar = true) => {
    if (!withStar) {
      return (
        <Text fontSize="xs" color="red.500">
          {message}
        </Text>
      );
    }

    return (
      <Text fontSize="xs" color="red.500">
        * {message}
      </Text>
    );
  };

  const requiredDisplay = () => <Text color="red.500"> *</Text>;

  const updateIcon = (result) => {
    setTeam({
      ...team,
      icon_url: result.url,
      icon_path: result.path,
    });
  };

  const removeIcon = () => {
    setTeam({
      ...team,
      icon_url: '',
      icon_path: 'REMOVE',
    });
  };

  const back = () => {
    reset(originTeam);
    cancelEdit();
  };

  const onSubmit = (data) => {
    const submitTeam = {
      icon_path: '',
      ...team,
      ...data,
    };

    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');

    const action = isNew ? 'createTeam' : 'updateTeam';
    FirstBaseResource[action]({ ...submitTeam, teamUniqid: team.uniqid })
      .then((data) => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage(isNew ? '新增成功！' : '更新成功！');
        setTeam(data.data);
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      completedEdit(team);
    }
  };

  return (
    <Box>
      <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={back}>
        取消
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} alignItems="start">
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">
                球隊名稱{requiredDisplay()}
                {errors.name && errorDisplay('球隊名稱不得為空', false)}
              </Flex>
            </FormLabel>
            <Input size="sm" type="text" name="name" ref={register({ required: true })} />
          </FormControl>
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">
                球隊全名{requiredDisplay()}
                {errors.name && errorDisplay('球隊全名不得為空', false)}
              </Flex>
            </FormLabel>
            <Input size="sm" type="text" name="full_name" ref={register({ required: true })} />
          </FormControl>
          <FormControl w="90%">
            <FormLabel>使用期限</FormLabel>
            {errors.due_date && errorDisplay('使用期限為必填且格式需符合 yyyy/MM/dd')}
            <Input
              type="text"
              name="due_date"
              ref={register({
                required: true,
                pattern: CommonUtils.dateStringPattern,
              })}
              size="sm"
            />
          </FormControl>
          <FormControl w="90%">
            <FormLabel>狀態</FormLabel>
            {errors.status && errorDisplay('狀態為必填')}
            <Select type="text" name="status" ref={register({ required: true })} size="sm">
              {Object.keys(TEAM_STATUS_MAPPING).map((key) => (
                <option key={key} value={key}>
                  {TEAM_STATUS_MAPPING[key]}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <HStack spacing={5}>
              <FormLabel>球隊 Logo - 長寬比 1：1</FormLabel>
              <UploadMedia updateMedia={updateIcon} iconSize={5} />
            </HStack>
            {team.icon_url && (
              <Box w="40vw" h="30vw" boxShadow="md" p="2" position="relative">
                <CloseButton variant="ghost" position="absolute" top="2" right="2" onClick={removeIcon} />
                <Center w="100%" h="100%">
                  <Image maxW="100%" maxH="100%" src={team.icon_url} />
                </Center>
              </Box>
            )}
          </FormControl>
          <Button type="submit">確認送出</Button>
        </VStack>
      </form>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={isNew ? '新增球隊' : '更新球隊'}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </Box>
  );
};

export default Edit;
