export const TYPE_MAPPING = {
  BASEBALL_FIELD: '棒球場',
};

export const INITIAL_LOCATION_DATA = {
  address1: '',
  address2: '',
  address3: '',
  city: '',
  comment: '',
  country: 'TW',
  cover_url: '',
  lat: 0.0,
  lng: 0.0,
  name: '',
  abbr: '',
  postcode: '',
  state: '',
  type: 'BASEBALL_FIELD',
  uniqid: '',
};
