import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getPlayer: false,
  getSeasonTeamPlayers: false,
  createSeasonTeamPlayer: false,
  deleteSeasonTeamPlayer: false,
  searchPlayers: false,
};

const Player = {
  getPlayer: async ({ playerUniqid }) => {
    if (debugStatus.getPlayer) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          avatar_url: '',
          experiences: [
            {
              number: '6',
              season: '2020 OOOO XX',
              season_uniqid: 'oooo-2020-xx',
              season_year: 2020,
              team: 'AAA',
            },
            {
              number: '27',
              season: '2021 OOOO XX',
              season_uniqid: 'oooo-2021-xx',
              season_year: 2021,
              team: 'BBB',
            },
          ],
          name: '魯夫',
          uniqid: 'wxyz',
        },
        error: false,
        message: 'Get player successfully',
      });
    }

    return ApiService.get(`/api/players/${playerUniqid}`);
  },

  getSeasonTeamPlayers: async ({ seasonUniqid, teamName }) => {
    if (debugStatus.getSeasonTeamPlayers) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [],
        error: false,
        message: 'Get season team players successfully',
      });
    }

    return ApiService.get(`/api/seasons/${seasonUniqid}/teams/${encodeURIComponent(teamName)}/players`);
  },
  createSeasonTeamPlayer: ({ seasonUniqid, teamName, data }) => {
    if (debugStatus.getSeasonTeamPlayers) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Create season team player successfully',
      });
    }

    return ApiService.post(`/api/seasons/${seasonUniqid}/teams/${encodeURIComponent(teamName)}/players`, {
      data,
    });
  },
  deleteSeasonTeamPlayer: ({ seasonUniqid, teamName, playerNumber }) => {
    if (debugStatus.deleteSeasonTeamPlayer) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Delete season team player successfully',
      });
    }

    return ApiService.delete(
      `/api/seasons/${seasonUniqid}/teams/${encodeURIComponent(teamName)}/players/${playerNumber}`,
    );
  },
  searchPlayers: ({ query }) => {
    if (debugStatus.searchPlayers) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [
          {
            avatar_url: '',
            experiences: [
              {
                number: '24',
                season: '2020 OOOO XX',
                season_uniqid: 'oooo-2020-xx',
                season_year: 2020,
                team: 'AAAA',
              },
            ],
            name: 'BBB',
            uniqid: 'zZzZzZ',
          },
        ],
        error: false,
        message: 'Get players successfully',
      });
    }

    return ApiService.get(`/api/players?query=${encodeURIComponent(query)}`);
  },
};

export default Player;
