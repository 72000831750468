import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, IconButton, Divider, Button, Text, Collapse, Heading, Kbd } from '@chakra-ui/react';
import { Input, Checkbox, Select, Switch } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronDownIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { find, sum, filter } from 'ramda';
import { useFieldArray, Controller } from 'react-hook-form';
import FieldingBoxScore from './FieldingBoxScore';
import BoxScoreHeader from './BoxScoreHeader';
import { BATTING_STAT_KEYS, PITCHING_STAT_KEYS, calcTeamHFromBatters } from '../Consts';
import { useGameDetailState } from '../hooks';

const getPlayerNameByNumber = (number, players) => {
  const player = find((p) => p.number === number)(players);
  return player ? player.name : '';
};

// let renderCount = 0;
const BoxScore = ({ teamSide, form }) => {
  // renderCount++;
  // console.log(`BoxScore ${renderCount}`);

  const { register, watch, control, getValues, setValue } = form;
  const { [`${teamSide}TeamPlayers`]: players } = useGameDetailState();
  const [headingLanguage, setHeadingLanguage] = useState('EN');
  const [editable, setEditable] = useState(false);
  const [showBoxScore, setShowBoxScore] = useState(false);

  const teamName = watch('team_name');
  const recordBattings = watch('record_battings');
  const recordPitchings = watch('record_pitchings');
  const recordFieldings = watch('record_fieldings');

  const { fields: batterRecords } = useFieldArray({
    control,
    name: 'batters',
  });

  const { fields: pitcherRecords } = useFieldArray({
    control,
    name: 'pitchers',
  });

  const { fields: fielderRecords } = useFieldArray({
    control,
    name: 'players',
  });

  const batterBoxRef = useRef();
  const pitcherBoxRef = useRef();
  const fielderBoxRef = useRef();

  useEffect(() => {
    if (!teamName || players.length === 0) {
      setEditable(false);
      setShowBoxScore(false);
      return;
    }

    if (!recordBattings && !recordPitchings && !recordFieldings) {
      setEditable(false);
      setShowBoxScore(false);
      return;
    }

    setEditable(true);
  }, [players, recordBattings, recordFieldings, recordPitchings, teamName]);

  const collapseOnClick = () => {
    setShowBoxScore(!showBoxScore);
  };

  const addBatterRecord = () => {
    const init = { player: { number: '' }, is_PH: false };
    BATTING_STAT_KEYS.forEach((key) => {
      init[key] = '0';
    });
    const data = getValues();
    if (!data.batters) {
      data.batters = [];
    }
    data.batters.push(init);
    syncPlayers({ ...data, setParams: ['players', 'batters'] });
  };

  const removeBatterRecord = (index) => () => {
    const data = getValues();
    data.batters.splice(index, 1);
    syncPlayers({ ...data, setParams: ['players', 'batters'] });

    const teamHits = sum(filter((b) => !Number.isNaN(parseInt(b.H)), data.batters).map((b) => b.H)).toString();
    setValue('hits', teamHits);
  };

  const updateBatterNumber = (index) => (e) => {
    const data = getValues();
    data.batters[index].player.number = e.target.value;
    syncPlayers({ ...data, setParams: ['players', 'batters'] });
  };

  const addPitcherRecord = () => {
    const init = { player: { number: '' } };
    PITCHING_STAT_KEYS.forEach((key) => {
      init[key] = '0';
    });
    const data = getValues();
    if (!data.pitchers) {
      data.pitchers = [];
    }
    data.pitchers.push(init);
    syncPlayers({ ...data, setParams: ['players', 'pitchers'] });
  };

  const removePitcherRecord = (index) => () => {
    const data = getValues();
    data.pitchers.splice(index, 1);
    syncPlayers({ ...data, setParams: ['players', 'pitchers'] });
  };

  const updatePitcherNumber = (index) => (e) => {
    const data = getValues();
    data.pitchers[index].player.number = e.target.value;
    syncPlayers({ ...data, setParams: ['players', 'pitchers'] });
  };

  const changeHeadingLanguage = () => {
    const targetLanguage = headingLanguage === 'CH' ? 'EN' : 'CH';
    setHeadingLanguage(targetLanguage);
  };

  const syncPlayers = ({ setParams, batters: currentBatters, pitchers: currentPitchers, players: currentFielders }) => {
    const keepFielders = [];

    if (!currentBatters) {
      currentBatters = [];
    }
    if (!currentPitchers) {
      currentPitchers = [];
    }
    if (!currentFielders) {
      currentFielders = [];
    }

    currentBatters.forEach((batter) => {
      if (batter.player.number === '') {
        return;
      }

      const keepFielder = find((f) => f.info.number === batter.player.number, keepFielders);
      if (keepFielder) {
        return;
      }

      const fielder = find((f) => f.info.number === batter.player.number, currentFielders);
      if (!fielder) {
        keepFielders.push({ info: { number: batter.player.number }, fieldings: [] });
        return;
      }
      keepFielders.push(fielder);
    });

    currentPitchers.forEach((pitcher) => {
      if (pitcher.player.number === '') {
        return;
      }

      const keepFielder = find((f) => f.info.number === pitcher.player.number, keepFielders);
      if (keepFielder) {
        return;
      }

      const fielder = find((f) => f.info.number === pitcher.player.number, currentFielders);
      if (!fielder) {
        keepFielders.push({ info: { number: pitcher.player.number }, fieldings: [] });
        return;
      }
      keepFielders.push(fielder);
    });

    setParams.forEach((param) => {
      switch (param) {
        case 'players':
          setValue('players', keepFielders);
          break;
        case 'batters':
          setValue('batters', currentBatters);
          break;
        case 'pitchers':
          setValue('pitchers', currentPitchers);
          break;
        default:
          break;
      }
    });
  };

  const calcTeamH = (onChange, index) => (e) => {
    onChange();

    const currentBatters = getValues().batters;
    const currentData = currentBatters[index];
    currentData.H = e.target.value;

    setValue('hits', calcTeamHFromBatters(currentBatters));
    setValue(`batters`, currentBatters);
  };

  return (
    <Box>
      <Button pl="0" variant="ghost" onClick={collapseOnClick} _focus={{}} isDisabled={!editable}>
        <Text fontSize="xl">
          {showBoxScore ? <ChevronDownIcon /> : <ChevronRightIcon />}
          {teamName ? teamName : teamSide}
          {recordBattings && <Kbd>打擊</Kbd>}
          {recordPitchings && <Kbd>投球</Kbd>}
          {recordFieldings && <Kbd>守備</Kbd>}
          Box Score
        </Text>
      </Button>
      <Divider borderColor="gray.400" my="2" />
      <Collapse in={showBoxScore}>
        <Heading fontSize="xl" my="5">
          打者
          {!recordBattings && (
            <Text as="mark" ml="2">
              不儲存紀錄
            </Text>
          )}
        </Heading>
        <Text as="i">
          顯示中文
          <Switch isChecked={headingLanguage === 'CH'} onChange={changeHeadingLanguage} />
        </Text>
        <BoxScoreHeader playerType="batter" headingLanguage={headingLanguage} boxRef={batterBoxRef} />
        <Box ref={batterBoxRef}>
          {batterRecords.map((record, index) => (
            <Grid
              key={`batting-records-${index}-${record.player.number}`}
              templateColumns={`3fr repeat(${BATTING_STAT_KEYS.length + 2}, 1fr)`}
              gap={1}
              bg="gray.200"
              my="1"
              py="2"
            >
              <Controller
                control={control}
                name={`batters[${index}].player.number`}
                defaultValue={record.player.number}
                render={({ value }) => (
                  <Select value={value} onChange={updateBatterNumber(index)} placeholder="請選擇" size="sm">
                    {players.map((player) => (
                      <option key={player.number} value={player.number}>
                        {`${player.number} ${player.name}`}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <Checkbox name={`batters[${index}].is_PH`} ref={register()} defaultIsChecked={record.is_PH} size="sm" />
              {BATTING_STAT_KEYS.map((key) => {
                if (key === 'H') {
                  return (
                    <Controller
                      control={control}
                      key={`batting-records-${record.id}-${key}`}
                      name={`batters[${index}][${key}]`}
                      defaultValue={record[key].toString()}
                      render={({ value, onChange }) => (
                        <Input
                          size="sm"
                          type="text"
                          variant="flushed"
                          textAlign="center"
                          defaultValue={value}
                          onChange={calcTeamH(onChange, index)}
                        />
                      )}
                    />
                  );
                }

                return (
                  <Input
                    key={`batting-records-${record.id}-${key}`}
                    size="sm"
                    type="text"
                    variant="flushed"
                    textAlign="center"
                    name={`batters[${index}][${key}]`}
                    ref={register()}
                    defaultValue={record[key].toString()}
                  />
                );
              })}
              <IconButton size="sm" icon={<DeleteIcon />} onClick={removeBatterRecord(index)} />
            </Grid>
          ))}
        </Box>
        <IconButton icon={<AddIcon />} onClick={addBatterRecord} size="sm" />
        <Heading fontSize="xl" my="5">
          投手
          {!recordPitchings && (
            <Text as="mark" ml="2">
              不儲存紀錄
            </Text>
          )}
        </Heading>
        <Text as="i">
          顯示中文
          <Switch isChecked={headingLanguage === 'CH'} onChange={changeHeadingLanguage} />
        </Text>
        <BoxScoreHeader playerType="pitcher" headingLanguage={headingLanguage} boxRef={pitcherBoxRef} />
        <Box ref={pitcherBoxRef}>
          {pitcherRecords.map((record, index) => (
            <Grid
              key={`pitching-records-${index}-${record.player.number}`}
              templateColumns={`3fr repeat(${PITCHING_STAT_KEYS.length + 1}, 1fr)`}
              gap={1}
              bg="gray.200"
              my="1"
              py="2"
            >
              <Controller
                control={control}
                name={`pitchers[${index}].player.number`}
                defaultValue={record.player.number}
                render={({ value }) => (
                  <Select value={value} onChange={updatePitcherNumber(index)} placeholder="請選擇" size="sm">
                    {players.map((player) => (
                      <option key={player.number} value={player.number}>
                        {`${player.number} ${player.name}`}
                      </option>
                    ))}
                  </Select>
                )}
              />
              {PITCHING_STAT_KEYS.map((key) => (
                <Input
                  key={`pitching-records-${index}-${key}`}
                  size="sm"
                  type="text"
                  variant="flushed"
                  textAlign="center"
                  name={`pitchers[${index}][${key}]`}
                  ref={register()}
                  defaultValue={record[key].toString()}
                />
              ))}
              <IconButton size="sm" icon={<DeleteIcon />} onClick={removePitcherRecord(index)} />
            </Grid>
          ))}
        </Box>
        <IconButton icon={<AddIcon />} onClick={addPitcherRecord} size="sm" />
        <Heading fontSize="xl" my="5">
          守備
          {!recordFieldings && (
            <Text as="mark" ml="2">
              不儲存紀錄
            </Text>
          )}
        </Heading>
        <Text as="i">
          顯示中文
          <Switch isChecked={headingLanguage === 'CH'} onChange={changeHeadingLanguage} />
        </Text>
        <BoxScoreHeader playerType="fielder" headingLanguage={headingLanguage} boxRef={fielderBoxRef} />
        <Box ref={fielderBoxRef}>
          {fielderRecords.map((record, index) => (
            <FieldingBoxScore
              key={record.id}
              id={`${record.id}-${index}`}
              playerNumber={record.info.number}
              recordIndex={index}
              form={form}
              getPlayerNameByNumber={getPlayerNameByNumber}
              players={players}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

export default BoxScore;
