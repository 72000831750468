import React, { useState } from 'react';
import { IconButton, Button, Text, Divider, Center, Box, SimpleGrid, Spacer, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import { filter } from 'ramda';
import { MediaResource } from '~~apis/resource';
import Single from './Single';

const UploadModal = ({ maxFileAmount, updataMediaRequest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState('');

  const [preparedFiles, setFiles] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [fileStatusList, setFileStatusList] = useState([]);
  const [fileResultList, setFileResultList] = useState([]);

  const [updateResult, setUpdateResult] = useState('');

  const handleFileOnChange = (e) => {
    const files = e.target.files;
    if (maxFileAmount - preparedFiles.length - files.length < 0) {
      setErrorMessage(`最多選擇 ${maxFileAmount} 個檔案`);
      return;
    }

    // Transfer FileList to normal array
    const fileList = [];
    for (let file of files) {
      fileList.push(file);
    }
    const newFiles = preparedFiles.concat(fileList);
    setFiles(newFiles);
    setFileStatusList(new Array(newFiles.length).fill('UPLOADING'));
    setErrorMessage('');
  };

  const removeFile = (removeIndex) => () => {
    // 不使用 splice 因為位址一樣無法觸發改動
    const newFiles = [];
    preparedFiles.forEach((file, index) => {
      if (index === removeIndex) {
        return;
      }

      newFiles.push(file);
    });
    setFiles(newFiles);
    setFileStatusList(new Array(newFiles.length).fill('UPLOADING'));
  };

  const startUpload = () => {
    preparedFiles.forEach((file, index) => {
      upload(file, index);
    });
    setIsUploading(true);
  };

  const upload = (file, index) => {
    MediaResource.uploadMedia({ file })
      .then((data) => {
        fileStatusList[index] = 'SUCCESS';
        fileResultList[index] = {
          ...data.data,
          type: 'PHOTO',
        };
        setFileResultList(fileResultList.slice(0));
      })
      .catch(() => {
        fileStatusList[index] = 'ERROR';
      })
      .finally(() => {
        setFileStatusList(fileStatusList.slice(0));
        uploadFinished();
      });
  };

  const uploadFinished = () => {
    if (filter((status) => status === 'UPLOADING', fileStatusList).length > 0) {
      return;
    }

    updataMediaRequest(fileResultList, (success) => {
      if (!success) {
        setFileStatusList(new Array(preparedFiles.length).fill('ERROR'));
        setUpdateResult('ERROR');
      } else {
        setUpdateResult('SUCCESS');
      }
    });
  };

  const cleanupThenClose = () => {
    setFiles([]);
    setIsUploading(false);
    setFileStatusList([]);
    setFileResultList([]);
    setUpdateResult('');
    onClose();
  };

  return (
    <>
      <IconButton onClick={onOpen} icon={<AddIcon />} />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="full"
        autoFocus={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent h="90vh" w="95%">
          <ModalHeader>花絮上傳</ModalHeader>
          <ModalBody>
            <Flex borderRadius="5">
              <Text as="mark">還能上傳{maxFileAmount}個</Text>同一攝影師最多上傳 10 個檔案
              <Spacer />
              <Text as="sub">已選擇{preparedFiles.length}個檔案</Text>
            </Flex>
            <Divider my="1" />
            <SimpleGrid columns="3" spacing="2" my="2">
              {preparedFiles.map((file, index) => (
                <Single
                  key={file.name}
                  file={file}
                  removeSelf={removeFile(index)}
                  status={isUploading ? fileStatusList[index] : ''}
                />
              ))}
            </SimpleGrid>
            {updateResult && (
              <Text fontSize="md" color={updateResult === 'SUCCESS' ? 'green.500' : 'red.500'} textAlign="center">
                {updateResult === 'SUCCESS' ? '上傳成功！' : '上傳過程發生錯誤，請稍後再試'}
              </Text>
            )}
            {errorMessage && <Text fontSize="sm" color="red.500" textAlign="center">{`* ${errorMessage}`}</Text>}
            {!isUploading && maxFileAmount - preparedFiles.length > 0 && (
              <Center>
                <Box as="button" borderRadius="md" borderWidth="1px" borderColor="blue" bg="gray" color="black">
                  <label style={{ cursor: 'pointer' }}>
                    <input
                      type="file"
                      accept="image/*"
                      multiple={true}
                      onChange={handleFileOnChange}
                      style={{ display: 'none' }}
                    />
                    <Box py="2" px="4">
                      選擇檔案
                    </Box>
                  </label>
                </Box>
              </Center>
            )}
          </ModalBody>
          <ModalFooter>
            {!updateResult ? (
              <>
                <Button variant="ghost" mr={3} disabled={isUploading} onClick={onClose}>
                  取消
                </Button>
                <Button colorScheme="blue" onClick={startUpload} disabled={isUploading}>
                  開始上傳
                </Button>
              </>
            ) : (
              <Button onClick={cleanupThenClose}>返回</Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UploadModal;
