import React, { useEffect, useState } from 'react';
import { parseAPIAbbrPAList } from './parseAPIAbbrPAList';
import ReactJson from 'react-json-view';

export default function Playground() {
  const [sample, setSample] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/formal/seasons/CPBL-2023-sk/teams/rSQKE/pa_logs`)
      .then((res) => res.json())
      .then((data) => {
        const games = data.map((g) => {
          const gameValues = {};
          Object.entries(g).forEach(([col, value]) => {
            const column = !(col in gameColumnMapping) ? col : gameColumnMapping[col];
            gameValues[column] = value;
          });

          gameValues.PAList = parseAPIAbbrPAList(gameValues.PAList);
          return gameValues;
        });
        console.log(games);
        setSample({
          ...games[0],
          PAList: games[0].PAList.slice(0, 5),
        });
      });
  }, []);

  return (
    <div>
      <ReactJson key={sample} src={sample} name={'SAMPLE'} enableClipboard={true} displayDataTypes={false} />
    </div>
  );
}

const API_GAME_COLUMN_MAPPING = {
  date: 'date',
  seq: 'seq',
  uniqid: 'uniqid',
  opponentAbbr: 'opponent_abbr',
  opponentUniqid: 'opponent_uniqid',
  away: 'away',
  home: 'home',
  PAList: 'PA_list',
  statsCompleteness: 'stats_completeness',
};
const gameColumnMapping = Object.entries(API_GAME_COLUMN_MAPPING).reduce((acc, [column, abbr]) => {
  acc[abbr] = column;
  return acc;
}, {});
