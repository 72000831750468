import React from 'react';
import { Input, HStack, Text } from '@chakra-ui/react';
import { indexOf } from 'ramda';

const sections = ['year', 'month', 'day', 'hour', 'min'];

const NumberInput = ({ w, name, values, handleDatePickerChange }) => {
  const nextSectionCheck = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    let valueLen = 2;
    if (key === 'year') {
      valueLen = 4;
    }

    const sectionIndex = indexOf(key, sections);
    if (sectionIndex + 1 === sections.length) {
      return handleDatePickerChange(e);
    }

    if (String(value).length >= valueLen) {
      const datePickerParent = e.currentTarget.parentNode.parentNode;
      const next = datePickerParent.querySelector('[name=' + sections[sectionIndex + 1] + ']');
      next.focus();
    }

    handleDatePickerChange(e);
  };

  return (
    <Input
      size="sm"
      variant="flushed"
      w={w}
      textAlign="center"
      type="number"
      name={name}
      onChange={nextSectionCheck}
      value={values[name] ? values[name] : ''}
    />
  );
};

const DatePicker = ({ handleDatePickerChange, values, styleProps }) => {
  return (
    <HStack {...styleProps}>
      <HStack spacing={2}>
        <NumberInput w="12" name="year" values={values} handleDatePickerChange={handleDatePickerChange} />
        <Text>/</Text>
        <NumberInput w="12" name="month" values={values} handleDatePickerChange={handleDatePickerChange} />
        <Text>/</Text>
        <NumberInput w="12" name="day" values={values} handleDatePickerChange={handleDatePickerChange} />
      </HStack>
      <HStack spacing={2}>
        <NumberInput w="10" name="hour" values={values} handleDatePickerChange={handleDatePickerChange} />
        <Text>:</Text>
        <NumberInput w="10" name="min" values={values} handleDatePickerChange={handleDatePickerChange} />
      </HStack>
    </HStack>
  );
};

export default DatePicker;
