import React, { useEffect, useState } from 'react';
import { Box, VStack, Flex, Button, Text, Divider, Grid } from '@chakra-ui/react';
import { FormControl, FormLabel, Input, Select, Switch } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useForm, useFieldArray } from 'react-hook-form';
import { filter } from 'ramda';
import { LoadingAlertDialog } from '~~components';
import { SeasonResource } from '~~apis/resource';
import CommonUtils from '~~utils/CommonUtils';
import { TYPES_DISPLAY } from '~~utils/Season';

const EditSeason = ({ leagueUniqid, season: originSeason, existTitles, cancelEdit, completedEdit }) => {
  const [season, setSeason] = useState(originSeason);

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const { register, handleSubmit, control, errors, reset } = useForm();
  const { fields: playerColumnFields, append: playerColumnAppend, remove: playerColumnRemove } = useFieldArray({
    control,
    name: 'config.player_registration.columns',
  });
  const { fields: staffFields, append: staffAppend, remove: staffRemove } = useFieldArray({
    control,
    name: 'config.player_registration.staffs',
  });

  useEffect(() => {
    setSeason(originSeason);
    let startAt = '';
    if (originSeason.start_at) {
      startAt = CommonUtils.objectifyDate(originSeason.start_at);
      startAt = `${startAt.year}/${startAt.month}/${startAt.day}`;
    }
    let endAt = '';
    if (originSeason.end_at) {
      endAt = CommonUtils.objectifyDate(originSeason.end_at);
      endAt = `${endAt.year}/${endAt.month}/${endAt.day}`;
    }

    let playerRegistrationStartAt = '';
    if (originSeason.config.player_registration.start_at) {
      playerRegistrationStartAt = CommonUtils.objectifyDate(originSeason.config.player_registration.start_at);
      playerRegistrationStartAt = `${playerRegistrationStartAt.year}/${playerRegistrationStartAt.month}/${playerRegistrationStartAt.day}`;
    }
    let playerRegistrationEndAt = '';
    if (originSeason.config.player_registration.end_at) {
      playerRegistrationEndAt = CommonUtils.objectifyDate(originSeason.config.player_registration.end_at);
      playerRegistrationEndAt = `${playerRegistrationEndAt.year}/${playerRegistrationEndAt.month}/${playerRegistrationEndAt.day}`;
    }
    reset({
      ...originSeason,
      startAt,
      endAt,
      config: {
        ...originSeason.config,
        player_registration: {
          ...originSeason.config.player_registration,
          startAt: playerRegistrationStartAt,
          endAt: playerRegistrationEndAt,
        },
      },
    });
  }, [originSeason, reset]);

  const handleAppendPlayerColumn = () => {
    playerColumnAppend({ name: '', hint: '' });
  };

  const handlePlayerColumnRemove = (index) => () => {
    playerColumnRemove(index);
  };

  const handleAppendStaff = () => {
    staffAppend({ title: '', amount: 1, need_number: false, need_avatar: false });
  };

  const handleStaffRemove = (index) => () => {
    staffRemove(index);
  };

  const errorDisplay = (message) => (
    <Text fontSize="xs" color="red.500">
      * {message}
    </Text>
  );

  const back = () => {
    reset(originSeason);
    cancelEdit();
  };

  const onSubmit = (data) => {
    const submitSeason = {
      ...season,
      ...data,
    };

    submitSeason.start_at = submitSeason.startAt;
    submitSeason.end_at = submitSeason.endAt;
    submitSeason.config.player_registration.start_at = submitSeason.config.player_registration.startAt;
    submitSeason.config.player_registration.end_at = submitSeason.config.player_registration.endAt;
    submitSeason.config.leader_board_config.PA_weight = parseFloat(submitSeason.config.leader_board_config.PA_weight);
    submitSeason.config.leader_board_config.IP_weight = parseFloat(submitSeason.config.leader_board_config.IP_weight);

    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');
    SeasonResource.updateSeason({
      leagueUniqid,
      seasonUniqid: originSeason.uniqid,
      data: submitSeason,
    })
      .then(() => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage('儲存成功！');
        setSeason(submitSeason);
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      completedEdit(season);
    }
  };

  return (
    <Box onSubmit={handleSubmit(onSubmit)}>
      <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={back}>
        取消
      </Button>
      <form>
        <VStack spacing={4} w="50vw">
          <FormControl>
            <FormLabel htmlFor="season-title">
              <Flex alignItems="center">
                賽事名稱
                {errors.title && errorDisplay(errors.title.type === 'required' ? '賽事名稱不得為空' : '賽事名稱重複')}
              </Flex>
            </FormLabel>
            <Input
              id="season-title"
              placeholder="賽事名稱"
              type="text"
              name="title"
              mb="2"
              size="sm"
              ref={register({
                required: true,
                validate: {
                  exists: (value) => filter((t) => t !== originSeason.title, existTitles).includes(value) === false,
                },
              })}
            />
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              賽事種類
              {errors.type && errorDisplay('請選擇賽事種類')}
            </Flex>
            <Select name="type" placeholder="賽事種類" ref={register({ required: true })} size="sm" mb="2">
              {Object.keys(TYPES_DISPLAY).map((type) => (
                <option key={type} value={type}>
                  {TYPES_DISPLAY[type]}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              開始日期
              {errors.startAt &&
                errorDisplay(
                  errors.startAt.type === 'required' ? '開始日期不得為空' : '開始日期請以 yyyy/mm/dd 方式輸入',
                )}
            </Flex>
            <Input
              placeholder="yyyy/mm/dd"
              type="text"
              name="startAt"
              mb="2"
              size="sm"
              ref={register({
                required: true,
                pattern: CommonUtils.dateStringPattern,
              })}
            />
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              結束日期
              {errors.endAt && errorDisplay('結束日期請以 yyyy/mm/dd 方式輸入（尚未結束可留空）')}
            </Flex>
            <Input
              placeholder="yyyy/mm/dd"
              type="text"
              name="endAt"
              mb="2"
              size="sm"
              ref={register({ pattern: CommonUtils.dateStringPattern })}
            />
          </FormControl>
          <FormControl>
            <Flex alignItems="center">比賽編號自動排序</Flex>
            <Switch name="auto_game_seq" size="sm" ref={register()} />
          </FormControl>
          <Divider />
          <FormControl>
            <Flex alignItems="center">顯示數據排行榜（開啟後下方資料才有作用）</Flex>
            <Switch name="config.open_leader_board" size="sm" ref={register()} />
          </FormControl>
          <Flex w="100%">
            <FormControl>
              <Flex alignItems="center">自訂規定打席（？ x 球隊已賽場數；-1 使用預設）</Flex>
              <Input
                placeholder="1.9"
                type="text"
                name="config.leader_board_config.PA_weight"
                mb="2"
                size="sm"
                ref={register()}
              />
            </FormControl>
            <FormControl>
              <Flex alignItems="center">自訂規定局數（？ x 球隊已賽場數；-1 使用預設）</Flex>
              <Input
                placeholder="0.8"
                type="text"
                name="config.leader_board_config.IP_weight"
                mb="2"
                size="sm"
                ref={register()}
              />
            </FormControl>
          </Flex>
          <Divider />
          <FormControl>
            <Flex alignItems="center">啟用球員自主註冊（開啟後下方資料才有作用）</Flex>
            <Switch name="config.open_registration" size="sm" ref={register()} />
          </FormControl>
          <Flex w="100%">
            <FormControl>
              <Flex alignItems="center">
                註冊開始日期
                {errors.config?.player_registration?.startAt && errorDisplay('請以 yyyy/mm/dd 方式輸入')}
              </Flex>
              <Input
                placeholder="yyyy/mm/dd"
                type="text"
                name="config.player_registration.startAt"
                mb="2"
                size="sm"
                ref={register({ pattern: CommonUtils.dateStringPattern })}
              />
            </FormControl>
            <FormControl>
              <Flex alignItems="center">
                註冊結束日期
                {errors.config?.player_registration?.endAt && errorDisplay('請以 yyyy/mm/dd 方式輸入')}
              </Flex>
              <Input
                placeholder="yyyy/mm/dd"
                type="text"
                name="config.player_registration.endAt"
                mb="2"
                size="sm"
                ref={register({ pattern: CommonUtils.dateStringPattern })}
              />
            </FormControl>
          </Flex>
          <Select size="sm" name="config.player_registration.avatar_ratio" ref={register()}>
            <option value="4:3">4:3</option>
            <option value="16:9">16:9</option>
          </Select>
          <FormControl>
            <Flex alignItems="center">
              除 照片 背號 姓名 外的欄位
              <Button size="xs" onClick={handleAppendPlayerColumn}>
                新增欄位
              </Button>
            </Flex>
          </FormControl>
          <Grid templateColumns="repeat(4, 1fr)" width="100%">
            {playerColumnFields.map((column, index) => (
              <Box p="3" key={column.id}>
                <FormControl>
                  <Flex alignItems="center">欄位名稱</Flex>
                  <Input
                    size="sm"
                    type="text"
                    name={`config.player_registration.columns[${index}].name`}
                    defaultValue={column.name}
                    ref={register()}
                  />
                </FormControl>
                <FormControl>
                  <Flex alignItems="center">欄位提示</Flex>
                  <Input
                    size="sm"
                    type="text"
                    name={`config.player_registration.columns[${index}].hint`}
                    defaultValue={column.hint}
                    ref={register()}
                  />
                </FormControl>
                <Button size="xs" colorScheme="red" onClick={handlePlayerColumnRemove(index)}>
                  移除
                </Button>
              </Box>
            ))}
          </Grid>
          <FormControl>
            <Flex alignItems="center">
              行政人員
              <Button size="xs" onClick={handleAppendStaff}>
                新增職稱
              </Button>
            </Flex>
          </FormControl>
          <Grid templateColumns="repeat(3, 1fr)" width="100%">
            {staffFields.map((staff, index) => (
              <Box p="3" key={staff.id}>
                <FormControl>
                  <Flex alignItems="center">職稱</Flex>
                  <Input
                    size="sm"
                    type="text"
                    name={`config.player_registration.staffs[${index}].title`}
                    defaultValue={staff.title}
                    ref={register()}
                  />
                </FormControl>
                <FormControl>
                  <Flex alignItems="center">職位人數</Flex>
                  <Input
                    size="sm"
                    type="number"
                    name={`config.player_registration.staffs[${index}].amount`}
                    defaultValue={staff.amount}
                    ref={register({ valueAsNumber: true })}
                  />
                </FormControl>
                <FormControl>
                  <Flex alignItems="center">需要背號</Flex>
                  <Switch
                    name={`config.player_registration.staffs[${index}].need_number`}
                    size="sm"
                    defaultChecked={staff.need_number}
                    ref={register()}
                  />
                </FormControl>
                <FormControl>
                  <Flex alignItems="center">需要照片</Flex>
                  <Switch
                    name={`config.player_registration.staffs[${index}].need_avatar`}
                    size="sm"
                    defaultChecked={staff.need_avatar}
                    ref={register()}
                  />
                </FormControl>
                <Button size="xs" colorScheme="red" onClick={handleStaffRemove(index)}>
                  移除
                </Button>
              </Box>
            ))}
          </Grid>
          <Button type="submit">確認送出</Button>
        </VStack>
      </form>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={'更新賽季設定'}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </Box>
  );
};

export default EditSeason;
