import React from 'react';
import GameDetail from './GameDetail';
import { GameDetailProvider } from './hooks';

const Index = () => {
  return (
    <GameDetailProvider>
      <GameDetail />
    </GameDetailProvider>
  );
};

export default Index;
