import React from 'react';
import { Box, Flex, Grid, Heading } from '@chakra-ui/react';

const MemberStats = () => {
  return (
    <Box padding="1rem 0">
      <Flex alignItems="center" padding="0 0 0.5rem 0">
        <Heading size="md" padding="0 2rem 0 0">
          會員數據
        </Heading>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap="0.5rem"></Grid>
    </Box>
  );
};

export default MemberStats;
