import React, { useState } from 'react';
import { Box, Button, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import { useGameDetailState } from '../hooks';

const PlayerDisplay = ({ awayForm, homeForm }) => {
  const [display, setDisplay] = useState(false);
  const [awayTeamName, setAwayTeamName] = useState('');
  const [homeTeamName, setHomeTeamName] = useState('');
  const { awayTeamPlayers, homeTeamPlayers } = useGameDetailState();
  const playerLength = Math.max(awayTeamPlayers.length, homeTeamPlayers.length);

  const handleSetDisplay = () => {
    if (!display) {
      setPlayers();
    }
    setDisplay(!display);
  };

  const setPlayers = () => {
    const away = awayForm.getValues();
    const home = homeForm.getValues();
    setAwayTeamName(away.team_name);
    setHomeTeamName(home.team_name);
  };

  if (!display) {
    return (
      <Box>
        <Button size="sm" onClick={handleSetDisplay}>
          顯示兩隊球員（Excel 紀錄表用）
        </Button>
      </Box>
    );
  }

  console.log(playerLength);
  console.log(awayTeamPlayers);
  console.log(homeTeamPlayers);

  return (
    <Box>
      <Button size="sm" onClick={handleSetDisplay}>
        隱藏兩隊球員（Excel 紀錄表用）
      </Button>
      <Button size="xs" onClick={setPlayers}>
        兩隊球員重新整理
      </Button>
      <Table>
        <Tbody>
          <Tr>
            <Td>{`客場（${awayTeamName}）球員背號`}</Td>
            <Td>{`客場（${awayTeamName}）球員名稱`}</Td>
            <Td></Td>
            <Td>{`主場（${homeTeamName}）球員背號`}</Td>
            <Td>{`主場（${homeTeamName}）球員名稱`}</Td>
          </Tr>
          {new Array(playerLength).fill('').map((_, index) => (
            <Tr key={index}>
              {awayTeamPlayers[index] ? (
                <>
                  <Td>{awayTeamPlayers[index].number}</Td>
                  <Td>{awayTeamPlayers[index].name}</Td>
                </>
              ) : (
                <>
                  <Td></Td>
                  <Td></Td>
                </>
              )}
              <Td></Td>
              {homeTeamPlayers[index] ? (
                <>
                  <Td>{homeTeamPlayers[index].number}</Td>
                  <Td>{homeTeamPlayers[index].name}</Td>
                </>
              ) : (
                <>
                  <Td></Td>
                  <Td></Td>
                </>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default PlayerDisplay;
