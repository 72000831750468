import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getGames: false,
  updateGame: false,
  deleteGame: false,
  appendMedia: false,
  removeMedia: false,
};

const GameDetail = {
  getGames: ({ seasonUniqid }) => {
    if (debugStatus.getGames) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [],
        error: false,
        message: 'Get games successfully',
      });
    }
    return ApiService.get(`/api/seasons/${seasonUniqid}/games?players=true`);
  },
  updateGame: async ({ seasonUniqid, gameUniqid, data }) => {
    if (debugStatus.updateGame) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: { uniqid: 'yyy', link: 'https://www.rebas.tw' },
        error: false,
        message: 'update game successfully',
      });
    }

    data.info.nodes = formatGameNodes(data.info.nodes);
    return ApiService.put(`/api/seasons/${seasonUniqid}/games/${gameUniqid}`, {
      data,
    });
  },
  deleteGame: ({ seasonUniqid, gameUniqid }) => {
    if (debugStatus.deleteGame) {
      return CommonUtils.fakeApiHelper(200, 'success', {});
    }

    return ApiService.delete(`/api/seasons/${seasonUniqid}/games/${gameUniqid}`);
  },

  appendMedia: ({ seasonUniqid, gameUniqid, mediaList }) => {
    if (debugStatus.appendMedia) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          game_uniqid: 'game_uniqid',
          media: [],
          link: 'https://www.rebas.tw/season/season_uniqid?game=game_uniqid',
        },
        error: false,
        message: 'apend media successfully',
      });
    }

    return ApiService.post(`/api/seasons/${seasonUniqid}/games/${gameUniqid}/media`, {
      data: mediaList,
    });
  },

  removeMedia: ({ seasonUniqid, gameUniqid, mediaPath, mediaType }) => {
    if (debugStatus.removeMedia) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          game_uniqid: 'game_uniqid',
          media: [],
          link: 'https://www.rebas.tw/season/season_uniqid?game=game_uniqid',
        },
        error: false,
        message: 'remove media successfully',
      });
    }

    return ApiService.delete(
      `/api/seasons/${seasonUniqid}/games/${gameUniqid}/media?path=${encodeURI(mediaPath)}&type=${mediaType}`,
    );
  },
};

export const formatGameNodes = (nodes) => {
  const flatNodes = [];
  nodes.base.forEach((node) => {
    flatNodes.push({
      ...node,
      link_game_uniqid: node.link.game_uniqid,
    });
  });
  nodes.link.forEach((node) => {
    flatNodes.push({
      ...node,
      base_game_uniqid: node.base.game_uniqid,
    });
  });

  return flatNodes;
};

export default GameDetail;
