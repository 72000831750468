import React, { useEffect, useState } from 'react';
import { Box, VStack, HStack, Flex, Button, Image, Text } from '@chakra-ui/react';
import { FormControl, FormLabel, Input, Center, CloseButton } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { UploadMedia, LoadingAlertDialog } from '~~components';
import { TeamResource } from '~~apis/resource';

const Edit = ({ team: originTeam, cancelEdit, completedEdit }) => {
  const [team, setTeam] = useState(originTeam);

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const {
    register,
    handleSubmit,
    errors,
    reset,
    // formState: { isDirty },
  } = useForm();

  useEffect(() => {
    setTeam(originTeam);
    reset(originTeam);
  }, [originTeam, reset]);

  const errorDisplay = (message) => (
    <Text fontSize="xs" color="red.500">
      * {message}
    </Text>
  );

  const updateIcon = (result) => {
    setTeam({
      ...team,
      icon_url: result.url,
      icon_path: result.path,
    });
  };

  const removeIcon = () => {
    setTeam({
      ...team,
      icon_url: '',
      icon_path: 'REMOVE',
    });
  };

  const back = () => {
    reset(originTeam);
    cancelEdit();
  };

  const onSubmit = (data) => {
    const submitTeam = {
      icon_path: '',
      ...team,
      ...data,
    };

    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');
    TeamResource.updateTeam({
      teamUniqid: team.uniqid,
      data: {
        ...submitTeam,
        name: submitTeam.name.trim(),
        city: submitTeam.city.trim(),
      },
    })
      .then((data) => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage('更新成功！');
        setTeam(data.data);
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      completedEdit(team);
    }
  };

  return (
    <Box>
      <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={back}>
        取消
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>
              <Flex alignItems="center">所屬縣市</Flex>
            </FormLabel>
            <Input size="sm" type="text" name="city" ref={register()} />
          </FormControl>
          <FormControl>
            <FormLabel>
              <Flex alignItems="center">
                球隊名稱
                {errors.name && errorDisplay('球隊名稱不得為空')}
              </Flex>
            </FormLabel>
            <Input size="sm" type="text" name="name" ref={register({ required: true })} />
          </FormControl>
          <FormControl>
            <HStack spacing={5}>
              <FormLabel>Icon - 長寬比 1：1</FormLabel>
              <UploadMedia updateMedia={updateIcon} iconSize={5} />
            </HStack>
            {team.icon_url && (
              <Box boxSize="sm" boxShadow="md" p="2" position="relative">
                <CloseButton variant="ghost" position="absolute" top="2" right="2" onClick={removeIcon} />
                <Center w="100%" h="100%">
                  <Image maxW="100%" maxH="100%" src={team.icon_url} />
                </Center>
              </Box>
            )}
          </FormControl>
          <Button type="submit">確認送出</Button>
        </VStack>
      </form>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={'更新球隊設定'}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </Box>
  );
};

export default Edit;
