import XLSX from 'xlsx';
import { format as formatDate } from 'date-fns';
import { FirstBaseResource } from '~~apis/resource';

export const exportTeamPbP = (team) => () => {
  const exportToXLSX = (games) => {
    const title = `${team.full_name}逐打席資料`;
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: title,
      Author: 'Rebas 野革',
      CreatedDate: new Date(),
    };
    wb.SheetNames.push('逐打席');

    const exportRows = [
      [
        'opponent',
        'side',
        'innings',
        'away_scores',
        'home_scores',
        'started_at',
        '',
        'inning',
        'inning_frame',
        'is_opponent_PA',
        'pitcher_name',
        'pitcher_number',
        'pitcher_hand',
        'catcher_name',
        'catcher_number',
        'batter_name',
        'batter_number',
        'batter_hand',
        'PA_round',
        'PA_order',
        'pinch',
        'away_score',
        'home_score',
        'start_outs',
        'outs',
        'bases',
        'ball_counts',
        'drop_point',
        'trajectory',
        'RBI',
        'result',
        'R',
        'R_pitcher_name',
        'R_pitcher_number',
        'is_ER',
        'SB',
        'CS',
        'PO',
      ],
    ];

    const getPCPerBall = (fielders) => {
      const pitcherPerBall = [];
      const catcherPerBall = [];
      fielders.forEach((fielder) => {
        if (fielder.position !== 1 && fielder.position !== 2) {
          return;
        }

        const targetPerBall = fielder.position === 1 ? pitcherPerBall : catcherPerBall;

        let lastTarget = targetPerBall[0];
        if (targetPerBall.length === 0) {
          lastTarget = fielder.player;
        }

        for (let i = 0; i < fielder.in_play_ball_counts; i++) {
          if (targetPerBall[i]) {
            continue;
          }
          targetPerBall[i] = lastTarget;
        }

        targetPerBall.push(fielder.player);
      });

      return { pitcherPerBall, catcherPerBall };
    };

    const appendRows = (gameInfo, isOpponent, pbp, opponentCatcherPerBall) => {
      let ballCount = 0;
      pbp.forEach((PA) => {
        let catcherName = '';
        let catcherNumber = '';
        if (opponentCatcherPerBall.length > 0) {
          let catcher = opponentCatcherPerBall[ballCount];
          if (!catcher) {
            catcher = opponentCatcherPerBall[opponentCatcherPerBall.length - 1];
          }

          catcherName = catcher.name;
          catcherNumber = catcher.number;
        }

        exportRows.push([
          ...gameInfo,
          '',
          PA.inning,
          PA.inning_frame,
          isOpponent,
          PA.pitcher.name,
          { t: 's', v: PA.pitcher.number },
          PA.p_hand,
          catcherName,
          { t: 's', v: catcherNumber },
          PA.batter.name,
          { t: 's', v: PA.batter.number },
          PA.b_hand,
          PA.PA_round,
          PA.PA_order,
          PA.pinch,
          PA.away_score,
          PA.home_score,
          PA.start_outs,
          PA.outs,
          PA.bases,
          { t: 's', v: PA.ball_counts },
          { t: 's', v: PA.drop_point },
          { t: 's', v: PA.trajectory },
          PA.RBI,
          PA.result,
          PA.R,
          PA.R === 0 ? '' : PA.R_count_on_pitcher ? PA.R_count_on_pitcher.name : PA.pitcher.name,
          { t: 's', v: PA.R === 0 ? '' : PA.R_count_on_pitcher ? PA.R_count_on_pitcher.number : PA.pitcher.number },
          PA.is_ER,
          { t: 's', v: PA.SB },
          PA.CS,
          PA.PO,
        ]);

        ballCount += PA.ball_counts.length;
      });
    };

    const appendGameSheet = (
      game,
      pbp,
      pitcherPerBall,
      catcherPerBall,
      opponentPbP,
      opponentPitcherPerBall,
      opponentCatcherPerBall,
    ) => {
      const perBallRows = [
        [
          'inning',
          'inning_frame',
          'pitcher_name',
          'pitcher_number',
          'pitcher_hand',
          'catcher_name',
          'catcher_number',
          'batter_name',
          'batter_number',
          'batter_hand',
          'PA_round',
          'PA_order',
          'ball_count',
          'ball_result',
          '',
          '',
          '',
          '',
          'inning',
          'inning_frame',
          'pitcher_name',
          'pitcher_number',
          'pitcher_hand',
          'catcher_name',
          'catcher_number',
          'batter_name',
          'batter_number',
          'batter_hand',
          'PA_round',
          'PA_order',
          'ball_count',
          'ball_result',
          '',
          '',
          '',
        ],
      ];

      const genRows = (targetPbP, targetOpponentPitcherPerBall, targetOpponentCatcherPerBall) => {
        const rows = [];

        let ballCount = 0;
        targetPbP.forEach((PA) => {
          let currentStrike = 0;
          let currentBall = 0;

          let currentPitcherUniqid = '';
          let currentCatcherUniqid = '';
          PA.ball_counts.forEach((ballResult, ballSeq) => {
            let pitcherName = PA.pitcher.name;
            let pitcherNumber = { t: 's', v: PA.pitcher.number };
            let pitcherHand = PA.p_hand;
            let pitcherUniqid = PA.pitcher.uniqid;
            if (targetOpponentPitcherPerBall.length > 0) {
              let pitcher = targetOpponentPitcherPerBall[ballCount];
              if (!pitcher) {
                pitcher = targetOpponentPitcherPerBall[targetOpponentPitcherPerBall.length - 1];
              }

              pitcherName = pitcher.name;
              pitcherNumber = { t: 's', v: pitcher.number };
              pitcherHand = '';
              pitcherUniqid = pitcher.uniqid;
            }

            let catcherName = '';
            let catcherNumber = '';
            let catcherUniqid = '';
            if (targetOpponentCatcherPerBall.length > 0) {
              let catcher = targetOpponentCatcherPerBall[ballCount];
              if (!catcher) {
                catcher = targetOpponentCatcherPerBall[targetOpponentCatcherPerBall.length - 1];
              }

              catcherName = catcher.name;
              catcherNumber = { t: 's', v: catcher.number };
              catcherUniqid = catcher.uniqid;
            }

            if (currentPitcherUniqid === pitcherUniqid) {
              pitcherName = '';
              pitcherNumber = '';
              pitcherHand = '';
            }
            if (currentCatcherUniqid === catcherUniqid) {
              catcherName = '';
              catcherNumber = '';
            }

            const row = [
              '',
              '',
              pitcherName,
              pitcherNumber,
              pitcherHand,
              catcherName,
              catcherNumber,
              '',
              '',
              '',
              '',
              '',
              `${currentBall}-${currentStrike}`,
            ];
            currentPitcherUniqid = pitcherUniqid;
            currentCatcherUniqid = catcherUniqid;

            let ballResultWording = '';
            // 該打席最後一顆球（而且是結束在打者身上）
            if (ballSeq === PA.ball_counts.length - 1 && PA.result) {
              switch (ballResult) {
                case 'S':
                  ballResultWording = '未揮棒';
                  break;
                case 'SW':
                  ballResultWording = '揮棒';
                  break;
                case 'B':
                  ballResultWording = '壞球';
                  break;
                case 'F':
                  ballResultWording = '擦棒';
                  break;
                case 'H':
                  ballResultWording = '打擊出去';
                  break;
                default:
                  break;
              }
              ballResultWording += `-${PA.result}`;
            } else {
              switch (ballResult) {
                case 'S':
                  ballResultWording = '未揮棒好球';
                  break;
                case 'SW':
                  ballResultWording = '揮棒好球';
                  break;
                case 'B':
                  ballResultWording = '壞球';
                  break;
                case 'F':
                  ballResultWording = '界外球';
                  break;
                default:
                  break;
              }
            }
            row.push(ballResultWording);

            if (ballSeq === 0) {
              row[0] = PA.inning;
              row[1] = PA.inning_frame;
              row[7] = PA.batter.name;
              row[8] = { t: 's', v: PA.batter.number };
              row[9] = PA.b_hand;
              row[10] = PA.PA_round;
              row[11] = PA.PA_order;
            }
            ballCount++;
            if (ballResult === 'B') {
              currentBall++;
            } else if (currentStrike < 2) {
              currentStrike++;
            }
            rows.push(row);
          });
        });

        return rows;
      };

      const rows = genRows(pbp, opponentPitcherPerBall, opponentCatcherPerBall);
      const opponentRows = genRows(opponentPbP, pitcherPerBall, catcherPerBall);
      let rowMaxIndex = Math.max(rows.length, opponentRows.length) - 1;
      for (let rowIndex = 0; rowIndex <= rowMaxIndex; rowIndex++) {
        const leftSideRow = rows[rowIndex] ? rows[rowIndex] : new Array(14).fill('');
        const row = opponentRows[rowIndex]
          ? [...leftSideRow, ...new Array(4).fill(''), ...opponentRows[rowIndex]]
          : leftSideRow;
        perBallRows.push(row);
      }

      const sheetName = `${formatDate(new Date(game.started_at), 'yyyyMMdd_HHmm')}_vs_${game.opponent_team_name}`;
      const gameSheet = XLSX.utils.aoa_to_sheet(perBallRows);
      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = gameSheet;
    };

    games.forEach((game) => {
      const gameInfo = [
        game.opponent_team_name,
        game.info.side,
        game.info.innings,
        { t: 's', v: game.info.away_scores },
        { t: 's', v: game.info.home_scores },
        game.started_at,
      ];

      const { pitcherPerBall, catcherPerBall } = getPCPerBall(game.fielders);
      const { pitcherPerBall: opponentPitcherPerBall, catcherPerBall: opponentCatcherPerBall } = getPCPerBall(
        game.opponent_fielders,
      );
      appendRows(gameInfo, false, game.pbp, opponentCatcherPerBall);
      appendRows(gameInfo, true, game.opponent_pbp, catcherPerBall);
      appendGameSheet(
        game,
        game.pbp,
        pitcherPerBall,
        catcherPerBall,
        game.opponent_pbp,
        opponentPitcherPerBall,
        opponentCatcherPerBall,
      );
    });

    const sheet = XLSX.utils.aoa_to_sheet(exportRows);
    wb.Sheets['逐打席'] = sheet;

    XLSX.writeFile(wb, `${title}.xlsx`);
  };

  FirstBaseResource.getGames({ teamUniqid: team.uniqid }).then(({ data }) => {
    exportToXLSX(data);
  });
};
