import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Box, ThemeProvider, CSSReset, extendTheme, Flex } from '@chakra-ui/react';
import { Sidebar } from '~~components';
import { UserResource } from '~~apis/resource';
import Dashboard from '~~features/Dashboard';
import League from '~~features/League';
import GameDetail from '~~features/GameDetail';
import Media from '~~features/Media';
import News from '~~features/News';
import Team from '~~features/Team';
import Player from '~~features/Player';
import Location from '~~features/Location';
import FirstBase from '~~features/FirstBase';
import CPBL from '~~features/CPBL';
import UserPlugin from '~~features/UserPlugin';
import PluginOrder from '~~features/PluginOrder';
import Permission from '~~features/Permission';
import TeamPlayerRegistry from '~~features/TeamPlayerRegistry';
import SeasonSchedule from '~~features/SeasonSchedule';
import Playground from '~~features/Playground';

const routes = [
  {
    path: '/',
    component: Dashboard,
    sidebar: 'Dashboard',
    exact: true,
  },
  {
    path: '/league',
    component: League,
    sidebar: 'League',
    exact: true,
  },
  /* ---- deprecated start ---- */
  {
    path: '/team_player_registry',
    component: TeamPlayerRegistry,
    sidebar: 'TeamPlayerRegistry',
    exact: true,
  },
  {
    path: '/season_schedule',
    component: SeasonSchedule,
    sidebar: 'SeasonSchedule',
    exact: true,
  },
  {
    path: '/game_detail',
    component: GameDetail,
    sidebar: 'GameDetail',
    exact: true,
  },
  {
    path: '/game_news',
    component: News,
    sidebar: 'News',
    exact: true,
  },
  {
    path: '/game_media',
    component: Media,
    sidebar: 'Media',
    exact: true,
  },
  /* ---- deprecated end ---- */
  {
    path: '/team',
    component: Team,
    sidebar: 'Team',
    exact: true,
  },
  {
    path: '/player',
    component: Player,
    sidebar: 'Player',
    exact: true,
  },
  {
    path: '/location',
    component: Location,
    sidebar: 'Location',
    exact: true,
  },
  {
    path: '/first-base',
    component: FirstBase,
    sidebar: 'FirstBase',
    exact: true,
  },
  {
    path: '/cpbl',
    component: CPBL,
    sidebar: 'CPBL',
    exact: true,
  },
  {
    path: '/user_plugin',
    component: UserPlugin,
    sidebar: 'UserPlugin',
    exact: true,
  },
  {
    path: '/plugin_order',
    component: PluginOrder,
    sidebar: 'PluginOrder',
    exact: true,
  },
  {
    path: ['/permission/:target/:target_uniqid', '/permission'],
    component: Permission,
    sidebar: 'Permission',
    exact: true,
  },
  {
    path: '/playground',
    component: Playground,
    exact: true,
  },
];

const theme = extendTheme({
  colors: {
    primary: '#0A2C51',
    secondary: '#E8373D',
    confirm: {
      500: '#0A2C51',
    },
  },
});

function App() {
  const [isValidUser, setIsValidUser] = useState(false);

  useEffect(() => {
    UserResource.getPermissionList({ target: 'platform', targetUniqid: 0 })
      .then(() => {
        setIsValidUser(true);
      })
      .catch((e) => {
        if (window.location.pathname === '/playground') {
          setIsValidUser(true);
          return;
        }
        console.log(e);
        // 沒有登入狀態或沒有權限，都帶去首頁
        window.location.replace(`${process.env.REACT_APP_MAINPAGE_URL}`);
        return;
      });
  }, []);

  if (!isValidUser) {
    return <></>;
  }

  return (
    <div className="App">
      <ThemeProvider resetCSS theme={theme}>
        <CSSReset />
        <Router>
          <Flex>
            {window.location.pathname !== '/playground' && (
              <Switch>
                {routes.map((route, i) => (
                  <RouteSidebarWithSubRoutes key={i} {...route} />
                ))}
              </Switch>
            )}
            <Box px="10" pt="5" w="85vw">
              <Switch>
                {routes.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
              </Switch>
            </Box>
          </Flex>
        </Router>
      </ThemeProvider>
    </div>
  );
}

function RouteSidebarWithSubRoutes(route) {
  return (
    <Route path={route.path} render={(props) => <Sidebar {...props} routes={route.routes} route={route.sidebar} />} />
  );
}

function RouteWithSubRoutes(route) {
  return <Route path={route.path} render={(props) => <route.component {...props} routes={route.routes} />} />;
}

export default App;
