import React, { useState, useEffect } from 'react';
import { Box, Grid, Text, SimpleGrid, Button, Heading, HStack, Tag, Flex, Center } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { isNil } from 'ramda';
import { TeamResource, PlayerResource } from '~~apis/resource';
import TeamCUD from './TeamCUD';
import PlayerRegistry from './PlayerRegistry';
import PlayerDelete from './PlayerDelete';

const displayTeamName = (team) => {
  if (team.name === team.origin.name) {
    return team.name;
  }

  return `${team.name} (${team.origin.name})`;
};

const Season = ({ season, back }) => {
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);
  const [players, setPlayers] = useState([]);
  const [reloadTeams, setReloadTeams] = useState(false);
  const [reloadPlayers, setReloadPlayers] = useState(false);

  useEffect(() => {
    setTeam(null);
    setTeams([]);

    TeamResource.getSeasonTeams({ seasonUniqid: season.uniqid }).then((data) => {
      setTeams(data.data);
    });
  }, [season]);

  useEffect(() => {
    if (!reloadTeams) {
      return;
    }
    setTeam(null);
    setTeams([]);

    TeamResource.getSeasonTeams({ seasonUniqid: season.uniqid }).then((data) => {
      setTeams(data.data);
    });
    setReloadTeams(false);
    setReloadPlayers(true);
  }, [reloadTeams, season]);

  useEffect(() => {
    if (!reloadPlayers) {
      return;
    }
    setPlayers([]);

    if (isNil(team?.name)) {
      return;
    }

    PlayerResource.getSeasonTeamPlayers({ seasonUniqid: season.uniqid, teamName: team.name }).then((data) => {
      setPlayers(data.data);
    });
    setReloadPlayers(false);
  }, [reloadPlayers, team, season]);

  const updateTeamSelect = (team) => () => {
    setTeam(team);
    setReloadPlayers(true);
  };

  const handleSeasonTeamCUD = () => {
    setReloadTeams(true);
  };

  const handlePlayerCreate = () => {
    setReloadPlayers(true);
  };

  const handlePlayeDelete = () => {
    setReloadPlayers(true);
  };

  return (
    <Box>
      <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={back}>
        返回
      </Button>
      <Text py="2">{season.title}</Text>
      <Grid templateColumns="repeat(1, 2fr 5fr)" gap={5} mt="2">
        <Box h="80vh" w="100%">
          <HStack spacing={4}>
            <Text as="cite" fontSize="xl">
              參賽隊伍
            </Text>
            {!reloadTeams && (
              <TeamCUD seasonUniqid={season.uniqid} seasonTeams={teams} seasonTeamCUD={handleSeasonTeamCUD} />
            )}
          </HStack>
          <Box h="100%" overflowY="auto">
            <Table size="sm" variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>球隊名稱</Th>
                  <Th>操作</Th>
                </Tr>
              </Thead>
              <Tbody>
                {teams.map((team) => (
                  <Tr key={`season-detail-teams-${team.name}-${team.origin.name}`}>
                    <Td w="1%" whiteSpace="nowrap">
                      {displayTeamName(team)}
                    </Td>
                    <Td w="1%" whiteSpace="nowrap">
                      <Flex>
                        <Button variant="outline" onClick={updateTeamSelect(team)} size="xs">
                          登錄球員
                        </Button>
                        <TeamCUD
                          seasonTeam={team}
                          seasonUniqid={season.uniqid}
                          seasonTeams={teams}
                          seasonTeamCUD={handleSeasonTeamCUD}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
        <Box w="100%">
          {team && (
            <>
              <HStack spacing={4}>
                <Heading>{team?.name}</Heading>
                <Text as="cite">隊伍選手</Text>
                <PlayerRegistry
                  season={season}
                  seasonTeam={team}
                  seasonTeamPlayers={players}
                  playerCreate={handlePlayerCreate}
                />
              </HStack>
              <Grid w="100%" templateColumns="repeat(auto-fill, 15rem)" columnGap="3" rowGap="3">
                {players.map((player) => (
                  <Box
                    key={`season-detail-players-${player.number}`}
                    borderRadius="2"
                    borderWidth="1px"
                    borderColor="gray.200"
                    p="2"
                    w="100%"
                  >
                    <HStack spacing={5} justifyContent="space-between">
                      <Tag>{player.number}</Tag>
                      <Text>
                        {player.name}
                        {player.joined_game && <Tag fontSize="xs">已賽</Tag>}
                      </Text>
                      <PlayerDelete
                        seasonUniqid={season.uniqid}
                        teamName={team.name}
                        playerNumber={player.number}
                        playerName={player.name}
                        joinedGame={player.joined_game}
                        playerDeleted={handlePlayeDelete}
                      />
                    </HStack>
                  </Box>
                ))}
              </Grid>
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default Season;
