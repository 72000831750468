import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Divider, useToast } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import ReactJson from 'react-json-view';
import { UserPluginResource } from '~~apis/resource';
import { FeatureTitle } from '~~components';
import { PluginMapping } from './Const';

const purchasedStatus = {
  CREATED: '新建（未付款）',
  PAID: '已付款',
  CANCELED: '取消',
};

const UserPlugin = () => {
  const toast = useToast();
  const [purchasedPlugins, setPurchasedPlugins] = useState([]);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPurchase, setSelectedPurchase] = useState({
    id: 0,
    status: 'CREATED',
    contact: {},
    content: {},
    currency: 'NTD',
    list_price: 0.0,
    retail_price: 0.0,
    user: {
      user_name: '',
      email: '',
    },
    plugin: {
      target: 'TOURNAMENT',
      name: 'open_registration',
    },
  });

  useEffect(() => {
    UserPluginResource.getPurchasedPlugins({ limit, page }).then(({ data }) => {
      setPurchasedPlugins(data);
    });
  }, [limit, page]);

  const viewModal = (purchasedPlugin) => () => {
    setSelectedPurchase(purchasedPlugin);
    onOpen();
  };

  const updateStatus = (targetStatus) => () => {
    UserPluginResource.updatePurchasedPlugin({ id: selectedPurchase.id, data: { status: targetStatus } }).then(() => {
      toast({ title: '更新成功！', status: 'success', second: 1 });
      onClose();
      UserPluginResource.getPurchasedPlugins({ limit, page }).then(({ data }) => {
        setPurchasedPlugins(data);
      });
    });
  };

  return (
    <Box w="100%">
      <FeatureTitle title="用戶購買套件" />
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>狀態</Th>
            <Th>購買人</Th>
            <Th>購買套件</Th>
            <Th>購買時價格</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {purchasedPlugins.map((purchasedPlugin, index) => (
            <Tr key={index}>
              <Td whiteSpace="nowrap">{purchasedStatus[purchasedPlugin.status]}</Td>
              <Td>{`${purchasedPlugin.contact.name} (帳號${purchasedPlugin.user.user_name})`}</Td>
              <Td>{PluginMapping[selectedPurchase.plugin.target][selectedPurchase.plugin.name]}</Td>
              <Td>{purchasedPlugin.retail_price}</Td>
              <Td>
                <Button size="xs" onClick={viewModal(purchasedPlugin)}>
                  檢視
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>購買套件</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md">套件名稱</Text>
            <Text fontSize="lg" textAlign="center">
              {PluginMapping[selectedPurchase.plugin.target][selectedPurchase.plugin.name]}
            </Text>
            <Divider opacity={0.2} my={2} />
            <Text fontSize="md">狀態</Text>
            <Text fontSize="lg" textAlign="center">
              {purchasedStatus[selectedPurchase.status]}
            </Text>
            <Divider opacity={0.2} my={2} />
            <Text fontSize="md">聯絡人</Text>
            <ReactJson src={selectedPurchase.contact} name={null} enableClipboard={false} displayDataTypes={false} />
            <Divider opacity={0.2} my={2} />
            <Text fontSize="md">帳號</Text>
            <Text fontSize="lg" textAlign="center">
              {selectedPurchase.user.user_name}
            </Text>
            <Text fontSize="lg" textAlign="center">
              {selectedPurchase.user.email}
            </Text>
            <Divider opacity={0.2} my={2} />
            <Text fontSize="md">購買時價格</Text>
            <Text fontSize="lg" textAlign="center">
              {`${selectedPurchase.retail_price} ${selectedPurchase.currency}`}
            </Text>
            <Divider opacity={0.2} my={2} />
            <Text fontSize="md">購買當下賽事資訊</Text>
            <ReactJson src={selectedPurchase.content} name={null} enableClipboard={false} displayDataTypes={false} />
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" w="100%" flexWrap="wrap">
              <Flex>
                {selectedPurchase.status !== 'CREATED' && (
                  <Button colorScheme="green" size="xs" onClick={updateStatus('CREATED')}>
                    未付款
                  </Button>
                )}
                {selectedPurchase.status !== 'PAID' && (
                  <Button colorScheme="red" size="xs" onClick={updateStatus('PAID')}>
                    已付款
                  </Button>
                )}
                {selectedPurchase.status !== 'CANCELED' && (
                  <Button colorScheme="orange" size="xs" onClick={updateStatus('CANCELED')}>
                    取消購買
                  </Button>
                )}
              </Flex>
              <Button size="xs" onClick={onClose}>
                關閉
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserPlugin;
