import axios, { CancelToken } from 'axios';
import { is, isNil } from 'ramda';
import Cookies from 'js-cookie';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 10000,
  withCredentials: true,
};

const CookieOptions = {
  path: '/',
  domain: process.env.REACT_APP_DOMAIN_PUBLIC_SUFFIX,
  sameSite: 'Lax',
};

export const RB_UID_CookieOptions = {
  expires: 4 / 1440 + 30 / 86400, // Token expired in 5min
  ...CookieOptions,
};

const axiosInstance = axios.create(API_CONFIG);
const ApiService = {
  instance: axiosInstance,

  mergeHeaders(headers, withToken) {
    let finalHeaders = {};

    if (withToken) {
      const accesstoken = Cookies.get('RB_UID');
      if (is(String, accesstoken) && accesstoken !== '') {
        finalHeaders = {
          ...finalHeaders,
          Authorization: `Bearer ${accesstoken}`,
        };
      }
    }

    if (headers) {
      finalHeaders = {
        ...finalHeaders,
        ...headers,
      };
    }

    return finalHeaders;
  },

  refreshToken() {
    return new Promise((resolve, reject) => {
      const refreshToken = Cookies.get('RB_RID');
      axios
        .post(`${process.env.REACT_APP_ACCOUNT_BACKEND_URL}/api/user/refresh_token`, { refresh_token: refreshToken })
        .then(({ data }) => {
          const accessToken = data.data;
          Cookies.set('RB_UID', accessToken, RB_UID_CookieOptions);
          Cookies.set('RB_RID', refreshToken, {
            expires: 7, // Token expired in 7days if not using
            ...CookieOptions,
          });
          resolve(accessToken);
        })
        .catch((e) => {
          // Token was broken, abandon it
          Cookies.remove('RB_UID');
          Cookies.remove('RB_RID');
          reject(e);
        });
    });
  },

  refreshTokenInRequest(resolve, reject, instanceOptions) {
    const self = this;
    const refreshToken = Cookies.get('RB_RID');
    if (!is(String, refreshToken) || refreshToken === '') {
      resolve();
    }

    this.refreshToken()
      .then(() => {
        const action = self.instance({
          ...instanceOptions,
          headers: self.mergeHeaders(instanceOptions.headers, true),
        });
        action
          .then((response) => {
            if (!isNil(response?.data?.error_code)) {
              reject(new Error(response.data.error_code));
            }
            if (response.data.error) {
              reject(new Error('UNKNOWN_ERROR'));
            }
            resolve(response.data);
          })
          .catch((e) => {
            try {
              self.handleApiError(e);
            } catch (e) {
              reject(e);
            }
          });
      })
      .catch((e) => {
        try {
          self.handleApiError(e);
        } catch (e) {
          reject(e);
        }
      });
  },

  responsePreprocess(instanceOptions) {
    const self = this;
    return new Promise((resolve, reject) => {
      const action = self.instance(instanceOptions);
      action
        .then((response) => {
          if (!isNil(response?.data?.error_code)) {
            reject(new Error(response.data.error_code));
          }
          if (response.data.error) {
            reject(new Error('UNKNOWN_ERROR'));
          }
          resolve(response.data);
        })
        .catch((e) => {
          // 如果是 Token 過期，接受用 Refresh Token 重拿一次後
          if (
            !isNil(e?.response?.data?.error_code) &&
            ((e.response.status === 401 && e.response.data.error_code === 'TOKEN_EXPIRED') ||
              (e.response.status === 403 && Cookies.get('RB_RID')))
          ) {
            this.refreshTokenInRequest(resolve, reject, instanceOptions);
          } else {
            try {
              self.handleApiError(e);
            } catch (e) {
              reject(e);
            }
          }
        });
    });
  },

  get(url, optionsConfig = {}) {
    const { preprocess = true, withToken = true, headers, ...args } = optionsConfig;
    const instanceOptions = {
      method: 'get',
      url,
      headers: this.mergeHeaders(headers, withToken),
      ...args,
    };
    if (!preprocess) {
      const action = this.instance(instanceOptions);
      return action.catch(this.handleApiError);
    }

    return this.responsePreprocess(instanceOptions);
  },

  cancelGet(url, optionsConfig = {}) {
    const { withToken = true, headers, ...args } = optionsConfig;
    const source = CancelToken.source();
    return {
      send: () =>
        this.instance({
          method: 'get',
          url,
          headers: this.mergeHeaders(headers, withToken),
          cancelToken: source.token,
          ...args,
        }).catch(this.handleApiError),
      cancel: source.cancel,
    };
  },

  post(url, optionsConfig = {}) {
    const { preprocess = true, withToken = true, headers, data, ...args } = optionsConfig;
    const instanceOptions = {
      method: 'post',
      url,
      headers: this.mergeHeaders(headers, withToken),
      data,
      ...args,
    };
    if (!preprocess) {
      const action = this.instance(instanceOptions);
      return action.catch(this.handleApiError);
    }

    return this.responsePreprocess(instanceOptions);
  },

  delete(url, optionsConfig = {}) {
    const { preprocess = true, withToken = true, headers, data, ...args } = optionsConfig;
    const instanceOptions = {
      method: 'delete',
      url,
      headers: this.mergeHeaders(headers, withToken),
      data,
      ...args,
    };
    if (!preprocess) {
      const action = this.instance(instanceOptions);
      return action.catch(this.handleApiError);
    }

    return this.responsePreprocess(instanceOptions);
  },

  put(url, optionsConfig = {}) {
    const { preprocess = true, withToken = true, headers, data, ...args } = optionsConfig;
    const instanceOptions = {
      method: 'put',
      url,
      headers: this.mergeHeaders(headers, withToken),
      data,
      ...args,
    };
    if (!preprocess) {
      const action = this.instance(instanceOptions);
      return action.catch(this.handleApiError);
    }

    return this.responsePreprocess(instanceOptions);
  },

  patch(url, optionsConfig = {}) {
    const { preprocess = true, withToken = true, headers, data, ...args } = optionsConfig;
    const instanceOptions = {
      method: 'put',
      url,
      headers: this.mergeHeaders(headers, withToken),
      data,
      ...args,
    };
    if (!preprocess) {
      const action = this.instance(instanceOptions);
      return action.catch(this.handleApiError);
    }

    return this.responsePreprocess(instanceOptions);
  },

  handleApiError(error) {
    if (!error.response) {
      throw new Error(`Unexpected Error: ${error.message}`);
    }

    const { response } = error;
    // const loginRequired = response?.status === 403;

    // if (loginRequired) {
    //   throw new Error('login required');
    // }

    if (!isNil(response.data?.error_code)) {
      throw new Error(response.data.error_code);
    }
    if (response.data.error) {
      throw new Error('UNKNOWN_ERROR');
    }

    throw error;
  },
};

export default ApiService;
