import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  createArticle: false,
  readArticles: false,
  updateArticle: false,
  deleteArticle: false,

  updateCPBLStats: false,
};

const CPBL = {
  createArticle: ({ title, link }) => {
    if (debugStatus.createArticle) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Create cpbl article successfully',
      });
    }

    return ApiService.post(`/api/cpbl/articles`, { data: { title, link } });
  },

  readArticles: () => {
    if (debugStatus.readArticles) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Get cpbl articles successfully',
      });
    }

    return ApiService.get(`/api/cpbl/articles`);
  },

  updateArticle: ({ articleID, title, link }) => {
    if (debugStatus.updateArticle) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Update cpbl article successfully',
      });
    }

    return ApiService.put(`/api/cpbl/articles/${articleID}`, { data: { title, link } });
  },

  deleteArticle: ({ articleID }) => {
    if (debugStatus.deleteArticle) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Delete cpbl article successfully',
      });
    }

    return ApiService.delete(`/api/cpbl/articles/${articleID}`);
  },

  updateCPBLStats: () => {
    if (debugStatus.updateCPBLStats) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Update CPBL surrent season stats successfully',
      });
    }

    return ApiService.put(`/api/cpbl/stats/current-season`);
  },
};

export default CPBL;
