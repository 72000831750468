import React, { useState, useRef } from 'react';
import { Button, IconButton, useToast, Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { PlayerResource } from '~~apis/resource';

const PlayerDelete = ({ seasonUniqid, teamName, playerNumber, playerName, joinedGame, playerDeleted }) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState();
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const cancelRef = useRef();

  const removePlayer = async () => {
    try {
      await PlayerResource.deleteSeasonTeamPlayer({ seasonUniqid, teamName, playerNumber });
      playerDeleted();
    } catch (e) {
      toast({
        title: '移除選手失敗',
        description: e.toString(),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };

  return (
    <>
      <IconButton icon={<DeleteIcon />} size="xs" colorScheme="red" onClick={onOpen} />
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              移除球員
            </AlertDialogHeader>

            <AlertDialogBody>
              確定要移除 背號 {playerNumber} 的 {playerName} ？
              {joinedGame && (
                <>
                  <br />
                  <Text fontSize="sm" textColor="red">
                    此球員已有參與比賽並記錄！！
                  </Text>
                </>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button colorScheme="red" onClick={removePlayer} ml={3}>
                確定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default PlayerDelete;
