export const parseAPIAbbrPAList = (paList) => {
  const prePAValues = {};
  return paList.map((pa) => {
    const paValues = {};
    Object.entries(pa).forEach(([abbr, value]) => {
      const column = !(abbr in abbrMapping) ? abbr : abbrMapping[abbr];
      paValues[column] = value;
      switch (column) {
        case 'batter':
        case 'pitcher':
        case 'catcher':
          paValues[column] = revertGamePlayer(value);
          break;
        case 'events': {
          const preEventValues = {};
          paValues[column] = value.map((v) => revertEvent(v, preEventValues));
          break;
        }
        case 'runners':
          paValues[column] = value.map(revertRunner);
          break;
        case 'substitutions':
          paValues[column] = value.map(revertSubstitution);
          break;
        default:
          break;
      }
    });

    API_ALLOW_GOON_PLAYERS.forEach((goonColumn) => {
      if (!(goonColumn in paValues)) {
        return;
      }

      if (paValues[goonColumn] === true) {
        paValues[goonColumn] = prePAValues[goonColumn];
      } else {
        prePAValues[goonColumn] = paValues[goonColumn];
      }
      return;
    });

    return paValues;
  });
};

const revertEvent = (value, preEventValues) => {
  const event = {};
  Object.entries(value).forEach(([abbr, value]) => {
    const column = !(abbr in abbrEventMapping) ? abbr : abbrEventMapping[abbr];
    event[column] = value;
    switch (column) {
      case 'pitch':
        event[column] = revertPitch(value);
        break;
      default:
        break;
    }
  });

  API_ALLOW_GOON_PLAYERS.forEach((goonColumn) => {
    if (!(goonColumn in event)) {
      return;
    }

    if (event[goonColumn] === true) {
      event[goonColumn] = preEventValues[goonColumn];
    } else {
      preEventValues[goonColumn] = event[goonColumn];
    }
    return;
  });

  return event;
};

const revertRunner = (value) => {
  const runner = {};
  Object.entries(value).forEach(([abbr, value]) => {
    const column = !(abbr in abbrRunnerMapping) ? abbr : abbrRunnerMapping[abbr];
    runner[column] = value;
    switch (column) {
      case 'runner':
      case 'count_on_pitcher':
        runner[column] = revertGamePlayer(value);
        break;
      default:
        break;
    }
  });

  return runner;
};

const revertSubstitution = (value) => {
  const substitution = {};
  Object.entries(value).forEach(([abbr, value]) => {
    const column = !(abbr in abbrSubstituationMapping) ? abbr : abbrSubstituationMapping[abbr];
    substitution[column] = value;
    switch (column) {
      case 'player':
      case 'replace':
        substitution[column] = revertGamePlayer(value);
        break;
      default:
        break;
    }
  });

  return substitution;
};

const revertPitch = (value) => {
  if (typeof value !== 'object' || !value) {
    return value;
  }
  if (Object.keys(value).length === 0) {
    return null;
  }

  const pitch = {};
  Object.entries(value).forEach(([abbr, value]) => {
    const column = !(abbr in abbrPitchMapping) ? abbr : abbrPitchMapping[abbr];
    pitch[column] = value;
  });

  return pitch;
};

const revertGamePlayer = (value) => {
  if (typeof value !== 'object' || !value) {
    return value;
  }
  if (Object.keys(value).length === 0) {
    return null;
  }

  const gamePlayer = {};
  Object.entries(value).forEach(([abbr, value]) => {
    const column = !(abbr in abbrGamePlayerMapping) ? abbr : abbrGamePlayerMapping[abbr];
    gamePlayer[column] = value;
  });

  return gamePlayer;
};

const API_ABBR_GAME_PLAYER_MAPPING = {
  name: 'n',
  number: 'u',
  uniqid: 'i',
};
const abbrGamePlayerMapping = Object.entries(API_ABBR_GAME_PLAYER_MAPPING).reduce((acc, [column, abbr]) => {
  acc[abbr] = column;
  return acc;
}, {});

const API_ABBR_EVENT_MAPPING = {
  type: 't',
  in_play: 'i',
  is_strike: 's',
  is_ball: 'a',
  is_scoring: 'o',
  pitch: 'h',
  pitcher: 'p',
  batter: 'b',
  catcher: 'c',
};
const abbrEventMapping = Object.entries(API_ABBR_EVENT_MAPPING).reduce((acc, [column, abbr]) => {
  acc[abbr] = column;
  return acc;
}, {});

const API_ABBR_PITCH_MAPPING = {
  code: 'c',
  type: 't',
  velocity: 'v',
  RPM: 'r',
  coord_x: 'x',
  coord_y: 'y',
  EV: 'e',
  trajectory: 'tr',
  hardness: 'h',
  location_code: 'l',
  location_coord_x: 'lx',
  location_coord_y: 'ly',
  fielder_position: 'p',
};
const abbrPitchMapping = Object.entries(API_ABBR_PITCH_MAPPING).reduce((acc, [column, abbr]) => {
  acc[abbr] = column;
  return acc;
}, {});

const API_ABBR_RUNNER_MAPPING = {
  event_index: 'v',
  runner: 'u',
  code: 'c',
  start_base: 's',
  end_base: 'b',
  is_out: 'o',
  is_scoring: 'i',
  is_RBI: 'r',
  is_ER: 'e',
  count_on_pitcher: 'n',
};
const abbrRunnerMapping = Object.entries(API_ABBR_RUNNER_MAPPING).reduce((acc, [column, abbr]) => {
  acc[abbr] = column;
  return acc;
}, {});

const API_ABBR_SUBSTITUTION_MAPPING = {
  event_index: 'v',
  is_offensive: 'o',
  player: 'p',
  position: 's',
  replace: 'r',
};
const abbrSubstituationMapping = Object.entries(API_ABBR_SUBSTITUTION_MAPPING).reduce((acc, [column, abbr]) => {
  acc[abbr] = column;
  return acc;
}, {});

const API_ABBR_MAPPING = {
  side: 'sd',
  inning: 'in',
  is_scoring: 'is',
  video_link: 'vl',
  batter: 'b',
  pitcher: 'p',
  catcher: 'c',
  b_hand: 'bh',
  p_hand: 'ph',
  PA_round: 'pr',
  PA_order: 'po',
  pinch: 'pi',
  start_away_score: 'sa',
  away_score: 'as',
  end_away_score: 'ea',
  start_home_score: 'sh',
  home_score: 'hs',
  end_home_score: 'eh',
  startOuts: 'so',
  outs: 'o',
  endOuts: 'eo',
  bases: 'bs',
  strikes: 's',
  balls: 'ba',
  ball_counts: 'bc',
  events: 'e',
  runners: 'r',
  location_code: 'l',
  location_coord_x: 'lx',
  location_coord_y: 'ly',
  trajectory: 't',
  hardness: 'h',
  RBI: 'rb',
  result: 'rs',
  LI: 'li',
  RE: 're',
  home_WE: 'he',
  WPA: 'w',
  RE24: 'rt',
  substitutions: 'su',
};
const abbrMapping = Object.entries(API_ABBR_MAPPING).reduce((acc, [column, abbr]) => {
  acc[abbr] = column;
  return acc;
}, {});

const API_ALLOW_GOON_PLAYERS = ['batter', 'pitcher', 'catcher'];
