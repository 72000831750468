import React from 'react';
import { Box, Center, Divider, Image, Link, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

const SidebarButton = ({ text, href, currentPage }) => {
  let textStyle = {
    fontSize: 'sm',
    fontWeight: 'normal',
  };
  let iconStyle = {
    color: 'white',
  };

  if (currentPage) {
    textStyle = {
      fontSize: 'md',
      fontWeight: 'bold',
    };
    iconStyle = {
      w: '5',
      h: '5',
      color: 'secondary',
    };
  }

  return (
    <a href={href}>
      <Box pl="3" pr="5" py="3" _hover={{ bg: 'secondary' }}>
        <Text {...textStyle} color="white">
          <ChevronRightIcon {...iconStyle} />
          {text}
        </Text>
      </Box>
    </a>
  );
};

const Sidebar = ({ route }) => {
  return (
    <Box w="10vw" h="100vh">
      {' '}
      <Box boxShadow="2xl" bgColor="primary" position="fixed" top="0" left="0" w="10vw" h="100vh" overflow="hidden">
        <Link href="/">
          <Center my="3">
            <Image src="/logo.png" rounded="full" alt="Logo" w="100px" border="1px" borderColor="white" />
          </Center>
        </Link>
        <Divider borderColor="gray.400" />
        <SidebarButton text="主辦單位" href="/league" currentPage={route === 'League'} />
        {/* <SidebarButton text="賽季登錄" href="/team_player_registry" currentPage={route === 'TeamPlayerRegistry'} />
        <SidebarButton text="賽程管理" href="/season_schedule" currentPage={route === 'SeasonSchedule'} />
        <SidebarButton text="賽事管理" href="/game_detail" currentPage={route === 'GameDetail'} />
        <SidebarButton text="賽事報導" href="/game_news" currentPage={route === 'News'} />
        <SidebarButton text="賽事花絮" href="/game_media" currentPage={route === 'Media'} /> */}
        <SidebarButton text="球隊管理" href="/team" currentPage={route === 'Team'} />
        <SidebarButton text="球員管理" href="/player" currentPage={route === 'Player'} />
        <SidebarButton text="地點管理" href="/location" currentPage={route === 'Location'} />
        <SidebarButton text="First Base" href="/first-base" currentPage={route === 'FirstBase'} />
        <SidebarButton text="CPBL" href="/cpbl" currentPage={route === 'CPBL'} />
        <SidebarButton text="擴充套件訂單" href="/plugin_order" currentPage={route === 'PluginOrder'} />
        <SidebarButton text="賽事購買" href="/user_plugin" currentPage={route === 'UserPlugin'} />
        <Box position="absolute" bottom="0" left="0" w="100%" bgColor="blue.500" boxShadow="xl">
          <SidebarButton text="權限管理" href="/permission" currentPage={route === 'Permission'} />
          <Text color="white" pl="4" py="3">
            權限：擁有者
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
