import React, { useState, useEffect } from 'react';
import { Button, VStack, HStack, Alert, AlertIcon, Box, Text, Heading } from '@chakra-ui/react';
import { InputGroup, Input, InputLeftAddon } from '@chakra-ui/react';
import { RadioGroup, Radio } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { find, propEq, isNil, isEmpty } from 'ramda';
import { PlayerResource } from '~~apis/resource';

const PlayerRegistry = ({ season, seasonTeam, seasonTeamPlayers, playerCreate }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [players, setPlayers] = useState([]);
  const [possiblePlayers, setPossiblePlayers] = useState([]);
  const [sameNamePlayer, setSameNamePlayer] = useState({ exist: false, ignore: false, showWarning: false });
  const [playerLink, setPlayerLink] = useState('none');
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitAble, setSubmitable] = useState(false);

  useEffect(() => {
    setPlayers([]);
    setPossiblePlayers([]);
    setSameNamePlayer({ exist: false, ignore: false, showWarning: false });
    setPlayerLink('none');
    setNumber('');
    setName('');
    setErrorMessage('');
    setSubmitable(false);
  }, [isOpen]);

  useEffect(() => {
    let message = '';
    const existPlayer = find(propEq('number', number))(seasonTeamPlayers);
    if (!isNil(existPlayer)) {
      message = `該背號(${number})已被 ${existPlayer.name} 使用`;
    }
    setErrorMessage(message);

    if (isEmpty(number) || isEmpty(name) || !isEmpty(errorMessage)) {
      return setSubmitable(false);
    }

    return setSubmitable(true);
  }, [number, name, errorMessage, seasonTeamPlayers]);

  useEffect(() => {
    if (isEmpty(name)) {
      return setPlayers([]);
    }

    PlayerResource.searchPlayers({ query: name }).then((data) => {
      setPlayers(data.data);
    });
  }, [name]);

  useEffect(() => {
    setPlayerLink('none');

    if (players.length === 0) {
      return;
    }
    const similarLimit = 6;
    const displayPlayers = [];
    players.forEach((player) => {
      if (displayPlayers.length > similarLimit && player.name !== name) {
        return;
      }

      if (player.name === name) {
        setSameNamePlayer({ exist: true });
      }
      displayPlayers.push(player);
    });
    setPossiblePlayers(displayPlayers);
  }, [players, name]);

  useEffect(() => {
    setSameNamePlayer({ showWarning: false });
  }, [playerLink]);

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async () => {
    let playerUniqid = '';
    if (playerLink !== 'none') {
      playerUniqid = playerLink;
    } else if (sameNamePlayer.exist && !sameNamePlayer.ignore && !sameNamePlayer.showWarning) {
      return setSameNamePlayer({ showWarning: true });
    }

    try {
      await PlayerResource.createSeasonTeamPlayer({
        seasonUniqid: season.uniqid,
        teamName: seasonTeam.name,
        data: { number, name, player_uniqid: playerUniqid },
      });
      onClose();
      playerCreate();
    } catch (e) {
      setErrorMessage(e.toString());
    }
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme="teal" size="sm" onClick={onOpen}>
        登錄
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>選手登錄</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={8}>
                <InputGroup>
                  <InputLeftAddon children="背號" />
                  <Input
                    type="text"
                    borderLeftRadius="0"
                    placeholder="背號"
                    value={number}
                    onChange={handleNumberChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon children="姓名" />
                  <Input type="text" borderLeftRadius="0" placeholder="姓名" value={name} onChange={handleNameChange} />
                </InputGroup>
                {errorMessage && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
              </VStack>
              {possiblePlayers.length > 0 && (
                <Box p={2}>
                  <Text>連動球員</Text>
                  <RadioGroup onChange={setPlayerLink} value={playerLink}>
                    <VStack p={3} spacing={2} bg="gray.200" borderRadius={10} align="left">
                      <Radio value="none">不連動</Radio>
                      {possiblePlayers.map((player) => (
                        <Radio value={player.uniqid}>
                          <HStack>
                            <Heading size="md">{player.name}</Heading>
                            <Text fontSize="sm" as="i">
                              經歷
                            </Text>
                            <Text fontSize="xs">
                              {player.experiences.map((experience) => (
                                <>
                                  {`${experience.season_year}-${experience.season} ${experience.team}(${experience.number})`}{' '}
                                  <br />
                                </>
                              ))}
                            </Text>
                          </HStack>
                        </Radio>
                      ))}
                    </VStack>
                  </RadioGroup>
                </Box>
              )}
              {sameNamePlayer.showWarning && (
                <Alert status="warning">
                  <AlertIcon />
                  如確認相同名稱的選手不是同一人，請繼續送出，紀錄將會拆開計算。否則請選擇連動
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <Button mr={3} variant="ghost" onClick={onClose}>
                取消
              </Button>
              <Button colorScheme="green" isDisabled={!submitAble} onClick={handleSubmit}>
                送出
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default PlayerRegistry;
