import React from 'react';
import { Button, IconButton, Flex, useToast } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { GameSimpleCUModal, ConfirmAlertDialog } from '~~components';
import CommonUtils from '~~utils/CommonUtils';
import { GameDetailResource } from '~~apis/resource';
import { GAME_STATUS_KEY } from '../Consts';
import { useGameDetailState } from '../hooks';

const winnerSideMapping = {
  AWAY: '客隊勝',
  HOME: '主隊勝',
  TIE: '和局',
  BOTHLOSE: '雙敗',
  NONE: '無',
};

const ListGame = ({ seasonUniqid, startEdit, created, deleted }) => {
  const toast = useToast();
  const { games } = useGameDetailState();
  const newGameIcon = (onOpen) => (
    <Button size="xs" onClick={onOpen}>
      新增賽事
    </Button>
  );

  const deleteGame = (gameUniqid) => () => {
    toast({
      title: '刪除中，請稍後',
      status: 'info',
      duration: 1000,
      isClosable: true,
    });
    GameDetailResource.deleteGame({ seasonUniqid, gameUniqid })
      .then(() => {
        toast({
          title: '刪除成功！',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
        deleted();
      })
      .catch(() => {
        toast({
          title: '刪除失敗！請稍後再試',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Table size="sm" variant="striped" colorScheme="gray" w="100%" style={{ 'table-layout': 'fixed' }}>
      <Thead>
        <Tr>
          <Th w="8%">賽事編號</Th>
          <Th w="12%">分類</Th>
          <Th w="20%">預計比賽日期</Th>
          <Th w="15%">客隊</Th>
          <Th w="15%">主隊</Th>
          <Th w="14%">狀態</Th>
          <Th w="10%">操作</Th>
          <Th w="6%" isNumeric>
            連結
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {games.map((game) => (
          <Tr key={game.uniqid}>
            <Td>G{game.seq}</Td>
            <Td>{game.group}</Td>
            <Td>{game.info.time.scheduled_start_at}</Td>
            <Td>{CommonUtils.displayGameTeam('away', game)}</Td>
            <Td>{CommonUtils.displayGameTeam('home', game)}</Td>
            <Td>
              {GAME_STATUS_KEY[game.info.status]}
              {game.info.status === 'FINISHED' && <> ({winnerSideMapping[game.info.winner_side]})</>}
            </Td>
            <Td>
              <Flex>
                <Button size="xs" colorScheme="orange" onClick={startEdit(game.uniqid)}>
                  編輯
                </Button>
                <ConfirmAlertDialog
                  buttonStyles={{ size: 'xs', colorScheme: 'red' }}
                  buttonDisplay="刪除"
                  title="刪除賽事"
                  content={`
                    確定要刪除該賽事嗎？
                    ${game.info.time.scheduled_start_at}
                    ${CommonUtils.displayGameTeam('away', game)} v.s. ${CommonUtils.displayGameTeam('home', game)}
                  `}
                  confirmColor="red"
                  handleConfirm={deleteGame(game.uniqid)}
                />
              </Flex>
            </Td>
            <Td isNumeric>
              <IconButton
                size="xs"
                icon={<ExternalLinkIcon />}
                as="a"
                target="_blank"
                href={`${process.env.REACT_APP_MAINPAGE_URL}/season/${seasonUniqid}?game=${game.uniqid}`}
              />
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td>
            <GameSimpleCUModal
              seasonUniqid={seasonUniqid}
              games={games}
              groups={[]}
              teams={[]}
              reloadRequired={created}
              customIcon={newGameIcon}
            />
          </Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default ListGame;
