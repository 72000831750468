import React, { useState, useRef } from 'react';
import { Box, Button, Alert, AlertIcon, Link, Flex, Divider, Text } from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { includes } from 'ramda';
import { useLocation } from 'react-router-dom';
import { GameDetailResource } from '~~apis/resource';
import { useGameDetailState } from '../hooks';
import BoxScoreUpload from './BoxScoreUpload';
import BoxScoreSimplifyUpload from './BoxScoreSimplifyUpload';

const Panel = ({ seasonUniqid, infoForm, awayForm, homeForm }) => {
  const { gameNodes } = useGameDetailState();
  const [alert, setAlert] = useState({ status: '', message: '' });
  const [resultLink, setResultLink] = useState('');
  const [isDiscardOpen, setIsDiscardOpen] = useState(false);
  const onDiscardClose = () => setIsDiscardOpen(false);
  const onDiscardOpen = () => setIsDiscardOpen(true);
  const discardCancelRef = useRef();

  const location = useLocation();

  const alertMessage = () => {
    if (alert.status === '') {
      return;
    }

    if (includes(alert.status, ['success', 'error'])) {
      return (
        <Alert status={alert.status} variant="subtle" size="sm" fontSize="sm">
          <AlertIcon size="sm" />
          {alert.message}
        </Alert>
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert({ status: 'loading', message: '' });
    setResultLink('');

    infoForm.trigger().then((valid) => {
      if (!valid) {
        setAlert({ status: 'error', message: '資訊欄內資料有誤' });
        return;
      }

      const info = infoForm.getValues();
      const away = awayForm.getValues();
      const home = homeForm.getValues();

      if (info.status === 'FINISHED') {
        if (!away.team_name) {
          setAlert({ status: 'error', message: '完賽狀態下，客隊為必填' });
          return;
        }

        if (!home.team_name) {
          setAlert({ status: 'error', message: '完賽狀態下，主隊為必填' });
          return;
        }
      }

      const data = {
        info: {
          ...info,
          nodes: gameNodes,
        },
        away: {
          scores: [],
          ...away,
        },
        home: {
          scores: [],
          ...home,
        },
      };

      GameDetailResource.updateGame({ seasonUniqid, gameUniqid: info.uniqid, data })
        .then((data) => {
          setResultLink(data.data.link);
          setAlert({ status: 'success', message: '更新成功' });
        })
        .catch((e) => {
          setAlert({ status: 'error', message: e.toString() });
        });
    });
  };

  const backToList = () => {
    window.location.href = `${location.pathname}?season=${seasonUniqid}`;
  };

  return (
    <Box ml="5" px="3" pt="3" h="100%" bg="gray.100" textAlign="center">
      {resultLink && (
        <Link href={resultLink} isExternal fontSize="sm">
          查看網站顯示 <ExternalLinkIcon mx="2px" size="sm" />
        </Link>
      )}
      {alertMessage()}
      {alert.status !== 'success' ? (
        <>
          <Box>
            <Flex justifyContent="space-evenly">
              <Button colorScheme="teal" onClick={handleSubmit} isDisabled={alert.status === 'loading'} size="sm">
                更新
              </Button>
              <Button colorScheme="orange" onClick={onDiscardOpen} size="sm">
                捨棄變更
              </Button>
            </Flex>
            <Divider my="3" />
            <BoxScoreUpload sampleDownload={true} awayForm={awayForm} homeForm={homeForm} />
            <Divider my="3" />
            <BoxScoreUpload sampleDownload={false} awayForm={awayForm} homeForm={homeForm} />
            <Divider my="3" />
            <Text fontSize="sm">新版 Excel 上傳</Text>
            <BoxScoreSimplifyUpload awayForm={awayForm} homeForm={homeForm} />
          </Box>
          <AlertDialog isOpen={isDiscardOpen} leastDestructiveRef={discardCancelRef} onClose={onDiscardClose}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  捨棄變更
                </AlertDialogHeader>
                <AlertDialogBody>確定要捨棄變更嗎？所有改動皆不會儲存</AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={discardCancelRef} onClick={onDiscardClose}>
                    取消
                  </Button>
                  <Button colorScheme="red" onClick={backToList} ml={3}>
                    確定
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      ) : (
        <Button colorScheme="orange" onClick={backToList} size="sm">
          回到列表
        </Button>
      )}
    </Box>
  );
};

export default Panel;
