import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  getTeamInfo: false,
  getTeams: false,
  getGames: false,
  createTeam: false,
  updateTeam: false,
  importGame: false,
  deleteGame: false,
  getBIGCoefficient: false,
  getBIGCoefficientHistory: false,
  updateBIGCoefficient: false,
  updateCPBLStats: false,
  getCPBLGameVerify: false,
};

const FirstBase = {
  getTeamInfo: async ({ teamUniqid }) => {
    if (debugStatus.getTeamInfo) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [],
        error: false,
        message: 'Get team successfully',
      });
    }

    return ApiService.get(`/api/firstbase/teams/${teamUniqid}/info`);
  },

  getTeams: async ({ query }) => {
    if (debugStatus.getTeams) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [],
        error: false,
        message: 'Get teams successfully',
      });
    }

    if (!query) {
      return ApiService.get(`/api/firstbase/teams`);
    }
    return ApiService.get(`/api/firstbase/teams?query=${encodeURIComponent(query)}`);
  },

  getGames: ({ teamUniqid, gameUniqids }) => {
    if (debugStatus.getGames) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [],
        error: false,
        message: 'Get games successfully',
      });
    }

    if (!gameUniqids) {
      return ApiService.get(`/api/firstbase/teams/${teamUniqid}/games`);
    }

    let batch = '';
    gameUniqids.forEach((uniqid, index) => {
      if (index !== 0) {
        batch += '&';
      }
      batch += `batch=${uniqid}`;
    });
    return ApiService.get(`/api/firstbase/teams/${teamUniqid}/games?${batch}`);
  },

  createTeam: ({ name, full_name, icon_path, due_date, status }) => {
    if (debugStatus.createTeam) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Create team successfully',
      });
    }

    return ApiService.post(`/api/firstbase/teams`, {
      data: {
        name,
        full_name,
        icon_path,
        due_date,
        status,
      },
    });
  },

  updateTeam: ({ teamUniqid, name, full_name, icon_path, due_date, status }) => {
    if (debugStatus.updateTeam) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Update team successfully',
      });
    }

    return ApiService.put(`/api/firstbase/teams/${teamUniqid}`, {
      data: {
        name,
        full_name,
        icon_path,
        due_date,
        status,
      },
    });
  },

  importGame: ({
    teamUniqid,
    opponent_team_name,
    started_at,
    tags,
    info,
    pbp,
    fielders,
    opponent_pbp,
    opponent_fielders,
  }) => {
    if (debugStatus.importGame) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Import game successfully',
      });
    }

    return ApiService.post(`/api/firstbase/teams/${teamUniqid}/games`, {
      data: {
        opponent_team_name,
        started_at,
        tags,
        info,
        pbp,
        fielders,
        opponent_pbp,
        opponent_fielders,
      },
    });
  },

  updateGameInfo: ({ teamUniqid, gameUniqid, started_at, info }) => {
    return ApiService.put(`/api/firstbase/teams/${teamUniqid}/games/${gameUniqid}/info`, {
      data: {
        started_at,
        info,
      },
    });
  },

  updateGamePA: ({ teamUniqid, gameUniqid, PA, updatableColumns }) => {
    return ApiService.put(`/api/firstbase/teams/${teamUniqid}/games/${gameUniqid}/pa`, {
      data: {
        PA,
        updatable_columns: updatableColumns,
      },
    });
  },

  deleteGame: ({ teamUniqid, gameUniqid }) => {
    if (debugStatus.deleteGame) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Delete game successfully',
      });
    }

    return ApiService.delete(`/api/firstbase/teams/${teamUniqid}/games/${gameUniqid}`);
  },

  getBIGCoefficient: () => {
    if (debugStatus.getBIGCoefficient) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'get B.I.G. cases coefficient successfully',
      });
    }

    return ApiService.get(`/api/firstbase/BIG/coefficient`);
  },

  getBIGCoefficientHistory: () => {
    if (debugStatus.getBIGCoefficientHistory) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'get B.I.G. cases coefficient history successfully',
      });
    }

    return ApiService.get(`/api/firstbase/BIG/coefficient/history`);
  },

  updateBIGCoefficient: () => {
    if (debugStatus.updateBIGCoefficient) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'update B.I.G. cases coefficient successfully',
      });
    }

    return ApiService.put(`/api/firstbase/BIG/coefficient`);
  },

  getCPBLGameVerify: ({ url }) => {
    return ApiService.get(`/api/cpbl/game-verify?url=${encodeURIComponent(url)}`, { preprocess: false });
  },
};

export default FirstBase;
