import React, { useEffect, useState } from 'react';
import { Box, Grid, SimpleGrid, Button, Image, Center, Divider, Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { find, findIndex } from 'ramda';
import { ConfirmAlertDialog, NestSelect, LoadingAlertDialog } from '~~components';
import { SeasonResource, GameDetailResource } from '~~apis/resource';
import CommonUtils from '~~utils/CommonUtils';
import UploadModal from './components/UploadModal';

const MAX_FILE_AMOUNT = 10;

const Media = () => {
  const [seasons, setSeasons] = useState([]);
  const [games, setGames] = useState([]);
  const [shoudSelectionsUpdate, updateSelections] = useState(false);
  const [nestSelections, setNestSelections] = useState([
    { placeholder: '聯賽選擇', options: [], isReady: false, target: '' },
    { placeholder: '賽事選擇', options: [], isReady: false, target: '' },
  ]);
  const [decisionFreeze, freezeDecision] = useState(false);

  const [season, setSeason] = useState();
  const [game, setGame] = useState();
  const [mediaList, setMediaList] = useState([]);
  const [groupedMediaList, setGroupedMediaList] = useState({});

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    if (nestSelections[0].isReady) {
      return;
    }

    SeasonResource.getSeasons().then((data) => {
      setSeasons(data.data);
      nestSelections[0].options = data.data.map((season) => {
        return {
          value: season.uniqid,
          display: season.title,
        };
      });
      nestSelections[0].isReady = true;
      setNestSelections(nestSelections);
      updateSelections(true);
    });
  }, [nestSelections, seasons]);

  useEffect(() => {
    const group = { Rebas: [] };
    mediaList.forEach((media) => {
      if (!group[media.photographer.name]) {
        group[media.photographer.name] = [];
      }

      group[media.photographer.name].push(media);
    });
    setGroupedMediaList(group);
  }, [mediaList]);

  const selectionsUpdated = () => {
    updateSelections(false);
  };

  const decisionUpdate = (index) => (e) => {
    if (index === 0) {
      return seasonUpdate(e.target.value);
    }
    if (index === 1) {
      return gameUpdate(e.target.value);
    }
  };

  const seasonUpdate = (seasonUniqid) => {
    nestSelections[0].target = seasonUniqid;
    nestSelections[1].isReady = false;
    nestSelections[1].target = '';
    setNestSelections(nestSelections);
    updateSelections(true);

    const selectedSeason = find((s) => s.uniqid === seasonUniqid)(seasons);
    setSeason(selectedSeason);
    setGame();
    if (!selectedSeason) {
      return;
    }

    GameDetailResource.getGames({ seasonUniqid }).then((data) => {
      setGames(data.data);
      nestSelections[1].options = data.data.map((game) => {
        return {
          value: game.uniqid,
          display: `G${game.seq} ${game.info.time.scheduled_start_at}
          ${CommonUtils.displayGameTeam('away', game)} vs ${CommonUtils.displayGameTeam('home', game)}`,
        };
      });
      nestSelections[1].isReady = true;
      setNestSelections(nestSelections);
      updateSelections(true);
    });
  };

  const gameUpdate = (gameUniqid) => {
    nestSelections[1].target = gameUniqid;
    setNestSelections(nestSelections);
    updateSelections(true);

    const selectedGame = find((g) => g.uniqid === gameUniqid)(games);
    setGame(selectedGame);
  };

  const startEdit = () => {
    if (!game.media) {
      setMediaList([]);
    } else {
      setMediaList(game.media);
    }
    freezeDecision(true);
  };

  const cancelEdit = () => {
    freezeDecision(false);
  };

  const updataMediaRequest = (mediaList, done) => {
    GameDetailResource.appendMedia({ seasonUniqid: season.uniqid, gameUniqid: game.uniqid, mediaList })
      .then((data) => {
        done(true);
        coverMediaData(data.data.media);
      })
      .catch(() => {
        done(false);
      });
  };

  const deleteMedia = (index) => () => {
    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');
    const target = mediaList[index];
    GameDetailResource.removeMedia({
      seasonUniqid: season.uniqid,
      gameUniqid: game.uniqid,
      mediaPath: target.path,
      mediaType: target.type,
    })
      .then(() => {
        mediaList.splice(index, 1);
        const newMediaList = mediaList.map((media) => media);
        coverMediaData(newMediaList);
        setLoadingStatus('SUCCESS');
      })
      .catch(() => {
        setLoadingStatus('ERROR');
        setLoadingMessage('發生錯誤，請稍後再試');
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
  };

  const coverMediaData = (media) => {
    const gameIndex = findIndex((g) => g.uniqid === game.uniqid)(games);
    games[gameIndex].media = media;
    setGames(games);

    game.media = media;
    setGame(game);

    setMediaList(media);
  };

  return (
    <Box>
      <Box w="80%">
        <Grid templateColumns="repeat(1, 4fr 1fr)" gap={5}>
          <NestSelect
            nestSelections={nestSelections}
            shoudUpdate={shoudSelectionsUpdate}
            selectionsUpdated={selectionsUpdated}
            decisionUpdate={decisionUpdate}
            decisionFreeze={decisionFreeze}
            styles={{ mt: 10, mb: 5 }}
          />
          {game && (
            <SimpleGrid alignSelf="end" columns={2} spacing={2} mb="5">
              {decisionFreeze ? (
                <Button variant="outline" onClick={cancelEdit}>
                  變更場次
                </Button>
              ) : (
                <div></div>
              )}
              <div></div>
              <Button isDisabled={decisionFreeze} onClick={startEdit}>
                套用
              </Button>
            </SimpleGrid>
          )}
        </Grid>
        {game && decisionFreeze && (
          <Box>
            {Object.keys(groupedMediaList).map((photographerName) => (
              <Box>
                <Text>攝影 {photographerName}</Text>
                <Divider />
                <SimpleGrid columns={3} spacing={2}>
                  {groupedMediaList[photographerName].map((media, index) => (
                    <Box boxSize="xs" boxShadow="md" p="2" position="relative">
                      <ConfirmAlertDialog
                        buttonStyles={{ variant: 'ghost', position: 'absolute', top: '2', right: '2' }}
                        icon={<DeleteIcon />}
                        title="刪除媒體檔"
                        content="確定要刪除該媒體檔嗎？"
                        confirmColor="orange"
                        handleConfirm={deleteMedia(index)}
                      />
                      <Center w="100%" h="100%">
                        <Image src={media.url} maxW="100%" maxH="100%" />
                      </Center>
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
            ))}
            <UploadModal maxFileAmount={MAX_FILE_AMOUNT - mediaList.length} updataMediaRequest={updataMediaRequest} />
          </Box>
        )}
      </Box>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title="刪除媒體檔"
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </Box>
  );
};

export default Media;
