import React, { useState, useRef } from 'react';
import { Button, IconButton } from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

const ConfirmAlertDialog = ({
  buttonStyles,
  buttonDisplay,
  icon,
  title,
  content,
  confirmColor = 'red',
  handleConfirm,
}) => {
  const [isDiscardOpen, setIsDiscardOpen] = useState(false);
  const onDiscardClose = () => setIsDiscardOpen(false);
  const onDiscardOpen = () => setIsDiscardOpen(true);
  const discardCancelRef = useRef();

  const triggerConfirm = () => {
    handleConfirm();
    onDiscardClose();
  };

  return (
    <>
      {icon ? (
        <IconButton {...buttonStyles} icon={icon} onClick={onDiscardOpen} />
      ) : (
        <Button {...buttonStyles} onClick={onDiscardOpen}>
          {buttonDisplay}
        </Button>
      )}
      <AlertDialog isOpen={isDiscardOpen} leastDestructiveRef={discardCancelRef} onClose={onDiscardClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            <AlertDialogBody>{content}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={discardCancelRef} onClick={onDiscardClose}>
                取消
              </Button>
              <Button colorScheme={confirmColor} onClick={triggerConfirm} ml={3}>
                確定
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ConfirmAlertDialog;
