import React from 'react';
import { Button, Text, useToast } from '@chakra-ui/react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import CommonUtils from '~~utils/CommonUtils';
import { GameDetailResource } from '~~apis/resource';

const GameDelete = ({ seasonUniqid, game, reloadRequired, size = 'md' }) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  const gameDisplay = () => {
    return (
      <Text>
        {`G${game.seq} ${game.info.time.scheduled_start_at}
        ${CommonUtils.displayGameTeam('away', game)} vs ${CommonUtils.displayGameTeam('home', game)}`}
      </Text>
    );
  };

  const removeGame = () => {
    GameDetailResource.deleteGame({ seasonUniqid, gameUniqid: game.uniqid })
      .then(() => {
        reloadRequired();
      })
      .catch((e) => {
        toast({
          title: '刪除賽事失敗',
          description: e.toString(),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <>
      <Button colorScheme="red" onClick={() => setIsOpen(true)} size={size}>
        刪除
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              刪除賽事
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>確定要刪除此賽事？</Text>
              {gameDisplay()}
              <Text>此動作不可回復</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                取消
              </Button>
              <Button colorScheme="red" onClick={removeGame} ml={3}>
                確定刪除
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default GameDelete;
