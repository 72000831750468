import React from 'react';
import { Box } from '@chakra-ui/react';
import BIGCoefficientList from './components/BIGCoefficientList';
import WebSiteStats from './components/WebSiteStats';
import MemberStats from './components/MemberStats';

const Dashboard = () => {
  return (
    <Box>
      <WebSiteStats />
      <MemberStats />
      <BIGCoefficientList />
      {/* <p>Yo, This is Rebas speaking.</p>
      <p>（之後會有一些數據統計在這邊，或是在這裡許願辦公室要有甚麼設備）</p> */}
    </Box>
  );
};

export default Dashboard;
