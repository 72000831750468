import React from 'react';
import { SimpleGrid, Button, Text, Select, HStack } from '@chakra-ui/react';
import { FormControl, FormLabel, IconButton } from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { filter } from 'ramda';

const NODE_TYPE = {
  PROMOTION: '勝者晉級',
  REMATCH: '排定重賽',
  REMAIN: '保留比賽',
};

const NODE_SIDE = {
  AWAY: '客場',
  HOME: '主場',
};

const GameNode = ({ gameUniqid, nodes, games, handleNodeChange, deleteNode, addNode }) => {
  return (
    <FormControl>
      <FormLabel>賽事連動</FormLabel>
      {nodes.base.map((node, index) => (
        <HStack spacing={2} key={`node-base-${index}`} my={2}>
          <SimpleGrid columns={4} gap={2}>
            <Text textAlign="center">本場比賽</Text>
            <Select name="type" onChange={handleNodeChange('base', index)} value={node.type} size="sm">
              <option value="">請選擇</option>
              {Object.keys(NODE_TYPE).map((key) => (
                <option key={key} value={key}>
                  {NODE_TYPE[key]}
                </option>
              ))}
            </Select>
            <Select
              name="game"
              onChange={handleNodeChange('base', index)}
              value={node?.link?.game_uniqid ? node.link.game_uniqid : ''}
              size="sm"
            >
              <option value="">請選擇</option>
              {filter((g) => g.uniqid !== gameUniqid, games).map((game) => (
                <option key={game.uniqid} value={game.uniqid}>
                  {`G${game.seq} ${game.info.time.scheduled_start_at}`}
                </option>
              ))}
            </Select>
            <Select name="side" onChange={handleNodeChange('base', index)} value={node.side} size="sm">
              <option value="">無主客場區分</option>
              {Object.keys(NODE_SIDE).map((key) => (
                <option key={key} value={key}>
                  {NODE_SIDE[key]}
                </option>
              ))}
            </Select>
          </SimpleGrid>
          <IconButton icon={<DeleteIcon />} onClick={deleteNode('base', index)} size="sm" />
        </HStack>
      ))}
      {nodes.link.map((node, index) => (
        <HStack spacing={2} key={`node-link-${index}`} my={2}>
          <SimpleGrid columns={4} gap={2}>
            <Select
              name="game"
              onChange={handleNodeChange('link', index)}
              value={node?.base?.game_uniqid ? node.base.game_uniqid : ''}
              size="sm"
            >
              <option value="">請選擇</option>
              {filter((g) => g.uniqid !== gameUniqid, games).map((game) => (
                <option key={game.uniqid} value={game.uniqid}>
                  {`G${game.seq} ${game.info.time.scheduled_start_at}`}
                </option>
              ))}
            </Select>
            <Select name="type" onChange={handleNodeChange('link', index)} value={node.type} size="sm">
              <option value="">請選擇</option>
              {Object.keys(NODE_TYPE).map((key) => (
                <option key={key} value={key}>
                  {NODE_TYPE[key]}
                </option>
              ))}
            </Select>
            <Text textAlign="center">本場比賽</Text>
            <Select name="side" onChange={handleNodeChange('link', index)} value={node.side} size="sm">
              <option value="">無主客場區分</option>
              {Object.keys(NODE_SIDE).map((key) => (
                <option key={key} value={key}>
                  {NODE_SIDE[key]}
                </option>
              ))}
            </Select>
          </SimpleGrid>
          <IconButton icon={<DeleteIcon />} onClick={deleteNode('link', index)} size="sm" />
        </HStack>
      ))}
      <Button leftIcon={<AddIcon />} onClick={addNode('base')} m={2} size="sm">
        本場比賽在左
      </Button>
      <Button leftIcon={<AddIcon />} onClick={addNode('link')} m={2} size="sm">
        本場比賽在右
      </Button>
    </FormControl>
  );
};

export default GameNode;
