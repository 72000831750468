import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation, useHistory } from 'react-router-dom';
import { find } from 'ramda';
import { SeasonResource } from '~~apis/resource';
import { FeatureTitle } from '~~components';
import ListSeason from './components/ListSeason';
import Season from './components/Season';

const TeamPlayerRegistry = () => {
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState({});
  const [action, setAction] = useState('LOADING');

  const history = useHistory();
  const location = useLocation();
  const parmas = new URLSearchParams(location.search);
  const urlSeasonUniqid = parmas.get('season');

  useEffect(() => {
    SeasonResource.getSeasons().then((data) => {
      setSeasons(data.data);
      if (!urlSeasonUniqid) {
        setAction('LIST_SEASONS');
        return;
      }

      const selectedSeason = find((s) => s.uniqid === urlSeasonUniqid)(data.data);
      setSeason(selectedSeason);
      setAction('EDIT_SEASON');
    });
  }, [urlSeasonUniqid]);

  const editSeason = (uniqid) => () => {
    const selectedSeason = find((s) => s.uniqid === uniqid)(seasons);
    setSeason(selectedSeason);
    setAction('EDIT_SEASON');
    history.push(`${location.pathname}?season=${uniqid}`);
  };

  const back = () => {
    setSeason({});
    setAction('LIST_SEASONS');
  };

  return (
    <Box w="100%">
      <FeatureTitle title="賽季登錄" />
      {action === 'LIST_SEASONS' && <ListSeason seasons={seasons} startEdit={editSeason} />}
      {action === 'EDIT_SEASON' && <Season season={season} back={back} />}
    </Box>
  );
};

export default TeamPlayerRegistry;
