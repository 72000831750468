import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, Grid, Table, Thead, Tbody, Tr, Th, Td, Button, Text, Select } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { Fragment } from 'react';
import { format as formatDate } from 'date-fns';
import { FirstBaseResource } from '~~apis/resource';

const PAResult = {
  '1B': '一安',
  '2B': '二安',
  '3B': '三安',
  HR: '全壘打',
  FC: '野選',
  GO: '滾地出局',
  FO: '飛球出局',
  GIDP: '滾地雙殺',
  SF: '犧牲飛球',
  uBB: '非故意四壞',
  HBP: '觸身球',
  SO: '三振',
};
const Tactic = {
  tactic_short: { name: '觸擊', perfect: '內安', success: '成功' },
  tactic_hit_run: { name: '打帶跑', perfect: '安打', success: '推進' },
  tactic_steal: { name: '盜壘', perfect: '無', success: '成功' },
};

const bases = {
  B0: '無人在壘',
  B4: '三壘有人',
  B2: '二壘有人',
  B6: '二三壘有人',
  B1: '一壘有人',
  B5: '一三壘有人',
  B3: '一二壘有人',
  B7: '滿壘',
};

const BIGCoefficientList = () => {
  const toast = useToast();
  const [BIGCasesCoefficientHistory, setBIGCoefficientHistory] = useState(null);
  const [BIGCasesReference, setBIGReference] = useState(null);
  const [BIGCasesCoefficient, setBIGCoefficient] = useState(null);
  const [coefficientDisplay, setCoefficientDisplay] = useState(0);
  const { isOpen: isBIGOpen, onOpen: onBIGOpen, onClose: onBIGClose } = useDisclosure();

  useEffect(() => {
    FirstBaseResource.getBIGCoefficientHistory().then(({ data }) => {
      setBIGCoefficientHistory(data);
    });
  }, []);

  useEffect(() => {
    if (!BIGCasesCoefficientHistory) {
      return;
    }

    if (!BIGCasesCoefficientHistory.history[coefficientDisplay]) {
      setBIGReference(BIGCasesCoefficientHistory.init.reference);
      setBIGCoefficient(BIGCasesCoefficientHistory.init.coefficient);
      return;
    }

    setBIGReference(BIGCasesCoefficientHistory.history[coefficientDisplay].reference);
    setBIGCoefficient(BIGCasesCoefficientHistory.history[coefficientDisplay].coefficient);
  }, [BIGCasesCoefficientHistory, coefficientDisplay]);

  const handleCoefficientDisplay = (e) => {
    setCoefficientDisplay(e.target.value);
  };

  const handleUpdateBIG = () => {
    FirstBaseResource.updateBIGCoefficient().then(({ data }) => {
      console.log(data);
      toast({
        title: '更新成功！',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  if (!BIGCasesCoefficientHistory || !BIGCasesReference || !BIGCasesCoefficient) {
    return <></>;
  }

  return (
    <Box padding="1rem 0">
      <Flex alignItems="center" padding="0 0 0.5rem 0">
        <Heading size="md" padding="0 2rem 0 0">
          演算法、模型開發
        </Heading>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap="0.5rem">
        <Box textAlign="center" border="1px solid gray" padding="1rem">
          <Button onClick={onBIGOpen}>B.I.G 係數</Button>
        </Box>
        <Box textAlign="center" border="1px solid gray" padding="1rem">
          <Text fontSize="lg">請在離峰時段更新</Text>
          <Button onClick={handleUpdateBIG}>更新 B.I.G 係數</Button>
        </Box>
      </Grid>
      <Modal onClose={onBIGClose} size="full" isOpen={isBIGOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>B.I.G 係數</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select value={coefficientDisplay} onChange={handleCoefficientDisplay}>
              {BIGCasesCoefficientHistory.history.map((row, rowIndex) => (
                <option key={row.time} value={rowIndex}>
                  {rowIndex === 0
                    ? `目前線上（${formatDate(new Date(row.time), 'yyyy/MM/dd HH:mm')}）`
                    : formatDate(new Date(row.time), 'yyyy/MM/dd HH:mm')}
                </option>
              ))}
              <option value="init">原始參考</option>
            </Select>
            <Grid templateColumns="repeat(4, 1fr)">
              <Box width="100%" overflowX="auto" borderBottom="1px solid gray" padding="1rem">
                <Table size="sm" variant="striped" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th fontSize="lg">RE24</Th>
                      <Th>無人出局</Th>
                      <Th>一出局</Th>
                      <Th>兩出局</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.keys(bases).map((base) => (
                      <Tr key={base}>
                        <Td>{bases[base]}</Td>
                        <Td isNumeric>{BIGCasesReference[base][0].toFixed(3)}</Td>
                        <Td isNumeric>{BIGCasesReference[base][1].toFixed(3)}</Td>
                        <Td isNumeric>{BIGCasesReference[base][2].toFixed(3)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <div></div>
              <div></div>
              <div></div>
              {Object.keys(PAResult).map((key) => (
                <Box key={key} width="100%" overflowX="auto" borderBottom="1px solid gray" padding="1rem">
                  <Table size="sm" variant="striped" colorScheme="gray">
                    <Thead>
                      <Tr>
                        <Th fontSize="lg">{PAResult[key]}</Th>
                        <Th>無人出局</Th>
                        <Th>一出局</Th>
                        <Th>兩出局</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {Object.keys(bases).map((base) => (
                        <Tr key={`${key}-${base}`}>
                          <Td>{bases[base]}</Td>
                          <Td isNumeric>{BIGCasesCoefficient[key][base][0].toFixed(3)}</Td>
                          <Td isNumeric>{BIGCasesCoefficient[key][base][1].toFixed(3)}</Td>
                          <Td isNumeric>{BIGCasesCoefficient[key][base][2].toFixed(3)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              ))}
              {Object.keys(Tactic).map((key) => (
                <Fragment key={key}>
                  {key !== 'tactic_steal' && (
                    <Box width="100%" overflowX="auto" borderBottom="1px solid gray" padding="1rem">
                      <Table size="sm" variant="striped" colorScheme="gray">
                        <Thead>
                          <Tr>
                            <Th fontSize="lg">{`${Tactic[key].name}-${Tactic[key].perfect}`}</Th>
                            <Th>無人出局</Th>
                            <Th>一出局</Th>
                            <Th>兩出局</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {Object.keys(bases).map((base) => (
                            <Tr key={`${key}-${base}`}>
                              <Td>{bases[base]}</Td>
                              <Td isNumeric>{BIGCasesCoefficient[key].perfect[base][0].toFixed(3)}</Td>
                              <Td isNumeric>{BIGCasesCoefficient[key].perfect[base][1].toFixed(3)}</Td>
                              <Td isNumeric>{BIGCasesCoefficient[key].perfect[base][2].toFixed(3)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  )}
                  <Box width="100%" overflowX="auto" borderBottom="1px solid gray" padding="1rem">
                    <Table size="sm" variant="striped" colorScheme="gray">
                      <Thead>
                        <Tr>
                          <Th fontSize="lg">{`${Tactic[key].name}-${Tactic[key].success}`}</Th>
                          <Th>無人出局</Th>
                          <Th>一出局</Th>
                          <Th>兩出局</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Object.keys(bases).map((base) => (
                          <Tr key={`${key}-${base}`}>
                            <Td>{bases[base]}</Td>
                            <Td isNumeric>{BIGCasesCoefficient[key].success[base][0].toFixed(3)}</Td>
                            <Td isNumeric>{BIGCasesCoefficient[key].success[base][1].toFixed(3)}</Td>
                            <Td isNumeric>{BIGCasesCoefficient[key].success[base][2].toFixed(3)}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </Fragment>
              ))}
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onBIGClose}>關閉</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BIGCoefficientList;
