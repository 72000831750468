import React, { useState, useEffect } from 'react';
import { Box, Select, HStack, Spinner } from '@chakra-ui/react';
import { find, propEq, isNil, sortWith, ascend, prop, filter } from 'ramda';
import { GameDetailResource, TeamResource, SeasonResource } from '~~apis/resource';
import { GameSimpleCUModal, FeatureTitle } from '~~components';
import Group from './components/Group';
import GroupCU from './components/GroupCU';

const SeasonSchedule = () => {
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState(null);
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSeasons([]);
    SeasonResource.getSeasons().then((data) => {
      setSeasons(data.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setTeams([]);
    setGames([]);
    setGroups([]);
    if (isNil(season?.uniqid)) {
      return;
    }

    setIsLoading(true);
    let countdown = 3;
    const checkFinishLoading = () => {
      countdown--;
      if (countdown <= 0) {
        setIsLoading(false);
      }
    };
    SeasonResource.getSeasonGroups({ seasonUniqid: season.uniqid }).then((data) => {
      const responseGroups = data.data;
      setGroups([{ name: 'NONE', priority: 0 }].concat(responseGroups));
      checkFinishLoading();
    });
    GameDetailResource.getGames({ seasonUniqid: season.uniqid }).then((data) => {
      const seqSort = sortWith([ascend(prop('seq'))]);
      const unsortGames = data.data;
      setGames(seqSort(unsortGames));
      checkFinishLoading();
    });
    TeamResource.getSeasonTeams({ seasonUniqid: season.uniqid }).then((data) => {
      setTeams(data.data);
      checkFinishLoading();
    });
  }, [season]);

  const updateSeasonSelect = (e) => {
    const season = find(propEq('uniqid', e.target.value))(seasons);
    setSeason(season);
  };

  const reloadRequired = () => {
    setGames([]);
    setGroups([]);

    setIsLoading(true);
    let countdown = 2;
    const checkFinishLoading = () => {
      countdown--;
      if (countdown <= 0) {
        setIsLoading(false);
      }
    };
    SeasonResource.getSeasonGroups({ seasonUniqid: season.uniqid }).then((data) => {
      const responseGroups = data.data;
      setGroups([{ name: 'NONE', priority: 0 }].concat(responseGroups));
      checkFinishLoading();
    });
    GameDetailResource.getGames({ seasonUniqid: season.uniqid }).then((data) => {
      const seqSort = sortWith([ascend(prop('seq'))]);
      const unsortGames = data.data;
      setGames(seqSort(unsortGames));
      checkFinishLoading();
    });
  };

  const genGameCUButton = (game) => {
    return (
      <GameSimpleCUModal
        game={game}
        seasonUniqid={season.uniqid}
        games={games}
        groups={groups}
        teams={teams}
        reloadRequired={reloadRequired}
      />
    );
  };

  return (
    <Box>
      <FeatureTitle title="賽程管理" />
      <Box w="50%" my="5">
        <Select placeholder="聯賽選擇" onChange={updateSeasonSelect} size="sm">
          {seasons.map((season) => (
            <option key={season.uniqid} value={season.uniqid}>
              {season.title}
            </option>
          ))}
        </Select>
      </Box>
      {isNil(season?.uniqid) ? (
        <></>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <>
          <HStack spacing={2}>
            <GroupCU seasonUniqid={season.uniqid} groups={groups} reloadRequired={reloadRequired} />
            {genGameCUButton(null)}
          </HStack>
          <Box w="70vw" h="80vh" overflowX="auto" overflowY="auto" whiteSpace="nowrap" pt={5}>
            {groups.map((group) => (
              <Group
                key={group.name}
                group={group}
                games={filter((g) => g.group === group.name, games)}
                gameCU={genGameCUButton}
                editButton={
                  <GroupCU group={group} seasonUniqid={season.uniqid} groups={groups} reloadRequired={reloadRequired} />
                }
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SeasonSchedule;
