import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';

const debugStatus = {
  uploadMedia: false,
};

const Media = {
  uploadMedia: async ({ file }) => {
    if (debugStatus.uploadMedia) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: { path: 'yyy', url: 'https://www.rebas.tw' },
        error: false,
        message: 'upload media successfully',
      });
    }

    const data = new FormData();
    data.append('media', file);

    return ApiService.post(`/api/medias`, {
      data,
    });
  },
};

export default Media;
