import React from 'react';
import { Box, Button, Text, useToast, Flex } from '@chakra-ui/react';
import XLSX from 'xlsx';
import { find } from 'ramda';
import { useGameDetailState } from '../hooks';
import { calcTeamHFromBatters, calcTeamEFromFielders } from '../Consts';
import {
  batterHeadings,
  batterColumnMapping,
  batterHeadingWidths,
  pitcherHeadings,
  pitcherHeadingWidths,
  pitcherColumnMapping,
  fieldPositionMapping,
} from './BoxScoreUploadConst';

const maxParsingColumns = batterHeadings.length > 50 ? batterHeadings.length : 50;
const excelColumnNames = [];
for (let j = 'A'.charCodeAt(0); j <= 'Z'.charCodeAt(0); j++) {
  excelColumnNames.push(String.fromCharCode(j));
}

for (let j = 'A'.charCodeAt(0); j <= 'Z'.charCodeAt(0); j++) {
  for (let k = 'A'.charCodeAt(0); k <= 'Z'.charCodeAt(0); k++) {
    excelColumnNames.push(String.fromCharCode(j) + String.fromCharCode(k));
    if (excelColumnNames.length > maxParsingColumns) {
      break;
    }
  }

  if (excelColumnNames.length > maxParsingColumns) {
    break;
  }
}

const getAVGFormula = (row) => {
  return { f: `IF(0 = G${row}, "-", J${row} / G${row})` };
};

const getOBPFormula = (row) => {
  return { f: `IF(0 = F${row}, "-", SUM(J${row}, Q${row}, V${row}) / F${row})` };
};

const getTCFormula = (row) => {
  return { f: `SUM(AE${row}, AF${row}, AG${row})` };
};

const getDAFormula = (row) => {
  return { f: `IF(0 = AD${row}, "-", (AE${row} + AF${row}) / AD${row})` };
};

const getERAFormula = (row) => {
  return { f: `IF(0 = D${row}, "-", (O${row} / (D${row} / 3)) * 9)` };
};

const getWHIPFormula = (row) => {
  return { f: `IF(0 = D${row}, "-", (G${row} + I${row} + J${row}) / (D${row} / 3))` };
};

const genDemoBatter = (withDemo) => {
  // prettier-ignore
  const rowTwoLine = ['999', '範例打者', '1', '3', '', 4, 3, 1, 2, 2, 1, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, getAVGFormula(2), getOBPFormula(2), '', getTCFormula(2), 2, 1, 0, getDAFormula(2), 0, 0, 0];
  // prettier-ignore
  const rowThreeLine = ['', '', '', '2', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, getAVGFormula(3), getOBPFormula(3), '', getTCFormula(3), 5, 1, 1, getDAFormula(3), 0, 1, 1];

  if (!withDemo) {
    rowTwoLine[0] = '';
    rowTwoLine[1] = '';
    rowTwoLine[2] = '';
    rowTwoLine[3] = '';
    return [
      rowTwoLine.map((v) => {
        if (Number.isInteger(v)) {
          return 0;
        }

        return v;
      }),
    ];
  }
  return [rowTwoLine, rowThreeLine];
};

const genDemoPitcher = (withDemo) => {
  // prettier-ignore
  const rowTwoLine = ['999', '範例投手', '', 14, 84, 16, 2, 1, 2, 3, 2, 0, 0, 4, 6, 0, getERAFormula(2), getWHIPFormula(2), '', 1, 0, 0, 0, 0];

  if (!withDemo) {
    rowTwoLine[0] = '';
    rowTwoLine[1] = '';
    return [
      rowTwoLine.map((v) => {
        if (Number.isInteger(v)) {
          return 0;
        }

        return v;
      }),
    ];
  }
  return [rowTwoLine];
};

const awayBatterSheetName = 'AWAY 客場 - 打者';
const awayPitcherSheetName = 'AWAY 客場 - 投手';
const homeBatterSheetName = 'HOME 主場 - 打者';
const homePitcherSheetName = 'HOME 主場 - 投手';

const BoxScoreUpload = ({ sampleDownload, awayForm, homeForm }) => {
  const toast = useToast();
  const { awayTeamPlayers, homeTeamPlayers } = useGameDetailState();

  const downloadSampleFile = (withDemo) => () => {
    const away = awayForm.getValues();
    const home = homeForm.getValues();

    const title = `${away.team_name} vs ${home.team_name} Box Score 範本`;
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: title,
      Author: 'Rebas 野革',
      CreatedDate: new Date(),
    };

    const guide = [
      '1. 背號與球員需要相符並已儲存在系統中方能讀取',
      '2. 守位請填寫數字 1 - 9，順序為 投 捕 一 二 三 遊 左 中 右。如果是指定打擊、代跑或代打則填寫 DH、PR、PH',
      '3. 守位更換請依序新增新的一列，只需填寫守備相關資訊，請參考範例（打擊相關系統不會採用）',
      '4. 投手的守備紀錄請一樣填在打者的表單中，棒次留空即可',
      '5. 打擊率、上壘率、守備機會、守備率、防禦率、被上壘率 皆已設定好公式。僅方便用戶留存觀看，上傳後系統不會採用。',
      '6. 如不儲存 打擊、守備、投球 任一數據，請直接忽略相關欄位。上傳系統時將提供忽略的選項',
      '7. 表單名稱請勿更換，順序可隨意更換；欄位名稱請勿更換，除了背號跟球員以外，順序可隨意更換',
      '8. 目前勝敗投等尚未能同步，上傳後請手動輸入相關紀錄',
      '',
      '紀錄常見問題',
      '1. 安打(H) = 1B + 2B + 3B + HR，因此球員打了二壘安打時，安打(H) 跟 二壘安打(2B) 都要 +1',
      '2. 四壞球(BB) = 一般四壞球 + 故意四壞球，因此球員被故意四壞時，四壞球(BB) 跟 故意四壞球(IBB) 都要 +1',
      '3. 觸身球(HBP) "不"包含在四壞球內',
      '4. 刺殺總數應與對手出局數相同',
    ];
    const indexData = [
      [
        `客場（${away.team_name}）球員背號`,
        `客場（${away.team_name}）球員名稱`,
        '',
        `主場（${home.team_name}）球員背號`,
        `主場（${home.team_name}）球員名稱`,
        '',
        '使用說明',
      ],
    ];
    for (let i = 0; i < awayTeamPlayers.length || i < homeTeamPlayers.length || i < guide.length; i++) {
      const rowData = ['', '', '', '', '', '', ''];
      if (awayTeamPlayers[i]) {
        rowData[0] = awayTeamPlayers[i].number;
        rowData[1] = awayTeamPlayers[i].name;
      }

      if (homeTeamPlayers[i]) {
        rowData[3] = homeTeamPlayers[i].number;
        rowData[4] = homeTeamPlayers[i].name;
      }

      if (guide[i]) {
        rowData[6] = guide[i];
      }
      indexData.push(rowData);
    }
    const indexWs = XLSX.utils.aoa_to_sheet(indexData);
    indexWs['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 7, r: indexData.length + 2 },
    });
    indexWs['!cols'] = [{ wch: 5 }, { wch: 8 }, { wch: 3 }, { wch: 5 }, { wch: 8 }, { wch: 3 }, { wch: 100 }];

    const batter = [batterHeadings, ...genDemoBatter(withDemo)];
    const batterWs = XLSX.utils.aoa_to_sheet(batter);
    batterWs['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: batterHeadings.length, r: batter.length + 2 },
    });
    batterWs['!cols'] = batterHeadingWidths;

    const pitcher = [pitcherHeadings, ...genDemoPitcher(withDemo)];
    const pitcherWs = XLSX.utils.aoa_to_sheet(pitcher);
    pitcherWs['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: pitcherHeadings.length, r: pitcher.length + 2 },
    });
    pitcherWs['!cols'] = pitcherHeadingWidths;

    wb.SheetNames.push('球員名單 x 使用說明');
    wb.Sheets['球員名單 x 使用說明'] = indexWs;

    wb.SheetNames.push(awayBatterSheetName);
    wb.Sheets[awayBatterSheetName] = batterWs;

    wb.SheetNames.push(awayPitcherSheetName);
    wb.Sheets[awayPitcherSheetName] = pitcherWs;

    wb.SheetNames.push(homeBatterSheetName);
    wb.Sheets[homeBatterSheetName] = batterWs;

    wb.SheetNames.push(homePitcherSheetName);
    wb.Sheets[homePitcherSheetName] = pitcherWs;

    XLSX.writeFile(wb, `${title}.xlsx`);
  };

  const getPlayer = (side, name, number) => {
    const players = {
      away: awayTeamPlayers,
      home: homeTeamPlayers,
    };

    return find((p) => p.name === name && p.number === number, players[side]);
  };

  const handleFileBatters = (side, sheet) => {
    const batters = [];
    const fielders = [];

    const battingColumnMapping = {};
    const fieldingColumnMapping = {};
    excelColumnNames.forEach((columnName) => {
      const cell = `${columnName}1`;
      if (!sheet[cell]) {
        return;
      }
      const keyName = sheet[cell].v.toString();

      if (batterColumnMapping.batting[keyName]) {
        battingColumnMapping[columnName] = batterColumnMapping.batting[keyName];
      } else if (batterColumnMapping.fielding[keyName]) {
        fieldingColumnMapping[columnName] = batterColumnMapping.fielding[keyName];
      }
    });

    let empty = true;
    let rowNumber = 1;
    do {
      empty = true;
      rowNumber++;
      const number = sheet[`A${rowNumber}`]?.v.toString();
      const name = sheet[`B${rowNumber}`]?.v.toString();
      const player = getPlayer(side, name, number);

      let lastFielder = batters[batters.length - 1];
      if (player) {
        empty = false;
        const batterData = {};
        Object.keys(battingColumnMapping).forEach((columnName) => {
          const cell = `${columnName}${rowNumber}`;
          if (!sheet[cell]) {
            return;
          }
          batterData[battingColumnMapping[columnName]] = sheet[cell].v.toString();
        });
        Object.values(battingColumnMapping).forEach((key) => {
          if (batterData[key]) {
            return;
          }

          batterData[key] = '0';
        });
        if (parseInt(batterData.order) > 0) {
          const orderPlayerExist = find((b) => b.order === batterData.order, batters);
          batters.push({ ...batterData, player: { number }, is_PH: !!orderPlayerExist });
        }
        lastFielder = { player: { number } };
      } else if (number || name) {
        toast({
          title: `找不到 背號${number} 名稱${name} 的球員`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (lastFielder) {
        const fielderData = {};
        Object.keys(fieldingColumnMapping).forEach((columnName) => {
          const cell = `${columnName}${rowNumber}`;
          if (!sheet[cell]) {
            return;
          }
          const value = sheet[cell].v.toString();
          fielderData[fieldingColumnMapping[columnName]] = value;
        });
        Object.values(fieldingColumnMapping).forEach((key) => {
          if (fielderData[key]) {
            return;
          }

          fielderData[key] = '0';
        });

        fielderData.position = fieldPositionMapping[fielderData.position]
          ? fieldPositionMapping[fielderData.position]
          : '';
        fielderData.TC = parseInt(fielderData.PO) + parseInt(fielderData.A) + parseInt(fielderData.E);
        fielderData.order = '1';
        if (fielderData.position) {
          const fielderExist = find((f) => f.info.number === lastFielder.player.number, fielders);
          if (fielderExist) {
            fielderData.order = (fielderExist.fieldings.length + 1).toString();
            fielderExist.fieldings.push(fielderData);
          } else {
            fielders.push({ info: { number }, fieldings: [fielderData] });
          }
          empty = false;
        }
      }
    } while (!empty && rowNumber < 50);

    const forms = {
      away: awayForm,
      home: homeForm,
    };

    console.log(side, 'batter');
    console.log(batters);
    console.log(fielders);
    forms[side].setValue('hits', calcTeamHFromBatters(batters));
    forms[side].setValue('errors', calcTeamEFromFielders(fielders));
    forms[side].setValue('batters', batters);
    forms[side].setValue('players', fielders);
  };

  const handleFilePitchers = (side, sheet) => {
    const forms = {
      away: awayForm,
      home: homeForm,
    };
    const pitchers = [];
    const records = forms[side].getValues().records ? forms[side].getValues().records : [];

    const pitchingColumnMapping = {};
    const recordColumnMapping = {};
    excelColumnNames.forEach((columnName) => {
      const cell = `${columnName}1`;
      if (!sheet[cell]) {
        return;
      }
      const keyName = sheet[cell].v.toString();

      if (pitcherColumnMapping.pitching[keyName]) {
        pitchingColumnMapping[columnName] = pitcherColumnMapping.pitching[keyName];
      } else if (pitcherColumnMapping.record[keyName]) {
        recordColumnMapping[columnName] = pitcherColumnMapping.record[keyName];
      }
    });

    let rowNumber = 1;
    do {
      rowNumber++;
      const number = sheet[`A${rowNumber}`]?.v.toString();
      const name = sheet[`B${rowNumber}`]?.v.toString();
      const player = getPlayer(side, name, number);

      if (!player) {
        if (number || name) {
          toast({
            title: `找不到 背號${number} 名稱${name} 的球員`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
        break;
      }

      const pitcherData = {};
      Object.keys(pitchingColumnMapping).forEach((columnName) => {
        const cell = `${columnName}${rowNumber}`;
        if (!sheet[cell]) {
          return;
        }
        pitcherData[pitchingColumnMapping[columnName]] = sheet[cell].v.toString();
      });
      Object.values(pitchingColumnMapping).forEach((key) => {
        if (pitcherData[key]) {
          return;
        }

        pitcherData[key] = '0';
      });

      pitcherData.order = (pitchers.length + 1).toString();
      pitchers.push({ ...pitcherData, player: { number } });

      const pitcherRecords = {};
      Object.keys(recordColumnMapping).forEach((columnName) => {
        const cell = `${columnName}${rowNumber}`;
        if (!sheet[cell]) {
          return;
        }
        pitcherRecords[recordColumnMapping[columnName]] = sheet[cell].v.toString();
      });
      Object.keys(pitcherRecords).forEach((key) => {
        if (pitcherRecords[key] !== '1') {
          return;
        }

        records.push({ player: { number }, player_type: 'PITCHER', value: key, comment: '' });
      });
    } while (rowNumber < 50);

    console.log(side, 'pitcher');
    console.log(pitchers);
    console.log(records);
    forms[side].setValue('pitchers', pitchers);
    // 還沒處理觸發顯示選項
    // forms[side].setValue('records', records);
  };

  const handleFileOnChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length !== 1) {
      return;
    }

    toast({
      title: '讀取中，請稍後',
      status: 'info',
      duration: 1000,
      isClosable: true,
    });

    const file = files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const awayBatters = workbook.Sheets[awayBatterSheetName];
      if (awayBatters) {
        handleFileBatters('away', awayBatters);
      }

      const homeBatters = workbook.Sheets[homeBatterSheetName];
      if (homeBatters) {
        handleFileBatters('home', homeBatters);
      }

      const awayPitchers = workbook.Sheets[awayPitcherSheetName];
      if (awayPitchers) {
        handleFilePitchers('away', awayPitchers);
      }

      const homePitchers = workbook.Sheets[homePitcherSheetName];
      if (homePitchers) {
        handleFilePitchers('home', homePitchers);
      }

      toast({
        title: '上傳檔案分析成功！請點擊 Box Score 檢查',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <Box>
      {sampleDownload === true ? (
        <Box fontSize="xs">
          <Text>Box Score 範例檔案（包含固定格式與球隊球員）</Text>
          <Flex justifyContent="space-around">
            {' '}
            <Button size="xs" colorScheme="green" onClick={downloadSampleFile(true)}>
              下載（含示意資料）
            </Button>
            <Button size="xs" colorScheme="blue" onClick={downloadSampleFile(false)}>
              下載
            </Button>
          </Flex>
        </Box>
      ) : (
        <Box fontSize="xs">
          <Text>Box Score 上傳（將覆蓋現有紀錄）</Text>
          <Flex justifyContent="center">
            <input type="file" accept=".xlsx,.xls,.csv" multiple={false} onChange={handleFileOnChange} />
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default BoxScoreUpload;
