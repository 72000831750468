import CommonUtils from '~~utils/CommonUtils';
import ApiService from '../ApiService';
import { formatGameNodes } from './GameDetail';

const debugStatus = {
  getSeasons: false,
  getSeasonGroups: false,
  createSeason: false,
  updateSeason: false,
  createSeasonGroup: false,
  updateSeasonGroup: false,
  updateGameSchedule: false,
  updateSeasonPlugin: false,
};

const Season = {
  getSeasons: () => {
    if (debugStatus.getSeasons) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [
          {
            description: '',
            end_at: null,
            start_at: null,
            title: '2020 北區高中棒球聯會夏季聯賽',
            type: 'CUP',
            uniqid: 'nH8l-2020-Sc',
            year: 2020,
          },
        ],
        error: false,
        message: 'Get seasons successfully',
      });
    }
    return ApiService.get(`/api/seasons`);
  },

  getSeasonGroups: ({ seasonUniqid }) => {
    if (debugStatus.getSeasonGroups) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: [
          {
            name: '預賽',
            priority: 1,
            scheduled_end_at: '2020-10-10T00:00:00+08:00',
            scheduled_start_at: '2020-10-05T00:00:00+08:00',
          },
          {
            name: '複賽',
            priority: 2,
            scheduled_end_at: '2020-10-11T00:00:00+08:00',
            scheduled_start_at: '2020-10-10T00:00:00+08:00',
          },
        ],
        error: false,
        message: 'Get season groups successfully',
      });
    }

    return ApiService.get(`/api/seasons/${seasonUniqid}/groups`);
  },

  createSeason: ({ leagueUniqid, title, type, startAt }) => {
    if (debugStatus.createSeason) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          description: '',
          end_at: null,
          start_at: '2020-10-10T00:00:00+08:00',
          title: '2020 北區高中棒球聯會夏季聯賽',
          type: 'CUP',
          uniqid: 'nH8l-2020-Sc',
          year: 2020,
        },
        error: false,
        message: 'Create season successfully',
      });
    }

    return ApiService.post(`/api/leagues/${leagueUniqid}/seasons`, {
      data: { title, type, start_at: startAt },
    });
  },

  updateSeason: ({ leagueUniqid, seasonUniqid, data }) => {
    if (debugStatus.createSeason) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          description: '',
          end_at: '2020-10-10T00:00:00+08:00',
          start_at: '2020-10-10T00:00:00+08:00',
          title: '2020 北區高中棒球聯會夏季聯賽',
          type: 'CUP',
          uniqid: 'nH8l-2020-Sc',
          year: 2020,
        },
        error: false,
        message: 'Update season successfully',
      });
    }

    return ApiService.put(`/api/leagues/${leagueUniqid}/seasons/${seasonUniqid}`, { data });
  },

  createSeasonGroup: ({
    seasonUniqid,
    name,
    afterGroup,
    standingsDisplay,
    isSingleElimination,
    scheduledStartAt,
    scheduledEndAt,
  }) => {
    if (debugStatus.createSeasonGroup) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          name: '預賽',
          priority: 1,
          scheduled_end_at: '2020-10-10T00:00:00+08:00',
          scheduled_start_at: '2020-10-05T00:00:00+08:00',
        },
        error: false,
        message: 'Get season groups successfully',
      });
    }

    return ApiService.post(`/api/seasons/${seasonUniqid}/groups`, {
      data: {
        name,
        after: afterGroup,
        standings_display: standingsDisplay,
        is_single_elimination: isSingleElimination,
        scheduled_start_at: CommonUtils.stringtifyDate(scheduledStartAt),
        scheduled_end_at: CommonUtils.stringtifyDate(scheduledEndAt),
      },
    });
  },

  updateSeasonGroup: ({
    seasonUniqid,
    originName,
    name,
    afterGroup,
    standingsDisplay,
    isSingleElimination,
    scheduledStartAt,
    scheduledEndAt,
  }) => {
    if (debugStatus.updateSeasonGroup) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {
          name: '預賽',
          priority: 1,
          scheduled_end_at: '2020-10-10T00:00:00+08:00',
          scheduled_start_at: '2020-10-05T00:00:00+08:00',
        },
        error: false,
        message: 'Update season groups successfully',
      });
    }

    if (afterGroup === 'NONE') {
      afterGroup = '';
    }
    return ApiService.put(`/api/seasons/${seasonUniqid}/groups`, {
      data: {
        origin_name: originName,
        name,
        after: afterGroup,
        standings_display: standingsDisplay,
        is_single_elimination: isSingleElimination,
        scheduled_start_at: CommonUtils.stringtifyDate(scheduledStartAt),
        scheduled_end_at: CommonUtils.stringtifyDate(scheduledEndAt),
      },
    });
  },

  deleteSeasonGroup: ({ seasonUniqid, name }) => {
    if (debugStatus.deleteSeasonGroup) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: 'Delete group successfully',
      });
    }

    return ApiService.delete(`/api/seasons/${seasonUniqid}/groups/${encodeURIComponent(name)}`);
  },

  updateGameSchedule: ({ seasonUniqid, gameUniqid, away, home, group, nodes, scheduledStartAt, seq }) => {
    if (debugStatus.updateGameSchedule) {
      return CommonUtils.fakeApiHelper(200, 'success', {
        data: {},
        error: false,
        message: '更新成功',
      });
    }

    seq = parseInt(seq);
    if (Number.isNaN(seq)) {
      seq = 0;
    }
    nodes = formatGameNodes(nodes);
    return ApiService.put(`/api/seasons/${seasonUniqid}/games/${gameUniqid}/schedule`, {
      data: {
        away,
        home,
        group,
        nodes,
        scheduled_start_at: CommonUtils.stringtifyDate(scheduledStartAt),
        seq,
      },
    });
  },

  updateSeasonPlugin: ({ seasonUniqid, pluginName, toOpen }) => {
    return ApiService.put(`/v2/api/seasons/${seasonUniqid}/plugins/${pluginName}`, {
      data: { open: toOpen },
    });
  },
};

export default Season;
