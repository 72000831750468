import React, { useState, useEffect } from 'react';
import { Button, VStack, Alert, AlertIcon, Spinner, HStack, Select, Text, IconButton, Switch } from '@chakra-ui/react';
import { InputGroup, Input, InputLeftAddon } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { filter, find, propEq } from 'ramda';
import CommonUtils from '~~utils/CommonUtils';
import { DatePicker, GroupDelete } from '~~components';
import { SeasonResource } from '~~apis/resource';

const GroupCU = ({ group, seasonUniqid, groups, reloadRequired }) => {
  const isNew = !group;
  if (isNew) {
    group = {
      name: '',
      priority: 0,
      standings_display: false,
      is_single_elimination: false,
      scheduled_start_at: '',
      scheduled_end_at: '',
    };
  }
  const preGroup = find(propEq('priority', group.priority - 1))(groups);
  const originName = group.name;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState(group.name);
  const [afterGroup, setAfterGroup] = useState(preGroup ? preGroup.name : '');
  const [standingsDisplay, setStandingsDisplay] = useState(group.standings_display);
  const [isSingleElimination, setIsSingleElimination] = useState(group.is_single_elimination);
  const [scheduledStartAt, setScheduledStartAt] = useState(CommonUtils.objectifyDate(group.scheduled_start_at));
  const [scheduledEndAt, setScheduledEndAt] = useState(CommonUtils.objectifyDate(group.scheduled_end_at));
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitAble, setSubmitable] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    setErrorMessage('');
  }, [isOpen]);

  useEffect(() => {
    if (find(propEq('name', name))(groups) && name !== originName) {
      setErrorMessage('分類名稱重複！');
      return setSubmitable(false);
    }

    setErrorMessage('');
    if (!name || !CommonUtils.stringtifyDate(scheduledStartAt) || !CommonUtils.stringtifyDate(scheduledEndAt)) {
      return setSubmitable(false);
    }

    setSubmitable(true);
  }, [name, scheduledStartAt, scheduledEndAt, groups, originName]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAfterGroupChange = (e) => {
    setAfterGroup(e.target.value);
  };

  const handleStandingsDisplayChange = () => {
    setStandingsDisplay(!standingsDisplay);
  };

  const handleIsSingleEliminatationChange = () => {
    setIsSingleElimination(!isSingleElimination);
  };

  const handleDatePickerChange = (currentInfo, settingFunction) => (e) => {
    const value = e.target.value;
    const type = e.target.name;
    settingFunction({ ...currentInfo, [type]: value });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const functionCalled = isNew ? 'createSeasonGroup' : 'updateSeasonGroup';
    SeasonResource[functionCalled]({
      seasonUniqid,
      originName,
      name,
      afterGroup,
      standingsDisplay,
      isSingleElimination,
      scheduledStartAt,
      scheduledEndAt,
    })
      .then(() => {
        reloadRequired();
        onClose();
      })
      .catch((e) => {
        setErrorMessage(e.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isNew ? (
        <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen} size="sm">
          增加分類
        </Button>
      ) : (
        <IconButton icon={<EditIcon />} colorScheme="orange" onClick={onOpen} size="sm" />
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>{isNew ? '增加分類' : `編輯分類 - ${originName}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={8} align="start">
                <InputGroup>
                  <InputLeftAddon children="名稱" />
                  <Input
                    type="text"
                    borderLeftRadius="0"
                    placeholder="如：預賽、複賽、循環賽等"
                    value={name}
                    onChange={handleNameChange}
                  />
                </InputGroup>
                <HStack spacing={4}>
                  <VStack spacing={8} align="center">
                    <Text height="2rem">時間順序</Text>
                    <Text height="2rem">預計開始時間</Text>
                    <Text height="2rem">預計結束時間</Text>
                    <Text height="2rem">屬單敗淘汰</Text>
                    <Text height="2rem">首頁顯示戰績排行</Text>
                  </VStack>
                  <VStack spacing={8} align="start">
                    <Select height="2rem" variant="filled" value={afterGroup} onChange={handleAfterGroupChange}>
                      <option value="">首位（聯/盃賽開始後最先舉辦）</option>
                      {filter((g) => g.name !== 'NONE' && g.name !== originName, groups).map((group) => (
                        <option key={group.name} value={group.name}>
                          {group.name}之後
                        </option>
                      ))}
                    </Select>
                    <DatePicker
                      handleDatePickerChange={handleDatePickerChange(scheduledStartAt, setScheduledStartAt)}
                      values={scheduledStartAt}
                      styleProps={{ spacing: 10, height: '2rem' }}
                    />
                    <DatePicker
                      handleDatePickerChange={handleDatePickerChange(scheduledEndAt, setScheduledEndAt)}
                      values={scheduledEndAt}
                      styleProps={{ spacing: 10, height: '2rem' }}
                    />
                    <Switch
                      height="2rem"
                      isChecked={isSingleElimination}
                      onChange={handleIsSingleEliminatationChange}
                    />
                    <Switch height="2rem" isChecked={standingsDisplay} onChange={handleStandingsDisplayChange} />
                  </VStack>
                </HStack>
                {errorMessage && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              {isLoading ? (
                <Spinner />
              ) : (
                <HStack w="100%" justify="space-between">
                  {isNew ? (
                    <div></div>
                  ) : (
                    <GroupDelete seasonUniqid={seasonUniqid} name={originName} reloadRequired={reloadRequired} />
                  )}
                  <HStack spacing={2}>
                    <Button mr={3} variant="ghost" onClick={onClose}>
                      取消
                    </Button>
                    <Button colorScheme="green" isDisabled={!submitAble} onClick={handleSubmit}>
                      送出
                    </Button>
                  </HStack>
                </HStack>
              )}
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
};

export default GroupCU;
