import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Text, Input, FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, Image, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { reverse, find, findIndex } from 'ramda';
import { Link } from 'react-router-dom';
import { FeatureTitle, ConfirmAlertDialog } from '~~components';
import { TeamResource } from '~~apis/resource';
import Edit from './components/Edit';

const Team = () => {
  const toast = useToast();
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState({});
  const [action, setAction] = useState('LIST_TEAM');

  const { register, watch } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const teamQuery = watch('query');

  useEffect(() => {
    if (!teamQuery || !teamQuery.trim()) {
      return;
    }

    setAction('LOADING');
    TeamResource.searchTeams({ query: teamQuery }).then((data) => {
      setTeams(data.data);
      setAction('LIST_TEAM');
    });
  }, [teamQuery]);

  const startEdit = (uniqid) => () => {
    const selectedTeam = find((t) => t.uniqid === uniqid)(teams);
    setTeam(selectedTeam);
    setAction('EDIT_TEAM');
  };

  const deleteTeam = (teamUniqid) => () => {
    toast({
      title: '刪除中，請稍後',
      status: 'info',
      duration: 1000,
      isClosable: true,
    });
    TeamResource.deleteTeam({ teamUniqid })
      .then(() => {
        toast({
          title: '刪除成功！',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });

        TeamResource.searchTeams({ query: teamQuery }).then((data) => {
          setTeams(data.data);
          setAction('LIST_TEAM');
        });
      })
      .catch((e) => {
        toast({
          title: `刪除失敗！${e.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const cancelEdit = () => {
    setTeam({});
    setAction('LIST_TEAM');
  };

  const completedEdit = (data) => {
    const selectedTeamIndex = findIndex((t) => t.uniqid === team.uniqid)(teams);
    teams[selectedTeamIndex] = data;
    setTeam({});
    setTeams(teams);
    setAction('LIST_TEAM');
  };

  return (
    <Box w="100%">
      <FeatureTitle title="球隊管理" />
      {action !== 'EDIT_TEAM' && (
        <>
          <FormControl my="10">
            <FormLabel>球隊搜尋</FormLabel>
            <Input name="query" ref={register({ required: true })} size="xs" />
          </FormControl>
          {action === 'LOADING' && <Spinner />}
          <Text>球隊列表</Text>
          <Table size="sm" variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>球隊名稱</Th>
                <Th>縣市</Th>
                <Th>球隊 Icon</Th>
                <Th>操作</Th>
                <Th>參與賽季</Th>
              </Tr>
            </Thead>
            <Tbody>
              {teams.map((team) => (
                <Tr key={team.uniqid}>
                  <Td whiteSpace="nowrap">{team.name}</Td>
                  <Td whiteSpace="nowrap">{team.city}</Td>
                  <Td>
                    {team.icon_url ? (
                      <Image boxSize="64px" objectFit="cover" src={team.icon_url} alt={team.name} />
                    ) : (
                      '無'
                    )}
                  </Td>
                  <Td>
                    <Flex>
                      <Button size="xs" colorScheme="orange" onClick={startEdit(team.uniqid)}>
                        編輯
                      </Button>
                      {team.experiences.length === 0 && (
                        <ConfirmAlertDialog
                          buttonStyles={{ size: 'xs', colorScheme: 'red' }}
                          buttonDisplay="刪除"
                          title="刪除球隊"
                          content={`確定要刪除 ${team.name} 嗎？`}
                          confirmColor="red"
                          handleConfirm={deleteTeam(team.uniqid)}
                        />
                      )}
                      <Link to={`/permission/team/${team.uniqid}`}>
                        <Button size="xs" colorScheme="purple">
                          權限管理
                        </Button>
                      </Link>
                    </Flex>
                  </Td>
                  <Td>
                    <SimpleGrid columns={2} spacing="20px">
                      {reverse(team.experiences).map((experience) => (
                        <Text
                          key={`${experience.season_uniqid}-${experience.team}`}
                          as="em"
                        >{`${experience.season_year} - ${experience.season} ${experience.team}`}</Text>
                      ))}
                    </SimpleGrid>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
      {action === 'EDIT_TEAM' && <Edit team={team} cancelEdit={cancelEdit} completedEdit={completedEdit} />}
    </Box>
  );
};

export default Team;
