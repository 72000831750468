import React from 'react';
import { Box, Button, Spinner, Text } from '@chakra-ui/react';
import { WarningTwoIcon, CheckCircleIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

const LoadingAlertDialog = ({ isOpen, status, title, message = '', handleConfirm }) => {
  const displayContent = () => {
    switch (status) {
      case 'ERROR':
        return (
          <Box alignItems="center">
            <WarningTwoIcon color="red.500" />
            <Text>{message}</Text>
          </Box>
        );
      case 'SUCCESS':
        return (
          <Box alignItems="center">
            <CheckCircleIcon color="green.500" />
            <Text>{message}</Text>
          </Box>
        );
      default:
        return <Spinner size="lg" color="blue.500" />;
    }
  };

  return (
    <>
      <AlertDialog isOpen={isOpen} closeOnOverlayClick={false}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{title}</AlertDialogHeader>
            <AlertDialogBody>{displayContent()}</AlertDialogBody>
            {status !== 'LOADING' && (
              <AlertDialogFooter alignItems="center">
                <Button onClick={handleConfirm} colorScheme={status === 'ERROR' ? 'red' : 'green'}>
                  確定
                </Button>
              </AlertDialogFooter>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default LoadingAlertDialog;
