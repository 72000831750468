import React, { createContext, useReducer, useContext } from 'react';
import Action from '../Actions';

const GameDetailStateContext = createContext();
const GameDetailDispatchContext = createContext();

function gameDetailReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case Action.UPDATE_GAME_NODES: {
      return {
        ...state,
        gameNodes: payload,
      };
    }
    case Action.UPDATE_GAMES: {
      return {
        ...state,
        games: payload,
      };
    }
    case Action.UPDATE_SEASON_GROUPS: {
      return {
        ...state,
        seasonGroups: payload,
      };
    }
    case Action.UPDATE_SEASON_TEAMS: {
      return {
        ...state,
        seasonTeams: payload,
      };
    }
    case Action.UPDATE_AWAY_TEAM_PLAYERS: {
      return {
        ...state,
        awayTeamPlayers: payload,
      };
    }
    case Action.UPDATE_HOME_TEAM_PLAYERS: {
      return {
        ...state,
        homeTeamPlayers: payload,
      };
    }
    default: {
      throw new Error('Invalid game detail action type');
    }
  }
}

function GameDetailProvider({ children }) {
  const [state, dispatch] = useReducer(gameDetailReducer, {
    gameNodes: [],
    games: [],
    seasonGroups: [],
    seasonTeams: [],
    awayTeamPlayers: [],
    homeTeamPlayers: [],
  });

  return (
    <GameDetailStateContext.Provider value={state}>
      <GameDetailDispatchContext.Provider value={dispatch}>{children}</GameDetailDispatchContext.Provider>
    </GameDetailStateContext.Provider>
  );
}

function useGameDetailState() {
  const context = useContext(GameDetailStateContext);

  if (context === undefined) {
    throw new Error('useGameDetailState must be used within a GameDetailProvider');
  }

  return context;
}

function useGameDetailDispatch() {
  const context = useContext(GameDetailDispatchContext);

  if (context === undefined) {
    throw new Error('useGameDetailDispatch must be used within a GameDetailProvider');
  }

  return context;
}

export { GameDetailProvider, useGameDetailState, useGameDetailDispatch };
