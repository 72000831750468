import React from 'react';
import { Button, IconButton, Flex } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import NewSeason from './NewSeason';

const ListSeason = ({ leagueUniqid, seasons, startEdit, startEditPlugin, created }) => {
  return (
    <Table size="sm" variant="striped" colorScheme="gray">
      <Thead>
        <Tr>
          <Th>賽事名稱</Th>
          <Th>操作</Th>
          <Th isNumeric>連結</Th>
        </Tr>
      </Thead>
      <Tbody>
        {seasons.map((season) => (
          <Tr key={season.uniqid}>
            <Td w="1%" whiteSpace="nowrap">
              {season.title}
            </Td>
            <Td>
              <Flex>
                <Button
                  size="xs"
                  colorScheme="blue"
                  as="a"
                  target="_blank"
                  href={`${process.env.REACT_APP_ACCOUNT_URL}/organizer/${leagueUniqid}/tournament/${season.uniqid}/basic`}
                >
                  編輯
                </Button>
                <Button size="xs" colorScheme="orange" onClick={startEdit(season.uniqid)}>
                  報名資訊設定
                </Button>
                <Button size="xs" colorScheme="orange" onClick={startEditPlugin(season.uniqid)}>
                  擴充套件設定
                </Button>
              </Flex>
            </Td>
            <Td isNumeric>
              <IconButton
                size="xs"
                icon={<ExternalLinkIcon />}
                as="a"
                target="_blank"
                href={`${process.env.REACT_APP_MAINPAGE_URL}/league/${leagueUniqid}?season=${season.uniqid}`}
              />
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td>
            <NewSeason leagueUniqid={leagueUniqid} existTitles={seasons.map((s) => s.title)} created={created} />
          </Td>
          <Td></Td>
          <Td></Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default ListSeason;
