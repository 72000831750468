import React, { useRef, useState } from 'react';
import { Button, Divider, Icon, Input, Text, useDisclosure, Select } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { MdAttachMoney } from 'react-icons/md';
import { LoadingAlertDialog } from '~~components';
import { SeasonResource } from '~~apis/resource';
import CommonUtils from '~~utils/CommonUtils';
import { TYPES_DISPLAY } from '~~utils/Season';

const NewSeason = ({ leagueUniqid, existTitles, created }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef();

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const { register, handleSubmit, errors, reset } = useForm();

  const errorDisplay = (message) => (
    <Text fontSize="xs" color="red.500">
      * {message}
    </Text>
  );

  const cancel = () => {
    reset({});
    onClose();
  };

  const onSubmit = (data) => {
    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');
    SeasonResource.createSeason({ ...data, leagueUniqid })
      .then(() => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage('新增成功！');
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      created();
      onClose();
    }
  };

  return (
    <>
      <Button leftIcon={<Icon as={MdAttachMoney} />} size="xs" onClick={onOpen}>
        新增賽事
      </Button>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={cancel} autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>新增賽事</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>刪除賽事需通知 KJ。很麻煩所以請想清楚再新增唷！</Text>
              <Divider my="5" />
              {errors.title && errorDisplay(errors.title.type === 'required' ? '賽事名稱不得為空' : '賽事名稱重複')}
              <Input
                placeholder="賽季名稱"
                type="text"
                name="title"
                mb="2"
                size="sm"
                ref={register({
                  required: true,
                  validate: {
                    exists: (value) => existTitles.includes(value) === false,
                  },
                })}
              />
              {errors.type && errorDisplay('請選擇賽事種類')}
              <Select name="type" placeholder="賽事種類" ref={register({ required: true })} size="sm" mb="2">
                {Object.keys(TYPES_DISPLAY).map((type) => (
                  <option key={type} value={type}>
                    {TYPES_DISPLAY[type]}
                  </option>
                ))}
              </Select>
              {errors.startAt &&
                errorDisplay(
                  errors.startAt.type === 'required' ? '開始日期不得為空' : '開始日期請以 yyyy/mm/dd 方式輸入',
                )}
              <Input
                placeholder="開始日期"
                type="text"
                name="startAt"
                mb="2"
                size="sm"
                ref={register({
                  required: true,
                  pattern: CommonUtils.dateStringPattern,
                })}
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" mr={3} onClick={cancel}>
                取消
              </Button>
              <Button colorScheme="confirm" type="submit">
                新增
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={'新增賽事'}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </>
  );
};

export default NewSeason;
