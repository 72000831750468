import React, { useEffect, useState } from 'react';
import { Box, Spinner, Image, Center, CloseButton, Flex } from '@chakra-ui/react';
import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';

const Single = ({ file, removeSelf, status }) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setSrc(e.target.result);
    };
    reader.readAsDataURL(file);
  }, [file]);

  const displayStatus = () => {
    switch (status) {
      case 'UPLOADING':
        return <Spinner size="sm" />;
      case 'SUCCESS':
        return <CheckCircleIcon color="green.500" />;
      case 'ERROR':
        return <WarningTwoIcon color="red.500" />;
      default:
        return <></>;
    }
  };

  return (
    <Box boxSize="sm" boxShadow="md" p="2" position="relative">
      {status ? (
        <Flex position="absolute" bg="gray.100" p="2" borderRadius="5">
          {displayStatus()}
        </Flex>
      ) : (
        <CloseButton variant="ghost" position="absolute" top="2" right="2" onClick={removeSelf} />
      )}
      <Center w="100%" h="100%">
        {!src ? <Spinner /> : <Image src={src} maxW="100%" maxH="100%" />}
      </Center>
    </Box>
  );
};

export default Single;
