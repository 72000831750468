import { filter } from 'ramda';

const randomBetween = (max, min) => Math.floor(Math.random() * (max - min + 1)) + min;
// fakeApiHelper
// responseType: success | fail | random | inOrder
let count = 1;
const fakeApiHelper = (
  interval = 1000,
  responseType = 'success',
  successResponse,
  ErrorResponse = 'SOMETHING_WRONG',
  preprocess = true,
) => {
  return new Promise((resolve, reject) => {
    const successAxiosFormat = preprocess ? successResponse : { data: successResponse };
    const errorAxiosFormat = preprocess ? ErrorResponse : { data: ErrorResponse };

    // 一次成功，一次失敗
    if (responseType === 'inOrder') {
      if (count % 2 === 1) {
        setTimeout(() => resolve(successAxiosFormat), interval);
      } else {
        setTimeout(() => reject(errorAxiosFormat), interval);
      }
      count += 1;
    }

    // 隨機的回傳成功或失敗
    if (responseType === 'random') {
      const random = randomBetween(1, 100);
      if (random % 2 === 1) {
        setTimeout(() => resolve(successAxiosFormat), interval);
      } else {
        setTimeout(() => reject(errorAxiosFormat), interval);
      }
      count += 1;
    }

    if (responseType === 'success') {
      setTimeout(() => resolve(successAxiosFormat), interval);
    }
    if (responseType === 'fail') {
      setTimeout(() => reject(errorAxiosFormat), interval);
    }
  });
};

const stringtifyDate = (info) => {
  let year = parseInt(info.year);
  let month = parseInt(info.month);
  let day = parseInt(info.day);
  let hour = parseInt(info.hour);
  let min = parseInt(info.min);
  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(hour) || Number.isNaN(min)) {
    return '';
  }

  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  if (hour < 10) {
    hour = '0' + hour;
  }
  if (min < 10) {
    min = '0' + min;
  }

  return `${year}/${month}/${day} ${hour}:${min}`;
};

const objectifyDate = (dateString) => {
  let date = new Date();
  if (dateString) {
    date = new Date(dateString);
  }

  return {
    year: date.getFullYear().toString(),
    month: dateAddZero(date.getMonth() + 1),
    day: dateAddZero(date.getDate()),
    hour: dateAddZero(date.getHours()),
    min: dateAddZero(date.getMinutes()),
  };
};

const dateAddZero = (number) => {
  number = '0' + number;
  return number.slice(-2);
};

const displayGameTeam = (side, game) => {
  if (game[side]?.team) {
    return game[side].team;
  }
  side = side.toUpperCase();
  const reverseSide = side === 'AWAY' ? 'HOME' : 'AWAY';

  const promotions = filter((g) => g.type === 'PROMOTION' && g.side !== reverseSide, game.info.nodes.link);
  const sidePromotions = filter((g) => g.side === side, promotions);
  const rematchs = filter((g) => g.type === 'REMATCH' && g.side !== reverseSide, game.info.nodes.link);
  const sideRematchs = filter((g) => g.side === side, rematchs);
  const remains = filter((g) => g.type === 'REMAIN' && g.side !== reverseSide, game.info.nodes.link);
  const sideRemains = filter((g) => g.side === side, remains);

  switch (sidePromotions.length) {
    case 0:
      break;
    case 1:
      return `[G${sidePromotions[0].base.game_seq}勝]`;
    default:
      return `[${sidePromotions.length}戰取勝]`;
  }

  switch (promotions.length) {
    case 0:
      break;
    case 1:
      return `[G${promotions[0].base.game_seq}勝]`;
    default:
      return `[${promotions.length}戰取勝]`;
  }

  if (sideRematchs.length > 0) {
    return `[G${sideRematchs[0].base.game_seq}重賽]`;
  }

  if (rematchs.length > 0) {
    return `[G${rematchs[0].base.game_seq}重賽]`;
  }

  if (remains.length > 0) {
    return `[G${remains[0].base.game_seq}保留比賽]`;
  }

  if (sideRemains.length > 0) {
    return `[G${sideRemains[0].base.game_seq}保留比賽]`;
  }

  return '[未定]';
};

const dateStringPattern = `[12][09][0-9]{2}/(0[1-9]|1[0-2])/([0-2][0-9]|3[01])`;

const dateTimeStringPattern = `${dateStringPattern} ([01][0-9]|2[0-4]):([0-5][0-9]|60)`;

export default {
  fakeApiHelper,
  stringtifyDate,
  objectifyDate,
  dateAddZero,
  displayGameTeam,
  dateStringPattern: new RegExp(`^${dateStringPattern}$`),
  dateTimeStringPattern: new RegExp(`^${dateTimeStringPattern}$`),
};
