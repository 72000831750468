export const batterHeadings = [
  '背號',
  '球員',
  '棒次',
  '守位',
  '',
  '打席(PA)',
  '打數(AB)',
  '打點(RBI)',
  '得分(R)',
  '安打(H)',
  '二壘安打(2B)',
  '三壘安打(3B)',
  '全壘打(HR)',
  '滾地雙殺(GIDP)',
  '犧牲觸擊(SAC)',
  '犧牲飛球(SF)',
  '四壞球(BB)',
  '三振(SO)',
  '盜壘成功(SB)',
  '盜壘失敗(CS)',
  '故意四壞球(IBB)',
  '觸身球(HBP)',
  '野手選擇(FC)',
  '失誤(E)',
  '飛球出局(FO)',
  '滾地出局(GO)',
  '打擊率(AVG)',
  '上壘率(OBP)',
  '',
  '守備機會(TC)',
  '刺殺(PO)',
  '助殺(A)',
  '守備失誤(E)',
  '守備率(DA)',
  '捕逸(PB)',
  '被盜壘(SB)',
  '阻殺(CS)',
];

export const batterColumnMapping = {
  batting: {
    棒次: 'order',
    '打席(PA)': 'PA',
    '打數(AB)': 'AB',
    '安打(H)': 'H',
    '二壘安打(2B)': 'TWOB',
    '三壘安打(3B)': 'THREEB',
    '全壘打(HR)': 'HR',
    '三振(SO)': 'SO',
    '四壞球(BB)': 'BB',
    '故意四壞球(IBB)': 'IBB',
    '觸身球(HBP)': 'HBP',
    '犧牲飛球(SF)': 'SF',
    '犧牲觸擊(SAC)': 'SAC',
    '滾地雙殺(GIDP)': 'GIDP',
    '野手選擇(FC)': 'FC',
    '失誤(E)': 'E',
    '飛球出局(FO)': 'F',
    '滾地出局(GO)': 'G',
    '盜壘成功(SB)': 'SB',
    '盜壘失敗(CS)': 'CS',
    '得分(R)': 'R',
    '打點(RBI)': 'RBI',
  },
  fielding: {
    守位: 'position',
    '刺殺(PO)': 'PO',
    '助殺(A)': 'A',
    '守備失誤(E)': 'E',
    '捕逸(PB)': 'PB',
    '被盜壘(SB)': 'SB',
    '阻殺(CS)': 'CS',
  },
};

export const batterHeadingWidths = batterHeadings.map((heading, index) => {
  if (!heading) {
    return { wch: 3 };
  }

  if (index === 1) {
    return { wch: 8 };
  }

  return { wch: heading.length + 3 };
});

export const pitcherHeadings = [
  '背號',
  '球員',
  '',
  '出局數(IPOut)',
  '投球數(NP)',
  '打席數(BF)',
  '安打(H)',
  '全壘打(HR)',
  '四壞球(BB)',
  '觸身球(HB)',
  '三振(SO)',
  '暴投(WP)',
  '投手犯規(BK)',
  '失分(R)',
  '自責分(ER)',
  '故意四壞球(IBB)',
  '防禦率(ERA)',
  '被上壘率(WHIP)',
  '',
  '勝(W)',
  '敗(L)',
  '中繼(H)',
  '救援成功(SV)',
  '救援失敗(BS)',
];

export const pitcherColumnMapping = {
  pitching: {
    '出局數(IPOut)': 'IPOut',
    '投球數(NP)': 'NP',
    '打席數(BF)': 'BF',
    '安打(H)': 'H',
    '全壘打(HR)': 'HR',
    '四壞球(BB)': 'BB',
    '故意四壞球(IBB)': 'IBB',
    '觸身球(HB)': 'HB',
    '三振(SO)': 'SO',
    '暴投(WP)': 'WP',
    '投手犯規(BK)': 'BK',
    '失分(R)': 'R',
    '自責分(ER)': 'ER',
  },
  record: {
    '勝(W)': 'W',
    '敗(L)': 'L',
    '中繼(H)': 'H',
    '救援成功(SV)': 'SV',
    '救援失敗(BS)': 'BS',
  },
};

export const pitcherHeadingWidths = pitcherHeadings.map((heading, index) => {
  if (!heading) {
    return { wch: 3 };
  }

  if (index === 1) {
    return { wch: 8 };
  }

  return { wch: heading.length + 3 };
});

export const fieldPositionMapping = {
  '1': 'P',
  '2': 'C',
  '3': '1B',
  '4': '2B',
  '5': '3B',
  '6': 'SS',
  '7': 'LF',
  '8': 'CF',
  '9': 'RF',
  DH: 'DH',
  PR: 'PR',
  PH: 'PH',
};
