import React, { useEffect, useState } from 'react';
import { Box, VStack, HStack, Flex, Button, Image, Text, SimpleGrid } from '@chakra-ui/react';
import { FormControl, FormLabel, Input, Center, CloseButton, Select } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { filter } from 'ramda';
import { UploadMedia, LoadingAlertDialog } from '~~components';
import { LocationResource } from '~~apis/resource';
import { TYPE_MAPPING } from '../Consts';

const Edit = ({ location: originLocation, existLocationNames, cancelEdit, completedEdit }) => {
  const [isNew, setIsNew] = useState(false);
  const [location, setLocation] = useState(originLocation);

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const {
    register,
    handleSubmit,
    errors,
    reset,
    // formState: { isDirty },
  } = useForm();

  useEffect(() => {
    setIsNew(originLocation.uniqid === '');
    setLocation(originLocation);
    reset(originLocation);
  }, [originLocation, reset]);

  const errorDisplay = (message, withStar = true) => {
    if (!withStar) {
      return (
        <Text fontSize="xs" color="red.500">
          {message}
        </Text>
      );
    }

    return (
      <Text fontSize="xs" color="red.500">
        * {message}
      </Text>
    );
  };

  const requiredDisplay = () => <Text color="red.500"> *</Text>;

  const updateCover = (result) => {
    setLocation({
      ...location,
      cover_url: result.url,
      cover_path: result.path,
    });
  };

  const removeCover = () => {
    setLocation({
      ...location,
      cover_url: '',
      cover_path: 'REMOVE',
    });
  };

  const back = () => {
    reset(originLocation);
    cancelEdit();
  };

  const onSubmit = (data) => {
    const submitLocation = {
      cover_path: '',
      ...location,
      ...data,
    };

    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');

    const action = isNew ? 'createLocation' : 'updateLocation';
    LocationResource[action]({ locationUniqid: location.uniqid, data: submitLocation })
      .then((data) => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage(isNew ? '新增成功！' : '更新成功！');
        setLocation(data.data);
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      completedEdit(location);
    }
  };

  return (
    <Box>
      <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={back}>
        取消
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} alignItems="start">
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">
                地點名稱{requiredDisplay()}
                {errors.name &&
                  errorDisplay(errors.name.type === 'required' ? '地點名稱不得為空' : '地點名稱重複', false)}
              </Flex>
            </FormLabel>
            <Input
              size="sm"
              type="text"
              name="name"
              ref={register({
                required: true,
                validate: {
                  exists: (value) =>
                    filter((n) => n !== originLocation.name, existLocationNames).includes(value) === false,
                },
              })}
            />
          </FormControl>
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">
                簡寫（二至三個字）
                {errors.abbr && errorDisplay('簡寫過長', false)}
              </Flex>
            </FormLabel>
            <Input
              size="sm"
              type="text"
              name="abbr"
              ref={register({
                validate: {
                  length: (value) => value.length <= 6,
                },
              })}
            />
          </FormControl>
          <FormControl w="90%">
            <Flex alignItems="center">
              地點型態{requiredDisplay()}
              {errors.type && errorDisplay('請選擇地點型態', false)}
            </Flex>
            <Select name="type" placeholder="請選擇" ref={register({ required: true })} size="sm">
              {Object.keys(TYPE_MAPPING).map((type) => (
                <option key={type} value={type}>
                  {TYPE_MAPPING[type]}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <HStack spacing={5}>
              <FormLabel>封面圖 - 長寬比 4：3</FormLabel>
              <UploadMedia updateMedia={updateCover} iconSize={5} />
            </HStack>
            {location.cover_url && (
              <Box w="40vw" h="30vw" boxShadow="md" p="2" position="relative">
                <CloseButton variant="ghost" position="absolute" top="2" right="2" onClick={removeCover} />
                <Center w="100%" h="100%">
                  <Image maxW="100%" maxH="100%" src={location.cover_url} />
                </Center>
              </Box>
            )}
          </FormControl>
          <SimpleGrid w="100%" columns="2">
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">國家（固定 TW）</Flex>
              </FormLabel>
              <Input size="sm" type="text" name="country" ref={register({ required: true })} isReadOnly={true} />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">州/省（請先無視）</Flex>
              </FormLabel>
              <Input size="sm" type="text" name="state" ref={register()} isDisabled={true} />
            </FormControl>
          </SimpleGrid>
          <SimpleGrid w="100%" columns="2">
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  城市{requiredDisplay()}
                  {errors.city && errorDisplay('城市不得為空', false)}
                </Flex>
              </FormLabel>
              <Input size="sm" type="text" name="city" ref={register({ required: true })} />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  郵遞區號{requiredDisplay()}
                  {errors.postcode && errorDisplay('郵遞區號不得為空', false)}
                </Flex>
              </FormLabel>
              <Input size="sm" type="text" name="postcode" ref={register({ required: true })} />
            </FormControl>
          </SimpleGrid>
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">
                地址 1{requiredDisplay()}
                {errors.address1 &&
                  errorDisplay(
                    errors.address1.type === 'required' ? '地址 1 不得為空' : '地址 1 不得超過 95 個字',
                    false,
                  )}
              </Flex>
            </FormLabel>
            <Input size="sm" type="text" name="address1" ref={register({ required: true, maxLength: 95 })} />
          </FormControl>
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">地址 2{errors.address2 && errorDisplay('地址 2 不得超過 95 個字')}</Flex>
            </FormLabel>
            <Input size="sm" type="text" name="address2" ref={register({ maxLength: 95 })} />
          </FormControl>
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">地址 3{errors.address3 && errorDisplay('地址 3 不得超過 95 個字')}</Flex>
            </FormLabel>
            <Input size="sm" type="text" name="address3" ref={register({ maxLength: 95 })} />
          </FormControl>
          <SimpleGrid w="100%" columns="2">
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  經度{requiredDisplay()}
                  {errors.lng && errorDisplay('經度格式不正確', false)}
                </Flex>
              </FormLabel>
              <Input
                size="sm"
                type="text"
                name="lng"
                ref={register({
                  required: true,
                  valueAsNumber: true,
                  pattern: /^-?([0-9]|[1-9][0-9]|1[0-7][0-9]|180)\.\d+$/,
                })}
              />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  緯度{requiredDisplay()}
                  {errors.lat && errorDisplay('緯度格式不正確', false)}
                </Flex>
              </FormLabel>
              <Input
                size="sm"
                type="text"
                name="lat"
                ref={register({
                  required: true,
                  valueAsNumber: true,
                  pattern: /^-?([0-9]|[1-8][0-9]|90)\.\d+$/,
                })}
              />
            </FormControl>
          </SimpleGrid>
          <Button type="submit">確認送出</Button>
        </VStack>
      </form>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={isNew ? '更新地點資訊' : '更新地點資訊'}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </Box>
  );
};

export default Edit;
