import React, { useEffect, useState } from 'react';
import { Box, Grid, SimpleGrid, VStack, HStack, Textarea, Flex, Button, Image, Text } from '@chakra-ui/react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { find } from 'ramda';
import { useForm } from 'react-hook-form';
import { ConfirmAlertDialog, NestSelect, UploadMedia, LoadingAlertDialog } from '~~components';
import { SeasonResource, GameDetailResource, NewsResource } from '~~apis/resource';
import CommonUtils from '~~utils/CommonUtils';
import { INITIAL_NEWS_DATA } from './Consts';

const News = () => {
  const [seasons, setSeasons] = useState([]);
  const [games, setGames] = useState([]);
  const [shoudSelectionsUpdate, updateSelections] = useState(false);
  const [nestSelections, setNestSelections] = useState([
    { placeholder: '聯賽選擇', options: [], isReady: false, target: '' },
    { placeholder: '賽事選擇', options: [], isReady: false, target: '' },
  ]);
  const [decisionFreeze, freezeDecision] = useState(false);

  const [season, setSeason] = useState();
  const [game, setGame] = useState();
  const [news, setNews] = useState();
  const [isNew, setIsNew] = useState();

  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingAction, setLoadingAction] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    if (nestSelections[0].isReady) {
      return;
    }

    SeasonResource.getSeasons().then((data) => {
      setSeasons(data.data);
      nestSelections[0].options = data.data.map((season) => {
        return {
          value: season.uniqid,
          display: season.title,
        };
      });
      nestSelections[0].isReady = true;
      setNestSelections(nestSelections);
      updateSelections(true);
    });
  }, [nestSelections, seasons]);

  const selectionsUpdated = () => {
    updateSelections(false);
  };

  const decisionUpdate = (index) => (e) => {
    if (index === 0) {
      return seasonUpdate(e.target.value);
    }
    if (index === 1) {
      return gameUpdate(e.target.value);
    }
  };

  const seasonUpdate = (seasonUniqid) => {
    nestSelections[0].target = seasonUniqid;
    nestSelections[1].isReady = false;
    nestSelections[1].target = '';
    setNestSelections(nestSelections);
    updateSelections(true);

    const selectedSeason = find((s) => s.uniqid === seasonUniqid)(seasons);
    setSeason(selectedSeason);
    setGame();
    if (!selectedSeason) {
      return;
    }

    GameDetailResource.getGames({ seasonUniqid }).then((data) => {
      setGames(data.data);
      nestSelections[1].options = data.data.map((game) => {
        return {
          value: game.uniqid,
          display: `G${game.seq} ${game.info.time.scheduled_start_at}
          ${CommonUtils.displayGameTeam('away', game)} vs ${CommonUtils.displayGameTeam('home', game)}`,
        };
      });
      nestSelections[1].isReady = true;
      setNestSelections(nestSelections);
      updateSelections(true);
    });
  };

  const gameUpdate = (gameUniqid) => {
    nestSelections[1].target = gameUniqid;
    setNestSelections(nestSelections);
    updateSelections(true);

    const selectedGame = find((g) => g.uniqid === gameUniqid)(games);
    setGame(selectedGame);
  };

  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isDirty },
  } = useForm();

  const errorDisplay = (message) => (
    <Text fontSize="xs" color="red.500">
      * {message}
    </Text>
  );

  const updateImage = (result) => {
    setNews({
      ...news,
      image_path: result.path,
      image_url: result.url,
    });
  };

  const updatePreviewImage = (result) => {
    setNews({
      ...news,
      preview_image_path: result.path,
      preview_image_url: result.url,
    });
  };

  const updateNewsForm = (updatedNews) => {
    if (!updatedNews) {
      return;
    }
    reset(updatedNews);
  };

  const startEdit = () => {
    if (!game.news) {
      setIsNew(true);
      setNews(INITIAL_NEWS_DATA);
      updateNewsForm(INITIAL_NEWS_DATA);
    } else {
      setIsNew(false);
      setNews(game.news);
      updateNewsForm(game.news);
    }
    freezeDecision(true);
  };

  const cancelEdit = () => {
    freezeDecision(false);
  };

  const onSubmit = (data) => {
    const submitNews = {
      ...news,
      ...data,
    };

    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');
    setLoadingAction('SUBMIT');
    let method = 'createNews';
    if (isNew) {
      method = 'updateNews';
    }

    NewsResource[method]({ seasonUniqid: season.uniqid, gameUniqid: game.uniqid, news: submitNews })
      .then(() => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage('發布成功！');
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const archiveNews = () => {
    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');
    setLoadingAction('DELETE');
    NewsResource.deleteNews({ seasonUniqid: season.uniqid, game: game.uniqid })
      .then(() => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage('刪除成功！');
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      freezeDecision(false);
      seasonUpdate(season.uniqid);
    }
  };

  return (
    <Box>
      <Box w="80%">
        <Grid templateColumns="repeat(1, 4fr 1fr)" gap={5}>
          <NestSelect
            nestSelections={nestSelections}
            shoudUpdate={shoudSelectionsUpdate}
            selectionsUpdated={selectionsUpdated}
            decisionUpdate={decisionUpdate}
            decisionFreeze={decisionFreeze}
            styles={{ mt: 10, mb: 5 }}
          />
          {game && (
            <SimpleGrid alignSelf="end" columns={2} spacing={2} mb="5">
              {decisionFreeze ? (
                isDirty ? (
                  <ConfirmAlertDialog
                    buttonStyles={{ variant: 'outline' }}
                    buttonDisplay="取消"
                    title="捨棄變更"
                    content="確定要捨棄變更嗎？所有改動皆不會儲存"
                    confirmColor="orange"
                    handleConfirm={cancelEdit}
                  />
                ) : (
                  <Button variant="outline" onClick={cancelEdit}>
                    取消
                  </Button>
                )
              ) : (
                <div></div>
              )}
              {decisionFreeze && !isNew ? (
                <ConfirmAlertDialog
                  buttonStyles={{ variant: 'ghost' }}
                  icon={<DeleteIcon />}
                  title="報導封存"
                  content="確定要封存報導？如欲還原需與工程師討論"
                  handleConfirm={archiveNews}
                />
              ) : (
                <div></div>
              )}
              <Button isDisabled={decisionFreeze} onClick={startEdit}>
                套用
              </Button>
              <></>
            </SimpleGrid>
          )}
        </Grid>
        {game && decisionFreeze && (
          <Box onSubmit={handleSubmit(onSubmit)}>
            <form>
              <VStack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="news-title">
                    <Flex alignItems="center">
                      標題
                      {errors.title && errorDisplay('標題不得為空')}
                    </Flex>
                  </FormLabel>
                  <Input id="news-title" size="sm" type="text" name="title" ref={register({ required: true })} />
                </FormControl>
                <SimpleGrid columns={2} spacing={2} w="100%">
                  <FormControl>
                    <HStack spacing={5}>
                      <FormLabel htmlFor="news-image-url">封面圖</FormLabel>
                      <UploadMedia id="news-image-url" updateMedia={updateImage} iconSize={5} />
                    </HStack>
                    {news.image_url && <Image my="1" height="150px" src={news.image_url} />}
                  </FormControl>
                  <FormControl>
                    <HStack spacing={5}>
                      <FormLabel htmlFor="news-preview-image-url">預覽圖</FormLabel>
                      <UploadMedia id="news-preview-image-url" updateMedia={updatePreviewImage} iconSize={5} />
                    </HStack>
                    {news.preview_image_url && <Image my="1" height="150px" src={news.preview_image_url} />}
                  </FormControl>
                </SimpleGrid>
                <FormControl>
                  <FormLabel>報導來源</FormLabel>
                  <SimpleGrid columns={4} spacing={2}>
                    <InputGroup size="sm">
                      <InputLeftAddon children="編輯" />
                      <Input id="news-author" type="text" name="source.author" ref={register} />
                    </InputGroup>
                    <InputGroup size="sm">
                      <InputLeftAddon children="採訪" />
                      <Input id="news-interviewer" type="text" name="source.interviewer" ref={register} />
                    </InputGroup>
                    <InputGroup size="sm">
                      <InputLeftAddon children="城市" />
                      <Input id="news-city" type="text" name="source.city" ref={register} />
                    </InputGroup>
                    <InputGroup size="sm">
                      <InputLeftAddon children="攝影" />
                      <Input id="news-photographer" type="text" name="source.photographer" ref={register} />
                    </InputGroup>
                  </SimpleGrid>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="game-news-content">
                    <Flex alignItems="center">
                      內文
                      {errors.content && errorDisplay('內文不得為空')}
                    </Flex>
                  </FormLabel>
                  <Textarea
                    size="lg"
                    id="game-news-content"
                    h="50vh"
                    name="content"
                    ref={register({ required: true })}
                  />
                </FormControl>
                <Button type="submit">確認送出</Button>
              </VStack>
            </form>
          </Box>
        )}
      </Box>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={`${loadingAction === 'DELETE' ? '刪除' : '發布'}報導`}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </Box>
  );
};

export default News;
