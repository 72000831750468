import React, { useEffect, useState } from 'react';
import { Box, VStack, Flex, Button, InputRightElement, Text, SimpleGrid } from '@chakra-ui/react';
import { FormControl, FormLabel, Input, InputGroup, IconButton, Select } from '@chakra-ui/react';
import { ChevronLeftIcon, AddIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { sum, filter, find, findIndex, sort } from 'ramda';
import CommonUtils from '~~utils/CommonUtils';
import { LoadingAlertDialog } from '~~components';
import { FirstBaseResource } from '~~apis/resource';
import PbPUpload from './PbPUpload';
import { INITIAL_GAME_DATA, INITIAL_TAG_DATA } from '../Consts';

const ImportGame = ({ team, originPlayers, locations, cancelImport, completedImport }) => {
  const [openLoadingAlert, setOpenLoadingAlert] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');

  const [awayScores, setAwayScores] = useState([]);
  const [homeScores, setHomeScores] = useState([]);
  const [tags, setTags] = useState({});
  const [pbp, setPbp] = useState([]);
  const [fielders, setFielders] = useState([]);
  const [opponentPbp, setOpponentPbp] = useState([]);
  const [opponentFielders, setOpponentFielders] = useState([]);
  const [similarPlayers, setSimilarPlayers] = useState([]);
  const [pbpError, setPbpError] = useState('');

  const [ableToSubmit, setAbleToSubmit] = useState(false);

  const { register, handleSubmit, errors, reset, setError: setFormError, watch, trigger, getValues } = useForm();
  const opponentTeamName = watch('opponent_team_name');
  const inningFrame = watch('info.side') === 'AWAY' ? 'TOP' : watch('info.side') === 'HOME' ? 'BOTTOM' : '';

  useEffect(() => {
    reset({ ...INITIAL_GAME_DATA });
  }, [reset]);

  const addTag = () => {
    tags[Object.keys(tags).length] = { ...INITIAL_TAG_DATA };
    setTags(Object.assign({}, tags));
  };

  const handleTagChange = (index) => (e) => {
    const newTags = Object.assign({}, tags);
    newTags[index].name = e.target.value;
    setTags(newTags);
  };

  const removeTag = (index) => () => {
    delete tags[index];
    setTags(Object.assign({}, tags));
  };

  const errorDisplay = (message, withStar = true) => {
    if (!withStar) {
      return (
        <Text fontSize="xs" color="red.500">
          {message}
        </Text>
      );
    }

    return (
      <Text fontSize="xs" color="red.500">
        * {message}
      </Text>
    );
  };

  const requiredDisplay = () => <Text color="red.500"> *</Text>;

  const back = () => {
    reset(INITIAL_GAME_DATA);
    cancelImport();
  };

  const validationCheck = () => {
    setAbleToSubmit(false);
    setPbpError('');
    trigger().then((available) => {
      if (!available) {
        return;
      }

      const data = getValues();
      const innings = data.info.innings;
      if (Number.isNaN(innings)) {
        setFormError('info.innings', {
          type: 'manual',
          message: 'Invalid innings',
        });
        return;
      }
      const awayScores = data.info.awayScores.split(',');
      const homeScores = data.info.homeScores.split(',');
      if (awayScores.length !== innings) {
        setFormError('info.awayScores', {
          type: 'manual',
          message: 'Invalid awayScores',
        });
        return;
      }

      if (homeScores.length !== innings) {
        setFormError('info.homeScores', {
          type: 'manual',
          message: 'Invalid homeScores',
        });
        return;
      }

      if (pbp.length === 0) {
        setPbpError('請上傳分析表單');
        return;
      }

      const awayRuns = sum(filter((score) => !Number.isNaN(parseInt(score)), awayScores));
      const homeRuns = sum(filter((score) => !Number.isNaN(parseInt(score)), homeScores));
      const pbpRuns = filter((PA) => PA.R !== 0, pbp).length;
      const pbpLastInning = pbp[pbp.length - 1].inning;
      if (
        (inningFrame === 'TOP' && pbpLastInning !== innings) ||
        (inningFrame === 'BOTTOM' && pbpLastInning !== innings && pbpLastInning !== innings - 1)
      ) {
        setPbpError('分析表單局數與總局數不相符');
        return;
      }

      if ((inningFrame === 'TOP' && awayRuns !== pbpRuns) || (inningFrame === 'BOTTOM' && homeRuns !== pbpRuns)) {
        setPbpError('分析表單球隊得分與各局比分不相符');
        return;
      }

      if (opponentPbp.length > 0) {
        const opponentPbpRuns = filter((PA) => PA.R !== 0, opponentPbp).length;
        const opponentPbpLastInning = opponentPbp[opponentPbp.length - 1].inning;
        const opponentInningFrame = inningFrame === 'TOP' ? 'BOTTOM' : 'TOP';
        if (
          (opponentInningFrame === 'TOP' && opponentPbpLastInning !== innings) ||
          (opponentInningFrame === 'BOTTOM' &&
            opponentPbpLastInning !== innings &&
            opponentPbpLastInning !== innings - 1)
        ) {
          setPbpError('（對手）分析表單局數與總局數不相符');
          return;
        }

        if (
          (opponentInningFrame === 'TOP' && awayRuns !== opponentPbpRuns) ||
          (opponentInningFrame === 'BOTTOM' && homeRuns !== opponentPbpRuns)
        ) {
          setPbpError('（對手）分析表單球隊得分與各局比分不相符');
          return;
        }
      }

      const scoreCheck = (pbp4Check, scoreKey, sideScores) => {
        const inningScores = [];
        for (let inningRun of sideScores) {
          inningRun = parseInt(inningRun);
          if (Number.isNaN(inningRun)) {
            inningRun = 0;
          }

          if (inningScores.length === 0) {
            inningScores.push(inningRun);
            continue;
          }

          inningScores.push(inningScores[inningScores.length - 1] + inningRun);
        }

        const sortedPbp4Check = filter(
          (PA) => !!PA.result,
          sort((a, b) => {
            if (a.inning !== b.inning) {
              return a.inning - b.inning;
            }

            if (a.PA_round !== b.PA_round) {
              return a.PA_round - b.PA_round;
            }

            if (a.PA_order === b.PA_order) {
              return a.pinch === 'PR' ? 1 : -1;
            }

            return a.PA_order - b.PA_order;
          }, pbp4Check),
        );
        console.log(sortedPbp4Check);

        let currentScore = 0;
        let error = false;
        let PAIndex = -1;
        for (let PA of sortedPbp4Check) {
          PAIndex++;
          if (currentScore > PA[scoreKey]) {
            setPbpError(`打席資訊得分倒退！局數${PA.inning} 棒次${PA.PA_order} 攻擊輪${PA.PA_round}`);
            error = true;
            break;
          }

          if (inningScores[PA.inning - 1] < PA[scoreKey]) {
            setPbpError(`表單各局比分與打席狀況不相符！局數${PA.inning} 棒次${PA.PA_order} 攻擊輪${PA.PA_round}`);
            error = true;
            break;
          }

          if (PAIndex === 0 || PA.inning === sortedPbp4Check[PAIndex - 1].inning) {
            continue;
          }

          if (PA[scoreKey] !== inningScores[PA.inning - 2]) {
            setPbpError(
              `表單${PA.inning - 1}局累積分數與打席狀況不相符！` +
                `局數${PA.inning} 棒次${PA.PA_order} 攻擊輪${PA.PA_round}`,
            );
            error = true;
            break;
          }

          currentScore = PA[scoreKey];
        }

        return error;
      };

      if (
        scoreCheck(
          pbp,
          inningFrame === 'TOP' ? 'away_score' : 'home_score',
          inningFrame === 'TOP' ? awayScores : homeScores,
        )
      ) {
        setPbpError((pre) => (inningFrame === 'TOP' ? '客' : '主') + `場${pre}`);
        return;
      }

      if (opponentPbp.length > 0) {
        if (
          scoreCheck(
            opponentPbp,
            inningFrame === 'TOP' ? 'home_score' : 'away_score',
            inningFrame === 'TOP' ? homeScores : awayScores,
          )
        ) {
          setPbpError((pre) => (inningFrame === 'TOP' ? '主' : '客') + `場${pre}`);
          return;
        }
      }

      const getSamePlayer = (isOpponent, player) => {
        return find(
          (p) => p.is_opponent === isOpponent && p.number === player.number && p.name === player.name,
          originPlayers,
        );
      };

      const getSameNamePlayerIndex = (isOpponent, player) => {
        return findIndex((p) => p.is_opponent === isOpponent && p.name === player.name, originPlayers);
      };

      const similarPlayers = [];
      const similarPlayerNames = [];
      pbp.forEach((PA, index) => {
        if (PA.pitcher.name !== '') {
          const sameOpponent = getSamePlayer(true, PA.pitcher);
          if (sameOpponent) {
            PA.pitcher.uniqid = sameOpponent.uniqid;
          }
        }

        if (PA.R_count_on_pitcher.name) {
          const sameOpponent = getSamePlayer(true, PA.R_count_on_pitcher);
          if (sameOpponent) {
            PA.R_count_on_pitcher.uniqid = sameOpponent.uniqid;
          }
        }

        if (PA.batter.uniqid !== '') {
          return;
        }

        const samePlayer = getSamePlayer(false, PA.batter);
        if (samePlayer) {
          PA.batter.uniqid = samePlayer.uniqid;
          return;
        }

        const sameNamePlayerIndex = getSameNamePlayerIndex(false, PA.batter);
        if (sameNamePlayerIndex < 0 || similarPlayerNames.includes(PA.batter.name)) {
          return;
        }

        similarPlayers.push({ origin: sameNamePlayerIndex, target: index, from: 'pbp' });
        similarPlayerNames.push(PA.batter.name);
      });

      fielders.forEach((fielder, index) => {
        if (fielder.player.uniqid !== '') {
          return;
        }

        const samePlayer = getSamePlayer(false, fielder.player);
        if (samePlayer) {
          fielder.player.uniqid = samePlayer.uniqid;
          return;
        }

        const sameNamePlayerIndex = getSameNamePlayerIndex(false, fielder.player);
        if (sameNamePlayerIndex < 0 || similarPlayerNames.includes(fielder.player.name)) {
          return;
        }

        similarPlayers.push({ origin: sameNamePlayerIndex, target: index, from: 'fielders' });
        similarPlayerNames.push(fielder.player.name);
      });

      opponentPbp.forEach((PA, index) => {
        if (PA.batter.name !== '') {
          const sameOpponent = getSamePlayer(true, PA.batter);
          if (sameOpponent) {
            PA.batter.uniqid = sameOpponent.uniqid;
          }
        }

        if (PA.R_count_on_pitcher.name && PA.R_count_on_pitcher.uniqid === '') {
          const samePlayer = getSamePlayer(false, PA.R_count_on_pitcher);
          if (samePlayer) {
            PA.R_count_on_pitcher.uniqid = samePlayer.uniqid;
          } else {
            const sameNamePlayerIndex = getSameNamePlayerIndex(false, PA.R_count_on_pitcher);
            if (sameNamePlayerIndex > 0 && !similarPlayerNames.includes(PA.R_count_on_pitcher.name)) {
              similarPlayers.push({ origin: sameNamePlayerIndex, target: index, from: 'opponentPbp_RCountOn' });
              similarPlayerNames.push(PA.R_count_on_pitcher.name);
            }
          }
        }

        if (PA.pitcher.uniqid !== '') {
          return;
        }

        const samePlayer = getSamePlayer(false, PA.pitcher);
        if (samePlayer) {
          PA.pitcher.uniqid = samePlayer.uniqid;
          return;
        }

        const sameNamePlayerIndex = getSameNamePlayerIndex(false, PA.pitcher);
        if (sameNamePlayerIndex < 0 || similarPlayerNames.includes(PA.pitcher.name)) {
          return;
        }

        similarPlayers.push({ origin: sameNamePlayerIndex, target: index, from: 'opponentPbp' });
        similarPlayerNames.push(PA.pitcher.name);
      });

      opponentFielders.forEach((fielder) => {
        if (fielder.player.uniqid !== '') {
          return;
        }

        const sameOpponent = getSamePlayer(true, fielder.player);
        if (sameOpponent) {
          fielder.player.uniqid = sameOpponent.uniqid;
        }
      });
      setSimilarPlayers(similarPlayers);

      if (similarPlayers.length === 0) {
        setAwayScores(awayScores);
        setHomeScores(homeScores);
        setAbleToSubmit(true);
      }
    });
  };

  const linkPlayer = (index, shouldLink) => () => {
    const similarPlayer = similarPlayers.splice(index, 1)[0];

    const targetMapping = {
      pbp: pbp[similarPlayer.target]?.batter,
      fielders: fielders[similarPlayer.target]?.player,
      opponentPbp: opponentPbp[similarPlayer.target]?.pitcher,
      opponentPbp_RCountOn: opponentPbp[similarPlayer.target]?.R_count_on_pitcher,
    };
    const targetPlayer = targetMapping[similarPlayer.from];

    let newUniqid = 'NONE';
    if (shouldLink) {
      newUniqid = originPlayers[similarPlayer.origin].uniqid;
    }
    filter((PA) => PA.batter.uniqid === '' && PA.batter.name === targetPlayer.name, pbp).forEach((PA) => {
      PA.batter.uniqid = newUniqid;
    });
    filter((fielder) => fielder.player.uniqid === '' && fielder.player.name === targetPlayer.name, fielders).forEach(
      (fielder) => {
        fielder.player.uniqid = newUniqid;
      },
    );
    filter((PA) => PA.pitcher.uniqid === '' && PA.pitcher.name === targetPlayer.name, opponentPbp).forEach((PA) => {
      PA.pitcher.uniqid = newUniqid;
    });
    filter(
      (PA) => PA.R_count_on_pitcher.uniqid === '' && PA.R_count_on_pitcher.name === targetPlayer.name,
      opponentPbp,
    ).forEach((PA) => {
      PA.R_count_on_pitcher.uniqid = newUniqid;
    });

    setPbp(pbp);
    setFielders(fielders);
    setOpponentPbp(opponentPbp);
    setSimilarPlayers([...similarPlayers]);
  };

  const onSubmit = (data) => {
    data.info.away_scores = awayScores;
    data.info.home_scores = homeScores;
    setOpenLoadingAlert(true);
    setLoadingStatus('LOADING');

    FirstBaseResource.importGame({
      ...data,
      pbp,
      fielders,
      opponent_pbp: opponentPbp,
      opponent_fielders: opponentFielders,
      tags: Object.values(tags),
      teamUniqid: team.uniqid,
    })
      .then(() => {
        setLoadingStatus('SUCCESS');
        setLoadingMessage('匯入成功！');
      })
      .catch((e) => {
        setLoadingStatus('ERROR');
        setLoadingMessage(e.toString());
      });
  };

  const handleLoadingAlertConfirm = () => {
    setOpenLoadingAlert(false);
    if (loadingStatus === 'SUCCESS') {
      completedImport();
    }
  };

  return (
    <Box>
      <Flex alignItems="center">
        <Button size="sm" variant="outline" leftIcon={<ChevronLeftIcon />} onClick={back}>
          取消
        </Button>
        <Text fontSize="lg">{`${team.name}(${team.full_name}) 賽事匯入`}</Text>
      </Flex>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} alignItems="start">
          <FormControl w="90%">
            <FormLabel>
              <Flex alignItems="center">
                對手名稱（需與表單內 球員名單 的球隊名稱相符）{requiredDisplay()}
                {errors.opponent_team_name && errorDisplay('對手名稱不得為空', false)}
              </Flex>
            </FormLabel>
            <Input size="sm" type="text" name="opponent_team_name" ref={register({ required: true })} />
          </FormControl>
          <SimpleGrid w="100%" columns="2">
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  開賽時間{requiredDisplay()}
                  {errors.started_at && errorDisplay('開賽時間為必填且格式需符合 yyyy/MM/dd hh:mm')}
                </Flex>
              </FormLabel>
              <Input
                type="text"
                name="started_at"
                ref={register({
                  required: true,
                  pattern: CommonUtils.dateTimeStringPattern,
                })}
                size="sm"
              />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  地點{requiredDisplay()}
                  {errors.info?.location_uniqid && errorDisplay('地點為必填', false)}
                </Flex>
              </FormLabel>
              <Select name="info.location_uniqid" placeholder="請選擇" ref={register()} size="sm">
                {locations.map((location) => (
                  <option key={location.uniqid} value={location.uniqid}>
                    {`${location.name}`}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  主客場{requiredDisplay()}
                  {errors.info?.side && errorDisplay('主客場為必填', false)}
                </Flex>
              </FormLabel>
              <Select name="info.side" placeholder="請選擇" ref={register({ required: true })} size="sm">
                <option value="AWAY">客場（先攻）</option>
                <option value="HOME">主場（後攻）</option>
              </Select>
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  總局數{requiredDisplay()}
                  {errors.info?.innings && errorDisplay('總局數格式不正確', false)}
                </Flex>
              </FormLabel>
              <Input
                size="sm"
                type="text"
                name="info.innings"
                ref={register({
                  required: true,
                  valueAsNumber: true,
                })}
              />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  客場各局比分（各局請以 , 區分）{requiredDisplay()}
                  {errors.info?.awayScores && errorDisplay('客場各局比分格式不正確', false)}
                </Flex>
              </FormLabel>
              <Input size="sm" type="text" name="info.awayScores" ref={register({ required: true })} />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  主場各局比分（各局請以 , 區分）{requiredDisplay()}
                  {errors.info?.homeScores && errorDisplay('主場各局比分格式不正確', false)}
                </Flex>
              </FormLabel>
              <Input size="sm" type="text" name="info.homeScores" ref={register({ required: true })} />
            </FormControl>
            <FormControl w="80%">
              <FormLabel>
                <Flex alignItems="center">
                  比賽結束出局數（通常是 3 ，除非是提前結束或裁定之類沒打完）{requiredDisplay()}
                  {errors.info?.end_outs && errorDisplay('比賽結束出局數格式不正確', false)}
                </Flex>
              </FormLabel>
              <Input
                size="sm"
                type="number"
                name="info.end_outs"
                ref={register({ required: true, valueAsNumber: true, min: 0, max: 3 })}
              />
            </FormControl>
          </SimpleGrid>
          <SimpleGrid w="100%" columns="5" spacing="5">
            <Flex>
              <Text>標籤</Text>
              <IconButton icon={<AddIcon />} size="sm" onClick={addTag} />
            </Flex>
            {Object.keys(tags).map((index) => {
              const tag = tags[index];
              return (
                <InputGroup size="sm" key={`${index}`}>
                  <Input type="text" value={tag.name} onChange={handleTagChange(index)} />
                  <InputRightElement>
                    <Button size="xs" onClick={removeTag(index)}>
                      &times;
                    </Button>
                  </InputRightElement>
                </InputGroup>
              );
            })}
          </SimpleGrid>
          {opponentTeamName && inningFrame && (
            <>
              <Text>
                逐打席表單上傳<small>{' >'}如出現錯誤後欲上傳相同檔名檔案，請點擊選擇檔案後按一次取消再重新選擇</small>
              </Text>
              {pbpError && errorDisplay(pbpError, true)}
              <PbPUpload
                targetTeamName={team.name}
                opponentTeamName={opponentTeamName}
                inningFrame={inningFrame}
                setPbp={setPbp}
                setFielders={setFielders}
                setOpponentPbp={setOpponentPbp}
                setOpponentFielders={setOpponentFielders}
              />
            </>
          )}
          {!ableToSubmit && (
            <Button colorScheme="blue" onClick={validationCheck}>
              檢查資料
            </Button>
          )}
          {similarPlayers.length > 0 && (
            <SimpleGrid w="100%" columns="3" spacing="5">
              <Text>相似球員確認{errorDisplay('請回答完再次檢查資料', true)}</Text>
              {similarPlayers.map((player, index) => (
                <Flex>
                  <Text>
                    {`${originPlayers[player.origin].name} #${originPlayers[player.origin].number} = ${
                      player.from === 'pbp' ? pbp[player.target].batter.name : opponentPbp[player.target].pitcher.name
                    } #${
                      player.from === 'pbp'
                        ? pbp[player.target].batter.number
                        : opponentPbp[player.target].pitcher.number
                    }`}
                  </Text>
                  <Button size="xs" onClick={linkPlayer(index, true)}>
                    O
                  </Button>
                  <Button size="xs" onClick={linkPlayer(index, false)}>
                    X
                  </Button>
                </Flex>
              ))}
            </SimpleGrid>
          )}
          {ableToSubmit && (
            <Flex>
              <Button onClick={() => setAbleToSubmit(false)} colorScheme="orange">
                取消
              </Button>
              <Button type="submit">確認送出</Button>
            </Flex>
          )}
        </VStack>
      </form>
      <LoadingAlertDialog
        isOpen={openLoadingAlert}
        status={loadingStatus}
        title={'匯入賽事'}
        message={loadingMessage}
        handleConfirm={handleLoadingAlertConfirm}
      />
    </Box>
  );
};

export default ImportGame;
