import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, VStack, Divider, Button, Text, Collapse, Select } from '@chakra-ui/react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { find, propEq } from 'ramda';
import CommonUtils from '~~utils/CommonUtils';
import { GameNode } from '~~components';
import { LocationResource } from '~~apis/resource';
import { GAME_STATUS_KEY, GAME_FINISHED_STATUS_KEY } from '../Consts';
import { useGameDetailState, useGameDetailDispatch } from '../hooks';
import Actions from '../Actions';

// let renderCount = 0;
const Info = ({ infoForm }) => {
  // renderCount++;
  // console.log(`Info ${renderCount}`);

  const { games, seasonGroups, gameNodes } = useGameDetailState();
  const gameDetailDispatch = useGameDetailDispatch();
  const [attemptHideInfo, setAttemptHideInfo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [locations, setLocations] = useState([]);

  const { register, errors, setValue, getValues, trigger, watch } = infoForm;

  const gameUniqid = watch('uniqid');
  const infoStatus = watch('status');

  useEffect(() => {
    LocationResource.getLocations().then((data) => {
      setLocations(data.data);
    });
  }, []);

  useEffect(() => {
    const { time } = getValues();
    let scheduled_start_at = '';
    if (time.scheduled_start_at) {
      scheduled_start_at = CommonUtils.objectifyDate(time.scheduled_start_at);
      scheduled_start_at = `${scheduled_start_at.year}/${scheduled_start_at.month}/${scheduled_start_at.day} ${scheduled_start_at.hour}:${scheduled_start_at.min}`;
    }

    let started_at = '';
    if (time.started_at) {
      started_at = CommonUtils.objectifyDate(time.started_at);
      started_at = `${started_at.year}/${started_at.month}/${started_at.day} ${started_at.hour}:${started_at.min}`;
    }

    let ended_at = '';
    if (time.ended_at) {
      ended_at = CommonUtils.objectifyDate(time.ended_at);
      ended_at = `${ended_at.year}/${ended_at.month}/${ended_at.day} ${ended_at.hour}:${ended_at.min}`;
    }
    setValue('time', {
      scheduled_start_at,
      started_at,
      ended_at,
    });
  }, [getValues, setValue]);

  useEffect(() => {
    if (!attemptHideInfo) {
      return;
    }

    setAttemptHideInfo(false);
    if (Object.keys(errors).length !== 0) {
      return;
    }

    setShowInfo(false);
  }, [attemptHideInfo, errors]);

  const collapseOnClick = () => {
    trigger();

    if (showInfo) {
      setAttemptHideInfo(true);
      return;
    }

    setShowInfo(true);
  };

  const addNode = (type) => () => {
    let reverseType = 'base';
    if (type === 'base') {
      reverseType = 'link';
    }

    gameNodes[type].push({ type: '', side: '', [reverseType]: { game_uniqid: '', game_seq: '' } });
    gameDetailDispatch({ type: Actions.UPDATE_GAME_NODES, payload: gameNodes });
  };

  const deleteNode = (type, index) => () => {
    gameNodes[type].splice(index, 1);
    gameDetailDispatch({ type: Actions.UPDATE_GAME_NODES, payload: gameNodes });
  };

  const handleNodeChange = (type, index) => (e) => {
    let reverseType = 'base';
    if (type === 'base') {
      reverseType = 'link';
    }

    const name = e.target.name;
    const value = e.target.value;
    const typeNodes = gameNodes[type];
    if (name === 'game') {
      const selectedGame = find(propEq('uniqid', value))(games);
      typeNodes[index][reverseType] = {
        game_uniqid: selectedGame.uniqid,
        game_seq: selectedGame.seq,
      };
    } else {
      typeNodes[index][name] = value;
    }
    gameDetailDispatch({ type: Actions.UPDATE_GAME_NODES, payload: gameNodes });
  };

  const errorDisplay = (message) => (
    <Text fontSize="xs" color="red.500">
      {message}
    </Text>
  );

  return (
    <Box>
      <Button pl="0" variant="ghost" onClick={collapseOnClick} _focus={{}}>
        <Text fontSize="xl">
          {showInfo ? <ChevronDownIcon /> : <ChevronRightIcon />}
          資訊欄
        </Text>
      </Button>
      <Divider borderColor="gray.400" my="2" />
      <Collapse in={showInfo}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor="game-info-group">賽程分類</FormLabel>
            {errors.group && errorDisplay('分類為必填')}
            <Select id="game-info-group" name="group" placeholder="請選擇" ref={register({ required: true })} size="sm">
              <option key="game-info-group-none" value="NONE">
                不分類
              </option>
              {seasonGroups.map((group) => (
                <option key={group.name} value={group.name}>
                  {group.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="game-info-status">狀態</FormLabel>
            {errors.status && errorDisplay('狀態為必填')}
            <Select
              id="game-info-status"
              name="status"
              placeholder="請選擇"
              ref={register({ required: true })}
              size="sm"
            >
              {Object.keys(GAME_STATUS_KEY).map((key) => (
                <option key={key} value={key}>
                  {GAME_STATUS_KEY[key]}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="game-info-finished-status">結束狀態（完賽更動方有效）</FormLabel>
            <Select id="game-info-finished-status" name="finished_status" ref={register()} size="sm">
              {Object.keys(GAME_FINISHED_STATUS_KEY).map((key) => (
                <option key={key} value={key}>
                  {GAME_FINISHED_STATUS_KEY[key]}
                </option>
              ))}
            </Select>
          </FormControl>
          {locations.length > 0 && (
            <FormControl>
              <FormLabel htmlFor="game-info-location">地點</FormLabel>
              <Select id="game-info-location" name="location_uniqid" placeholder="請選擇" ref={register()} size="sm">
                {locations.map((location) => (
                  <option key={location.uniqid} value={location.uniqid}>
                    {`${location.name}`}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl isRequired>
            <FormLabel htmlFor="game-info-scheduled-start-at">預計開始時間</FormLabel>
            {errors.time?.scheduled_start_at && errorDisplay('預計開始時間為必填且格式需符合 yyyy/MM/dd hh:mm')}
            <Input
              placeholder="預計開始時間"
              type="text"
              name="time.scheduled_start_at"
              ref={register({
                required: true,
                pattern: CommonUtils.dateTimeStringPattern,
              })}
              size="sm"
            />
          </FormControl>
          <FormControl isRequired>
            <SimpleGrid columns={2} spacing={2}>
              {infoStatus !== 'SCHEDULED' && (
                <Box>
                  <FormLabel htmlFor="game-info-started-at">實際開始時間</FormLabel>
                  {errors.time?.started_at && errorDisplay('實際開始時間為必填且格式需符合 yyyy/MM/dd hh:mm')}
                  <Input
                    id="game-info-started-at"
                    placeholder="實際開始時間"
                    type="text"
                    name="time.started_at"
                    ref={register({
                      required: true,
                      pattern: CommonUtils.dateTimeStringPattern,
                    })}
                    size="sm"
                  />
                </Box>
              )}
              {infoStatus === 'FINISHED' && (
                <Box>
                  <FormLabel htmlFor="game-info-ended-at">實際結束時間</FormLabel>
                  {errors.time?.ended_at && errorDisplay('實際結束時間為必填且格式需符合 yyyy/MM/dd hh:mm')}
                  <Input
                    id="game-info-ended-at"
                    placeholder="實際結束時間"
                    type="text"
                    name="time.ended_at"
                    ref={register({
                      required: true,
                      pattern: CommonUtils.dateTimeStringPattern,
                    })}
                    size="sm"
                  />
                </Box>
              )}
            </SimpleGrid>
          </FormControl>
          <GameNode
            gameUniqid={gameUniqid}
            nodes={gameNodes}
            games={games}
            handleNodeChange={handleNodeChange}
            deleteNode={deleteNode}
            addNode={addNode}
          />
        </VStack>
      </Collapse>
      <Input
        name="uniqid"
        ref={register({
          required: true,
        })}
        display="none"
      />
    </Box>
  );
};

export default Info;
